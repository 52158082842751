export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
  Upload: any;
};

export type ABadge = {
  __typename?: 'ABadge';
  /** 배지의 내부 아이디 */
  aBadgeId: Scalars['Int'];
  /** 개발자가 확장하여 사용할 수 있는 임의의 string data */
  customData: Scalars['String'];
  /** 배지 설명 */
  description: Scalars['String'];
  /** 배지 획득시 배지 이름 (외부 공개용) */
  display: Scalars['String'];
  /** 배지 미획득시 배지 이름 (외부 공개용) */
  displayLocked: Scalars['String'];
  /** 배지 미획득시에는 사용자에게 공개하지 않을 것인가? */
  hideBeforeAcquire: Scalars['Boolean'];
  /** 배지 이미지 링크 */
  imageLink?: Maybe<Scalars['String']>;
  /** 획득 전 배지 이미지 링크 */
  imageLockedLink?: Maybe<Scalars['String']>;
  /** 배지가 현재 사용중지 상태인가? */
  isPaused: Scalars['Boolean'];
  /** 배지 이벤트 이름 */
  name: Scalars['String'];
  /** 정렬 우선순위 */
  sortOrder: Scalars['Int'];
};

export type ABadgeProgress = {
  __typename?: 'ABadgeProgress';
  /** 현재 달성 횟수 */
  current: Scalars['Int'];
  /** 달성해야 하는 횟수 */
  expected: Scalars['Int'];
};

export type ABadgeWithAcquisition = {
  __typename?: 'ABadgeWithAcquisition';
  /** 배지 정보 */
  badge: ABadgeWithLastAcquisition;
  /** 배지 달성 진행상태 */
  progress: ABadgeProgress;
};

export type ABadgeWithLastAcquisition = {
  __typename?: 'ABadgeWithLastAcquisition';
  /** 배지의 아이디 */
  aBadgeId: Scalars['Int'];
  /** 획득한 적이 있는가? */
  acquired: Scalars['Boolean'];
  /** 최초 획득 시각 */
  acquiredAt?: Maybe<Scalars['DateTime']>;
  /** 개발자가 입력한 커스텀 데이터 (배지별) */
  customData: Scalars['String'];
  /** 배지 설명 (관리자 입력값) */
  description: Scalars['String'];
  /** 획득 후 배지 이름 */
  display: Scalars['String'];
  /** 획득 전 배지 이름 */
  displayLocked: Scalars['String'];
  /** 배지 이미지 링크 */
  imageLink?: Maybe<Scalars['String']>;
  /** 획득 전 배지 이미지 링크 */
  imageLockedLink?: Maybe<Scalars['String']>;
  /** 배지의 내부 이름 */
  name: Scalars['String'];
};

/** 어드민이 수행하는 액션 중 기록할 것들 */
export enum Admin_Action_Type {
  AddCoin = 'AddCoin',
  CreateUser = 'CreateUser',
  DeactivateUser = 'DeactivateUser',
  UpdateUser = 'UpdateUser',
  ViewUser = 'ViewUser'
}

export type AEventCreationResponse = {
  __typename?: 'AEventCreationResponse';
  /** 업적 획득 내역 */
  badgesAcquired?: Maybe<Array<ABadgeWithAcquisition>>;
  /** 오류 발생시 내용 */
  error?: Maybe<Scalars['String']>;
  /** 성공 여부 */
  success: Scalars['Boolean'];
};

export type AEventDef = {
  __typename?: 'AEventDef';
  /** 아이디 */
  aEventDefId: Scalars['Int'];
  /** 이 이벤트가 연동된 업적 */
  badges: Array<ABadge>;
  /** 이벤트에 대한 설명 */
  description: Scalars['String'];
  /** 이 이벤트가 업적에 해당하는가? */
  isAchievement: Scalars['Boolean'];
  /** 내부에서 활용하는 이벤트의 이름 */
  name: Scalars['String'];
};

/** AI 요청 상태 */
export enum AiRequestStatus {
  Completed = 'Completed',
  Error = 'Error',
  Failed = 'Failed',
  Progress = 'Progress'
}

/** 유저가 업로드한 이미지파일 */
export type AiUserUploadFile = {
  __typename?: 'AIUserUploadFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 앱 이벤트 타입 */
export enum App_Event_Type {
  Invalid = 'Invalid',
  LeaveChatroom = 'LeaveChatroom'
}

/** acceptTerms의 input */
export type AcceptTermsInput = {
  /** 개인정보 수집 및 이용 아이디 */
  collectionAndUseOfPrivacyId: Scalars['Int'];
  /** 서비스 이용약관 아이디 */
  tosId: Scalars['Int'];
};

/** acceptTerms의 input */
export type AcceptTermsInputV2 = {
  /** 개인정보 수집 및 이용 아이디. 한국에서는 필수, 그 외 국가에서는 필요 없음 */
  collectionAndUseOfPrivacyId?: InputMaybe<Scalars['Int']>;
  /** 개인정보 처리방침 아이디. 한국 외 국가에서는 필수, 한국에서는 필요 없음 */
  ppId?: InputMaybe<Scalars['Int']>;
  /** 어떤 서비스의 약관인가? 기본값은 StoryPlay */
  serviceName?: InputMaybe<Terms_Service_Name>;
  /** 서비스 이용약관 아이디 */
  tosId: Scalars['Int'];
};

/** 업적 정보 output */
export type AchievementOutPut = {
  __typename?: 'AchievementOutPut';
  /** 업적 업데이트 정보 있을시, 업적 획득 내역 */
  badgesAcquired?: Maybe<Array<ABadgeWithAcquisition>>;
  /** 업적 업데이트 정보 있을시, 업적 오류 발생시 내용 */
  error?: Maybe<Scalars['String']>;
  /** 업적 업데이트 정보 있을시, 업적 성공 여부 */
  success?: Maybe<Scalars['Boolean']>;
};

/** 미디에이션 광고 플랫폼의 활성화 상태를 관리. 지금은 애드팝콘만 사용하고, iOS, Android 통합으로 관리 */
export type AdNetworkActivation = {
  __typename?: 'AdNetworkActivation';
  /** 광고 아이디 */
  adId: Scalars['String'];
  /** 아이디 */
  adNetworkActivationId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 광고 아이디에 대한 설명 */
  description?: Maybe<Scalars['String']>;
  /** 광고 노출 여부. true면 앱에 광고 노출 */
  isShow: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

/** AdNetworkActivation 생성을 위한 input */
export type AdNetworkActivationCreationInput = {
  /** 광고 아이디 */
  adId: Scalars['String'];
  /** 광고 아이디에 대한 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 광고 노출 여부. true면 앱에 광고 노출 */
  isShow: Scalars['Boolean'];
};

/** AdNetworkActivation 업데이트를 위한 input */
export type AdNetworkActivationUpdateInput = {
  /** 광고 아이디 */
  adId?: InputMaybe<Scalars['String']>;
  /** 광고 아이디에 대한 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 광고 노출 여부. true면 앱에 광고 노출 */
  isShow?: InputMaybe<Scalars['Boolean']>;
};

/** 코드로 티켓 충전 시 사용하는 입력값 */
export type AddBonusTicketInput = {
  /** 보너스 티켓 코드 */
  code: Scalars['String'];
};

export type AddBonusTicketOutput = {
  __typename?: 'AddBonusTicketOutput';
  /** 보너스 티켓 충전 결과 */
  ticketAmount: Scalars['Int'];
};

/** 코드로 지정된 유저의 티켓 충전 시 사용하는 입력값 */
export type AddBonusTicketToUserInput = {
  /** 보너스 티켓 코드 */
  code: Scalars['String'];
  /** 충전 대상 유저 */
  userId?: InputMaybe<Scalars['Int']>;
};

/** 관리자에 의해 사용자 코인추가시 사용할 입력값 */
export type AddCoinInput = {
  /** 추가할 양 (음수면 회수) */
  amount: Scalars['Int'];
  /** 충전 이유 */
  reason?: InputMaybe<Scalars['String']>;
  /** userId */
  userId: Scalars['Int'];
};

/** UserHasFCMToken 생성을 위한 input */
export type AddFcmTokenInput = {
  /** 모바일 기기의 OS 종류 */
  os: Os;
  /** 앱으로 부터 수집한 FCM 토큰 */
  token: Scalars['String'];
};

export type AddStorygameToSectionInput = {
  /** 스토리게임이 추가된 섹션의 아이디 */
  sectionId: Scalars['Int'];
  /** 추가된 스토리게임의 아이디 */
  storyId: Scalars['Int'];
};

/** 어드민 활동 로그 */
export type AdminLog = {
  __typename?: 'AdminLog';
  /** 어드민 접속 IP */
  accessIp?: Maybe<Scalars['String']>;
  /** 수행한 액션 (ADMIN_ACTION_TYPE) */
  action: Admin_Action_Type;
  /** 아이디 */
  adminLogId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 추가 정보 (있는 경우) */
  data?: Maybe<Scalars['String']>;
  /** 특정 사용자에 대한 액션인 경우 사용자 */
  targetUser?: Maybe<User>;
  /** 특정 사용자에 대한 액션인 경우 사용자의 ID */
  targetUserId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  /** 액션을 취한 사용자 */
  user: User;
  /** 액션을 취한 사용자의 아이디 */
  userId: Scalars['Int'];
};

/** 모든 종류의 약관을 관리 */
export type AllTypeTerms = {
  __typename?: 'AllTypeTerms';
  /** 개인정보 수집 및 이용 */
  CollectionAndUseOfPrivacy: Terms;
  /** 개인정보 처리방침 */
  PP: Terms;
  /** 서비스 이용 약관 */
  TOS: Terms;
  /** true이면 회원가입 시 보여주는 레거시 약관(개인정보 위탁제공, 안드로이드 UGC 정책)을 숨긴다 */
  hideLegacyTerms: Scalars['Boolean'];
};

/** 기본적인 서비스 설정 */
export type AppConfig = {
  __typename?: 'AppConfig';
  /** aOS 기능 활성화 여부 (JSON string. key: 기능명, value: true면 활성) */
  aOSFeatureFlag?: Maybe<Scalars['String']>;
  /** 안드로이드 앱 최소 버전 */
  aOSVersion: Scalars['Int'];
  /** 광고 네트워크 활성화 여부 (JSON string. { "광고 ID": boolean }) */
  adNetworkActivationFlag: Scalars['String'];
  configId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 버전업 내용 설명 */
  description?: Maybe<Scalars['String']>;
  /** iOS 기능 활성화 여부 (JSON string. key: 기능명, value: true면 활성) */
  iOSFeatureFlag?: Maybe<Scalars['String']>;
  /** iOS 앱 최소 버전 */
  iOSVersion: Scalars['Int'];
  /** 앱의 도전스토리 탭 하위 배너 정보 json 포맷 string (IRawContestBannerInfo) */
  rawContestBannerInfo?: Maybe<Scalars['String']>;
  /**
   * (deprecated) 지원하는 시스템 언어 리스트
   * @deprecated 글로벌 적용으로 systemLanguages로 대체 되었습니다.
   */
  sysLangs: Array<SystemLanguage>;
  /** 지원하는 시스템 언어 리스트 */
  systemLanguages: Array<Language>;
  updatedAt: Scalars['DateTime'];
};

/** AppConfig 업데이트 (실제로는 생성됨) 위한 입력값 */
export type AppConfigUpdateInput = {
  /** aOS 기능 활성화 여부 (JSON string. key: 기능명, value: true면 활성) */
  aOSFeatureFlag?: InputMaybe<Scalars['String']>;
  /** 안드로이드 앱 최소 버전 */
  aOSVersion?: InputMaybe<Scalars['Int']>;
  /** 버전업 내용 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** iOS 기능 활성화 여부 (JSON string. key: 기능명, value: true면 활성) */
  iOSFeatureFlag?: InputMaybe<Scalars['String']>;
  /** iOS 앱 최소 버전 */
  iOSVersion?: InputMaybe<Scalars['Int']>;
  /** 앱의 도전스토리 탭 하위 배너 정보 json 포맷 string (IRawContestBannerInfo) */
  rawContestBannerInfo?: InputMaybe<Scalars['String']>;
};

/** 앱 이벤트를 저장하기 위한 엔티티 */
export type AppEvent = {
  __typename?: 'AppEvent';
  /** 아이디 */
  appEventId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** rawParam을 type에 맞게 가공한 데이터 (JSON object) */
  processedParam?: Maybe<Scalars['String']>;
  /** 앱 이벤트의 raw 파라미터 (JSON object) */
  rawParam?: Maybe<Scalars['String']>;
  /** 앱 이벤트 종류 */
  type: App_Event_Type;
  updatedAt: Scalars['DateTime'];
  /** 이벤트를 발생시킨 유저 */
  user: User;
  /** 유저의 아이디 */
  userId: Scalars['Int'];
};

/** 앱 스플래쉬 이미지를 관리하는 엔티티 */
export type AppSplashImage = {
  __typename?: 'AppSplashImage';
  /** 아이디 */
  appSplashImageId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 앱 스플래쉬 이미지 */
  imageFile: ImageFile;
  /** 앱 스플래쉬 이미지 아이디 */
  imageFileId: Scalars['Int'];
  /** 해당 앱 스플래쉬 이미지 숨김 여부 (true: 숨김) */
  isHidden: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

/** AppSplashImage 생성을 위한 input */
export type AppSplashImageCreationInput = {
  /** 앱 스플래쉬 이미지 */
  imageFile: Scalars['Upload'];
  /** 해당 앱 스플래쉬 이미지 숨김 여부 (true: 숨김) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
};

/** SplashImage 업데이트를 위한 input */
export type AppSplashImageUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 앱 스플래쉬 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 해당 앱 스플래쉬 이미지 숨김 여부 (true: 숨김) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
};

/** ChapterDraft 항목을 적용할 때 필요한 데이터 */
export type ApplyChapterDraftInput = {
  /** 실제 챕터 script */
  chapterScript: Scalars['String'];
  /** 적용할 ChapterDraft 의 id */
  id: Scalars['Int'];
};

/** [_관리_] 시트를 작품에 적용하기 위한 입력값 */
export type ApplyMgmtSheetToStoryInput = {
  /** JSON 으로 인코딩된 MetaDataUpdateAction[] */
  jsonEncodedMetaUpdateActions: Scalars['String'];
  /** 작품 번호 */
  storyId: Scalars['Int'];
};

/** 웹소설인 ChapterDraft 항목을 적용할 때 필요한 데이터 */
export type ApplyNovelChapterDraftInput = {
  /** 웹소설 텍스트 컨텐츠 */
  chapterScript: Scalars['String'];
  /** 적용할 ChapterDraft 의 id */
  id: Scalars['Int'];
};

/** 섹션 내 작품에 대한 appu 관련 데이터를 관리하는 엔티티 */
export type Appu = {
  __typename?: 'Appu';
  /** 계산 된 appu */
  appu: Scalars['Int'];
  /** 아이디 */
  appuId: Scalars['Int'];
  /** appu 마지막 업데이트 시각 */
  appuLastUpdatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  /** 특정 섹션을 통해 작품이 플레이 된 횟수 */
  numPlay: Scalars['Int'];
  /** 작품 */
  storygame: Story;
  /** 작품 아이디 */
  storygameId: Scalars['Int'];
  /** 섹션 */
  storygameSection: StorygameSection;
  /** 섹션 아이디 */
  storygameSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 출석체크를 통해 받을 수 있는 코인 */
export type AttendanceCoin = {
  __typename?: 'AttendanceCoin';
  /** 보상 코인 개수 */
  amount: Scalars['Int'];
  /** 아이디 */
  attendanceCoinId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 숨김 여부 */
  isHidden: Scalars['Int'];
  /** 언어코드. 현재는 국가를 대신함 (ko=한국, en=글로벌) */
  langCode: Scalars['String'];
  /** 하루에 받을 수 있는 보상 횟수 */
  numDailyReward: Scalars['Int'];
  /** 유저가 하루동안 받은 보상 횟수 */
  numRewarded: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** AttendanceCoin 생성을 위한 input */
export type AttendanceCoinCreationInput = {
  /** 보상 코인 개수 */
  amount: Scalars['Int'];
  /** 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** 언어코드. 현재는 국가를 대신함 (ko=한국, en=글로벌) */
  langCode?: InputMaybe<Scalars['String']>;
  /** 하루에 받을 수 있는 보상 횟수 */
  numDailyReward: Scalars['Int'];
};

/** AttendanceCoin 업데이트를 위한 input */
export type AttendanceCoinUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 배너 링크타입 */
export enum Banner_Link_Type {
  AppExternalBrowserLink = 'AppExternalBrowserLink',
  AppInappBrowserLink = 'AppInappBrowserLink',
  DeepLink = 'DeepLink',
  WebCurrentWindowLink = 'WebCurrentWindowLink',
  WebNewWindowLink = 'WebNewWindowLink'
}

/** 배너가 노출될 위치 */
export enum Banner_Location {
  Epub = 'EPUB',
  Home = 'Home',
  Interactive = 'Interactive'
}

/** 배너 노출 상태 */
export enum Banner_State {
  Error = 'Error',
  Off = 'Off',
  On = 'On',
  Reserved = 'Reserved'
}

/** 배너 타입(앱) */
export enum Banner_Type {
  Custom = 'Custom',
  Story = 'Story'
}

/** 사용자 계정정지에 필요한 입력값 */
export type BanWriterForStudioInput = {
  /** 계정 정지 사유 */
  banReason: Scalars['String'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 배너를 관리하는 entity */
export type Banner = {
  __typename?: 'Banner';
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: Maybe<Scalars['String']>;
  /** 아이디 */
  bannerId: Scalars['Int'];
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: Maybe<File>;
  /** 배너 이미지 파일의 아이디 */
  bannerImageFileId?: Maybe<Scalars['Int']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: Maybe<Scalars['String']>;
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow: Scalars['Boolean'];
  /** 언어코드. 현재는 국가를 대신함 (ko=한국, en=글로벌) */
  langCode: Scalars['String'];
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: Maybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: Maybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location: Banner_Location;
  /** 노출 상태(On, Off, Preserved) */
  state: Banner_State;
  /** 배너에 연결된 작품.(type이 Custom일 때만 유효함) */
  story?: Maybe<Story>;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: Maybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient: Client;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: Maybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type: Banner_Type;
  updatedAt: Scalars['DateTime'];
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: Maybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt: Scalars['DateTime'];
};

/** Banner input */
export type BannerCommonInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow?: InputMaybe<Scalars['Boolean']>;
  /** 언어코드. 현재는 국가를 대신함 (ko=한국, en=글로벌) */
  langCode?: InputMaybe<Scalars['String']>;
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: InputMaybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: InputMaybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location?: InputMaybe<Banner_Location>;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient?: InputMaybe<Client>;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: InputMaybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type?: InputMaybe<Banner_Type>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** Banner 생성을 위한 input */
export type BannerCreationInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow: Scalars['Boolean'];
  /** 언어코드. 현재는 국가를 대신함 (ko=한국, en=글로벌) */
  langCode: Scalars['String'];
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: InputMaybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: InputMaybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location: Banner_Location;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient: Client;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: InputMaybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type?: InputMaybe<Banner_Type>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** 배너 이미지를 저장할 테이블 */
export type BannerImageFile = {
  __typename?: 'BannerImageFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Banner 업데이트를 위한 input */
export type BannerUpdateInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow?: InputMaybe<Scalars['Boolean']>;
  /** 언어코드. 현재는 국가를 대신함 (ko=한국, en=글로벌) */
  langCode?: InputMaybe<Scalars['String']>;
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: InputMaybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: InputMaybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location?: InputMaybe<Banner_Location>;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient?: InputMaybe<Client>;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: InputMaybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type?: InputMaybe<Banner_Type>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlockBounceStatOfChapter = {
  __typename?: 'BlockBounceStatOfChapter';
  /** android 이탈율 */
  aos: Scalars['Float'];
  /** 블럭이름 */
  blockName: Scalars['String'];
  /** ios 이탈율 */
  ios: Scalars['Float'];
  /** 총 이탈율 */
  rate: Scalars['Float'];
  /** 총 이탈수 */
  total: Scalars['Int'];
};

/** 블록의 이탈행 통계정보 */
export type BlockLineBounceStatCacheData = {
  __typename?: 'BlockLineBounceStatCacheData';
  /** Android 이탈수 */
  aos: Scalars['Int'];
  /** iOS + Android 이탈수 */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐시 날짜 */
  date: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** iOS 이탈수 */
  ios: Scalars['Int'];
  /** 스크립트 내 고유 번호 (sourceLine) */
  line: Scalars['Int'];
  /** 블록 이름 */
  name: Scalars['String'];
  /** 총 진입 횟수 */
  total: Scalars['Int'];
  /** 일간, 주간, 월간 */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
  /** 스크립트 버전 */
  version: Scalars['Int'];
};

export type BlockLineBounceStatOfChapter = {
  __typename?: 'BlockLineBounceStatOfChapter';
  /** android 이탈율 */
  aos: Scalars['Float'];
  /** ios 이탈율 */
  ios: Scalars['Float'];
  /** 고유 라인번호 */
  line: Scalars['Int'];
  /** 총 이탈율 */
  rate: Scalars['Float'];
  /** 총 이탈수 */
  total: Scalars['Int'];
};

/** 보너스 티켓 발급 히스토리 */
export type BonusTicketClaim = {
  __typename?: 'BonusTicketClaim';
  /** 발급 받은 티켓 코드 */
  bonusTicketCode: BonusTicketCode;
  /** 발급 받은 티켓 코드 ID */
  bonusTicketCodeId: Scalars['Int'];
  /** 아이디 */
  claimId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 발급 받은 티켓 장수 */
  ticketAmount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 티켓을 발급 받은 유저 */
  user: User;
  /** 티켓을 발급 받은 유저의 ID */
  userId: Scalars['Int'];
};

/** 보너스 티켓 코드 */
export type BonusTicketCode = {
  __typename?: 'BonusTicketCode';
  /** 아이디 */
  btcId: Scalars['Int'];
  /** 티켓 코드 */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 티켓 코드에 대한 설명 */
  desc: Scalars['String'];
  /** 코드가 비활성화 된 시각. null이면 활성화 된 코드 */
  inactivatedAt?: Maybe<Scalars['DateTime']>;
  /** 코드를 생성한 유저 */
  issuedUser: User;
  /** 코드를 생성한 유저의 ID */
  issuedUserId: Scalars['Int'];
  /** 코드의 재충전 가능한 횟수. 선착순 지급 기능으로 활용 */
  numOfReuses?: Maybe<Scalars['Int']>;
  /** 남은 티켓 재충전 횟수. 생성시에는 numOfReuses와 같은 값을 가짐 */
  remainingReuses?: Maybe<Scalars['Int']>;
  /** 코드를 입력했을 때 충전되는 티켓 수 */
  ticketAmount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 코드 활성화가 되는 시각 */
  willActivateAt?: Maybe<Scalars['DateTime']>;
  /** 코드 만료 시각 */
  willExpireAt?: Maybe<Scalars['DateTime']>;
};

/** 보너스 티켓 코드를 생성하기 위한 입력값 */
export type BonusTicketCodeCreationInput = {
  /** 티켓 코드 */
  code: Scalars['String'];
  /** 티켓 코드에 대한 설명 */
  desc: Scalars['String'];
  /** 코드가 비활성화 된 시각. null이면 활성화 된 코드 */
  inactivatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 코드의 재충전 가능한 횟수. 선착순 지급 기능으로 활용 */
  numOfReuses?: InputMaybe<Scalars['Int']>;
  /** 코드를 입력했을 때 충전되는 티켓 수 */
  ticketAmount: Scalars['Int'];
  /** 코드 활성화가 되는 시각 */
  willActivateAt?: InputMaybe<Scalars['DateTime']>;
  /** 코드 만료 시각 */
  willExpireAt?: InputMaybe<Scalars['DateTime']>;
};

/** 보너스 티켓 코드를 수정하기 위한 입력값 */
export type BonusTicketCodeUpdateInput = {
  /** 티켓 코드 */
  code?: InputMaybe<Scalars['String']>;
  /** 티켓 코드에 대한 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 코드가 비활성화 된 시각. null이면 활성화 된 코드 */
  inactivatedAt?: InputMaybe<Scalars['DateTime']>;
  /** 코드의 재충전 가능한 횟수. 선착순 지급 기능으로 활용 */
  numOfReuses?: InputMaybe<Scalars['Int']>;
  /** 남은 티켓 재충전 횟수. 생성시에는 numOfReuses와 같은 값을 가짐 */
  remainingReuses?: InputMaybe<Scalars['Int']>;
  /** 코드를 입력했을 때 충전되는 티켓 수 */
  ticketAmount?: InputMaybe<Scalars['Int']>;
  /** 코드 활성화가 되는 시각 */
  willActivateAt?: InputMaybe<Scalars['DateTime']>;
  /** 코드 만료 시각 */
  willExpireAt?: InputMaybe<Scalars['DateTime']>;
};

/** 하단 띠 배너 관리 테이블 */
export type BottomBanner = {
  __typename?: 'BottomBanner';
  /** 아이디 */
  bottomBannerId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow: Scalars['Boolean'];
  /** 노출 상태(On, Off, Preserved) */
  state: Banner_State;
  /** 굵은 글씨 처리할 텍스트 */
  styleTitle?: Maybe<Scalars['String']>;
  /** 배너타이틀 */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 누르면 이동할 웹링크 */
  webLink: Scalars['String'];
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: Maybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt: Scalars['DateTime'];
};

/** BottomBanner 생성을 위한 input */
export type BottomBannerCreationInput = {
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow: Scalars['Boolean'];
  /** 굵은 글씨 처리할 텍스트 */
  styleTitle?: InputMaybe<Scalars['String']>;
  /** 배너타이틀 */
  title: Scalars['String'];
  /** 누르면 이동할 웹링크 */
  webLink: Scalars['String'];
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** BottomBanner 업데이트를 위한 input */
export type BottomBannerUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow?: InputMaybe<Scalars['Boolean']>;
  /** 굵은 글씨 처리할 텍스트 */
  styleTitle?: InputMaybe<Scalars['String']>;
  /** 배너타이틀 */
  title?: InputMaybe<Scalars['String']>;
  /** 누르면 이동할 웹링크 */
  webLink?: InputMaybe<Scalars['String']>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** 배너를 관리하는 entity */
export type BundleBanner = {
  __typename?: 'BundleBanner';
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: Maybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: Maybe<File>;
  /** 배너 이미지 파일의 아이디 */
  bannerImageFileId?: Maybe<Scalars['Int']>;
  /** 배너로 설정된 스토리 번들 */
  bundle: StoryBundle;
  /** 아이디 */
  bundleBannerId: Scalars['Int'];
  /** 배너로 설정된 스토리 번들 아이디 */
  bundleId?: Maybe<Scalars['Int']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: Maybe<Scalars['String']>;
  /** 노출될 국가 */
  country: Country;
  /** 노출될 국가코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 어드민에서 표시되어야 할 언어코드. 작품 타입은 해당 작품의 번들에서 제공하는 언어들을, 커스텀 타입은 설정된 언어를 보여줌 */
  displayLanguages: Scalars['String'];
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: Maybe<Scalars['String']>;
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow: Scalars['Boolean'];
  /** 언어코드 정보 */
  language?: Maybe<Language>;
  /** 언어코드(직접 등록 일때만 유효함) */
  languageCode?: Maybe<Scalars['String']>;
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: Maybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: Maybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location: Banner_Location;
  /** 노출 상태(On, Off, Preserved) */
  state: Banner_State;
  /** 배너에 연결된 작품.(type이 Custom일 때만 유효함) */
  story?: Maybe<Story>;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: Maybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient: Client;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: Maybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type: Banner_Type;
  updatedAt: Scalars['DateTime'];
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: Maybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt: Scalars['DateTime'];
};

/** BundleBanner input */
export type BundleBannerCommonInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: InputMaybe<Scalars['String']>;
  /** 노출될 국가코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow?: InputMaybe<Scalars['Boolean']>;
  /** 언어코드(직접 등록 일때만 유효함) */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: InputMaybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: InputMaybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location?: InputMaybe<Banner_Location>;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient?: InputMaybe<Client>;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: InputMaybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type?: InputMaybe<Banner_Type>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** BundleBanner 생성을 위한 input */
export type BundleBannerCreationInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: InputMaybe<Scalars['String']>;
  /** 노출될 국가코드 */
  countryCode: Scalars['String'];
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow: Scalars['Boolean'];
  /** 언어코드(직접 등록 일때만 유효함) */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: InputMaybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: InputMaybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location: Banner_Location;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient: Client;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: InputMaybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type?: InputMaybe<Banner_Type>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** BundleBanner 업데이트를 위한 input */
export type BundleBannerUpdateInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 배너 이미지 파일. null이면 story.introImage, story.mainImage 차례로 null이 아닌 것을 리턴함 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 배너 본문(type이 Custom일 때만 유효함) */
  content?: InputMaybe<Scalars['String']>;
  /** 노출될 국가코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 화면 표시 여부. 1: 표시, 0: 숨김 */
  isShow?: InputMaybe<Scalars['Boolean']>;
  /** 언어코드(직접 등록 일때만 유효함) */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 배너를 눌렀을 때 연결되는 링크(type이 Custom일 때만 유효함) */
  link?: InputMaybe<Scalars['String']>;
  /** 링크 타입. targetClient가 앱이면 DeepLink, AppInappBrowserLink, AppExternalBrowserLink을 가지고 웹이면 DeepLink, WebCurrentWindowLink, WebNewWindowLink를 가질 수 있음 */
  linkType?: InputMaybe<Banner_Link_Type>;
  /** 배너 위치 (홈 탭, 인터랙티브 탭, 웹소설 탭) */
  location?: InputMaybe<Banner_Location>;
  /** 배너에 연결된 작품의 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 노출 될 클라이언트(App, Web) */
  targetClient?: InputMaybe<Client>;
  /** 배너 타이틀(type이 Custom일 때만 유효함) */
  title?: InputMaybe<Scalars['String']>;
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  type?: InputMaybe<Banner_Type>;
  /** 배너 노출 종료 시각(null 이면 계속 유지) */
  willCloseAt?: InputMaybe<Scalars['DateTime']>;
  /** 배너 노출 시작 시각 */
  willOpenAt?: InputMaybe<Scalars['DateTime']>;
};

/** 번들 피쳐드를 관리하기 위한 엔티티 */
export type BundleFeatured = {
  __typename?: 'BundleFeatured';
  /** 피쳐드로 등록된 번들 */
  bundle: StoryBundle;
  /** 아이디 */
  bundleFeaturedId: Scalars['Int'];
  /** 피쳐드로 등록된 번들 아이디 */
  bundleId?: Maybe<Scalars['Int']>;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  /** 커버 이미지 */
  coverImageFile?: Maybe<ImageFile>;
  /** 커버 이미지 아이디 */
  coverImageFileId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 숨김 여부. true면 숨김 */
  isHidden: Scalars['Boolean'];
  /** 매칭 조건이 되는 시스템 언어 */
  language: Language;
  /** 매칭 조건이 되는 시스템 언어 코드 */
  languageCode: Scalars['String'];
  /** 피쳐드를 눌렀을 때 연결되는 web or app link */
  link?: Maybe<Scalars['String']>;
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** BundleFeatured 생성을 위한 input */
export type BundleFeaturedCreationInput = {
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  /** 커버 이미지 */
  coverImageFile: Scalars['Upload'];
  /** 숨김 여부. true면 숨김 */
  isHidden: Scalars['Boolean'];
  /** 매칭 조건이 되는 시스템 언어 코드 */
  languageCode: Scalars['String'];
  /** 피쳐드를 눌렀을 때 연결되는 web or app link */
  link?: InputMaybe<Scalars['String']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** BundleFeatured 업데이트를 위한 input */
export type BundleFeaturedUpdateInput = {
  /** 대상 국가 코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 커버 이미지 */
  coverImageFile?: InputMaybe<Scalars['Upload']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 숨김 여부. true면 숨김 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 매칭 조건이 되는 시스템 언어 코드 */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 피쳐드를 눌렀을 때 연결되는 web or app link */
  link?: InputMaybe<Scalars['String']>;
  /** 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 작품에 대한 전체 구매 */
export type BuyAllRemainingChaptersInput = {
  /** 어떤 작품에 대한 구매인가? */
  storyId: Scalars['Int'];
};

/** 작품에 대한 전체 구매 */
export type BuyRemainingChaptersInput = {
  /** 얼마를 사용하는 것으로 알고 있는가? (현재 상황과 미스매치하는 경우 오류) */
  coinToUse: Scalars['Int'];
  /** 어떤 작품에 대한 구매인가? */
  storyId: Scalars['Int'];
};

/** 스토리 장르 */
export enum Challenge_Story_Genre {
  BlGl = 'BL_GL',
  ContestWinner = 'CONTEST_WINNER',
  Etc = 'ETC',
  Fantasy = 'FANTASY',
  FanFiction = 'FAN_FICTION',
  ModernFantasy = 'MODERN_FANTASY',
  MysteryPlay = 'MYSTERY_PLAY',
  PeriodDrama = 'PERIOD_DRAMA',
  Romance = 'ROMANCE',
  RomanceFantasy = 'ROMANCE_FANTASY',
  SliceOfLife = 'SLICE_OF_LIFE',
  Unknown = 'UNKNOWN'
}

/** 도전 스토리 탭의 정렬 타입 */
export enum Challenge_Story_Sorting {
  LatestComment = 'LATEST_COMMENT',
  LatestUpdated = 'LATEST_UPDATED',
  NumLikes = 'NUM_LIKES',
  NumPlay = 'NUM_PLAY',
  NumViews = 'NUM_VIEWS',
  Random = 'RANDOM',
  Recommended = 'RECOMMENDED'
}

/** 회차 댓글의 정렬 기준 컬럼 */
export enum Chapter_Comment_Order_Field {
  CreatedDate = 'CreatedDate',
  NestedComment = 'NestedComment',
  Reports = 'Reports',
  UserLikes = 'UserLikes',
  UserLikesByUsers = 'UserLikesByUsers',
  UserSystemLanguage = 'UserSystemLanguage'
}

/** 챕터 댓글 타입 */
export enum Chapter_Comment_Type {
  Statistics = 'Statistics',
  Text = 'Text',
  UserInputChoice = 'UserInputChoice'
}

/** 회차의 컨텐츠 타입 */
export enum Chapter_Content_Type {
  Epub = 'Epub',
  Interactive = 'Interactive',
  StudioNovel = 'StudioNovel'
}

/** 챕터 읽음 상태 */
export enum Chapter_Current_Playing_Status {
  Finished = 'FINISHED',
  NotPlayed = 'NOT_PLAYED',
  Playing = 'PLAYING'
}

/** 드래프트의 현재 상태 (CHAPTER_DRAFT_STATUS) */
export enum Chapter_Draft_Status {
  Applied = 'Applied',
  Discarded = 'Discarded',
  Editing = 'Editing'
}

/** 선택지 타입 */
export enum Choice_Type {
  Default = 'Default',
  ImageChoice = 'ImageChoice',
  UserInputChoice = 'UserInputChoice'
}

/** 클라이언트 종류 */
export enum Client {
  App = 'App',
  Web = 'Web'
}

/** 문의 메일 타입 */
export enum Contact_Mail_Type {
  Advertisement = 'advertisement',
  App = 'app',
  Job = 'job',
  Partner = 'partner'
}

/** 일정 주기로 캐싱되는 번들 통계값 */
export type CachedStoryBundleStat = {
  __typename?: 'CachedStoryBundleStat';
  /** 아이디 */
  cachedStoryBundleStatId: Scalars['Int'];
  /** 대상 국가 */
  country: Country;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 추천작이 적용될 시각의 dateToken(YYYY-MM-DD). TZ가 적용된 시각임. 모두 Asia/Seoul로 설정 */
  dateTokenTZ: Scalars['String'];
  /** 대상 번들의 현재까지 달린 모든 댓글 수 */
  numComment: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 좋아요 수 */
  numLikes: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 회차 플레이 수 */
  numPlay: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 작품상세 조회수 */
  numViews: Scalars['Int'];
  /** 통계 대상 번들 */
  storyBundle: StoryBundle;
  /** 통계 대상 번들 아이디 */
  storyBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 버전 */
  version: Scalars['Int'];
};

/** 스토리 통계값을 관리 */
export type CachedStoryStat = {
  __typename?: 'CachedStoryStat';
  /** 아이디 */
  cachedStoryStatId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 추천작이 적용될 시각의 dateToken(YYYY-MM-DD). TZ가 적용된 시각임. 2022-08-24 기준 모두 Asia/Seoul로 설정 */
  dateTokenTZ: Scalars['String'];
  /** 해당 작품에 마지막 댓글이 달린 시각 (long type) */
  latestCommentedAt?: Maybe<Scalars['Int']>;
  /** 대상 작품에 달린 모든 댓글 수 */
  numComment: Scalars['Int'];
  /** 대상 작품의 좋아요 수 */
  numLikes: Scalars['Int'];
  /** 대상 작품의 총 회차 플레이 수 */
  numPlay: Scalars['Int'];
  /** 대상 작품의 총 작품상세 조회수 */
  numViews: Scalars['Int'];
  /** 작품이 보여지는 순서. 클수록 먼저 나옴 */
  showOrder: Scalars['Int'];
  /** 정렬 타입 */
  sortingType: Challenge_Story_Sorting;
  /** 통계 대상 작품 */
  story: Story;
  /** 통계 대상 작품의 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 버전 */
  version: Scalars['Int'];
};

/** 작품 번들 좋아요를 취소합니다. */
export type CancelLikeBundleInput = {
  /** 좋아요 누른 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 스토리 장르 정보 */
export type ChallengeStoryGenre = {
  __typename?: 'ChallengeStoryGenre';
  /** 앱에서 사용하는 장리 서브타이틀 */
  subTitle?: Maybe<LocalizedText>;
  /** 장르 텍스트 */
  text: LocalizedText;
  /** 엡에서 사용하는 장르 타이틀 */
  title?: Maybe<LocalizedText>;
  /** 장르 구분 타입 */
  type: Scalars['String'];
};

/** 도전 스토리 신고 사유 */
export type ChallengeStoryReportingReason = {
  __typename?: 'ChallengeStoryReportingReason';
  createdAt: Scalars['DateTime'];
  /** 해당 사유에 대한 세부 설명 */
  description?: Maybe<Scalars['String']>;
  /** 유저가 직접 사유를 입력할 수 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasCustomReason: Scalars['Boolean'];
  /** 항목 아래에 구분선이 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasUnderSeparator: Scalars['Boolean'];
  /** 댓글 신고 후 즉시 댓글 목록 업데이트 할 지 여부 */
  isRealtimeUpdate?: Maybe<Scalars['Boolean']>;
  /** 아이디 */
  reasonId: Scalars['Int'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 신고 사유 텍스트 */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 도전 스토리 신고 사유 생성을 위한 입력값 */
export type ChallengeStoryReportingReasonCreationInput = {
  /** 해당 사유에 대한 세부 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 유저가 직접 사유를 입력할 수 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasCustomReason: Scalars['Boolean'];
  /** 항목 아래에 구분선이 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasUnderSeparator: Scalars['Boolean'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 신고 사유 텍스트 */
  title: Scalars['String'];
};

/** 도전 스토리 신고 사유 업데이트를 위한 입력값 */
export type ChallengeStoryReportingReasonUpdateInput = {
  /** 해당 사유에 대한 세부 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 유저가 직접 사유를 입력할 수 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasCustomReason?: InputMaybe<Scalars['Boolean']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 신고 사유 텍스트 */
  title?: InputMaybe<Scalars['String']>;
};

/** 무료,공개 여부 수정 */
export type ChangeChapterBooleanInput = {
  /** 작품 챕터의 ID */
  id: Scalars['Int'];
  /** true/false */
  isTrue: Scalars['Boolean'];
};

/** 무료 설정을 변경하기 위한 입력값 */
export type ChangeChapterFreeSettingInput = {
  /** 기스무 N화 엔딩을 본 후 M(기간) 후 무료 조건에서 N화에 해당하는 chapter의 chapterIndex */
  freeDependencyChapterIndex?: InputMaybe<Scalars['Int']>;
  /** 기스무 N화 엔딩을 본 후 M(기간) 후 무료 조건에서 M에 해당하는 기간 값(초단위 값) */
  freeDependencyPeriod?: InputMaybe<Scalars['Int']>;
  /** 작품 챕터의 ID */
  id: Scalars['Int'];
  /** 무료 예정 시각 (null이면 유료) */
  willFreeAt?: InputMaybe<Scalars['DateTime']>;
};

/** 스토리를 강제로 Publish or Unpublish */
export type ChangePublishInput = {
  /** ID */
  id: Scalars['Int'];
  /** 오픈할 것인가? */
  shouldPublish: Scalars['Boolean'];
};

/** 작품에 대한 알림 설정 여부를 수정한다. */
export type ChangeStorySubscriptionInput = {
  /** 알림을 enable 할 것인가? */
  enable: Scalars['Boolean'];
  /** storyId */
  storyId: Scalars['Int'];
};

/** 스토리에 포함되는 챕터에 대한 정보 */
export type Chapter = {
  __typename?: 'Chapter';
  /** 이 챕터를 보려면 광고를 봐야 하는가? */
  adsOn: Scalars['Boolean'];
  /** applied 된 ChapterDraft id, -1 이면 ChapterDraft 가 없는 회차 */
  appliedDraftId: Scalars['Int'];
  /** 아이디 */
  chapterId: Scalars['Int'];
  /** 스토리 내에서 몇 번째 챕터인가? */
  chapterIndex: Scalars['Int'];
  /** 챕터 옵션 데이터(배너 등) */
  chapterOption?: Maybe<ChapterOption>;
  /** 챕터 옵션 데이터(배너 등) STATUS Inactive 인것도 표시 */
  chapterOptionByAdmin?: Maybe<ChapterOption>;
  /** 챕터에 포함된 블락들 (json-encoded) */
  chapterScript: Scalars['String'];
  /** 챕터에 포함된 분기들 */
  choices: Array<Choice>;
  /** 인터렉티브/Epub/스튜디오 직접제작 */
  contentType: Chapter_Content_Type;
  createdAt: Scalars['DateTime'];
  /** 해당 작품을 재생한 이력(현재 playingVersion 기준)이 있는지 확인하는 값 NOT_PLAYED: 플레이이력 없음, PLAYING: 플레이 중임, FINISHED: 챕터/최종 엔딩까지 봄 */
  currentPlayingStatus: Chapter_Current_Playing_Status;
  /** 유저가 스프레드에서 정의한 챕터의 ID */
  customId?: Maybe<Scalars['String']>;
  /** 챕터 추가로 인해 생성된 유저 플레이 기록의 엔딩 */
  defaultEnding?: Maybe<Ending>;
  /** defaultEnding의 ID */
  defaultEndingId?: Maybe<Scalars['Int']>;
  /** 만약 현재 편집중인 ChapterDraft 가 있는 경우 이를 반환한다. */
  draft?: Maybe<ChapterDraft>;
  /** 챕터에 포함된 엔딩들 */
  endings: Array<Ending>;
  /** EPUB의 파일 */
  epubFile?: Maybe<EpubFile>;
  /** EPUB의 파일 ID */
  epubFileId?: Maybe<Scalars['Int']>;
  /** 기스무 N화 엔딩을 본 후 M(기간) 후 무료 조건에서 N화에 해당하는 chapter의 chapterIndex */
  freeDependencyChapterIndex?: Maybe<Scalars['Int']>;
  /** 기스무 N화 엔딩을 본 후 M(기간) 후 무료 조건에서 M에 해당하는 기간 값(초단위 값) */
  freeDependencyPeriod?: Maybe<Scalars['Int']>;
  /** 무료가 된 경우, 무료가 된 시각 */
  freedAt?: Maybe<Scalars['DateTime']>;
  /** 무료가 된 경우, 무료가 된 시각 => 계정별 계산된 값이 아닌 오리지널 값 */
  freedAtRaw?: Maybe<Scalars['DateTime']>;
  /** 이 챕터에 BGM이 포함되어 있는가? */
  hasBGM: Scalars['Boolean'];
  /** Graph icon을 가지고 있는가? (각 챕터에 속한 엔딩 갯수가 2개 이상이면 true) */
  hasGraphIcon: Scalars['Boolean'];
  /** New 배지를 달아주어야 하는가? */
  hasNewBadge: Scalars['Boolean'];
  /** 19금 회차이면 true. Story.isAdult의 우선순위가 더 높다 */
  isAdult: Scalars['Boolean'];
  /** 이 챕터는 아직 드래프트 버전인가? */
  isDraft: Scalars['Int'];
  /** 작성 완료 여부 */
  isFinal: Scalars['Boolean'];
  /** 사용자에게 보여주지 않고 숨겨둘 것인가? (오픈보다 앞서며, isHidden=true 이면 이 챕터는 사용자에게 보여지지 않음) */
  isHidden: Scalars['Int'];
  /** 타임리프 무료 기간 이면서, 어드민이 지정한 기간(purchaseFreeStartAt ~ freeTimeLeapEndAt) 동인 해당 회차 구매이력이 있는 유저는 true / 나머지 false  */
  isTimeLeapFree: Scalars['Boolean'];
  /** 이 챕터에 대한 스크립트 검증이 이루어졌는가? 단, isDraft = 1 인 경우에만 유효한 값이다. 기존 방식으로 스프레드시트로 진행하는 경우엔 무조건 0. */
  isVerified: Scalars['Int'];
  /** 챕터명 */
  name: Scalars['String'];
  /** 다음 챕터 정보 null이라면 현재 챕터가 마지막임 */
  nextChapter?: Maybe<Chapter>;
  /** 블럭 수 */
  numBlocks?: Maybe<Scalars['Int']>;
  /** draft(초안)의 블럭 수 */
  numBlocksDraft?: Maybe<Scalars['Int']>;
  /** 글자 수 */
  numChars?: Maybe<Scalars['Int']>;
  /** draft(초안)의 글자 수 */
  numCharsDraft?: Maybe<Scalars['Int']>;
  /** 문장 수 */
  numSens?: Maybe<Scalars['Int']>;
  /** draft(초안)의 문장 수 */
  numSensDraft?: Maybe<Scalars['Int']>;
  /** 온보딩 팝업 모음 */
  onboardingPopups?: Maybe<Array<OnboardingPopup>>;
  /** 이전 챕터 정보 null이라면 현재 챕터가 첫번쨰임 */
  previousChapter?: Maybe<Chapter>;
  /** 가격 정보 */
  price: Scalars['Int'];
  /** 챕터의 대표 속성. 설정 되어 있지 않으면 작품의 대표 속성을 반환한다. */
  primaryProperty?: Maybe<StoryProperty>;
  /** 챕터 대표 속성 ID */
  primaryPropertyId?: Maybe<Scalars['Int']>;
  /** 실제 디비에 저장된 챕터의 대표 속성 */
  primaryPropertyRaw?: Maybe<StoryProperty>;
  /** 오픈한 경우, 오픈한 시각 */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** 챕터가 무료가 될 때까지 남은 시간 (초단위). 무료 설정이 없으면 null, 이미 지났으면 0 을 반환합니다. */
  remainingTimeToFree?: Maybe<Scalars['Int']>;
  /** chapterScript가 저장된 file */
  scriptFile?: Maybe<ChapterScriptFile>;
  /** scriptFile의 Id */
  scriptFileId?: Maybe<Scalars['Int']>;
  /** 기타 통계 수치 json stringify (IStats format) */
  stats?: Maybe<Scalars['String']>;
  /** draft(초안)의 기타 통계 수치 json stringify (IStats format) */
  statsDraft?: Maybe<Scalars['String']>;
  /** 이 챕터가 포함된 책 */
  story: Story;
  /** 이 챕터가 포함된 책 Id */
  storyId: Scalars['Int'];
  /** 스토리 가격 정책 정보 */
  storyPriceSetting?: Maybe<Scalars['Int']>;
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 유효한 회차 구매이력 정보(null 이면, 유효한 구매이력없어서 유료회차라면 개별구매/전체구매 후 플레이 가능) */
  userPurchasedChapter?: Maybe<UserPurchasedChapter>;
  /** 무료 예정 시각 (null이면 유료) */
  willFreeAt?: Maybe<Scalars['DateTime']>;
  /** 무료 예정 시각 (null이면 유료) => 계정별 계산된 값이 아닌 오리지널 값 */
  willFreeAtRaw?: Maybe<Scalars['DateTime']>;
  /** 오픈 예정 시각 */
  willPublishAt?: Maybe<Scalars['DateTime']>;
};

/** 챕터 1개에 대한 export input */
export type ChapterAsSheetInput = {
  /** 챕터의 이름 (시트명) */
  chapterName: Scalars['String'];
  /** 스튜디오에서 생성한 챕터 데이터 (IStudioSheetLine[])을 json 으로 인코딩한 데이터여야 한다. */
  encodedLines: Scalars['String'];
};

/** 회차의 블록 이탈 수 */
export type ChapterBlockBounceStatCacheData = {
  __typename?: 'ChapterBlockBounceStatCacheData';
  /** Android 이탈수 */
  aos: Scalars['Int'];
  /** iOS + Android 이탈수 */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐시 날짜 */
  date: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** iOS 이탈수 */
  ios: Scalars['Int'];
  /** 블록 이름 */
  name: Scalars['String'];
  /** 총 진입 횟수 */
  total: Scalars['Int'];
  /** 일간, 주간, 월간 */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
  /** 발행된 ChapterDraftId */
  version: Scalars['Int'];
};

/** 회차별 코멘트 */
export type ChapterComment = {
  __typename?: 'ChapterComment';
  /** 댓글 작성자 */
  author?: Maybe<User>;
  /** 댓글 작성자 아이디 */
  authorId?: Maybe<Scalars['Int']>;
  /** 아이디 */
  ccId: Scalars['Int'];
  /** 해당 댓글이 속한 챕터 */
  chapter: Chapter;
  /** 해당 댓글이 속한 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 댓글이 달린 선택지 ID */
  choice?: Maybe<Choice>;
  /** 댓글이 달린 선택지 ID */
  choiceId?: Maybe<Scalars['Int']>;
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 댓글 내용(content)이 수정된 날짜 */
  contentEditedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** 댓글이 삭제된 날짜 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 관리자가 삭제했으면 true, 나머지 경우는 false */
  deletedByAdmin: Scalars['Boolean'];
  /** 댓글에 업로드될 이미지 */
  imageFile?: Maybe<ChapterCommentImageFile>;
  /** 댓글에 업로드될 이미지 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 베스트 댓글 여부 */
  isBestComment: Scalars['Boolean'];
  /** 숨김처리 여부. 어드민이 설정할 수 있음 */
  isHidden: Scalars['Boolean'];
  /** 스포일러 여부 */
  isSpoiler: Scalars['Boolean'];
  /** 내가 좋아요를 눌렀는지 여부 */
  likedByMe: Scalars['Boolean'];
  /** 댓글 좋아요 기본 갯수. 이 데이터는 어드민에 의해서 수정됨 */
  numOfBaseLikes: Scalars['Int'];
  /** 댓글의 좋아요 갯수. numOfBaseLikes + numOfuserLikes로 계산됨 */
  numOfLikes: Scalars['Int'];
  /** 해당 댓글에 대한 대댓글 갯수 */
  numOfNestedComments: Scalars['Int'];
  /** 댓글 신고 횟수 */
  numOfReports: Scalars['Int'];
  /** 실제 유저가 좋아요를 누른 수. 유저가 좋아요를 누를 때 마다 업데이트 됨 */
  numOfUserLikes: Scalars['Int'];
  /** 해당 댓글이 대댓글 일 때, 부모 댓글 */
  parent?: Maybe<ChapterComment>;
  /** 부모 댓글의 아이디 */
  parentId?: Maybe<Scalars['Int']>;
  /** 댓글 신고 내역 */
  reports: Array<UserReportChapterComment>;
  /** 해당 댓글이 속한 스토리 */
  story: Story;
  /** 해당 댓글이 속한 스토리 아이디 */
  storyId: Scalars['Int'];
  /** 댓글 타입 Text | UserInputText | Statistics */
  type: Chapter_Comment_Type;
  updatedAt: Scalars['DateTime'];
  /** 댓글 작성자가 탈퇴 시 생성되는 토큰으로 유저마다 유니크 한 값을 가짐 */
  userToken?: Maybe<Scalars['String']>;
};

/** 댓글 생성을 위한 기본 input */
export type ChapterCommentCreationBaseInput = {
  /** 댓글이 속한 챕터의 아이디 */
  chapterId: Scalars['Int'];
  /** 선택지 ID 선택지 댓글일 경우 필수 값 */
  choiceId?: InputMaybe<Scalars['Int']>;
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 댓글이 스포일러를 포함하고 있는가? */
  isSpoiler?: InputMaybe<Scalars['Boolean']>;
  /** 대댓글의 부모 댓글 아이디. 대댓글일 생성 시에만 사용됨 */
  parentId?: InputMaybe<Scalars['Int']>;
  /** 댓글이 속한 스토리의 아이디 */
  storyId: Scalars['Int'];
  /** 댓글의 타입 Text(기본값) | UserInputText | Statistics */
  type?: InputMaybe<Chapter_Comment_Type>;
};

/** 관리자가 댓글을 작성하기 위한 입력 값 */
export type ChapterCommentCreationByAdminInput = {
  /** 댓글이 속한 챕터의 아이디 */
  chapterId: Scalars['Int'];
  /** 선택지 ID 선택지 댓글일 경우 필수 값 */
  choiceId?: InputMaybe<Scalars['Int']>;
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 댓글 작성일 */
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** 이 값이 null이 아니면 author가 displayUser로 대체됨 */
  displayUserId?: InputMaybe<Scalars['Int']>;
  /** 댓글 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 댓글이 스포일러를 포함하고 있는가? */
  isSpoiler?: InputMaybe<Scalars['Boolean']>;
  /** 대댓글의 부모 댓글 아이디. 대댓글일 생성 시에만 사용됨 */
  parentId?: InputMaybe<Scalars['Int']>;
  /** 댓글이 속한 스토리의 아이디 */
  storyId: Scalars['Int'];
  /** 댓글의 타입 Text(기본값) | UserInputText | Statistics */
  type?: InputMaybe<Chapter_Comment_Type>;
};

/** 회차 댓글 작성을 위한 입력값 */
export type ChapterCommentCreationInput = {
  /** 댓글이 속한 챕터의 아이디 */
  chapterId: Scalars['Int'];
  /** 선택지 ID 선택지 댓글일 경우 필수 값 */
  choiceId?: InputMaybe<Scalars['Int']>;
  /** 댓글 입력시 업로드한 이미지 파일 정보 */
  commentImageFileInfo?: InputMaybe<ChapterCommentImageFileInfo>;
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 댓글이 스포일러를 포함하고 있는가? */
  isSpoiler?: InputMaybe<Scalars['Boolean']>;
  /** 대댓글의 부모 댓글 아이디. 대댓글일 생성 시에만 사용됨 */
  parentId?: InputMaybe<Scalars['Int']>;
  /** 댓글이 속한 스토리의 아이디 */
  storyId: Scalars['Int'];
  /** 댓글의 타입 Text(기본값) | UserInputText | Statistics */
  type?: InputMaybe<Chapter_Comment_Type>;
};

/** chapterComment 이미지 파일 */
export type ChapterCommentImageFile = {
  __typename?: 'ChapterCommentImageFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 댓글 업로드 이미지 파일 정보 */
export type ChapterCommentImageFileInfo = {
  /** 원본 파일 이름 */
  fileName?: InputMaybe<Scalars['String']>;
  /** s3에 저장된 파일의 key 값 */
  hashId: Scalars['String'];
  /** s3에 임시저장소에 저장되었는지 여부 */
  isTemp: Scalars['Boolean'];
  /** 저장된 스토리지 정보 */
  storageType: Storages;
};

/** 데이터 정렬을 위한 Input */
export type ChapterCommentOrderBy = {
  /** 정렬 방향. DESC/ASC */
  direction: Order_By_Direction;
  /** 정렬을 위한 필드명 */
  field: Chapter_Comment_Order_Field;
};

/** 댓글 신고 사유 */
export type ChapterCommentReportingReason = {
  __typename?: 'ChapterCommentReportingReason';
  createdAt: Scalars['DateTime'];
  /** 해당 사유에 대한 세부 설명 */
  description?: Maybe<Scalars['String']>;
  /** 유저가 직접 사유를 입력할 수 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasCustomReason: Scalars['Boolean'];
  /** 항목 아래에 구분선이 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasUnderSeparator: Scalars['Boolean'];
  /** 댓글 신고 후 즉시 댓글 목록 업데이트 할 지 여부 */
  isRealtimeUpdate?: Maybe<Scalars['Boolean']>;
  /** 스팸 신고 여부, true면 해당 사유로 신고된 사용자 댓글은 신고한 사람, 그리고 신고당한 사람에게 숨김처리(isHidden: true)와 같은 댓글로 보인다. */
  isSpam?: Maybe<Scalars['Boolean']>;
  /** 아이디 */
  reasonId: Scalars['Int'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 댓글 신고 사유 텍스트 */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 회차 댓글 신고 사유 생성을 위한 입력값 */
export type ChapterCommentReportingReasonCreationInput = {
  /** 해당 사유에 대한 세부 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 유저가 직접 사유를 입력할 수 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasCustomReason: Scalars['Boolean'];
  /** 항목 아래에 구분선이 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasUnderSeparator: Scalars['Boolean'];
  /** 스팸 신고 여부, true면 해당 사유로 신고된 사용자 댓글은 신고한 사람, 그리고 신고당한 사람에게 숨김처리(isHidden: true)와 같은 댓글로 보인다. */
  isSpam?: InputMaybe<Scalars['Boolean']>;
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 댓글 신고 사유 텍스트 */
  title: Scalars['String'];
};

/** 회차 댓글 신고 사유 업데이트를 위한 입력값 */
export type ChapterCommentReportingReasonUpdateInput = {
  /** 해당 사유에 대한 세부 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 유저가 직접 사유를 입력할 수 있는지 여부. 현재 기획상으로 기타 사유일 경우 true */
  hasCustomReason?: InputMaybe<Scalars['Boolean']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 스팸 신고 여부, true면 해당 사유로 신고된 사용자 댓글은 신고한 사람, 그리고 신고당한 사람에게 숨김처리(isHidden: true)와 같은 댓글로 보인다. */
  isSpam?: InputMaybe<Scalars['Boolean']>;
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 댓글 신고 사유 텍스트 */
  title?: InputMaybe<Scalars['String']>;
};

/** 관리자가 회차 댓글/대댓글 수정을 위한 입력값 */
export type ChapterCommentUpdateByAdminInput = {
  /** 수정할 댓글/대댓글의 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 이 값이 null이 아니면 author가 displayUser로 대체됨 */
  displayUserId?: InputMaybe<Scalars['Int']>;
  /** 수정할 댓글/대댓글의 아이디 */
  id: Scalars['Int'];
  /** 댓글 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 댓글 숨김 여부 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 수정할 댓글/대댓글의 스포일러 여부 */
  isSpoiler?: InputMaybe<Scalars['Boolean']>;
  /** 댓글의 좋아요 수 */
  numOfLikes?: InputMaybe<Scalars['Int']>;
};

/** 회차 댓글/대댓글 수정을 위한 입력값 */
export type ChapterCommentUpdateInput = {
  /** 수정할 댓글/대댓글의 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 수정할 댓글/대댓글의 아이디 */
  id: Scalars['Int'];
  /** 수정할 댓글/대댓글의 스포일러 여부 */
  isSpoiler?: InputMaybe<Scalars['Boolean']>;
};

/** Studio API 에 의한 챕터 생성 입력 */
export type ChapterCreationInput = {
  /** 해당 챕터의 뒤에 챕터를 생성하겠다는 경우의 chapterId. 맨 처음인 경우 null, 만약 -1을 넣으면 무조건 제일 뒤에 챕터 추가. */
  afterChapterId?: InputMaybe<Scalars['Int']>;
  /** 인터렉티브/Epub/스튜디오 직접제작 */
  contentType?: InputMaybe<Chapter_Content_Type>;
  /** 챕터의 커스텀 ID. 비어있으면 자동 생성 */
  customId: Scalars['String'];
  /** AI 블록 생성기 적용을 위한 엔딩 */
  ending?: InputMaybe<Scalars['String']>;
  /** EPUB의 파일 */
  epubFile?: InputMaybe<Scalars['Upload']>;
  /** 작품의 ID */
  id: Scalars['Int'];
  /** 19금 회차이면 true. Story.isAdult의 우선순위가 더 높다 */
  isAdult?: InputMaybe<Scalars['Boolean']>;
  /** 챕터의 이름 */
  name: Scalars['String'];
  /** 시작블록의 이름 */
  startingBlock?: InputMaybe<Scalars['String']>;
  /** AI 블록 생성기 적용을 위한 시놉시스 */
  synopsis?: InputMaybe<Scalars['String']>;
};

/** 스튜디오에서 사용할 챕터 스크립트의 드래프트 버전 */
export type ChapterDraft = {
  __typename?: 'ChapterDraft';
  /** 드래프트 스크립트, CHAPTER_SCRIPT_JSON 데이터 형태를 만족하는 데이터의 json-encoded string 이어야 합니다. */
  bundle: Scalars['String'];
  /** 어떤 챕터에 대하여 편집중인가? */
  chapter: Chapter;
  /** 드래프트의 아이디 */
  chapterDraftId: Scalars['Int'];
  /** 어떤 챕터에 대하여 편집중인가? (ID) */
  chapterId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 편집 중인 사용자 */
  editor: User;
  /** 편집 중인 사용자 Id */
  editorId: Scalars['Int'];
  /** 웹 소설인 경우 epub 파일 */
  epubFile?: Maybe<EpubFile>;
  /** 웹 소설인 경우 epub 파일 */
  epubFileId?: Maybe<Scalars['Int']>;
  /** 블럭 수 */
  numBlocks?: Maybe<Scalars['Int']>;
  /** 글자 수 */
  numChars?: Maybe<Scalars['Int']>;
  /** 문장 수 */
  numSens?: Maybe<Scalars['Int']>;
  /** 드래프트 스크립트, CHAPTER_SCRIPT_JSON 데이터 형태를 만족하는 데이터의 json-encoded string 이어야 합니다. */
  script?: Maybe<Scalars['String']>;
  /** 기타 통계 수치 json stringify (IStats format) */
  stats?: Maybe<Scalars['String']>;
  /** 드래프트의 현재 상태 (CHAPTER_DRAFT_STATUS) */
  status: Chapter_Draft_Status;
  updatedAt: Scalars['DateTime'];
};

/** 초안 백업 히스토리 */
export type ChapterDraftArchive = {
  __typename?: 'ChapterDraftArchive';
  /** 스크립트 */
  bundle: Scalars['String'];
  /** 일련번호 */
  cdaId: Scalars['Int'];
  /** 챕터 */
  chapter?: Maybe<Chapter>;
  /** 챕터 아이디 (Chapter 삭제시 nullify) */
  chapterId?: Maybe<Scalars['Int']>;
  /** 챕터 이름 */
  chapterName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 챕터의 customId */
  customId?: Maybe<Scalars['String']>;
  /** epub 파일 */
  epubFile?: Maybe<EpubFile>;
  /** epub 파일 아이디 */
  epubFileId?: Maybe<Scalars['Int']>;
  /** 블럭 수 */
  numBlocks: Scalars['Int'];
  /** 글자 수 */
  numChars: Scalars['Int'];
  /** 문장 수 */
  numSens: Scalars['Int'];
  /** 스크립트 */
  script?: Maybe<Scalars['String']>;
  /** 기타 통계 수치 json stringify (IStats format) */
  stats?: Maybe<Scalars['String']>;
  /** 작품 */
  story: Story;
  /** 작품 아아디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 저장한 사용자 */
  userId: Scalars['Int'];
};

/** ChapterDraft 항목을 생성할 때 필요한 데이터 */
export type ChapterDraftCreationInput = {
  /** 번들 데이터 json-encoded IChapterBundle */
  bundle: Scalars['String'];
  /** 특정 챕터에 대하여 드래프트 버전을 신규 생성한다. */
  chapterId: Scalars['Int'];
  /** epubFileId */
  epubFileId?: InputMaybe<Scalars['Int']>;
  /** 블럭수 */
  numBlocks?: InputMaybe<Scalars['Int']>;
  /** 글자수 */
  numChars?: InputMaybe<Scalars['Int']>;
  /** 문장수 */
  numSens?: InputMaybe<Scalars['Int']>;
  /** 기타 통계 수치 json 형태의 string */
  stats?: InputMaybe<Scalars['String']>;
};

/** ChapterDraft 항목을 업데이트할 때 필요한 데이터 */
export type ChapterDraftUpdateInput = {
  /** 드래프트 스크립트, CHAPTER_SCRIPT_JSON 데이터 형태를 만족하는 데이터의 json-encoded string 이어야 합니다. */
  bundle: Scalars['String'];
  /** epubFile 파일 아이디 */
  epubFileId?: InputMaybe<Scalars['Int']>;
  /** 드래프트의 아이디 */
  id: Scalars['Int'];
  /** 블럭수 */
  numBlocks?: InputMaybe<Scalars['Int']>;
  /** 글자수 */
  numChars?: InputMaybe<Scalars['Int']>;
  /** 문장수 */
  numSens?: InputMaybe<Scalars['Int']>;
  /** 기타 통계 수치 json 형태의 string */
  stats?: InputMaybe<Scalars['String']>;
};

/** ChapterDraft 항목을 생성/업데이트할 때 필요한 데이터 */
export type ChapterDraftUpsertInput = {
  /** 번들 데이터 json-encoded IChapterBundle */
  bundle: Scalars['String'];
  /** 특정 챕터에 대하여 드래프트 버전을 신규 생성하거나 현재 편집본에 업데이트한다. */
  chapterId: Scalars['Int'];
  /** 챕터 스크립트 데이터 json-encoded IChapterScriptData. 이 값이 주어지면 적용까지 시도한다. */
  chapterScript?: InputMaybe<Scalars['String']>;
  /** 웹 소설인 경우 epub 파일 */
  epubFile?: InputMaybe<Scalars['Upload']>;
  /** 블럭수 */
  numBlocks?: InputMaybe<Scalars['Int']>;
  /** 글자수 */
  numChars?: InputMaybe<Scalars['Int']>;
  /** 문장수 */
  numSens?: InputMaybe<Scalars['Int']>;
  /** 기타 통계 수치 json 형태의 string (IStats 참고) */
  stats?: InputMaybe<Scalars['String']>;
};

export type ChapterDraftUpsertOutput = {
  __typename?: 'ChapterDraftUpsertOutput';
  /** 적용할 때 발생한 문제 */
  errorsOnApplying?: Maybe<Scalars['String']>;
  /** 적용되었는가? */
  isApplied: Scalars['Boolean'];
  /** 성공적으로 저장되었는가? */
  isSaved: Scalars['Boolean'];
};

/** 챕터의 부가 적인 옵션을 저장하는 테이블 */
export type ChapterOption = {
  __typename?: 'ChapterOption';
  /** 배너이미지 이동 URL */
  bannerImageConnectUrl?: Maybe<Scalars['String']>;
  /** 배너이미지 파일 */
  bannerImageFile?: Maybe<BannerImageFile>;
  /** 배너이미지 파일 ID */
  bannerImageFileId?: Maybe<Scalars['Int']>;
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 챕터 옵션 아이디 */
  chapterOptionId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 배너이미지 상태값 */
  status: Status;
  updatedAt: Scalars['DateTime'];
};

/** ChapterOption 생성을 위한 input */
export type ChapterOptionCreationInput = {
  /** 배너이미지 이동 URL */
  bannerImageConnectUrl?: InputMaybe<Scalars['String']>;
  /** 배너이미지 파일 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 배너이미지 상태값 */
  status?: InputMaybe<Status>;
};

/** ChapterOption 업데이트를 위한 input */
export type ChapterOptionUpdateInput = {
  /** 배너이미지 이동 URL */
  bannerImageConnectUrl?: InputMaybe<Scalars['String']>;
  /** 배너이미지 파일 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 챕터 옵션 아이디 */
  id: Scalars['Int'];
  /** 배너이미지 상태값 */
  status?: InputMaybe<Status>;
};

/** Chapter 항목을 생성할 때 필요한 데이터 */
export type ChapterPropsInput = {
  /** 이 챕터를 보려면 광고를 봐야 하는가? */
  adsOn?: InputMaybe<Scalars['Boolean']>;
  /** 인터렉티브/Epub/스튜디오 직접제작 */
  contentType?: InputMaybe<Chapter_Content_Type>;
  /** 기스무 N화 엔딩을 본 후 M(기간) 후 무료 조건에서 N화에 해당하는 chapter의 chapterIndex */
  freeDependencyChapterIndex?: InputMaybe<Scalars['Int']>;
  /** 기스무 N화 엔딩을 본 후 M(기간) 후 무료 조건에서 M에 해당하는 기간 값(초단위 값) */
  freeDependencyPeriod?: InputMaybe<Scalars['Int']>;
  /** 무료가 된 경우, 무료가 된 시각 */
  freedAt?: InputMaybe<Scalars['DateTime']>;
  /** 아이디 */
  id?: InputMaybe<Scalars['Int']>;
  /** 19금 회차이면 true. Story.isAdult의 우선순위가 더 높다 */
  isAdult?: InputMaybe<Scalars['Boolean']>;
  /** 작성 완료 여부 */
  isFinal?: InputMaybe<Scalars['Boolean']>;
  /** 사용자에게 보여주지 않고 숨겨둘 것인가? (오픈보다 앞서며, isHidden=true 이면 이 챕터는 사용자에게 보여지지 않음) */
  isHidden?: InputMaybe<Scalars['Int']>;
  /** 챕터명 */
  name?: InputMaybe<Scalars['String']>;
  /** 블럭수 */
  numBlocks?: InputMaybe<Scalars['Int']>;
  /** 블럭수 */
  numBlocksDraft?: InputMaybe<Scalars['Int']>;
  /** 글자수 */
  numChars?: InputMaybe<Scalars['Int']>;
  /** 글자수 */
  numCharsDraft?: InputMaybe<Scalars['Int']>;
  /** 문장수 */
  numSens?: InputMaybe<Scalars['Int']>;
  /** 문장수 */
  numSensDraft?: InputMaybe<Scalars['Int']>;
  /** 가격 정보 */
  price?: InputMaybe<Scalars['Int']>;
  /** 챕터 대표 속성 ID */
  primaryPropertyId?: InputMaybe<Scalars['Int']>;
  /** 기타 통계 수치 json 형태의 string */
  stats?: InputMaybe<Scalars['String']>;
  /** 기타 통계 수치 json 형태의 string */
  statsDraft?: InputMaybe<Scalars['String']>;
  /** 이 챕터가 포함된 책 Id */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 무료 예정 시각 (null이면 유료) */
  willFreeAt?: InputMaybe<Scalars['DateTime']>;
  /** 오픈 예정 시각 */
  willPublishAt?: InputMaybe<Scalars['DateTime']>;
};

/** 사용자가 보유하고 있는 챕터 구매기록 */
export type ChapterPurchase = {
  __typename?: 'ChapterPurchase';
  /** 어떤 챕터에 대한 구매 기록인가? */
  chapter: Chapter;
  /** 어떤 챕터에 대한 구매 기록인가? (Id) */
  chapterId: Scalars['Int'];
  /** 이 구매에 사용된 코인의 갯수 */
  coinUsed: Scalars['Int'];
  /** 아이디 */
  cpId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 이 구매기록이 환불되었다면, 언제인가? */
  refundedAt?: Maybe<Scalars['DateTime']>;
  /** 어떤 작품에 대한 구매 기록인가? */
  story: Story;
  /** 어떤 작품에 대한 구매 기록인가? (Id) */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 언제 이 구매기록이 사용되었는가? */
  usedAt?: Maybe<Scalars['DateTime']>;
  /** 사용되었다면, 어떤 UPC 에 의해 사용되었는가? */
  usedChapterPlaying?: Maybe<UserPlayedChapter>;
  /** 사용되었다면, 어떤 UPC 에 의해 사용되었는가? (Id) */
  usedChapterPlayingId: Scalars['Int'];
  /** 이 구매는 누가한 것인가? */
  user: User;
  /** 이 구매는 누가한 것인가? (Id) */
  userId: Scalars['Int'];
};

/** 챕터 스크립트를 저장할 테이블 */
export type ChapterScriptFile = {
  __typename?: 'ChapterScriptFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChapterStat = {
  __typename?: 'ChapterStat';
  /** 챕터 */
  chapter: Chapter;
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 아이디 */
  chapterStatId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 챕터의 댓글 수 */
  numComment: Scalars['Int'];
  /** 챕터의 총 플레이수 */
  numPlay: Scalars['Int'];
  /** 챕터를 플레이한 사용자 수 */
  numPlayer: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 작품 동기화시 캐릭터 마이그레이션을 위한 입력값 */
export type CharacterMigrationInput = {
  /** 캐릭터 변경 매핑 */
  mapping: Array<CharacterMigrationMapping>;
  /** 작품의 ID */
  storyId: Scalars['Int'];
};

/** 어떤 캐릭터를 어떤 캐릭터로 migration 할 것인가? */
export type CharacterMigrationMapping = {
  /** 기존 캐릭터의 name */
  from: Scalars['String'];
  /** 변경될 캐릭터의 name, 삭제된 캐릭터면 null */
  to?: InputMaybe<Scalars['String']>;
};

export type CheckBonusTicketCodeResponse = {
  __typename?: 'CheckBonusTicketCodeResponse';
  /** 코드가 존재하는지 여부 */
  codeExists: Scalars['Boolean'];
};

/** 일일 보상 체크 결과 */
export type CheckDailyRewardCoinOutput = {
  __typename?: 'CheckDailyRewardCoinOutput';
  /** 받을 보상이 있는가? */
  hasRewardCoin: Scalars['Boolean'];
  /** 구독이 갱신 되었나? */
  isRenewaled: Scalars['Boolean'];
  /** 보상 받을 코인 갯수 */
  rewardAmount: Scalars['Int'];
};

/** 이메일 존재여부 체크 결과 */
export type CheckEmailResponse = {
  __typename?: 'CheckEmailResponse';
  /** 해당 이메일로 가입된 계정이 이미 존재하는가? */
  emailExists: Scalars['Boolean'];
};

/** 작품에 존재하는 분기(선택)점들 */
export type Choice = {
  __typename?: 'Choice';
  /** 사용자 입력 이미지 좌표 정보등이 들어갑니다 */
  backgroundImageInfo?: Maybe<Scalars['String']>;
  /** 해당 선택지의 댓글 총 개수와 top1 댓글 list 정보가 있습니다. */
  bestChapterComments?: Maybe<ListableChapterComment>;
  /** 분기가 속하는 작품의 챕터 */
  chapter: Chapter;
  /** 분기가 속하는 작품의 챕터 Id */
  chapterId: Scalars['Int'];
  /** 1번 선택지에 대해 어드민이 추가로 조작한 값 */
  choice1AdminAdd: Scalars['Int'];
  /** 1번 선택지 내용 */
  choice1Content?: Maybe<Scalars['String']>;
  /** 1번 선택지 보여지는 선택이 이름 */
  choice1DisplayName?: Maybe<Scalars['String']>;
  /** 1번 선택지 이미지 url */
  choice1ImageUrl?: Maybe<Scalars['String']>;
  /** 1번 선택지에 매칭되는 속성 */
  choice1PropName?: Maybe<Scalars['String']>;
  /** 선택지1의 선택 확률 */
  choice1Rate?: Maybe<Scalars['Float']>;
  /** 선택지1의 선택 실제 확률 */
  choice1RealRate?: Maybe<Scalars['Float']>;
  /** 1번 분기 누적 선택 횟수 */
  choice1Selected: Scalars['Int'];
  /** 2번 선택지에 대해 어드민이 추가로 조작한 값 */
  choice2AdminAdd: Scalars['Int'];
  /** 2번 선택지 내용 */
  choice2Content?: Maybe<Scalars['String']>;
  /** 2번 선택지 보여지는 선택이 이름 */
  choice2DisplayName?: Maybe<Scalars['String']>;
  /** 2번 선택지 이미지 url */
  choice2ImageUrl?: Maybe<Scalars['String']>;
  /** 2번 선택지에 매칭되는 속성 */
  choice2PropName?: Maybe<Scalars['String']>;
  /** 선택지2의 선택 확률 */
  choice2Rate?: Maybe<Scalars['Float']>;
  /** 선택지2의 선택 실제 확률 */
  choice2RealRate?: Maybe<Scalars['Float']>;
  /** 2번 분기 누적 선택 횟수 */
  choice2Selected: Scalars['Int'];
  /** 3번 선택지에 대해 어드민이 추가로 조작한 값 */
  choice3AdminAdd: Scalars['Int'];
  /** 3번 선택지 내용 */
  choice3Content?: Maybe<Scalars['String']>;
  /** 3번 선택지 보여지는 선택이 이름 */
  choice3DisplayName?: Maybe<Scalars['String']>;
  /** 3번 선택지 이미지 url */
  choice3ImageUrl?: Maybe<Scalars['String']>;
  /** 3번 선택지에 매칭되는 속성 */
  choice3PropName?: Maybe<Scalars['String']>;
  /** 선택지3의 선택 확률 */
  choice3Rate?: Maybe<Scalars['Float']>;
  /** 선택지3의 선택 실제 확률 */
  choice3RealRate?: Maybe<Scalars['Float']>;
  /** 3번 분기 누적 선택 횟수 */
  choice3Selected: Scalars['Int'];
  /** 4번 선택지에 대해 어드민이 추가로 조작한 값 */
  choice4AdminAdd: Scalars['Int'];
  /** 4번 선택지 내용 */
  choice4Content?: Maybe<Scalars['String']>;
  /** 4번 선택지 보여지는 선택이 이름 */
  choice4DisplayName?: Maybe<Scalars['String']>;
  /** 4번 선택지 이미지 url */
  choice4ImageUrl?: Maybe<Scalars['String']>;
  /** 4번 선택지에 매칭되는 속성 */
  choice4PropName?: Maybe<Scalars['String']>;
  /** 선택지4의 선택 확률 */
  choice4Rate?: Maybe<Scalars['Float']>;
  /** 선택지4의 선택 실제 확률 */
  choice4RealRate?: Maybe<Scalars['Float']>;
  /** 4번 분기 누적 선택 횟수 */
  choice4Selected: Scalars['Int'];
  /** 5번 선택지에 대해 어드민이 추가로 조작한 값 */
  choice5AdminAdd: Scalars['Int'];
  /** 5번 선택지 내용 */
  choice5Content?: Maybe<Scalars['String']>;
  /** 5번 선택지 보여지는 선택이 이름 */
  choice5DisplayName?: Maybe<Scalars['String']>;
  /** 5번 선택지 이미지 url */
  choice5ImageUrl?: Maybe<Scalars['String']>;
  /** 5번 선택지에 매칭되는 속성 */
  choice5PropName?: Maybe<Scalars['String']>;
  /** 선택지5의 선택 확률 */
  choice5Rate?: Maybe<Scalars['Float']>;
  /** 선택지5의 선택 실제 확률 */
  choice5RealRate?: Maybe<Scalars['Float']>;
  /** 5번 분기 누적 선택 횟수 */
  choice5Selected: Scalars['Int'];
  /** 6번 분기 누적 선택 횟수 */
  choice6Selected: Scalars['Int'];
  /** 7번 분기 누적 선택 횟수 */
  choice7Selected: Scalars['Int'];
  /** 8번 분기 누적 선택 횟수 */
  choice8Selected: Scalars['Int'];
  /** 9번 분기 누적 선택 횟수 */
  choice9Selected: Scalars['Int'];
  /** playerClass 별로 choice 결과를 누적한 데이터 */
  choiceFromPlayerClasses?: Maybe<Array<ChoiceFromPlayerClass>>;
  /** 아이디 */
  choiceId: Scalars['Int'];
  /** 선택지 타입 */
  choiceType: Choice_Type;
  createdAt: Scalars['DateTime'];
  /** 커스텀 선택지 명 */
  displayName?: Maybe<Scalars['String']>;
  /** 분기의 이름 */
  name: Scalars['String'];
  /** 분기의 갯수 */
  numChoice: Scalars['Int'];
  /** 선택지 확률 보여줄 지 여부 */
  showStatistics: Scalars['Int'];
  /** 분기가 속한 스토리 (챕터를 통해서 알 수 있지만, DB query 를 편하게 하기 위해 추가적으로 저장한다. */
  story: Story;
  /** 분기가 속한 스토리 ID */
  storyId: Scalars['Int'];
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 텍스트 입력 선택지의 룰베이스 */
export type ChoiceAffectionRule = {
  __typename?: 'ChoiceAffectionRule';
  /** 선택지 */
  choice: Choice;
  /** 선택지 id */
  choiceId: Scalars['Int'];
  /** 반환될 선택지 결과 index */
  choiceIndex: Scalars['Int'];
  /** 선택지 이름 */
  choiceName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** 룰베이스 텍스트 (해당 텍스트와 일치하는 입력값이 들어온다면 자연어 처리 하지 않음) */
  ruleText: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** ChoiceAffectionRule 반환되는 데이터 */
export type ChoiceAffectionRuleResponse = {
  __typename?: 'ChoiceAffectionRuleResponse';
  /** 반환될 choice index */
  choiceIndex: Scalars['Int'];
  /** choiceName */
  choiceName: Scalars['String'];
  /** \n으로 부분된 텍스트 */
  ruleTexts: Scalars['String'];
};

/** player Class 타입 별로 선택지 통계 데이터를 쌓는 테이블 */
export type ChoiceFromPlayerClass = {
  __typename?: 'ChoiceFromPlayerClass';
  /** 어드민이 더하거나 뺀 선택 수 */
  adminAddCount: Scalars['Int'];
  /** 맵핑된 선택지 정보 */
  choice: Choice;
  /** 유저 타입(player Class) 아이디 Primary */
  choiceFromPlayerClassId: Scalars['Int'];
  /** 맵핑된 선택지 정보 ID */
  choiceId: Scalars['Int'];
  /** 유저 타입(player class)별 선택지 선택 확률 (어드민 + 사용자) */
  choiceRate?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  /** 해당 선택지를 선택한 유저 타입(playerClass) 정보 */
  playerClass: PlayerClass;
  /** 해당 선택지를 선택한 유저 타입(playerClass) ID */
  playerClassId: Scalars['Int'];
  /** 유저 타입(player class)별 선택지 선택 확률 (사용자) */
  realChoiceRate?: Maybe<Scalars['Float']>;
  /** 사용자가 선택한 선택지 번호 ex) 2 (최대 5까지) */
  selectedChoice: Scalars['Int'];
  /** 사용자가 선택한 누적 선택 합계 */
  totalCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** ChoiceFromPlayerClass 생성을 위한 input */
export type ChoiceFromPlayerClassCreationInput = {
  /** 어드민이 더하거나 뺀 선택 수 */
  adminAddCount?: InputMaybe<Scalars['Int']>;
  /** 맵핑된 선택지 정보 ID */
  choiceId: Scalars['Int'];
  /** 해당 선택지를 선택한 유저 타입(playerClass) ID */
  playerClassId: Scalars['Int'];
  /** 사용자가 선택한 선택지 번호 ex) 2 (최대 5까지) */
  selectedChoice: Scalars['Int'];
};

/** ChoiceFromPlayerClass 업데이트를 위한 input */
export type ChoiceFromPlayerClassUpdateInput = {
  /** 어드민이 더하거나 뺀 선택 수 */
  adminAddCount?: InputMaybe<Scalars['Int']>;
  /** 유저 타입(player Class) 아이디 Primary */
  id: Scalars['Int'];
};

/** 스토리 내의 캐릭터 정보 */
export type Chr = {
  __typename?: 'Chr';
  /** 아이디 */
  chrId: Scalars['Int'];
  /** 캐릭터가 사용하는 컬러 (#ffffff)와 같이 #이 붙어있는 7자 문자열을 사용한다. */
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 캐릭터 기본 이름 */
  defaultFirstName?: Maybe<Scalars['String']>;
  /** 캐릭터 기본 성 */
  defaultLastName?: Maybe<Scalars['String']>;
  /** 앱에서 보여지는 이름 */
  displayName: Scalars['String'];
  /** 캐릭터 성별 */
  gender?: Maybe<Gender>;
  /** 캐릭터 이미지 파일 */
  imageFile?: Maybe<File>;
  /** 캐릭터 이미지 파일 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 캐릭터 레이블 */
  label?: Maybe<Scalars['String']>;
  /** 이 캐릭터가 정식 캐릭터인가? */
  main: Scalars['Boolean'];
  /** 이름 */
  name: Scalars['String'];
  /** 해당 캐릭터의 온보딩팝업 */
  onboardingPopups?: Maybe<Array<OnboardingPopup>>;
  /** 스토리게임용 캐릭터 상반신 이미지 파일 */
  portraitFile?: Maybe<File>;
  /** 스토리게임용 캐릭터 상반신 이미지 파일 ID */
  portraitFileId?: Maybe<Scalars['Int']>;
  /** 스토리게임용 캐릭터 상반신 이미지 파일 (원본) */
  portraitFileRaw?: Maybe<File>;
  /** AI 로 생성시 활용되는 캐릭터의 기본 프롬프트 */
  prompt?: Maybe<Scalars['String']>;
  /** 캐릭터가 속한 스토리 */
  story: Story;
  /** 캐릭터가 속한 스토리 ID */
  storyId: Scalars['Int'];
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Chr 항목을 생성할 때 필요한 데이터 */
export type ChrPropsInput = {
  /** 캐릭터가 사용하는 컬러 (#ffffff)와 같이 #이 붙어있는 7자 문자열을 사용한다. */
  color?: InputMaybe<Scalars['String']>;
  /** 캐릭터 기본 이름 */
  defaultFirstName?: InputMaybe<Scalars['String']>;
  /** 캐릭터 기본 성 */
  defaultLastName?: InputMaybe<Scalars['String']>;
  /** 앱에서 보여지는 이름 */
  displayName?: InputMaybe<Scalars['String']>;
  /** 성별 */
  gender?: InputMaybe<Gender>;
  /** 아이디 */
  id?: InputMaybe<Scalars['Int']>;
  /** 캐릭터 이미지 파일 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile 에서 fileId 를 제공하면 이 값으로 업데이트 */
  imageFromStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 캐릭터 레이블 */
  label?: InputMaybe<Scalars['String']>;
  /** 이 캐릭터가 정식 캐릭터인가? */
  main?: InputMaybe<Scalars['Boolean']>;
  /** 캐릭터 이미지 파일 */
  mainImage?: InputMaybe<Scalars['Upload']>;
  /** 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** (배경 인물 이미지 - 상반신) StoryHasStudioFile 에서 fileId 를 제공하면 이 값으로 업데이트 */
  portraitFromStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 캐릭터의 의상 프롬프트 (AI 자케 생성) */
  prompt?: InputMaybe<Scalars['String']>;
  /** 캐릭터가 속한 스토리 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 결제 퍼널을 줄이기 위해 작품, 회차에 연결될 수 있는 상품을 관리 */
export type CoinProduct = {
  __typename?: 'CoinProduct';
  /** 구매시 획득하는 코인 갯수 */
  amount: Scalars['Int'];
  /** chapter */
  chapter?: Maybe<Chapter>;
  /** chapter id */
  chapterId?: Maybe<Scalars['Int']>;
  /** (deprecated: countryCodeV3을 참조하세요) 인앱 코인 나라코드 */
  countryCode?: Maybe<Scalars['String']>;
  /** 인앱 코인 나라코드 */
  countryCodeV3?: Maybe<Scalars['String']>;
  /** countryCodeV3에 해당하는 지역에 대한 정보 */
  countryV3?: Maybe<Country>;
  createdAt: Scalars['DateTime'];
  /** 인앱 코인 환율 */
  currency?: Maybe<Scalars['String']>;
  /** 수동으로 입력되는 정수형 할인율. 현재는 앱에서 스토어에서 받은 가격과 이 값으로 원가를 계산하는데 사용됨 */
  discountRate: Scalars['Int'];
  /** float type 가격에 대한 정수형 할인율. 수동으로 입력되어야 한다. */
  discountRateForFloat: Scalars['Float'];
  /** 아이디 */
  inappCoinId: Scalars['Int'];
  /** 스토어에 등록 되어 있는 인앱 상품 ID */
  inappProductId: Scalars['String'];
  /** (deprecated: languageCode를 참조하세요) 인앱 코인 언어코드 */
  langCode: Scalars['String'];
  /** 인앱 코인 언어 */
  language?: Maybe<Language>;
  /** 인앱 코인 언어코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 코인의 할인 전 구매 가격(원) */
  originalPrice: Scalars['Int'];
  /** 코인의 할인 전 구매 가격(실수형) */
  originalPriceFloat?: Maybe<Scalars['Float']>;
  /** 클라이언트의 os */
  os: Os;
  /** 코인의 구매 가격(원) */
  price: Scalars['Int'];
  /** 코인의 구매 가격(실수형) */
  priceFloat: Scalars['Float'];
  /** 본 상품이 속할 모델의 ID로 productType에 따라 모델이 달라질 수 있음. 현재는 productType이 ai-character일 때 OnboardingPopupId를 참조. 확장성을 위해 FK 설정하지 않음 */
  productId?: Maybe<Scalars['Int']>;
  /** 앱에 노출될 코인 상품 이름 */
  productName: Scalars['String'];
  /** coin, ai-character(자캐 생성). 코인 전용에서 인앱 상품으로 테이블이 확장됨 */
  productType?: Maybe<Scalars['String']>;
  /** 목록내에서의 상품 정렬 순서 */
  seq: Scalars['Int'];
  /** story */
  story: Story;
  /** story id */
  storyId: Scalars['Int'];
  /** 태그 정보 */
  tag?: Maybe<InappCoinTag>;
  updatedAt: Scalars['DateTime'];
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

/** 엔딩 + 수집정보 */
export type CollectibleEnding = {
  __typename?: 'CollectibleEnding';
  /** 엔딩 정보 */
  ending: Ending;
  /** 유저의 엔딩 획득 정보 */
  userHasEnding?: Maybe<UserHasEnding>;
};

/** 엔딩 수집과 관련하여 보여줄 수 있는 것들 */
export type CollectibleEndingOutput = {
  __typename?: 'CollectibleEndingOutput';
  /** 엔딩과 사용자의 수집정보 */
  endings: Array<CollectibleEnding>;
  /** 작품이 종료되어 엔딩 숫자가 결정된 상태인가? */
  isStoryFinished: Scalars['Boolean'];
  /** 현재까지 등록된 엔딩의 종류 */
  numTotalEnding: Scalars['Int'];
};

export type Color = {
  __typename?: 'Color';
  /** 아이디 */
  colorId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** color 값 hex */
  hex: Scalars['String'];
  /** color name */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Color 생성을 위한 input */
export type ColorCreationInput = {
  /** color 값 hex */
  hex: Scalars['String'];
  /** color name */
  name: Scalars['String'];
};

/** Color 업데이트를 위한 input */
export type ColorUpdateInput = {
  /** color 값 hex */
  hex: Scalars['String'];
  /** 아이디 */
  id: Scalars['Int'];
  /** color name */
  name: Scalars['String'];
};

/** 결제 요청을 위한 input */
export type CompleteImpPaymentsInput = {
  /** 결제 결과에 포함되어 있는 impUID */
  impUID: Scalars['String'];
};

/** 토스 페이먼츠 결제 승인을 위한 input */
export type ConfirmTossPaymentsInput = {
  /** 결제한 금액(float) */
  amountFloat: Scalars['Float'];
  /** 스플 서버에서 생성해준 결제 orderId */
  orderId: Scalars['String'];
  /** 결제한 기기 OS */
  os: Os;
  /** 결제 타입 OVERSEAS: 해외(해와카드사 + 페이팔) DOMESTIC: 국내(국내카드사 + 휴대폰 등등) */
  payType: Toss_Payments_Pay_Type;
  /** 토스 페이먼츠에서 결제후 발급해준 paymentsKey */
  paymentKey: Scalars['String'];
};

/** 작품에 업적 프로젝트를 연계하기 위한 입력값. */
export type ConnectStoryToAchievementProjectInput = {
  /** 프로젝트의 ID */
  projId: Scalars['String'];
  /** storyId */
  storyId: Scalars['Int'];
};

/** 진행중인 공모전 배너 정보 */
export type ContestBannerInfo = {
  __typename?: 'ContestBannerInfo';
  /** 공모전 배너 링크 */
  bannerStyleText: Scalars['String'];
  /** 공모전 배너 타이틀 */
  bannerTitle: Scalars['String'];
  /** 공모전 링크 */
  link: Scalars['String'];
};

/** 공모전에 참여한 작품 */
export type ContestHasStory = {
  __typename?: 'ContestHasStory';
  /** 일련번호 */
  chsId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 선택한 숫자들 (심사위원 번호 1~15 중 5개) */
  selectedNumbers: Array<Scalars['Int']>;
  /** 공모전에 참여한 작품 */
  story: Story;
  /** 공모전에 참여한 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 현재 공모전 버전 */
  version: Scalars['Int'];
};

/** 콘테스트에 참여하는데 필요한 입력값 */
export type ContestHasStoryCreationInput = {
  /** 선택한 번호들 (심사위원 번호) 콤마로 구분된 문자열 */
  selectedNumbers: Array<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 콘테스트 참여 정보를 수정하는데 필요한 입력값 */
export type ContestHasStoryUpdateInput = {
  /** 선택한 번호 (심사위원 번호) 콤마로 구분된 문자열 */
  selectedNumbers?: InputMaybe<Array<Scalars['Int']>>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 진행중인 공모전 정보 조회 */
export type ContestInfo = {
  __typename?: 'ContestInfo';
  /** 공모전 종료 시각 */
  endAt: Scalars['DateTime'];
  /** 공모전 링크 */
  link: Scalars['String'];
  /** 공모전 시작 시각 */
  startAt: Scalars['DateTime'];
  /** 공모전 타이틀 */
  title: Scalars['String'];
  /** 해당 공모전의 작품 타입 */
  type: Story_Play_Type;
  /** 공모전 버전 */
  version: Scalars['Int'];
};

export type ConversionOfStoryResponse = {
  __typename?: 'ConversionOfStoryResponse';
  /** 전일 또는 전주 대비 전환율 변화 */
  changedRate: Scalars['Float'];
  /** 그래프를 그리기 위한 정보 */
  dataForGraph: Array<ConversionRateOfStoryRow>;
  /** 조회 기간의 1->3화 전환율 */
  latestRate: Scalars['String'];
  /** 상세내역 */
  list: Array<ConversionStatOfStoryRow>;
  /** 1화 시작 총 인원 */
  total: Scalars['Int'];
};

/** 전환율 그래프 row */
export type ConversionRateOfStoryRow = {
  __typename?: 'ConversionRateOfStoryRow';
  /** 급락 구간 여부 */
  isDrop?: Maybe<Scalars['Boolean']>;
  /** 전환율 */
  rate: Scalars['String'];
  /** x축 텍스트 */
  x: Scalars['String'];
};

/** 상세 내역 */
export type ConversionStatOfStoryRow = {
  __typename?: 'ConversionStatOfStoryRow';
  /** 1화 플레이어 수 */
  chapter1: Scalars['Int'];
  /** 2화 플레이어 수 */
  chapter2: Scalars['Int'];
  /** 3화 플레이어 수 */
  chapter3: Scalars['Int'];
  /** 마지막 회차 플레이어 수 */
  lastChapter: Scalars['Int'];
  /** 기간 */
  period: Scalars['String'];
  /** 1화 -> 2화 전환율 */
  to2Rate: Scalars['String'];
  /** 1화 -> 3화 전환율 */
  to3Rate: Scalars['String'];
  /** 1 -> 마지막 회차 전환율 (정주행률) */
  toLastRate: Scalars['String'];
};

/** 텍스트를 조건를 목소리로 변환합니다. */
export type ConvertTextToVoiceInput = {
  /** TTS 서버 accessToken */
  accessToken?: InputMaybe<Scalars['String']>;
  /** TTS 목소리의 속도, 높낮이 설정 값 */
  dpeId: Tts_Dpe_Id;
  /** TTS 발화자의 감정 */
  emotion: Tts_Emotion;
  /** 발화자 ID */
  speakerId: Scalars['Int'];
  /** TTS로 변환할 문장 공백 포함 최대 200자 */
  text: Scalars['String'];
};

/** PlayerReport2 보고서 복사에 필요한 데이터 */
export type CopyPlayerReport2Input = {
  /** 복제본 보고서의 이름 */
  name: Scalars['String'];
  /** 복사할 플레이어 보고서 아이디 */
  prId: Scalars['Int'];
};

/** 지역 코드 엔티티 */
export type Country = {
  __typename?: 'Country';
  /** 어드민에서 true인 것만 콘텐츠 허용 국가로 설정 가능 */
  activated: Scalars['Boolean'];
  /** 지역 코드. ISO 3166 2 code */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 해당 코드에 대한 설명 */
  description?: Maybe<Scalars['String']>;
  /** 해당 지역에서 메인으로 사용되는 언어 */
  majorLanguage: Language;
  /** 해당 지역에서 메인으로 사용되는 언어 코드 */
  majorLanguageCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Country 생성을 위한 input */
export type CountryCreationInput = {
  /** 지역 코드. ISO 3166 2 code */
  code: Scalars['String'];
  /** 해당 코드에 대한 설명 */
  description: Scalars['String'];
};

/** Country 업데이트를 위한 input */
export type CountryUpdateInput = {
  /** 지역 코드. ISO 3166 2 code */
  code: Scalars['String'];
  /** 해당 코드에 대한 설명 */
  description?: InputMaybe<Scalars['String']>;
};

/** 다날 결제를 위해 주문서를 생성합니다. */
export type CreateDanalPaymentInput = {
  /** 언어 코드 ko: WON, 그외 DOLLAR */
  langCode: Language_Code;
  /** 상품 아이디 */
  productId: Scalars['String'];
  /** redirect 될 url */
  redirectUrl: Scalars['String'];
  /** wp: Desktop Browser, mp: Mobile Browser */
  userAgent: Danal_Payment_User_Agent;
};

/** 선택 팝업 선택지 등록 */
export type CreateOnboardingPopupChoiceInput = {
  /** 선책지 속성값 display: 사용자에게 보여지는 초기 속성값, effect: 사용자에게 보여지지 않는 초기 속성값 */
  choiceProps?: InputMaybe<Scalars['JSONObject']>;
  /** 선택지 텍스트 */
  content?: InputMaybe<Scalars['String']>;
  /** 팝업 타입이 Profile 일때는 프로필 이미지, Choice 일때는 선택지 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 유저 타입(playerClass) ID */
  playerClassId?: InputMaybe<Scalars['Int']>;
  /** 온보딩 팝업 ID */
  popupId: Scalars['Int'];
  /** 선택지 순서 숫자가 높으면 먼저 노출 */
  showOrder: Scalars['Int'];
  /** 선택지 상태값 */
  status?: InputMaybe<Status>;
};

/** 선택 팝업 등록 */
export type CreateOnboardingPopupInput = {
  /** 등장인물 AI 캐릭터로 활성화 여부 */
  canAICharacter?: InputMaybe<Scalars['Boolean']>;
  /** 주인공 이름/상대방 이름 팝업에 프로필 이미지 업로드 가능 여부 */
  canUploadProfileImage?: InputMaybe<Scalars['Boolean']>;
  /** 팝업이 속한 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 기타 인물 원래 캐릭터 ID */
  characterId?: InputMaybe<Scalars['Int']>;
  /** 팝업의 지문/질문 */
  description: Scalars['String'];
  /** 해당 팝업에 대해 최대 광고 가능 횟수 */
  maxAdCount?: InputMaybe<Scalars['Int']>;
  /** 이름 팝업 이름만 입력할지 여/부 다른 팝업은 default false */
  onlyName: Scalars['Boolean'];
  /** 팝업에 대한 coin 비용 */
  price?: InputMaybe<Scalars['Int']>;
  /** 팝업 순서 숫자가 높으면 먼저 노출 */
  showOrder: Scalars['Int'];
  /** Active: 활성, Inactive: 비활성 */
  status?: InputMaybe<Status>;
  /** 팝업이 속한 스토리 아이디 */
  storyId: Scalars['Int'];
  /** Text: 텍스트, Choice: 선택, Profile: 프로필, Name: 주인공 이름, OtherName: 상대역 이름 */
  type: Onboarding_Popup_Type;
  /** 온보딩 파업 노출 조건(속성 값(number)으로만 설정 가능) */
  visibleConditions?: InputMaybe<Array<OnboardingPopupVisibleConditionsInput>>;
};

/** OnboardingPopupProfileImage 생성을 위한 input */
export type CreateOnboardingPopupProfileImageInput = {
  /** 프로필 이미지 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 온보딩 팝업 ID */
  popupId: Scalars['Int'];
  /** 프로필 이미지 파일 */
  profileImageFile: Scalars['Upload'];
  /** 숫자가 높으면 먼저 노출 */
  showOrder: Scalars['Int'];
  /** 프로필 이미지 상태값 */
  status: Status;
};

/** 커스텀 프로필 이미지 파일 정보 */
export type CustomProfileFileInfo = {
  /** 원본 파일 이름 */
  fileName: Scalars['String'];
  /** s3에 저장된 파일의 key 값 */
  hashId: Scalars['String'];
  /** s3에 임시저장소에 저장되었는지 여부 */
  isTemp: Scalars['Boolean'];
  /** 저장된 스토리지 정보 */
  storageType: Storages;
};

/** 커스텀 스크립트 데이터가 담겨있다. */
export type CustomScript = {
  __typename?: 'CustomScript';
  createdAt: Scalars['DateTime'];
  /** 커스텀 스크립트 아이디 */
  customScriptId: Scalars['Int'];
  /** customScriptType마다 쓸수있는 uniqueKey 01111 과 같은 선택지 조합으로 사용된다. */
  customScriptKey: Scalars['String'];
  /** 커스텀 스크립트의 타입 원격 스크립트의 provider의 키값을 가져온다. */
  customScriptProvider: Scalars['String'];
  /** script 형태로 들어 있어, 서버는 별도의 파싱이 필요하지 않다. JSONString */
  customScripts: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 다날 결제 진행 상태 */
export enum Danal_Payment_Status {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Ready = 'READY',
  Request = 'REQUEST'
}

/** 다날 사용자 접속 환경 */
export enum Danal_Payment_User_Agent {
  Wm = 'WM',
  Wp = 'WP'
}

/** 직영광고 타입 */
export enum Direct_Ads_Type {
  ChapterEnding = 'ChapterEnding',
  Details = 'Details',
  History = 'History',
  Home = 'Home',
  More = 'More',
  Splash = 'Splash'
}

/** 휴면 계정 처리 로그 타입 */
export enum Dormant_User_Log_Type {
  Terminate = 'Terminate',
  Transition = 'Transition',
  Warning = 'Warning'
}

/** 예약 푸시 이벤트 */
export type DpPushReservationEvent = {
  __typename?: 'DPPushReservationEvent';
  createdAt: Scalars['DateTime'];
  /** 예약 푸시 이벤트 일련번호 */
  dprId: Scalars['Int'];
  /** 푸시가 되었는지 */
  isPushed?: Maybe<Scalars['Boolean']>;
  /** 해당 푸시 이벤트가 진행된 시간 */
  processedAt?: Maybe<Scalars['DateTime']>;
  /** 푸시되어야 할 시간 */
  pushAt: Scalars['DateTime'];
  /** 예약 푸시 이벤트를 생성한 룰셋 */
  ruleSetData: DpRuleSetData;
  /** 예약 푸시 이벤트를 생성한 룰셋 아이디 */
  ruleSetId: DpSourceEvent;
  /** 원본 이벤트 */
  sourceEvent: DpSourceEvent;
  /** 원본 이벤트 아이디 */
  sourceEventId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** DP RuleSet Data */
export type DpRuleSetData = {
  __typename?: 'DPRuleSetData';
  createdAt: Scalars['DateTime'];
  /** RuleSet Name For Manage */
  data: Scalars['String'];
  /** pk */
  dpRuleSetId: Scalars['Int'];
  /** RuleSet Name For Manage */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** DP source Event stream */
export type DpSourceEvent = {
  __typename?: 'DPSourceEvent';
  createdAt: Scalars['DateTime'];
  /** pk */
  dpSourceEventId: Scalars['Int'];
  /** Extra Custom Data */
  extra?: Maybe<Scalars['String']>;
  /** Event Name */
  name: Scalars['String'];
  /** numParam 1 */
  np1: Scalars['Int'];
  /** numParam 2 */
  np2: Scalars['Int'];
  /** numParam 3 */
  np3: Scalars['Int'];
  /** 푸시 메세지를 보낼때 필요한 데이터값을 담고 있습니다. */
  payload?: Maybe<Scalars['String']>;
  /** StrParam 1 */
  sp1: Scalars['String'];
  /** StrParam 2 */
  sp2: Scalars['String'];
  /** uniqueKey */
  uniqueKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** userId */
  userId: Scalars['Int'];
};

/** 정액권의 일일 보상 코인 정보 */
export type DailyRewardCoin = {
  __typename?: 'DailyRewardCoin';
  /** 일일 보상 코인 갯수 */
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  drcId: Scalars['Int'];
  /** 정액권 코인 정보 */
  subsCoin: SubsCoin;
  /** 정액권 코인의 ID */
  subsCoinId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** DailyRewardCoin을 생성할 때 필요한 데이터 */
export type DailyRewardCoinInput = {
  /** 일일 보상 코인 갯수 */
  amount: Scalars['Int'];
  /** 정액권 코인의 ID */
  subsCoinId: Scalars['Int'];
};

/** 일별로 쌓이는 누적 번들 통계 */
export type DailyStoryBundleStat = {
  __typename?: 'DailyStoryBundleStat';
  /** 대상 국가 */
  country: Country;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 통계 값이 쌓인 날짜 토큰(YYYY-MM-DD) */
  dateTokenKST: Scalars['String'];
  /** 대상 번들의 현재까지 달린 모든 댓글 수 */
  numComment: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 좋아요 수 */
  numLikes: Scalars['Int'];
  /** 대상 번들의 총 회차 플레이 수 */
  numPlay: Scalars['Int'];
  /** 대상 번들의 총 작품상세 조회수 */
  numViews: Scalars['Int'];
  /** 통계 대상 번들 */
  storyBundle: StoryBundle;
  /** 통계 대상 번들 아이디 */
  storyBundleId: Scalars['Int'];
  /** 아이디 */
  storyBundleStatId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 매일 00시 정식작품 인기순위 통계 테이블 */
export type DailyStoryRanking = {
  __typename?: 'DailyStoryRanking';
  /** 순위 변동값 */
  amountChange: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  dailyStoryRankingId: Scalars['Int'];
  /** 장르가 null이면 전체 장르에 대한 랭킹 */
  genre?: Maybe<Scalars['String']>;
  /** 새롭게 순위권 진입했는가? */
  isNew: Scalars['Boolean'];
  /** 작품의 순위 */
  ranking: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 생성할때마다 1씩 증가 */
  version: Scalars['Int'];
};

/** UGC 작품의 랭킹 정보 */
export type DailyUgcStoryRanking = {
  __typename?: 'DailyUGCStoryRanking';
  /** 순위 변동값 */
  amountChange: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  dailyUGCStoryRankingId: Scalars['Int'];
  /** 장르가 null이면 전체 장르에 대한 랭킹 */
  genre?: Maybe<Scalars['String']>;
  /** 새롭게 순위권 진입했는가? */
  isNew: Scalars['Boolean'];
  /** UGC 작품의 순위 */
  ranking: Scalars['Int'];
  /** UGC 작품 */
  story: Story;
  /** UGC 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 생성할때마다 1씩 증가 */
  version: Scalars['Int'];
};

/** 다날 결제 정보 */
export type DanalPayment = {
  __typename?: 'DanalPayment';
  createdAt: Scalars['DateTime'];
  /** 고유 아이디 */
  danalPaymentId: Scalars['Int'];
  /** 예외가 발생했다면 에러 메세지 */
  error?: Maybe<Scalars['String']>;
  /** 언어 코드 ko: WON, 그외 DOLLAR */
  langCode: Scalars['String'];
  /** 스플에서 부여한 주문번호 */
  orderId: Scalars['String'];
  /** 상품 아이디 */
  productId: Scalars['String'];
  /** client 의 redirect Url */
  redirectUrl: Scalars['String'];
  /** 결제 상태 */
  status: Danal_Payment_Status;
  /** 다날에서 부여한 주문번호 */
  tId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 결제한 사용자 */
  user: User;
  /** wp: Desktop Browser, mp: Mobile Browser */
  userAgent: Scalars['String'];
  /** 결제한 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 주문서 생성 Response */
export type DanalPaymentResponse = {
  __typename?: 'DanalPaymentResponse';
  /** 상품 가격 */
  amount: Scalars['String'];
  /** 재화 종류 */
  currency: Scalars['String'];
  /** 상품 아이디 */
  itemName: Scalars['String'];
  /** 스플에서 부여한 주문번호 */
  orderId: Scalars['String'];
  /** client 의 redirect Url */
  redirectUrl: Scalars['String'];
  /** 서비스 이름 (STORYPLAY 고정) */
  serviceName: Scalars['String'];
  /** wp: Desktop Browser, mp: Mobile Browser */
  userAgent: Scalars['String'];
};

/** 세부 푸시 알림 설정 */
export type DetailPushSetting = {
  /** 작가 알림 푸시 수신 여부 */
  authorUpdatePushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 댓글/대댓글 좋아요 알림 푸시 수신 여부 */
  commentLikePushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 추천작품/이벤트 혜택 알림 푸시 수신 여부 */
  eventPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 관심 스토리 알림 푸시 수신 여부 */
  likeStoryPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 대댓글 알림 푸시 수신 여부 */
  nestedCommentPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 야간 푸시 수신 여부 */
  nightPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 일반 푸시 수신 여부 */
  pushEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** 직영 광고 엔티티 */
export type DirectAds = {
  __typename?: 'DirectAds';
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 삭제된 시각 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 아이디 */
  directAdsId: Scalars['Int'];
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: Maybe<Scalars['String']>;
  /** 광고 이미지 */
  imageFile: ImageFile;
  /** 광고 이미지 아이디 */
  imageFileId: Scalars['Int'];
  /** 해당 광고 숨김 여부 (true: 숨김) */
  isHidden: Scalars['Boolean'];
  /** 광고를 눌렀을 때 연결되는 web or app link */
  link?: Maybe<Scalars['String']>;
  /** 직영 광고 이름 */
  title: Scalars['String'];
  /** 광고 타입(Splash, Home, Details, History) */
  type: Direct_Ads_Type;
  updatedAt: Scalars['DateTime'];
};

/** DirectAds 생성을 위한 input */
export type DirectAdsCreationInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 광고 이미지 */
  imageFile: Scalars['Upload'];
  /** 해당 광고 숨김 여부 (true: 숨김) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 광고를 눌렀을 때 연결되는 web or app link */
  link?: InputMaybe<Scalars['String']>;
  /** 직영 광고 이름 */
  title: Scalars['String'];
  /** 광고 타입(Splash, Home, Details, History) */
  type: Direct_Ads_Type;
};

/** 단일 DirectAds 가져오기 위한 input */
export type DirectAdsTypeInput = {
  /** 직영광고 타입 */
  type: Direct_Ads_Type;
};

/** DirectAds 업데이트를 위한 input */
export type DirectAdsUpdateInput = {
  /** 웹에서 link 접속 실패했을 때 폴백 링크(aOS) */
  aOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 웹에서 link 접속 실패했을 때 폴백 링크(iOS) */
  iOSFallbackLink?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 광고 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 해당 광고 숨김 여부 (true: 숨김) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 광고를 눌렀을 때 연결되는 web or app link */
  link?: InputMaybe<Scalars['String']>;
  /** 직영 광고 이름 */
  title?: InputMaybe<Scalars['String']>;
};

export type DormantUser = {
  __typename?: 'DormantUser';
  /** 작품 관련 이메일 연락처 */
  authorEmail?: Maybe<Scalars['String']>;
  /** 일반 사용자가 작가로 전환시 작성한 필명 */
  authorName?: Maybe<Scalars['String']>;
  /** 계정정지 사유 */
  banReason?: Maybe<Scalars['String']>;
  /** 계정정지된 시각 (해당 필드가 있으면 정지된 계정) */
  bannedAt?: Maybe<Scalars['DateTime']>;
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: Maybe<Scalars['String']>;
  /** 해당 사용자가 스프레드 시트 export 할 수 있는지 여부 */
  canExportStory?: Maybe<Scalars['Boolean']>;
  /** 성인인증 한 날짜 */
  certificatedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** 현재 보유한 코인의 갯수 */
  currentCoin: Scalars['Int'];
  /** 탈퇴한 사용자의 탈퇴 이유 */
  deactivateReason?: Maybe<Scalars['String']>;
  /** 로그인용 이메일 주소 */
  email: Scalars['String'];
  /** 유저가 처음으로 스토어를 방문한 시각으로 패키지 상품의 타임세일 기준값이 됨. 최초 한번만 업데이트가 됨. */
  firstStoreVisitedAt?: Maybe<Scalars['DateTime']>;
  /** 사용자가 익명 사용자인가? */
  isAnonymous: Scalars['Boolean'];
  /** 개발자 여부 */
  isDev?: Maybe<Scalars['Boolean']>;
  /** 해당 사용자가 내부 작가인지 여부 */
  isInternalAuthor?: Maybe<Scalars['Boolean']>;
  /** 프리미엄 사용자인가? (현재는 구독중일때만 true) */
  isPremium: Scalars['Boolean'];
  /** 사용자가 푸시 동의를 받아야 하는 상황인가? */
  isPushInfoRequired: Scalars['Boolean'];
  /** 사용자가 테스터인가? */
  isTester: Scalars['Boolean'];
  /** 마지막 접속 시각 */
  lastAccessTime: Scalars['DateTime'];
  /** 마지막 접속 날짜 토큰 (YYYY-MM-DD) */
  lastLoginToken: Scalars['String'];
  /** 마지막으로 비밀번호가 변경된 또는 "다음에 변경하기"를 누른 시각 */
  lastPasswordModifiedAt?: Maybe<Scalars['DateTime']>;
  /** 탈퇴한 경우 탈퇴시각 */
  leftAt?: Maybe<Scalars['DateTime']>;
  /** 마케팅 푸시가 허용된 경우, 허용시각 */
  marketingPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: Maybe<Scalars['String']>;
  /** 야간푸시가 허용된 경우, 허용시각 */
  nightPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 사용자가 등록한 otp Key, 등록하지 않은 경우 null */
  otpKey?: Maybe<Scalars['String']>;
  /** 비밀번호 변경한지가 너무 오래되었다. */
  passwordTooOld?: Maybe<Scalars['Boolean']>;
  /** 휴대폰 번호 (010-1111-2222) */
  phone?: Maybe<Scalars['String']>;
  /** 사용자 권한 레벨 */
  priv: Scalars['Int'];
  /** 프로필 파일 */
  profileFile?: Maybe<UserProfileFile>;
  /** 프로필 이미지 아이디 */
  profileId?: Maybe<Scalars['Int']>;
  /** 푸시가 허용된 경우, 허용시각 */
  pushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 익명 사용자가 일반 사용자로 변경된 시각 */
  registeredAt?: Maybe<Scalars['DateTime']>;
  /** 연동된 소셜 로그인 계정들 */
  ssoLogins: Array<Sso>;
  /** 개정된 약관 동의 시각. 2022-07-27 현재 서비스 약관, 개인정보처리방침 동의를 이 필드 하나로 관리 */
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
  /** 현재까지 소비한 코인의 총 갯수 */
  totalCoinUsed: Scalars['Int'];
  /** 읽지 않은 알림의 갯수 */
  unread: Scalars['Int'];
  /** 일반 사용자가 작가로 변경된 시각 (약관 동의 시점) */
  updateToAuthorAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
  /** 이메일 인증시, 인증된 시각 */
  verifiedAt?: Maybe<Scalars['DateTime']>;
};

/** 휴면 계정 전환 관련 로그 */
export type DormantUserLog = {
  __typename?: 'DormantUserLog';
  createdAt: Scalars['DateTime'];
  /** 로그 아이디 */
  logId: Scalars['Int'];
  /** 실행 결과 */
  result?: Maybe<Scalars['String']>;
  /** 로그 타입 (Warning: 휴면 30일전 안내, Transition: 휴면 전환, Terminate: 휴면 해지) */
  type: Dormant_User_Log_Type;
  updatedAt: Scalars['DateTime'];
  /** 대상 유저 */
  user: User;
  /** 대상 유저 ID */
  userId: Scalars['Int'];
};

/** drop 된 푸시 이력 */
export type DropPush = {
  __typename?: 'DropPush';
  /** FCM notification.body */
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** FCM data (JSON object) */
  data: Scalars['String'];
  /** 아이디 */
  dropPushId: Scalars['Int'];
  /** FCM notification.imageUrl */
  imageUrl?: Maybe<Scalars['String']>;
  /** snooze 정보 */
  snooze: Scalars['String'];
  /** FCM notification.title */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 푸쉬 메시지를 받은 유저 */
  user: User;
  /** 유저의 아이디 */
  userId: Scalars['Int'];
};

/** 두 개의 아이디 Input */
export type DualIdInput = {
  /** first id */
  firstId: Scalars['Int'];
  /** second id */
  secondId: Scalars['Int'];
};

/** 엔딩 카드 타입 */
export enum Ending_Card_Type {
  ArrivalRate = 'ArrivalRate',
  ChoiceCount = 'ChoiceCount',
  EndingCollectRate = 'EndingCollectRate'
}

/** 범위 level */
export enum Ending_Rate_Range_Level {
  Minority = 'Minority',
  Normal = 'Normal',
  Rarity = 'Rarity',
  Unknown = 'Unknown',
  VeryRarity = 'VeryRarity'
}

/** EPUB파일을 저장할 테이블 */
export type EpubFile = {
  __typename?: 'EPUBFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  /** 파일 사이즈 */
  size: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 이메일을 이용한 로그인 정보 */
export type EmailLogin = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** 이메일을 이용한 가입 정보 */
export type EmailSignup = {
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
};

/** 이메일을 이용한 가입 정보 */
export type EmailSignupByAdmin = {
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  /** 사용자의 권한 */
  priv: Scalars['Int'];
};

/** 이메일을 이용한 가입 정보 */
export type EmailSignupV2 = {
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  /** 유저가 동의한 약관 */
  termsToAccept: AcceptTermsInput;
};

/** 이메일을 이용한 가입 정보 */
export type EmailSignupV3 = {
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  /** 유저가 동의한 약관. 국가별로 약관 종류 다름 */
  termsToAccept: AcceptTermsInputV2;
};

/** 입력값을 넣을 필요가 없는 Input */
export type EmptyInput = {
  /** Just ignore this input. */
  ignoreMe?: InputMaybe<Scalars['String']>;
};

export type EnabledPurchasedChapter = {
  __typename?: 'EnabledPurchasedChapter';
  /** 해당 스토리에서 현재 구매할수 있는 회차 개수 */
  chapterCount?: Maybe<Scalars['Int']>;
  /** 개별 구매한 회차를 제외한 나머지 회차를 구매를 위해 필요한 코인 개수 */
  coinAmount: Scalars['Int'];
  /** 구매 가능한 회차 중 19금이 포함되어 있으면 true */
  hasAdultChapter: Scalars['Boolean'];
};

/** 챕터에 존재하는 각종 엔딩들 */
export type Ending = {
  __typename?: 'Ending';
  /** 해당 엔딩에 추가적인 엔딩카드 리스트 */
  additionalCards: Array<EndingCard>;
  /** 앱에 내려줄 도착률 실제값/세팅값/미표시에 따라 값이 다르게 세팅됨 */
  arrivalRate?: Maybe<Scalars['Float']>;
  /** 엔딩 도착률 */
  arrivalRateByAdmin?: Maybe<Scalars['Int']>;
  /** 속하는 챕터 */
  chapter: Chapter;
  /** 속하는 챕터의 ID */
  chapterId: Scalars['Int'];
  /** 엔딩의 컬렉션 제목 */
  collectionDescription: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 작가가 스프레드시트에 할당한 엔딩 아이디 */
  customId?: Maybe<Scalars['String']>;
  /** 서버에서 계산한 엔딩 도착률 (매일 업데이트 된다) */
  dailyChangeRate?: Maybe<Scalars['Float']>;
  /** 실제로 표시될 엔딩이름 */
  displayName: Scalars['String'];
  /** 아이디 */
  endingId: Scalars['Int'];
  /** 엔딩 이미지 */
  imageFile?: Maybe<File>;
  /** 엔딩 이미지 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 이 엔딩으로써 스토리가 종료되는가? */
  isFinal: Scalars['Boolean'];
  /** 엔딩의 이름 */
  name: Scalars['String'];
  /** 엔딩 부분 캡쳐 이미지 */
  previewImageFile?: Maybe<File>;
  /** 엔딩 부분 캡쳐 이미지 ID */
  previewImageFileId?: Maybe<Scalars['Int']>;
  /** 엔딩 미리보기 이름 */
  previewName?: Maybe<Scalars['String']>;
  /** 도착률에 따른 범위 보통, 희소, 매우 희소, 최고 희소 */
  rateLevel?: Maybe<Ending_Rate_Range_Level>;
  /** 서버에서 마지막으로 엔딩도착률 계산한 일자 */
  rateUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 실제엔딩 도착률 */
  realArrivalRate?: Maybe<Scalars['Float']>;
  /** 공유용 해시 */
  shareKey: Scalars['String'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 엔딩이 속한 스토리 (챕터를 통해서 알 수 있지만, DB query 를 편하게 하기 위해 추가적으로 저장한다. */
  story: Story;
  /** 엔딩이 속한 스토리 ID */
  storyId: Scalars['Int'];
  /** 엔딩에 연결된 스토리 정보 */
  storyProperties?: Maybe<Array<EndingShowsProperty>>;
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 해당 엔딩을 가진 사용자 리스트 */
  userHasEndings: Array<UserHasEnding>;
};

/** 최종 엔딩 카드를 관리하는 테이블 */
export type EndingCard = {
  __typename?: 'EndingCard';
  /** 해당 엔딩 엔딩 도착률 */
  arrivalRate?: Maybe<Scalars['Float']>;
  /** 아이디 */
  cardId: Scalars['Int'];
  /** 엔딩 카드에서 선택한 캐릭터 */
  character: Chr;
  /** 엔딩 카드에서 선택한 캐릭터 ID */
  characterId: Scalars['Int'];
  /** 스토리 플레이 전체 선택의 기로 선 횟수 */
  choiceCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 엔딩카드에 해당하는 엔딩 */
  ending: Ending;
  /** 작품 내에서 엔딩 컬렉션 수집 완료율 */
  endingCollectRate?: Maybe<Scalars['Float']>;
  /** 엔딩카드에 해당하는 엔딩 ID */
  endingId: Scalars['Int'];
  /** 도착률에 따른 범위 보통, 희소, 매우 희소, 최고 희소 */
  rateLevel?: Maybe<Ending_Rate_Range_Level>;
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 엔딩 카드 타입 */
  type: Ending_Card_Type;
  updatedAt: Scalars['DateTime'];
};

/** 엔딩카드 생성을 위한 입력값 */
export type EndingCardCreationInput = {
  /** 엔딩 카드에서 선택한 캐릭터 ID */
  characterId: Scalars['Int'];
  /** 엔딩카드에 해당하는 엔딩 ID */
  endingId: Scalars['Int'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 엔딩 카드 타입 */
  type: Ending_Card_Type;
};

/** 엔딩카드 수정을 위한 입력값 */
export type EndingCardUpdateInput = {
  /** 엔딩 카드에서 선택한 캐릭터 ID */
  characterId?: InputMaybe<Scalars['Int']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
};

/** 작품 동기화시 엔딩 마이그레이션을 위한 입력값 */
export type EndingMigrationInput = {
  /** 엔딩 변경 매핑 */
  mapping: Array<EndingMigrationMapping>;
  /** 작품의 ID */
  storyId: Scalars['Int'];
};

/** 어떤 엔딩을 어떤 엔딩으로 migration 할 것인가? */
export type EndingMigrationMapping = {
  /** 엔딩들이 속한 챕터의 ID */
  chapterId: Scalars['Int'];
  /** 삭제될 엔딩의 customId */
  from: Scalars['String'];
  /** migration 할 엔딩의 customId */
  to: Scalars['String'];
};

/** 엔딩 항목을 업데이트 할 때 필요한 데이터 */
export type EndingPropsInput = {
  /** 엔딩 도착률 */
  arrivalRateByAdmin?: InputMaybe<Scalars['Int']>;
  /** 실제로 표시될 엔딩이름 */
  displayName?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id?: InputMaybe<Scalars['Int']>;
  /** 엔딩 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 엔딩 부분 캡쳐 이미지 */
  previewImageFile?: InputMaybe<Scalars['Upload']>;
  /** 엔딩 미리보기 이름 */
  previewName?: InputMaybe<Scalars['String']>;
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
};

/** 도착률 범위를 관리하는 테이블 */
export type EndingRateRange = {
  __typename?: 'EndingRateRange';
  createdAt: Scalars['DateTime'];
  /** 끝 도착률 범위 */
  endRate: Scalars['Float'];
  /** 범위 level */
  level: Ending_Rate_Range_Level;
  /** 범위 이름 */
  name: Scalars['String'];
  /** 아이디 */
  rangeId: Scalars['Int'];
  /** 시작 도착률 범위 */
  startRate: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

/** 엔딩 도착률 범위 데이터 생성을 위한 입력값 */
export type EndingRateRangeCreationInput = {
  /** 끝 도착률 범위 */
  endRate: Scalars['Float'];
  /** 범위 level */
  level: Ending_Rate_Range_Level;
  /** 범위 이름 */
  name: Scalars['String'];
  /** 시작 도착률 범위 */
  startRate: Scalars['Float'];
};

/** 엔딩에서 보여줄 StoryProperty */
export type EndingShowsProperty = {
  __typename?: 'EndingShowsProperty';
  createdAt: Scalars['DateTime'];
  /** 엔딩에서 보여줄 속성의 이미지 */
  customImageFile?: Maybe<File>;
  /** 엔딩에서 보여줄 속성의 이미지 ID */
  customImageFileId?: Maybe<Scalars['Int']>;
  /** 엔딩 */
  ending: Ending;
  /** 엔딩 ID */
  endingId: Scalars['Int'];
  /** 아이디 */
  espId: Scalars['Int'];
  /** 스토리 프로퍼티 */
  storyProp: StoryProperty;
  /** 스토리 프로퍼티 ID */
  storyPropId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 엔딩에 보여질 스토리 속성을 생성할 때 필요한 데이터 */
export type EndingShowsPropertyCreationInput = {
  /** 엔딩에서 보여줄 속성의 이미지 */
  customImageFile?: InputMaybe<Scalars['Upload']>;
  /** 엔딩 ID */
  endingId: Scalars['Int'];
  /** 스토리 프로퍼티 ID */
  storyPropId: Scalars['Int'];
};

/** 엔딩에 보여질 스토리 속성을 수정할 때 필요한 데이터 */
export type EndingShowsPropertyUpdateInput = {
  /** 엔딩에서 보여줄 속성의 이미지 */
  customImageFile?: InputMaybe<Scalars['Upload']>;
  /** 엔딩 ID */
  endingId?: InputMaybe<Scalars['Int']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 스토리 프로퍼티 ID */
  storyPropId?: InputMaybe<Scalars['Int']>;
};

/** 엔딩별 통계 정보 */
export type EndingWithStat = {
  __typename?: 'EndingWithStat';
  /** 엔딩 정보 */
  ending: Ending;
  /** 엔딩별 percentage */
  percentage: Scalars['Float'];
};

/** 엔딩별 통계 정보 */
export type EndingsStatOutput = {
  __typename?: 'EndingsStatOutput';
  /** 스토리의 엔딩정보 + 사용자의 엔딩 수집 정보 */
  endingInfo: CollectibleEndingOutput;
  /** 엔딩별 통계 정보, 수치가 높은 것부터 정렬되어 보여짐 */
  endingWithStats: Array<EndingWithStat>;
};

export type ExportStoryForStudio = {
  __typename?: 'ExportStoryForStudio';
  /** 작품의 모든 챕터 */
  allChapters: Array<Chapter>;
  /** 작품의 모든 엔딩 카드 */
  allEndingCards: Array<EndingCard>;
  /** 작품의 모든 endingShowProperty */
  allEndingShowProperties: Array<EndingShowsProperty>;
  /** 작품의 모든 엔딩 */
  allEndings: Array<Ending>;
  /** 작품에 등록되어 있는 fallback remote script */
  allFallbackRemoteScripts: Array<FallbackRemoteScript>;
  /** 작품의 모든 온보딩 팝업 선택지 */
  allOnboardingPopupChoices: Array<OnboardingPopupChoice>;
  /** 작품의 모든 온보딩 팝업 프로필 이미지 */
  allOnboardingPopupProfileImages: Array<OnboardingPopupProfileImage>;
  /** 작품의 모든 온보딩 팝업 */
  allOnboardingPopups: Array<OnboardingPopup>;
  /** 작품의 모든 PlayerClass */
  allPlayerClasses: Array<PlayerClass>;
  /** 플레이어 보고서 정보 */
  playerReportInfo: PlayerReport2Info;
  /** 작품 */
  story?: Maybe<Story>;
};

/** StudioSheetExporter 항목을 생성할 때 필요한 데이터 */
export type ExportStudioChapterAsSheetInput = {
  /** 챕터의 customId */
  chapterCustomId: Scalars['String'];
  /** 챕터의 이름 (시트명) */
  chapterName: Scalars['String'];
  /** 실행 전에 모든 시트를 제거한다. */
  clearBeforeRun?: InputMaybe<Scalars['Boolean']>;
  /** 스튜디오에서 생성한 챕터 데이터 (IStudioSheetLine[])을 json 으로 인코딩한 데이터여야 한다. */
  encodedLines: Scalars['String'];
  /** 스튜디오에서 생성한 메타데이터 업데이트 데이터(MetaDataUpdateAction[])을 json 으로 인코딩한 데이터여야 한다. */
  metaDataUpdateActions: Scalars['String'];
  /** 구글 스프레드 시트 ID */
  sheetId: Scalars['String'];
};

/** exportStudioChapterAsSheet mutation input */
export type ExportStudioStoryAsSheetInput = {
  /** 내보낼 챕터들 */
  chapters: Array<ChapterAsSheetInput>;
  /** 실행 전에 모든 시트를 제거한다. */
  clearBeforeRun?: InputMaybe<Scalars['Boolean']>;
  /** 스튜디오에서 생성한 메타데이터 업데이트 데이터(MetaDataUpdateAction[])을 json 으로 인코딩한 데이터여야 한다. */
  metaDataUpdateActions: Scalars['String'];
  /** 구글 스프레드 시트 ID */
  sheetId: Scalars['String'];
};

/** 스토리게임 섹션 내 스토리의 노출 정보를 유저별로 저장하는 엔티티 */
export type ExposedSectionStory = {
  __typename?: 'ExposedSectionStory';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  exposedSectionStoryId: Scalars['Int'];
  /** 작품 */
  storygame: Story;
  /** 작품 아이디 */
  storygameId: Scalars['Int'];
  /** 작품이 속한 섹션 */
  storygameSection: StorygameSection;
  /** 작품이 속한 섹션의 아이디 */
  storygameSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 작품에 노출된 유저 */
  user: User;
  /** 작품에 노출된 유저의 아이디 */
  userId: Scalars['Int'];
};

/** ExposedSectionStory 생성을 위한 input */
export type ExposedSectionStoryCreationInput = {
  /** 작품 아이디 */
  storygameId: Scalars['Int'];
  /** 작품이 속한 섹션의 아이디 */
  storygameSectionId: Scalars['Int'];
};

/** ExposedSectionStory 업데이트를 위한 input */
export type ExposedSectionStoryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 어드민 로그인 토큰 연장 입력값 */
export type ExtendAdminLoginTokenInput = {
  /** 연장할 시간 (초단위) */
  extendInSec: Scalars['Int'];
};

/** 이 파일의 용도 */
export enum File_Type {
  Image = 'Image',
  Unspecified = 'Unspecified',
  Video = 'Video'
}

/** 원격스크립트 실패시, 실행되는 구문 저장소 */
export type FallbackRemoteScript = {
  __typename?: 'FallbackRemoteScript';
  /** 챕터 */
  chapter: Chapter;
  /** 챕터 ID */
  chapterId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  fallbackRemoteScriptId: Scalars['Int'];
  /** 원격스크립트 ID */
  remoteScriptId: Scalars['String'];
  /** statements list json string으로 들어감 */
  statements: Scalars['String'];
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Featured = {
  __typename?: 'Featured';
  /** 커버 이미지 */
  coverImageFile?: Maybe<ImageFile>;
  /** 커버 이미지 아이디 */
  coverImageFileId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  featuredId: Scalars['Int'];
  /** 숨김 여부. true면 숨김 */
  isHidden: Scalars['Boolean'];
  /** 피쳐드를 눌렀을 때 연결되는 web or app link */
  link?: Maybe<Scalars['String']>;
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Featured 생성을 위한 input */
export type FeaturedCreationInput = {
  /** 커버 이미지 */
  coverImageFile: Scalars['Upload'];
  /** 숨김 여부. true면 숨김 */
  isHidden: Scalars['Boolean'];
  /** 피쳐드를 눌렀을 때 연결되는 web or app link */
  link?: InputMaybe<Scalars['String']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** Featured 업데이트를 위한 input */
export type FeaturedUpdateInput = {
  /** 커버 이미지 */
  coverImageFile?: InputMaybe<Scalars['Upload']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 숨김 여부. true면 숨김 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 피쳐드를 눌렀을 때 연결되는 web or app link */
  link?: InputMaybe<Scalars['String']>;
  /** 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 현재 플레이 중인 챕터에서의 모든 아이템을 가져오기 위한 input */
export type FetchAllUserItemsInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
};

/** 각종 파일 */
export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  /** 이 파일의 용도 */
  fileType: File_Type;
  hashId: Scalars['String'];
  /** 권한을 가진 사용자가 접속할 수 있는 주소 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ForceMigrateChrProfileInput = {
  /** 끝 챕터 인덱스(포함) */
  endChapterIndex: Scalars['Int'];
  /** 시작 챕터 인덱스(포함) */
  startChapterIndex: Scalars['Int'];
  /** 유저 ID */
  storyId: Scalars['Int'];
};

/** 사용자 성별 */
export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Unknown = 'Unknown'
}

/** 클라이언트 위치정보 */
export type Geo = {
  __typename?: 'Geo';
  /** ISO 3166 2 code. ref: https://www.iban.com/country-codes */
  country: Scalars['String'];
  /** region */
  region: Scalars['String'];
  /** timezone */
  timezone: Scalars['String'];
};

/** [어드민 기능] 파우스트 서버에서 자연어 응답값을 리턴하는 api input 입니다. */
export type GetAffectionByAdminInput = {
  /** [자연어 입력 선택지] 상대방 텍스트 */
  leftUserInputText: Scalars['String'];
  /** [자연어 입력 선택지] 주인공 사용자가 입력한 텍스트 */
  rightUserInputText: Scalars['String'];
};

/** [어드민 기능] 파우스트 서버에서 자연어 애정도 응답값을 리턴하는 api output */
export type GetAffectionByAdminOutput = {
  __typename?: 'GetAffectionByAdminOutput';
  /** 자연어 입력 선택지 선택 결과 값 */
  affection: Scalars['String'];
};

/** 자연어 애정도 레벨 예측 값 output */
export type GetAffectionLevelOutput = {
  __typename?: 'GetAffectionLevelOutput';
  /** 예측 값 */
  prediction: Scalars['String'];
};

/** 조건에 맞는 모든 배너를 가져오기 위한 input */
export type GetAllBannersInput = {
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  location: Banner_Location;
};

/** 조건에 맞는 모든 배너를 가져오기 위한 input */
export type GetAllBundleBannersInput = {
  /** 배너 타입(Story: 작품, Custom: 직접 등록) */
  location: Banner_Location;
};

/** 모든 미리보기 리소스를 가져오기 위한 input */
export type GetAllStoryPreviewsInput = {
  /** 리소스 타입 (Image or Video) */
  fileType?: InputMaybe<Storygame_Preview_Resource_Type>;
  /** 스토리 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 모든 StoryTag을 가져오기 위한 input */
export type GetAllStoryTagsInput = {
  /** 작품에 속한 모든 StoryTag을 가져오기 위한 input */
  storyId: Scalars['Int'];
};

export type GetAllStorygameSectionPreviewsInput = {
  /** 미리보기에서 설정한 국가 코드 */
  countryCode: Scalars['String'];
  /** 미리보기에서 설정한 언어 코드 */
  languageCode: Scalars['String'];
  /** 섹션이 속한 스토리게임의 탭 아이디 */
  tabId: Scalars['Int'];
};

export type GetAllStorygameSectionViewsInput = {
  /** 섹션이 속한 스토리게임의 탭 아이디 */
  tabId: Scalars['Int'];
};

export type GetCertificationInfoResponse = {
  __typename?: 'GetCertificationInfoResponse';
  /** 생년월일 */
  birthday: Scalars['String'];
  /** 성별 */
  gender: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** DI 값과 동일. 상점아이디(사이트)별로 할당되는 식별키 */
  unique_in_site: Scalars['String'];
  /** CI 값과 동일. 온라인 주민번호와 같은 개인고유식별키 */
  unique_key: Scalars['String'];
};

/** 사용자 입력 텍스트 룰베이스 조회하는데 필요한 데이터 */
export type GetChoiceAffectionRuleInput = {
  /** choice 아이디 */
  choiceId: Scalars['Int'];
  /** choice index */
  choiceIndex: Scalars['Int'];
};

/** 클라이언트가 채팅에서 이어보기 할 때, 이어보기 할 챕터 아이디 */
export type GetChoicesForContinueInput = {
  /** 검색어 */
  chapterId?: InputMaybe<Scalars['Int']>;
};

/** 작품에 대한 전체 구매 */
export type GetCoinAmountForBuyRemainingChaptersInput = {
  /** 어떤 작품에 대한 구매인가? */
  storyId: Scalars['Int'];
};

export type GetCoinAmountForBuyRemainingChaptersOutput = {
  __typename?: 'GetCoinAmountForBuyRemainingChaptersOutput';
  /** 개별 구매한 회차를 제외한 나머지 회차를 구매를 위해 필요한 코인 개수 */
  coinAmount: Scalars['Int'];
};

/** 작품 전환율 통계 정보를 조회하기 위한 입력값 */
export type GetConversionStatOfStoryInput = {
  /** 전환율 타입 */
  conversionType: Story_Conversion_Stat_Type;
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** endDate 기준으로 얼마나 전 데이터를 조회할 것인지? */
  size?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 일간, 주간 */
  type: Story_Stat_Cache_Type;
};

/** 엔딩 정보를 알기위한 input */
export type GetEndingInfo = {
  /** 엔딩들이 속한 챕터의 ID */
  chapterId: Scalars['Int'];
  /** 엔딩 name */
  name: Scalars['String'];
};

/** get fallback remote script for studio */
export type GetFallbackRemoteScriptByStudioInput = {
  /** chapterId */
  chapterId: Scalars['Int'];
  /** remoteScriptId */
  remoteScriptId: Scalars['String'];
};

export type GetImportStudioGoogleInfoOutput = {
  __typename?: 'GetImportStudioGoogleInfoOutput';
  /** client email */
  clientEmail: Scalars['String'];
  /** pkey result */
  pkey: Scalars['String'];
};

/** 웹소설 작품의 사용자 회차 플레이기록을 가져오기위한(만들기 위한) 입력값 */
export type GetLastPlayingEpubChapterInput = {
  /** 챕터 ID */
  chapterId: Scalars['Int'];
  /** 사용자가 플레이중인 playingVersion */
  playingVersion: Scalars['Int'];
  /** ups ID */
  upsId: Scalars['Int'];
};

/** getLatestTerms API를 위한 input */
export type GetLatestTermsInput = {
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
};

/** HelloBotServer 마이봇 시퀀스로 최근 7일간 못 알아 듣는말 목록을 가져옵니다. */
export type GetMyBotMissRulesInput = {
  /** 마이봇 시퀀스 */
  chatbotSeq: Scalars['Int'];
};

export type GetMyBotMissRulesItem = {
  __typename?: 'GetMyBotMissRulesItem';
  /** 누적 횟수 */
  count: Scalars['Int'];
  /** 못 알아먹는 말 */
  input: Scalars['String'];
};

export type GetMyBotMissRulesOutput = {
  __typename?: 'GetMyBotMissRulesOutput';
  /** 마이봇이 못 알아먹는 말 리스트 */
  missRules: Array<GetMyBotMissRulesItem>;
};

/** HelloBotServer 마이봇의 시퀀스를 이름으로 찾아옵니다. */
export type GetMyBotSequenceByNameInput = {
  /** 마이봇 이름 */
  name: Scalars['String'];
};

export type GetMyBotSequenceByNameOutput = {
  __typename?: 'GetMyBotSequenceByNameOutput';
  /** 마이봇의 시퀀스 */
  seq: Scalars['Int'];
};

/** 작품 및 회차의 댓글 수 통계를 조회하기 위한 입력값 */
export type GetNumCommentStatOfStoryInput = {
  /** 챕터 아이디 */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** endDate 기준으로 얼마나 전 데이터를 조회할 것인지? */
  size?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

/** 작품의 좋아요 통계 정보를 조회하기 위한 입력값 */
export type GetNumLikeStatOfStoryInput = {
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** endDate 기준으로 얼마나 전 데이터를 조회할 것인지? */
  size?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

/** 작품 및 회차 조회수(플레이수) 통계 정보를 조회하기 위한 입력값 */
export type GetNumPlayStatOfStoryInput = {
  /** 챕터 아이디 (해당 값이 null 이면 작품에 대한 통계) */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** endDate 기준으로 얼마나 전 데이터를 조회할 것인지? */
  size?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

/** 작품을 import 하기 위한 input */
export type GetPresignedUrlForStoryImportV3Input = {
  /** 작품언어 */
  bundleId?: InputMaybe<Scalars['Int']>;
  /** 작품언어 */
  languageCode?: InputMaybe<Scalars['String']>;
};

export type GetPresignedUrlInput = {
  /** s3에 업로드할 원본파일 이름 리스트 */
  fileName?: InputMaybe<Scalars['String']>;
  /** 임시 저장소에 저장할지 여부 true: 임시 저장소에 저장 */
  isTemp?: InputMaybe<Scalars['Boolean']>;
  /** 스토리지 타입을 지정합니다. */
  storageType: Storages;
};

/** presigned url 클라이언트에서 s3에 저장할 url output */
export type GetPresignedUrlOutput = {
  __typename?: 'GetPresignedUrlOutput';
  /** 파일 원본 이름 */
  fileName?: Maybe<Scalars['String']>;
  /** s3에 저장된 파일의 key 값 */
  hashId: Scalars['String'];
  /** s3에 임시저장소에 저장되었는지 여부 */
  isTemp: Scalars['Boolean'];
  /** 저장된 스토리지 정보 */
  storageType: Storages;
  /** 업로드 성공 시 파일이 위치할 url */
  uploadedUrl: Scalars['String'];
  /** presigned url 클라이언트에서 s3에 저장할 url */
  url: Scalars['String'];
};

export type GetPresignedUrlsInput = {
  /** s3에 업로드할 원본파일 이름 리스트 */
  fileNames: Array<Scalars['String']>;
  /** 임시 저장소에 저장할지 여부 true: 임시 저장소에 저장 */
  isTemp?: InputMaybe<Scalars['Boolean']>;
  /** 스토리지 타입을 지정합니다. */
  storageType: Storages;
};

/** presigned url 클라이언트에서 s3에 저장할 url output */
export type GetPresignedUrlsOutput = {
  __typename?: 'GetPresignedUrlsOutput';
  /** 파일 원본 이름 */
  fileName: Scalars['String'];
  /** s3에 저장된 파일의 key 값 */
  hashId: Scalars['String'];
  /** s3에 임시저장소에 저장되었는지 여부 */
  isTemp: Scalars['Boolean'];
  /** 저장된 스토리지 정보 */
  storageType: Storages;
  /** presigned url 클라이언트에서 s3에 저장할 url */
  url: Scalars['String'];
};

export type GetRecommendationOnboardingForClientOutput = {
  __typename?: 'GetRecommendationOnboardingForClientOutput';
  /** List of RecommendationOnboarding data */
  list: Array<RecommendationOnboarding>;
  /** Total count */
  totalCount: Scalars['Int'];
};

/** RemoteScript 목록을 가져오기 위한 input */
export type GetRemoteScriptStatementsInput = {
  /** 원격스크립트의 배경이름 */
  background?: InputMaybe<Scalars['String']>;
  /** 원격 스크립트 챕터 ID */
  chapterId: Scalars['Int'];
  /** 원격스크립트 파라미터 */
  params?: InputMaybe<Array<Scalars['String']>>;
  /** 원격 스크립트 provider */
  provider: Scalars['String'];
  /** 원격 스크립트 ID */
  remoteScriptId: Scalars['String'];
  /** 원격 스크립트 타입 */
  remoteScriptType: Remote_Script_Type;
};

export type GetRemoteScriptStatementsOutput = {
  __typename?: 'GetRemoteScriptStatementsOutput';
  statements: Scalars['String'];
};

/** s3에 업로드된 파일 url 가져오기 위한 옵션 */
export type GetS3UploadedFileUrlInput = {
  /** s3에 저장된 파일의 key 값 */
  hashId: Scalars['String'];
  /** 임시 저장소에 저장됨 여부 true: 임시 저장소에 저장 */
  isTemp?: InputMaybe<Scalars['Boolean']>;
  /** 저장된 스토리지 타입 */
  storageType: Storages;
};

/** s3에 업로드된 파일 url */
export type GetS3UploadedFileUrlOutput = {
  __typename?: 'GetS3UploadedFileUrlOutput';
  /** presigned url 클라이언트에서 s3에 저장한 파일 url */
  url: Scalars['String'];
};

/** 선택한 AI 생성 이미지 조회 */
export type GetSelectedRequestAiCharacterInput = {
  /** 캐릭터 아이디, -1이면 주인공 */
  chrId: Scalars['Int'];
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 작품 통계 정보 조회하는데 공통 입력값 */
export type GetStatOfStoryInput = {
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** endDate 기준으로 얼마나 전 데이터를 조회할 것인지? */
  size?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

/** 스토어 섹션 목록(어드민용) input */
export type GetStorePageSectionsForAdminInput = {
  /** 숨김 여부 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 스토어 탭 타입 */
  storePageTabType: StorePageTabType;
};

/** 스토어 섹션 목록(미리보기용) input */
export type GetStorePageSectionsForPreviewInput = {
  /** 스토어 탭 타입 */
  storePageTabType: StorePageTabType;
};

/** 스토어 섹션 목록 input */
export type GetStorePageSectionsInput = {
  /** 스토어 탭 타입 */
  storePageTabType: StorePageTabType;
};

/** 개별 스토어 결제 input */
export type GetStorePaymentInput = {
  /** 스토어 결제 주문 아이디 */
  orderId: Scalars['String'];
};

/** 스토어 결제 SKU 항목 */
export type GetStoreSkusInput = {
  /** 스토어 번들 아이디 목록  */
  storeBundleIds?: InputMaybe<Array<Scalars['Int']>>;
  /** 작품(스토리 번들) 아이디 목록 */
  storyBundleIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** StoryBundle의 ServiceCountry의 정보와 Activated 된 국가 정보를 가져오기위한 storyBundleId. */
export type GetStoryBundleCountriesInfoForStudioInput = {
  /** 대상 번들 아이디 */
  bundleId: Scalars['Int'];
};

export type GetStoryBundleCountriesInfoForStudioOutput = {
  __typename?: 'GetStoryBundleCountriesInfoForStudioOutput';
  /** 스플에서 서비스 가능한 국가 목록 */
  activatedCountries: Array<Country>;
  /** 해당 번들(작품)이 서비스 되는 국가 목록 */
  serviceCountries: Array<Country>;
};

/** StoryPriceSetting 정보를 가져오기 위한 input */
export type GetStoryPriceSettingInput = {
  /** 적용 국가 코드 */
  countryCode: Scalars['String'];
  /** 작품아이디 */
  storyId: Scalars['Int'];
};

/** AI 캐릭터 생성시 보정 프롬프트 목록 조회 */
export type GetStorygameAdditionalPromptsInput = {
  /** 성별 */
  gender: Gender;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

export type GetStorygameSectionViewMoreInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 섹션의 컬럼 (0-based). 한 섹션에 여러 컬럼이 있을 경우 사용. 현재는 RankList UI type에서만 사용 */
  sectionColumn?: InputMaybe<Scalars['Int']>;
  /** 더보기 대상 섹션 아이디 */
  sectionId: Scalars['Int'];
  /** 섹션이 속한 스토리게임의 탭 아이디 */
  tabId: Scalars['Int'];
  /** 태그 기반 더보기를 위한 태그명. 해당 태그의 데이터만 가져온다 */
  tagName?: InputMaybe<Scalars['String']>;
};

/** 스토리게임 섹션 더보기의 결과 데이터 */
export type GetStorygameSectionViewMoreOutput = {
  __typename?: 'GetStorygameSectionViewMoreOutput';
  sectionView?: Maybe<StorygameSectionView>;
  totalCount: Scalars['Int'];
};

export type GetTtsUrlResponse = {
  __typename?: 'GetTtsUrlResponse';
  /** TTS 음성 URL */
  url: Scalars['String'];
};

/** 스크립트에서 선택지 결과를 가져옵니다. */
export type GetUserChoiceResultInput = {
  /** 회차 ID */
  chapterId: Scalars['Int'];
  /** 선택지 ID primaryKey */
  choiceId: Scalars['Int'];
  /** 작품 ID */
  storyId: Scalars['Int'];
};

export type GetUserChoiceResultOutput = {
  __typename?: 'GetUserChoiceResultOutput';
  /** 선택지 통계 정보 */
  choiceResult?: Maybe<Choice>;
  /** 사용자가 선택한 선택지 index 정보 */
  userSelectedChoice?: Maybe<UserSelectedChoice>;
};

/** 자연어 입력 선택지 인덱스 판단하는 api input 입니다. */
export type GetUserInputChoiceInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 선택의 이름 */
  choiceName: Scalars['String'];
  /** [자연어 입력 선택지] 상대방 텍스트 */
  leftUserInputText: Scalars['String'];
  /** [자연어 입력 선택지] 주인공 사용자가 입력한 텍스트 */
  rightUserInputText: Scalars['String'];
};

/** 자연어 입력 선택지 인덱스 판단하는 api output */
export type GetUserInputChoiceOutput = {
  __typename?: 'GetUserInputChoiceOutput';
  /** 자연어 입력 선택지 선택 결과 index */
  choiceIndex: Scalars['Int'];
};

export type GetUserPlayerReportOutput = {
  __typename?: 'GetUserPlayerReportOutput';
  /** ending.endingId */
  endingId: Scalars['Int'];
  /** playerReport2.prId */
  reportId: Scalars['Int'];
  /** story.storyId */
  storyId: Scalars['Int'];
  /** story.name */
  storyName: Scalars['String'];
  /** 플레이어 보고서 url */
  url?: Maybe<Scalars['String']>;
};

/** 홈 화면의 섹션 타입 */
export enum Home_Section_Type {
  Favorite = 'Favorite',
  Genre = 'Genre',
  UserGeneration = 'UserGeneration'
}

export type HomeSection = {
  __typename?: 'HomeSection';
  createdAt: Scalars['DateTime'];
  /** 각 섹션마다 필요한 다양한 형태의 데이터 (JSON object) */
  customData?: Maybe<Scalars['String']>;
  /** 이벤트 트랙킹을 위한 고유키. 기존 설정 유지를 위해 지 */
  key?: Maybe<Scalars['String']>;
  /** 해당 섹션의 언어 코드 */
  langCode: Scalars['String'];
  /** 아이디 */
  sectionId: Scalars['Int'];
  /** 정렬 우선 순위. (높을수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 섹션에 속한 작품 */
  stories: Array<Story>;
  /** 섹션 제목 */
  title: Scalars['String'];
  /** 홈 화면의 섹션 종류 */
  type: Home_Section_Type;
  updatedAt: Scalars['DateTime'];
};

/** HomeSection 생성을 위한 input */
export type HomeSectionCreationInput = {
  /** 각 섹션마다 필요한 다양한 형태의 데이터 (JSON object) */
  customData?: InputMaybe<Scalars['String']>;
  /** 이벤트 트랙킹을 위한 고유키. 기존 설정 유지를 위해 지 */
  key?: InputMaybe<Scalars['String']>;
  /** 해당 섹션의 언어 코드 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 섹션 제목 */
  title: Scalars['String'];
  /** 홈 화면의 섹션 종류 */
  type: Home_Section_Type;
};

export type HomeSectionStory = {
  __typename?: 'HomeSectionStory';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  homeSectionStoryId: Scalars['Int'];
  /** 홈 섹션 */
  section: HomeSection;
  /** 홈 섹션 아이디 */
  sectionId: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** HomeSectionStory 생성을 위한 input */
export type HomeSectionStoryCreationInput = {
  /** 홈 섹션 아이디 */
  sectionId: Scalars['Int'];
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** HomeSectionStory 업데이트를 위한 input */
export type HomeSectionStoryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 타입별 HomeSection을 가져오기 위한 input */
export type HomeSectionTypeInput = {
  /** 홈 화면의 섹션 종류 */
  type: Home_Section_Type;
};

/** HomeSection 업데이트를 위한 input */
export type HomeSectionUpdateInput = {
  /** 각 섹션마다 필요한 다양한 형태의 데이터 (JSON object) */
  customData?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 이벤트 트랙킹을 위한 고유키. 기존 설정 유지를 위해 지 */
  key?: InputMaybe<Scalars['String']>;
  /** 섹션 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 홈 화면의 섹션 종류 */
  type?: InputMaybe<Home_Section_Type>;
};

/** 아임포트 결제 상태 */
export enum Imp_State {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Forgery = 'FORGERY',
  Requested = 'REQUESTED'
}

/** 작품기반 추천 케이스 */
export enum Item_Based_Recommendation_Case {
  Details = 'Details',
  DetailsMore = 'DetailsMore',
  EndingModal = 'EndingModal'
}

/** 아임포트 결제 */
export type IamportPayments = {
  __typename?: 'IamportPayments';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  iamportPaymentsId: Scalars['Int'];
  /** 매 결제 요청마다 서버에서 생성하는 merchant_uid */
  merchantUID: Scalars['String'];
  /** 아임포트 결제 결과 데이터 */
  paymentData?: Maybe<Scalars['String']>;
  /** 상품을 구분하는 고유 아이디 */
  productId: Scalars['String'];
  /** 결제 진행 상태 */
  state: Imp_State;
  updatedAt: Scalars['DateTime'];
  /** 결제를 요청한 유저 */
  user: User;
  /** 결제를 요청한 유저의 아이디 */
  userId: Scalars['Int'];
};

/** 이미지 파일이 등록되는 테이블 */
export type ImageFile = {
  __typename?: 'ImageFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 이미지의 height */
  height: Scalars['Int'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 이미지의 width */
  width: Scalars['Int'];
};

/** ImageFile 항목을 생성할 때 필요한 데이터 */
export type ImageFileCreationInput = {
  /** 이미지 파일 */
  image: Scalars['Upload'];
};

export type ImportStoryInput = {
  /** files */
  files: Array<Scalars['Upload']>;
  /** story info */
  storyInfo: Scalars['String'];
};

/** 추출된 작품 import 진행 결과 */
export type ImportStoryResponse = {
  __typename?: 'ImportStoryResponse';
  /** 에러 메세지 */
  error?: Maybe<Scalars['String']>;
  /** 실패했다면 실패 operation */
  op?: Maybe<Scalars['String']>;
  /** import 성공 여부 */
  success: Scalars['Boolean'];
};

/** 인앱 결제 코인 정보 */
export type InappCoin = {
  __typename?: 'InappCoin';
  /** 구매시 획득하는 코인 갯수 */
  amount: Scalars['Int'];
  /** (deprecated: countryCodeV3을 참조하세요) 인앱 코인 나라코드 */
  countryCode?: Maybe<Scalars['String']>;
  /** 인앱 코인 나라코드 */
  countryCodeV3?: Maybe<Scalars['String']>;
  /** countryCodeV3에 해당하는 지역에 대한 정보 */
  countryV3?: Maybe<Country>;
  createdAt: Scalars['DateTime'];
  /** 인앱 코인 환율 */
  currency?: Maybe<Scalars['String']>;
  /** 수동으로 입력되는 정수형 할인율. 현재는 앱에서 스토어에서 받은 가격과 이 값으로 원가를 계산하는데 사용됨 */
  discountRate: Scalars['Int'];
  /** float type 가격에 대한 정수형 할인율. 수동으로 입력되어야 한다. */
  discountRateForFloat: Scalars['Float'];
  /** 아이디 */
  inappCoinId: Scalars['Int'];
  /** 스토어에 등록 되어 있는 인앱 상품 ID */
  inappProductId: Scalars['String'];
  /** (deprecated: languageCode를 참조하세요) 인앱 코인 언어코드 */
  langCode: Scalars['String'];
  /** 인앱 코인 언어 */
  language?: Maybe<Language>;
  /** 인앱 코인 언어코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 코인의 할인 전 구매 가격(원) */
  originalPrice: Scalars['Int'];
  /** 코인의 할인 전 구매 가격(실수형) */
  originalPriceFloat?: Maybe<Scalars['Float']>;
  /** 클라이언트의 os */
  os: Os;
  /** 코인의 구매 가격(원) */
  price: Scalars['Int'];
  /** 코인의 구매 가격(실수형) */
  priceFloat: Scalars['Float'];
  /** 본 상품이 속할 모델의 ID로 productType에 따라 모델이 달라질 수 있음. 현재는 productType이 ai-character일 때 OnboardingPopupId를 참조. 확장성을 위해 FK 설정하지 않음 */
  productId?: Maybe<Scalars['Int']>;
  /** 앱에 노출될 코인 상품 이름 */
  productName: Scalars['String'];
  /** coin, ai-character(자캐 생성). 코인 전용에서 인앱 상품으로 테이블이 확장됨 */
  productType?: Maybe<Scalars['String']>;
  /** 목록내에서의 상품 정렬 순서 */
  seq: Scalars['Int'];
  /** 태그 정보 */
  tag?: Maybe<InappCoinTag>;
  updatedAt: Scalars['DateTime'];
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

/** 인앱 결제 코인을 등록할 때 필요한 데이터 */
export type InappCoinInput = {
  /** 구매시 획득하는 코인 갯수 */
  amount: Scalars['Int'];
  /** 스토어에 등록 되어 있는 인앱 상품 ID */
  inappProductId: Scalars['String'];
  /** (deprecated: languageCode를 참조하세요) 인앱 코인 언어코드 */
  langCode: Scalars['String'];
  /** 코인의 할인 전 구매 가격(원) */
  originalPrice?: InputMaybe<Scalars['Int']>;
  /** 클라이언트의 os */
  os: Os;
  /** 코인의 구매 가격(원) */
  price: Scalars['Int'];
  /** 앱에 노출될 코인 상품 이름 */
  productName: Scalars['String'];
  /** 인앱 상품에 대한 추가적인 속성을 부여. (InAppCoinRulesType enum 을 콤마로 구분하여 저장) */
  rules?: InputMaybe<Scalars['String']>;
  /** 목록내에서의 상품 정렬 순서 */
  seq: Scalars['Int'];
  /** 인앱 결제 코인(티켓)의 태그 정보 */
  tag?: InputMaybe<InappCoinInputTag>;
  /** 해당 인앱상품이 적용된 앱의 feature version */
  version?: InputMaybe<Scalars['Int']>;
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

/** 인앱 결제 코인(티켓)의 태그 정보 */
export type InappCoinInputTag = {
  /** 태그의 배경색 hex color code 값 (예: #A6A67A) */
  backgroundColor: Scalars['String'];
  /** 태그의 텍스트 스트링 */
  text: Scalars['String'];
};

/** 인앱 결제 코인(티켓)의 태그 정보 */
export type InappCoinTag = {
  __typename?: 'InappCoinTag';
  /** 태그 배경색의 hex color code(예: #DD3955) */
  backgroundColor: Scalars['String'];
  /** 태그 텍스트 */
  text: Scalars['String'];
};

export type InappPackage = {
  __typename?: 'InappPackage';
  /** 패키지 내에 포함되는 코인 상품 (MAX: 1개) */
  coin?: Maybe<PackageCoin>;
  /** 패키지 내에 포함되는 코인 상품이 보여지는 순서. 클수록 상단에 보여져야 함 */
  coinShowOrder: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 할인율 */
  discountRate: Scalars['Int'];
  /** 헤더의 배경색(hex colors) */
  headerBGColor: Scalars['String'];
  /** 헤더 이미지 */
  headerImageFile?: Maybe<ImageFile>;
  /** 헤더 이미지의 아이디 */
  headerImageFileId?: Maybe<Scalars['Int']>;
  /** 아이디 */
  inappPackageId: Scalars['Int'];
  /** 스토어에 등록 되어 있는 인앱 상품 ID */
  inappProductId: Scalars['String'];
  /** 숨김 여부. true면 숨김 */
  isHidden: Scalars['Boolean'];
  /** 할인 적용 전 구매에 필요한 코인 수 */
  originalPrice: Scalars['Int'];
  /** 모바일 기기 os */
  os: Os;
  /** OS별로 나눠진 패키지를 하나의 상품으로 취급할 수 있는 ID */
  pID: Scalars['String'];
  /** 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 유저가 해당 패키지를 구매했나? */
  purchased: Scalars['Boolean'];
  /** 패키지가 보여지는 순서. 클수록 상단에 보여짐 */
  showOrder: Scalars['Int'];
  /** 패키지 내에 포함되는 광고 스킵 상품 (MAX: 1개) */
  skipAds?: Maybe<SkipAds>;
  /** 패키지 내에 포함되는 광고 스킵 상품이 보여지는 순서. 클수록 상단에 보여져야 함 */
  skipAdsShowOrder: Scalars['Int'];
  /** 패키지 내에 포함되는 작품 그룹 */
  stories: Array<Story>;
  /** 패키지 내에 포함되는 작품 그룹이 보여지는 순서. 클수록 상단에 보여져야 함 */
  storiesShowOrder: Scalars['Int'];
  /** 스타일 단어 */
  styleWords?: Maybe<Scalars['String']>;
  /** 스타일 단어의 폰트 색상(hex colors) */
  styleWordsColor?: Maybe<Scalars['String']>;
  /** 서브타이틀 */
  subtitle?: Maybe<Scalars['String']>;
  /** 서브타이틀의 폰트 색상(hex colors) */
  subtitleColor?: Maybe<Scalars['String']>;
  /** 패키지 상품에 최종 적용된 타임세일 */
  timeSale?: Maybe<PackageTimeSale>;
  /** 헤더 타이틀 */
  title: Scalars['String'];
  /** 헤더의 배경색(hex colors) */
  titleColor: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** InappPackage 생성을 위한 input */
export type InappPackageCreationInput = {
  /** 패키지 내에 포함되는 코인 상품이 보여지는 순서. 클수록 상단에 보여져야 함 */
  coinShowOrder: Scalars['Int'];
  /** 헤더 타이틀 */
  headerBGColor: Scalars['String'];
  /** 헤더 이미지 */
  headerImageFile?: InputMaybe<Scalars['Upload']>;
  /** 스토어에 등록 되어 있는 인앱 상품 ID */
  inappProductId: Scalars['String'];
  /** 숨김 여부. true면 숨김 */
  isHidden: Scalars['Boolean'];
  /** 할인 적용 전 구매에 필요한 코인 수 */
  originalPrice: Scalars['Int'];
  /** 모바일 기기 os */
  os: Os;
  /** OS별로 나눠진 패키지를 하나의 상품으로 취급할 수 있는 ID */
  pID: Scalars['String'];
  /** 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 패키지가 보여지는 순서. 클수록 상단에 보여짐 */
  showOrder: Scalars['Int'];
  /** 패키지 내에 포함되는 광고 스킵 상품이 보여지는 순서. 클수록 상단에 보여져야 함 */
  skipAdsShowOrder: Scalars['Int'];
  /** 패키지 내에 포함되는 작품 그룹이 보여지는 순서. 클수록 상단에 보여져야 함 */
  storiesShowOrder: Scalars['Int'];
  /** 스타일 단어 */
  styleWords?: InputMaybe<Scalars['String']>;
  /** 스타일 단어의 폰트 색상(hex colors) */
  styleWordsColor?: InputMaybe<Scalars['String']>;
  /** 서브타이틀 */
  subtitle?: InputMaybe<Scalars['String']>;
  /** 서브타이틀의 폰트 색상(hex colors) */
  subtitleColor?: InputMaybe<Scalars['String']>;
  /** 헤더 타이틀 */
  title: Scalars['String'];
  /** 헤더의 배경색(hex colors) */
  titleColor: Scalars['String'];
};

/** InappPackage 업데이트를 위한 input */
export type InappPackageUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 스토어 API를 통해 받은 인앱 상품 정보 */
export type InappProduct = {
  __typename?: 'InappProduct';
  /** 제품 요약 문구 */
  displayLabel: Scalars['String'];
  /** 제품 ID */
  productId: Scalars['String'];
};

export type InitUserSystemLanguageInput = {
  /** 업데이트 할 유저 시스템 언어 코드 */
  languageCode: Scalars['String'];
};

/** Input of changeUserPriv */
export type InputChangeUserPriv = {
  /** 사용자 ID */
  id: Scalars['Int'];
  /** 권한 */
  priv: Scalars['Int'];
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  /** 아이템 상세 설명 */
  desc?: Maybe<Scalars['String']>;
  /** 아이템 display 이름 */
  displayName?: Maybe<Scalars['String']>;
  /** 아이템 이미지 링크 */
  imageLink?: Maybe<Scalars['String']>;
  /** 아이템 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** 아이템 아이디 */
  itemId: Scalars['Int'];
  /** 아이템 이름 */
  itemName: Scalars['String'];
  /** 가지고 있는 아이템 갯수 */
  numOfItems: Scalars['Int'];
};

/** ip sourcing 정보 테이블 */
export type IpSourcing = {
  __typename?: 'IpSourcing';
  createdAt: Scalars['DateTime'];
  /** ip sourcing icon 이미지 파일 */
  iconImageFile: File;
  /** ip sourcing icon 이미지 파일 ID */
  iconImageFileId: Scalars['Int'];
  /** 아이디 */
  ipSourcingId: Scalars['Int'];
  /** ip sourcing 이름 */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 작품기반 추천 작품을 관리하는 엔티티 */
export type ItemBasedRecommendation = {
  __typename?: 'ItemBasedRecommendation';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  id: Scalars['Int'];
  /** 퍼스널라이즈 추천 결과 원본 데이터 (JSON string) */
  rawOutput: Scalars['String'];
  /** 추천 작품의 아이디가 콤마로 구분된 문자열 (e.g. 1,2,3) */
  recommendedStoryIds: Scalars['String'];
  /** 추천 기준 작품 */
  story: Story;
  /** 추천 기준 작품의 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 작품기반 작품 추천을 위한 input */
export type ItemBasedRecommendationInput = {
  /** 작품 기반 추천 케이스 */
  case: Item_Based_Recommendation_Case;
  /** 추천의 기준이 되는 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 언어 코드 */
export enum Language_Code {
  En = 'en',
  Ko = 'ko'
}

/** 스튜디오 회차 정렬 옵션 */
export enum List_Chapter_For_Writer_Sort_Option {
  ChapterIndexAsc = 'CHAPTER_INDEX_ASC',
  ChapterIndexDesc = 'CHAPTER_INDEX_DESC'
}

/** 스튜디오 작품 목록 정렬 옵션 */
export enum List_Story_For_Writer_Sort_Option {
  PublishedAtAsc = 'PUBLISHED_AT_ASC',
  PublishedAtDesc = 'PUBLISHED_AT_DESC',
  StoryIdAsc = 'STORY_ID_ASC',
  StoryIdDesc = 'STORY_ID_DESC'
}

/** 시스템 언어 설정, 콘텐츠 언어 설정에 사용되는 언어 코드 */
export type Language = {
  __typename?: 'Language';
  /** true인 것만 콘텐츠 언어로 설정 가능 */
  activatedContents: Scalars['Boolean'];
  /** true인 것만 시스템 언어로 설정 가능 */
  activatedSystem: Scalars['Boolean'];
  /** 언어 코드 */
  code: Scalars['String'];
  /** 콘텐츠 언어 목록 노출 순서 */
  contentsShowOrder: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 해당 언어 코드에 대한 설명 */
  description?: Maybe<Scalars['String']>;
  /** 클라이언트에서 표시할 텍스트(한국어, English..) */
  display: Scalars['String'];
  /** 시스템 언어 목록 노출 순서 */
  systemShowOrder: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Language 업데이트를 위한 input */
export type LanguageUpdateInput = {
  id: Scalars['Int'];
};

/** 탈퇴 이유 */
export type LeaveServiceReasonInput = {
  /** 탈퇴 이유 */
  reason: Scalars['String'];
};

/** 사용자의 코인 획득 및 소비를 기록해두는 장부 */
export type Ledger = {
  __typename?: 'Ledger';
  /** 코인의 증감 */
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  ledgerId: Scalars['Int'];
  /** 클라이언트 플랫폼 종류 (iOS, Android, Web) */
  platform?: Maybe<Scalars['String']>;
  /** 코인 사용/획득 로그 */
  reason: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 데이터 정렬을 위한 Input */
export type LedgerOrderBy = {
  /** 정렬 방향. DESC/ASC */
  direction: Order_By_Direction;
};

/** 작품 번들을 좋아요합니다. */
export type LikeBundleInput = {
  /** 좋아요 누른 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 작품에 이벤트를 발생시킬 때 필요한 입력값 */
export type ListAchievementInput = {
  /** storyId */
  storyId: Scalars['Int'];
};

export type ListChallengeStoryReportingReason = {
  __typename?: 'ListChallengeStoryReportingReason';
  /** List of challenge story reporting reason */
  list: Array<ChallengeStoryReportingReason>;
};

export type ListChapterCommentReportingReason = {
  __typename?: 'ListChapterCommentReportingReason';
  /** List of chapter comment reporting reason */
  list: Array<ChapterCommentReportingReason>;
};

/** 사용자의 작품 개수 (연재중, 미공개, 완결, 샘플) */
export type ListStoryCounts = {
  __typename?: 'ListStoryCounts';
  /** 완결 작품 개수 */
  finished: Scalars['Int'];
  /** 미공개 작품 개수 */
  noPublished: Scalars['Int'];
  /** 연재중인 작품 개수 */
  published: Scalars['Int'];
  /** 샘플 작품 개수 */
  sample: Scalars['Int'];
};

/** 사용자가 좋아요한 작품 번들 리스트를 반환합니다. */
export type ListUserLikeStoryBundleInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 업적 목록 조회용 */
export type ListableABadgeWithAcquisition = {
  __typename?: 'ListableABadgeWithAcquisition';
  /** 특정 사용자의 업적 목록 */
  list: Array<ABadgeWithAcquisition>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableAEventDef = {
  __typename?: 'ListableAEventDef';
  /** AEventDef (업적 이벤트) 목록 */
  list: Array<AEventDef>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AEventDef 목록 조회 및 검색 */
export type ListableAEventDefInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** storyId */
  storyId: Scalars['Int'];
};

export type ListableAdNetworkActivation = {
  __typename?: 'ListableAdNetworkActivation';
  /** List of AdNetworkActivation data */
  list: Array<AdNetworkActivation>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AdNetworkActivation 목록을 가져오기 위한 input */
export type ListableAdNetworkActivationInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableAdminLog = {
  __typename?: 'ListableAdminLog';
  /** List of AdminLog data */
  list: Array<AdminLog>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AdminLog 목록 조회 및 검색 */
export type ListableAdminLogInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableAppConfig = {
  __typename?: 'ListableAppConfig';
  /** List of AppConfig data */
  list: Array<AppConfig>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AppConfig 목록 조회, 검색을 위한 입력값 */
export type ListableAppConfigInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableAppSplashImage = {
  __typename?: 'ListableAppSplashImage';
  /** List of SplashImage data */
  list: Array<AppSplashImage>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AppSplashImage 목록을 가져오기 위한 input */
export type ListableAppSplashImageInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableAttendanceCoin = {
  __typename?: 'ListableAttendanceCoin';
  /** List of AttendanceCoin data */
  list: Array<AttendanceCoin>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AttendanceCoin 목록을 가져오기 위한 input */
export type ListableAttendanceCoinInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Author 권한이 이상 밴되지 않은 사용자 조회를 위한 입력값 */
export type ListableAuthorForStudioInput = {
  /** 검색어 - 필명, 유저아이디(userId) */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 해당 작품에 권한이 있는 사용자는 제외합니다. */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListableBanner = {
  __typename?: 'ListableBanner';
  /** List of Banner data */
  list: Array<Banner>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Banner 목록을 가져오기 위한 input */
export type ListableBannerInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 언어코드 */
  langCode?: InputMaybe<Array<Language_Code>>;
  /** 노출 탭 위치  */
  location?: InputMaybe<Array<Banner_Location>>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 노출 상태 */
  state?: InputMaybe<Array<Banner_State>>;
  /** 노출 위치(앱/웹) */
  targetClient?: InputMaybe<Array<Client>>;
};

export type ListableBlockBounceStatOfChapter = {
  __typename?: 'ListableBlockBounceStatOfChapter';
  /** 회차 이탈율 */
  currentRate: Scalars['Float'];
  list: Array<BlockBounceStatOfChapter>;
};

/** 챕터의 이탈된 블럭 통계를 조회하는데 필요한 입력값 */
export type ListableBlockBounceStatOfChapterInput = {
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** 일간, 주간, 월간 (null 이면 전체) */
  type?: InputMaybe<Story_Stat_Cache_Type>;
  /** 버전 아이디 (ChapterDraftId) null 이면 최신 */
  version?: InputMaybe<Scalars['Int']>;
};

export type ListableBlockLineStatOfBlock = {
  __typename?: 'ListableBlockLineStatOfBlock';
  list: Array<BlockLineBounceStatOfChapter>;
};

/** 블럭의 이탈행 통계 정보를 조회하는 필요한 입력값 */
export type ListableBlockLineStatOfBlockInput = {
  /** 블럭 이름 */
  blockName: Scalars['String'];
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** 일간, 주간, 월간 (null 이면 전체) */
  type?: InputMaybe<Story_Stat_Cache_Type>;
  /** 버전 아이디 (ChapterDraftId) null 이면 최신 */
  version?: InputMaybe<Scalars['Int']>;
};

export type ListableBonusTicketCode = {
  __typename?: 'ListableBonusTicketCode';
  /** List of bonus ticket code data */
  list: Array<BonusTicketCode>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 보너스 티켓 코드 목록 조회 및 검색 */
export type ListableBonusTicketCodeInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableBottomBanner = {
  __typename?: 'ListableBottomBanner';
  /** List of BottomBanner data */
  list: Array<BottomBanner>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** BottomBanner 목록을 가져오기 위한 input */
export type ListableBottomBannerInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 노출 상태 */
  state?: InputMaybe<Array<Banner_State>>;
};

export type ListableBundleBanner = {
  __typename?: 'ListableBundleBanner';
  /** List of BundleBanner data */
  list: Array<BundleBanner>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** BundleBanner 목록을 가져오기 위한 input */
export type ListableBundleBannerInput = {
  /** 국가코드 */
  countryCode?: InputMaybe<Array<Scalars['String']>>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 언어코드 */
  languageCode?: InputMaybe<Array<Scalars['String']>>;
  /** 노출 탭 위치  */
  location?: InputMaybe<Array<Banner_Location>>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 노출 상태 */
  state?: InputMaybe<Array<Banner_State>>;
  /** 노출 위치(앱/웹) */
  targetClient?: InputMaybe<Array<Client>>;
};

export type ListableBundleFeatured = {
  __typename?: 'ListableBundleFeatured';
  /** List of BundleFeatured data */
  list: Array<BundleFeatured>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** BundleFeatured 목록을 가져오기 위한 input */
export type ListableBundleFeaturedInput = {
  /** 국가코드 */
  countryCode?: InputMaybe<Array<Scalars['String']>>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 언어코드 */
  languageCode?: InputMaybe<Array<Scalars['String']>>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableCachedStory = {
  __typename?: 'ListableCachedStory';
  /** List of story data */
  list: Array<Story>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
  /** cached version */
  version?: Maybe<Scalars['Int']>;
};

/** 도전 스토리 목록 조회 및 검색 */
export type ListableChallengeStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 도전 스토리 장르 */
  genre?: InputMaybe<Scalars['String']>;
  /** 성인 작품 탭 여부 */
  isAdultTab?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType?: InputMaybe<Story_Play_Type>;
  /** 도전 스토리 리스트 정렬 기준 */
  sorting?: InputMaybe<Challenge_Story_Sorting>;
  /** version */
  version?: InputMaybe<Scalars['Int']>;
};

export type ListableChapter = {
  __typename?: 'ListableChapter';
  /** List of Chapter data */
  list: Array<Chapter>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Chapter 목록 조회 및 검색 */
export type ListableChapterByClientInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 ID */
  storyId: Scalars['Int'];
};

export type ListableChapterComment = {
  __typename?: 'ListableChapterComment';
  /** true이면 유저가 해당 회차를 플레이 했음 */
  canUserWriteComment: Scalars['Boolean'];
  /** 작품 작가 OR 어드민 이상 유저면 읽지 않은 작품에도 대/댓글을 달수 있다. */
  canWriteComment: Scalars['Boolean'];
  /** List of ChapterComment data */
  list: Array<ChapterComment>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 챕터별 댓글 목록 조회 및 검색 */
export type ListableChapterCommentInput = {
  /** 댓글이 속한 챕터의 아이디. 회차별 댓글을 가져올 때는 이 값만 설정해야 한다 */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 선택지 ID 선택지 댓글 리스트 일 경우 필수 값 */
  choiceId?: InputMaybe<Scalars['Int']>;
  /** 리스트에서 삭제된 댓글을 제외할 것인가? */
  exceptDeleted?: InputMaybe<Scalars['Boolean']>;
  /** 리스트에서 숨겨진 댓글을 제외할 것인가? */
  exceptHidden?: InputMaybe<Scalars['Boolean']>;
  /** 검색 키워드 */
  filter?: InputMaybe<Scalars['String']>;
  /** 회차 댓글의 정렬 기준. 정렬 기준이 여러개 일 때는 앞에서 부터 차례로 기준을 적용한다. 기본값은 [UserLikes DESC, CreatedDate DESC] */
  orderBy?: InputMaybe<Array<ChapterCommentOrderBy>>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 대댓글의 부모 댓글 아이디. 대댓글을 가져올 때는 이 값만 설정해야 한다 */
  parentId?: InputMaybe<Scalars['Int']>;
  /** 댓글이 속한 스토리 아이디. 스토리에 속한 모든 댓글을 가져올 때는 이 값만 설정해야 한다 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 댓글의 타입 Text(기본값) | UserInputText | Statistics */
  type?: InputMaybe<Chapter_Comment_Type>;
};

/** 챕터별 댓글 목록 조회 및 검색 */
export type ListableChapterCommentInputV3 = {
  /** 댓글이 속한 챕터의 회차 인덱스. 회차별 댓글을 가져올 때는 이 값만 설정해야 한다 */
  chapterIndex?: InputMaybe<Scalars['Int']>;
  /** 선택지 ID 선택지 댓글 리스트 일 경우 필수 값 */
  choiceId?: InputMaybe<Scalars['Int']>;
  /** 리스트에서 삭제된 댓글을 제외할 것인가? */
  exceptDeleted?: InputMaybe<Scalars['Boolean']>;
  /** 리스트에서 숨겨진 댓글을 제외할 것인가? */
  exceptHidden?: InputMaybe<Scalars['Boolean']>;
  /** 검색 키워드 */
  filter?: InputMaybe<Scalars['String']>;
  /** 유저의 시스템 언어에 매칭되는 작품의 댓글만 볼것인가? */
  onlySystemLanguageMatched?: InputMaybe<Scalars['Boolean']>;
  /** 회차 댓글의 정렬 기준. 정렬 기준이 여러개 일 때는 앞에서 부터 차례로 기준을 적용한다. 기본값은 [UserLikes DESC, CreatedDate DESC] */
  orderBy?: InputMaybe<Array<ChapterCommentOrderBy>>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 대댓글의 부모 댓글 아이디. 대댓글을 가져올 때는 이 값만 설정해야 한다 */
  parentId?: InputMaybe<Scalars['Int']>;
  /** 댓글이 속한 스토리 아이디 */
  storyId: Scalars['Int'];
  /** 댓글의 타입 Text(기본값) | UserInputText | Statistics */
  type?: InputMaybe<Chapter_Comment_Type>;
};

export type ListableChapterDraft = {
  __typename?: 'ListableChapterDraft';
  /** List of ChapterDraft data */
  list: Array<ChapterDraft>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableChapterDraftArchive = {
  __typename?: 'ListableChapterDraftArchive';
  /** List of ChapterDraftArchive */
  list: Array<ChapterDraftArchive>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 휴지통에 있는 백업 목록 조회에 필요한 입력값 */
export type ListableChapterDraftArchiveInTrashInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 초안 백업 목록 조회에 필요한 입력값 */
export type ListableChapterDraftArchiveInput = {
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** ChapterDraft 목록 조회 및 검색 */
export type ListableChapterDraftInput = {
  /** 어떤 챕터에 대한 드래프트 목록을 조회할 것인가? */
  chapterId: Scalars['Int'];
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 드래프트의 현재 상태 (CHAPTER_DRAFT_STATUS) */
  status?: InputMaybe<Chapter_Draft_Status>;
};

/** Chapter 목록 조회 및 검색 */
export type ListableChapterInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 회차 목록 정렬 옵션 */
  sortOption?: InputMaybe<List_Chapter_For_Writer_Sort_Option>;
  /** 작품 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListableChapterOption = {
  __typename?: 'ListableChapterOption';
  /** List of ChapterOption data */
  list: Array<ChapterOption>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** ChapterOption 목록을 가져오기 위한 input */
export type ListableChapterOptionInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableChoice = {
  __typename?: 'ListableChoice';
  /** List of Choice data */
  list: Array<Choice>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableChoiceFromPlayerClass = {
  __typename?: 'ListableChoiceFromPlayerClass';
  /** List of ChoiceFromPlayerClass data */
  list: Array<ChoiceFromPlayerClass>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** ChoiceFromPlayerClass 목록을 가져오기 위한 input */
export type ListableChoiceFromPlayerClassInput = {
  /** 맵핑된 선택지 정보 ID */
  choiceId: Scalars['Int'];
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 해당 선택지를 선택한 유저 타입(playerClass) ID */
  playerClassId?: InputMaybe<Scalars['Int']>;
};

/** Choice 목록 조회 및 검색 */
export type ListableChoiceInput = {
  /** 회차 ID */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListableChr = {
  __typename?: 'ListableChr';
  /** List of Chr data */
  list: Array<Chr>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Chr 목록 조회 및 검색 */
export type ListableChrInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 메인 또는 보조캐릭터만? */
  mainFilter?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** storyId */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListableColor = {
  __typename?: 'ListableColor';
  /** List of Color data */
  list: Array<Color>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Color 목록을 가져오기 위한 input */
export type ListableColorInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableCommonOutput = {
  __typename?: 'ListableCommonOutput';
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableContestHasStory = {
  __typename?: 'ListableContestHasStory';
  /** List of ContestHasStory */
  list: Array<ContestHasStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 콘테스트 참여한 작품 목록 조회하는데 필요한 입력값 */
export type ListableContestHasStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 작품 장르 */
  genre?: InputMaybe<Challenge_Story_Genre>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 공모전 회차 */
  version?: InputMaybe<Scalars['Int']>;
};

export type ListableContestInfo = {
  __typename?: 'ListableContestInfo';
  /** 현재 진행중인 공모전 목록 */
  list: Array<ContestInfo>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 현재 진행중인 공모전 조회하는데 필요한 입력값 (no pagination) */
export type ListableContestInfoInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 어떤 작품의 공모전인지 여부 */
  type?: InputMaybe<Story_Play_Type>;
};

export type ListableCountry = {
  __typename?: 'ListableCountry';
  /** List of Country data */
  list: Array<Country>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Country 목록을 가져오기 위한 input */
export type ListableCountryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 도전 스토리 일간 인기 목록 조회 및 검색 */
export type ListableDailyUgcStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 도전 스토리 장르 */
  genre?: InputMaybe<Scalars['String']>;
  /** 성인 작품 탭 여부 */
  isAdultTab?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** version */
  version?: InputMaybe<Scalars['Int']>;
};

export type ListableDirectAds = {
  __typename?: 'ListableDirectAds';
  /** List of DirectAds data */
  list: Array<DirectAds>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** DirectAds 목록을 가져오기 위한 input */
export type ListableDirectAdsInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 타입 */
  type?: InputMaybe<Scalars['String']>;
};

export type ListableEnding = {
  __typename?: 'ListableEnding';
  /** List of Ending data */
  list: Array<Ending>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableEndingCard = {
  __typename?: 'ListableEndingCard';
  /** List of Ending card */
  list: Array<EndingCard>;
};

/** 엔딩 카드 목록 조회 및 검색 */
export type ListableEndingCardByAdminInput = {
  /** 엔딩카드에 해당하는 엔딩 ID */
  endingId?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 엔딩 카드 타입 */
  type?: InputMaybe<Ending_Card_Type>;
};

/** 엔딩 목록 조회 및 검색 */
export type ListableEndingInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 최종 엔딩 필터링 여부 */
  isFinal?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 엔딩 도착률 범위 리스트 */
export type ListableEndingRateRange = {
  __typename?: 'ListableEndingRateRange';
  /** List of Ending Rate Range */
  list: Array<EndingRateRange>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableEndingShowsProperty = {
  __typename?: 'ListableEndingShowsProperty';
  /** 엔딩에서 보여줄 스토리 속성의 리스트 */
  list: Array<EndingShowsProperty>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 엔딩에 보여질 스토리 속성의 검색 및 조회할 떄 필요한 데이터 */
export type ListableEndingShowsPropertyInput = {
  /** 엔딩 ID */
  endingId?: InputMaybe<Scalars['Float']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 ID */
  storyId?: InputMaybe<Scalars['Float']>;
};

export type ListableExposedSectionStory = {
  __typename?: 'ListableExposedSectionStory';
  /** List of ExposedSectionStory data */
  list: Array<ExposedSectionStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** ExposedSectionStory 목록을 가져오기 위한 input */
export type ListableExposedSectionStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableFeatured = {
  __typename?: 'ListableFeatured';
  /** List of Featured data */
  list: Array<Featured>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Featured 목록을 가져오기 위한 input */
export type ListableFeaturedInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableHomeSection = {
  __typename?: 'ListableHomeSection';
  /** List of HomeSection data */
  list: Array<HomeSection>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** HomeSection 목록을 가져오기 위한 input */
export type ListableHomeSectionInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 리스트 정렬 기준 */
  sorting?: InputMaybe<Story_Sorting>;
  /** 홈 화면의 섹션 종류 */
  type?: InputMaybe<Home_Section_Type>;
};

export type ListableHomeSectionStory = {
  __typename?: 'ListableHomeSectionStory';
  /** List of HomeSectionStory data */
  list: Array<HomeSectionStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** HomeSectionStory 목록을 가져오기 위한 input */
export type ListableHomeSectionStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 스토리가 포함될 homeSectionId */
  homeSectionId: Scalars['Int'];
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableImageFile = {
  __typename?: 'ListableImageFile';
  /** List of ImageFile data */
  list: Array<ImageFile>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** ImageFile 목록 조회 및 검색 */
export type ListableImageFileInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableInappPackage = {
  __typename?: 'ListableInappPackage';
  /** List of InappPackage data */
  list: Array<InappPackage>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** InappPackage 목록을 가져오기 위한 input */
export type ListableInappPackageInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 플랫폼  OS */
  os: Os;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Paging input type */
export type ListableInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableIpSourcing = {
  __typename?: 'ListableIpSourcing';
  /** List of IpSourcing data */
  list: Array<IpSourcing>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableLanguage = {
  __typename?: 'ListableLanguage';
  /** List of Language data */
  list: Array<Language>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Language 목록을 가져오기 위한 input */
export type ListableLanguageInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableLedger = {
  __typename?: 'ListableLedger';
  /** List of Ledger data */
  list: Array<Ledger>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 어드민에서 Ledger 목록 조회 및 검색 */
export type ListableLedgerByAdminInput = {
  /** 사용 이력/지급 이력 */
  amountFilter?: InputMaybe<Scalars['String']>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 기록 생성일 기준 최신순 (desc/asc) */
  orderBy?: InputMaybe<Order_By_Direction>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 사용자 ID */
  userId: Scalars['Int'];
};

/** Ledger 목록 조회 및 검색 */
export type ListableLedgerInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 핵심 등장인물 목록 */
export type ListableMainCharacter = {
  __typename?: 'ListableMainCharacter';
  /** 조회된 목록 데이터 */
  list: Array<MainCharacter>;
};

/** 핵심 등장인물 목록 조회 */
export type ListableMainCharacterInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

export type ListableMonthlyBundle = {
  __typename?: 'ListableMonthlyBundle';
  /** List of MonthlyBundle data */
  list: Array<MonthlyBundle>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** MonthlyBundle 목록을 가져오기 위한 input */
export type ListableMonthlyBundleInput = {
  /** 국가 코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** undefined */
export type ListableNotice = {
  __typename?: 'ListableNotice';
  /** 조회된 목록 데이터 */
  list: Array<Notice>;
  /** 현재 조회하고 있는 페이지 (1-based index) */
  page: Scalars['Int'];
  /** 현재 조회된 페이지의 크기 query. 클라이언트에서 전달한 값을 최대한 활용하나, 서버의 사정에 따라 변경되어 전달 가능. */
  pageSize: Scalars['Int'];
  /** 조건에 맞는 조회된 데이터의 총 갯수 */
  totalCount: Scalars['Int'];
};

/** Notice 목록 조회, 검색을 위한 입력값 */
export type ListableNoticeInput = {
  /** 공지사항 검색용 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** undefined */
export type ListableNoticePopup = {
  __typename?: 'ListableNoticePopup';
  /** 조회된 목록 데이터 */
  list: Array<NoticePopup>;
  /** 현재 조회하고 있는 페이지 (1-based index) */
  page: Scalars['Int'];
  /** 현재 조회된 페이지의 크기 query. 클라이언트에서 전달한 값을 최대한 활용하나, 서버의 사정에 따라 변경되어 전달 가능. */
  pageSize: Scalars['Int'];
  /** 조건에 맞는 조회된 데이터의 총 갯수 */
  totalCount: Scalars['Int'];
};

/** NoticePopup 목록 조회, 검색을 위한 입력값 */
export type ListableNoticePopupInput = {
  /** 공지사항 검색용 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 작품의 회차별 댓글수를 조회하기 위한 입력값 */
export type ListableNumCommentOfChapterInput = {
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** 어드 회차(chapterIndex)까지 조회할 것인지? */
  endIndex: Scalars['Int'];
  /** 어느 회차(chapterIndex)부터 조회할 것인지? */
  startIndex?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

/** 작품의 회차별 누적 조회수 정보를 조회하기 위한 입력값 */
export type ListableNumPlayOfChapterInput = {
  /** 조회하고자 하는 마지막 날짜 */
  endDate: Scalars['String'];
  /** 어드 회차(chapterIndex)까지 조회할 것인지? */
  endIndex: Scalars['Int'];
  /** 어느 회차(chapterIndex)부터 조회할 것인지? */
  startIndex?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

export type ListableOnboardingPopup = {
  __typename?: 'ListableOnboardingPopup';
  /** List of OnboardingPopup data */
  list: Array<OnboardingPopup>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableOnboardingPopupChoice = {
  __typename?: 'ListableOnboardingPopupChoice';
  /** List of OnboardingPopupChoice data */
  list: Array<OnboardingPopupChoice>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 선택 팝업 선택지 리스트 */
export type ListableOnboardingPopupChoiceInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 온보딩 팝업 ID */
  popupId: Scalars['Int'];
};

/** 선택 팝업 리스트 */
export type ListableOnboardingPopupInput = {
  /** 팝업이 속한 챕터 아이디 */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 캐릭터 아이디 */
  characterId?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 팝업이 속한 스토리 아이디 */
  storyId: Scalars['Int'];
  /** Text: 텍스트, Choice: 선택, Profile: 프로필, Name: 주인공 이름, OtherName: 상대역 이름 */
  type?: InputMaybe<Onboarding_Popup_Type>;
};

export type ListableOnboardingPopupProfileImage = {
  __typename?: 'ListableOnboardingPopupProfileImage';
  /** List of OnboardingPopupProfileImage data */
  list: Array<OnboardingPopupProfileImage>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** OnboardingPopupProfileImage 목록을 가져오기 위한 input */
export type ListableOnboardingPopupProfileImageInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 온보딩 팝업 ID */
  popupId: Scalars['Int'];
};

export type ListablePackageCoin = {
  __typename?: 'ListablePackageCoin';
  /** List of PackageCoin data */
  list: Array<PackageCoin>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PackageCoin 목록을 가져오기 위한 input */
export type ListablePackageCoinInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListablePackageSkipAds = {
  __typename?: 'ListablePackageSkipAds';
  /** List of PackageSkipAds data */
  list: Array<PackageSkipAds>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PackageSkipAds 목록을 가져오기 위한 input */
export type ListablePackageSkipAdsInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListablePackageStory = {
  __typename?: 'ListablePackageStory';
  /** List of PackageStory data */
  list: Array<PackageStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PackageStory 목록을 가져오기 위한 input */
export type ListablePackageStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListablePackageTimeSale = {
  __typename?: 'ListablePackageTimeSale';
  /** List of PackageTimeSale data */
  list: Array<PackageTimeSale>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PackageTimeSale 목록을 가져오기 위한 input */
export type ListablePackageTimeSaleInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListablePlayerClass = {
  __typename?: 'ListablePlayerClass';
  /** List of PlayerClass data */
  list: Array<PlayerClass>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PlayerClass 목록을 가져오기 위한 input */
export type ListablePlayerClassInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 스토리 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListablePlayerReport2 = {
  __typename?: 'ListablePlayerReport2';
  /** 현재 운영중인 플레이어 보고서 아이디 */
  activeReportId?: Maybe<Scalars['Int']>;
  /** List of PlayerReport2 data */
  list: Array<PlayerReport2>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListablePlayerReport2Function = {
  __typename?: 'ListablePlayerReport2Function';
  /** List of PlayerReport2Function data */
  list: Array<PlayerReport2Function>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PlayerReport2Function 목록 조회 및 검색 */
export type ListablePlayerReport2FunctionInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** PlayerReport2 목록 조회 및 검색 */
export type ListablePlayerReport2Input = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 이 분석 보고서가 활용되는 작품 Id */
  storyId: Scalars['Int'];
};

export type ListablePlayerReport2RenderBlock = {
  __typename?: 'ListablePlayerReport2RenderBlock';
  /** List of PlayerReport2RenderBlock data */
  list: Array<PlayerReport2RenderBlock>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PlayerReport2RenderBlock 목록 조회 및 검색 */
export type ListablePlayerReport2RenderBlockInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListablePreviewEnding = {
  __typename?: 'ListablePreviewEnding';
  /** List of Ending data */
  list: Array<PreviewEnding>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** preview 엔딩 목록 조회 및 검색 */
export type ListablePreviewEndingInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListablePublicResourceCategory = {
  __typename?: 'ListablePublicResourceCategory';
  /** List of PublicResourceCategory data */
  list: Array<PublicResourceCategory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 공용리소스 카테고리 조회하는데 필요한 입력값 */
export type ListablePublicResourceCategoryInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 리소스 타입 */
  type?: InputMaybe<Public_Resource_Type>;
};

export type ListablePublicResourceFile = {
  __typename?: 'ListablePublicResourceFile';
  /** List of PublicResourceFile data */
  list: Array<PublicResourceFile>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 공용리소스 목록을 조회하는데 필요한 데이터 */
export type ListablePublicResourceFileInput = {
  /** 카테고리 아이디 (없으면 전체) */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 리소스 타입 */
  resourceType?: InputMaybe<Public_Resource_Type>;
};

/** 통구매 가능한 작품 리스트를 위한 input */
export type ListablePurchasableStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListablePushNotification = {
  __typename?: 'ListablePushNotification';
  /** List of PushNotification data */
  list: Array<PushNotification>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** PushNotification 목록을 가져오기 위한 input */
export type ListablePushNotificationInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableRecommendSectionStory = {
  __typename?: 'ListableRecommendSectionStory';
  /** List of RecommendSectionStory data */
  list: Array<RecommendSectionStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** RecommendSectionStory 목록을 가져오기 위한 input By Client */
export type ListableRecommendSectionStoryByClientInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 섹션 Id */
  storyId: Scalars['Int'];
};

/** RecommendSectionStory 목록을 가져오기 위한 input */
export type ListableRecommendSectionStoryInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 섹션 Id */
  sectionId: Scalars['Int'];
};

export type ListableRecommendationForFirstUser = {
  __typename?: 'ListableRecommendationForFirstUser';
  /** List of RecommendationForFirstUser data */
  list: Array<RecommendationForFirstUser>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** RecommendationForFirstUser 목록을 가져오기 위한 input */
export type ListableRecommendationForFirstUserInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableRecommendationOnboarding = {
  __typename?: 'ListableRecommendationOnboarding';
  /** List of RecommendationOnboarding data */
  list: Array<RecommendationOnboarding>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** RecommendationOnboarding 목록을 가져오기 위한 input */
export type ListableRecommendationOnboardingInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableRemoteScriptProvider = {
  __typename?: 'ListableRemoteScriptProvider';
  /** List of RemoteScriptProvider data */
  list: Array<RemoteScriptProvider>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** RemoteScriptProvider 목록을 가져오기 위한 input */
export type ListableRemoteScriptProviderInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** ai 캐릭터 생성 결과 목록 */
export type ListableRequestAiCharacter = {
  __typename?: 'ListableRequestAICharacter';
  /** 호출된 chrId */
  chrId?: Maybe<Scalars['Int']>;
  /** 조회된 목록 데이터 */
  list: Array<RequestAiCharacter>;
  /** 현재 조회하고 있는 페이지 (1-based index) */
  page: Scalars['Int'];
  /** 현재 조회된 페이지의 크기 query. 클라이언트에서 전달한 값을 최대한 활용하나, 서버의 사정에 따라 변경되어 전달 가능. */
  pageSize: Scalars['Int'];
  /** 호출된 sessionId */
  sessionId: Scalars['String'];
  /** 조건에 맞는 조회된 데이터의 총 갯수 */
  totalCount: Scalars['Int'];
};

/** ai 캐릭터 생성 결과 목록 조회를 위한 입력값 */
export type ListableRequestAiCharacterInput = {
  /** 캐릭터 아이디, -1이면 주인공 */
  chrId?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** sessionId */
  sessionId: Scalars['String'];
};

export type ListableSfxFile = {
  __typename?: 'ListableSFXFile';
  /** List of SFXFile data */
  list: Array<SfxFile>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** SFXFile 목록 조회 및 검색 */
export type ListableSfxFileInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 홈 화면의 section 조회 */
export type ListableSectionStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 출간여부 (노출여부) */
  published?: InputMaybe<Scalars['Boolean']>;
  /** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
  sectionType?: InputMaybe<Story_Section_Type>;
};

export type ListableSimilarStory = {
  __typename?: 'ListableSimilarStory';
  /** List of story data */
  list: Array<Story>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 유사한 작품을 가져오기 위한 input */
export type ListableSimilarStoryInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** storyId */
  storyId: Scalars['Int'];
};

export type ListableSkipAds = {
  __typename?: 'ListableSkipAds';
  /** List of SkipAds data */
  list: Array<SkipAds>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** SkipAds 목록을 가져오기 위한 input */
export type ListableSkipAdsInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoreBundle = {
  __typename?: 'ListableStoreBundle';
  /** List of StoreBundle data */
  list: Array<StoreBundle>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoreBundle 목록을 가져오기 위한 input */
export type ListableStoreBundleInput = {
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoreCartItem = {
  __typename?: 'ListableStoreCartItem';
  /** List of StoreCartItem data */
  list: Array<StoreCartItem>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableStorePayment = {
  __typename?: 'ListableStorePayment';
  /** List of StorePayment data */
  list: Array<StorePayment>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 스토어 결제 목록을 가져오기 위한 input(어드민용) */
export type ListableStorePaymentForAdminInput = {
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoreReview = {
  __typename?: 'ListableStoreReview';
  /** List of StoreReview data */
  list: Array<StoreReview>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableStoreReviewComment = {
  __typename?: 'ListableStoreReviewComment';
  /** List of StoreReviewComment data */
  list: Array<StoreReviewComment>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 스토어 리뷰 댓글 목록을 가져오기 위한 input */
export type ListableStoreReviewCommentInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 스토어 리뷰 아이디 */
  storeReviewId: Scalars['Float'];
};

/** StoreReview 목록을 가져오기 위한 input */
export type ListableStoreReviewInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 스토리 번들(작품) 아이디 */
  storyBundleId: Scalars['Float'];
};

export type ListableStoreWishlistItem = {
  __typename?: 'ListableStoreWishlistItem';
  /** List of StoreWishlistItem data */
  list: Array<StoreWishlistItem>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableStory = {
  __typename?: 'ListableStory';
  /** List of story data */
  list: Array<Story>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
  /** cached version */
  version?: Maybe<Scalars['Int']>;
};

export type ListableStoryBlockedCountry = {
  __typename?: 'ListableStoryBlockedCountry';
  /** List of StoryBlockedCountry data */
  list: Array<StoryBlockedCountry>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryBlockedCountry 목록을 가져오기 위한 input */
export type ListableStoryBlockedCountryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryBundle = {
  __typename?: 'ListableStoryBundle';
  /** List of StoryBundle data */
  list: Array<StoryBundle>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryBundle 목록을 가져오기 위한 input */
export type ListableStoryBundleForStoreAdminInput = {
  /** 스토어 출시(노출) 여부 */
  isStorePublished?: InputMaybe<Scalars['Boolean']>;
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** StoryBundle 목록을 가져오기 위한 input */
export type ListableStoryBundleForStoreInput = {
  /** 검색어 */
  keyword?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** StoryBundle 목록을 가져오기 위한 input */
export type ListableStoryBundleInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 작품 목록 조회에 필요한 입력값 */
export type ListableStoryByAdminForStudioInput = {
  /** 국가코드 */
  countryCode?: InputMaybe<Array<Scalars['String']>>;
  /** 제외할 섹션 타입. sectionType이 없을 때만 유효하게 동작함 */
  excludedSectionTypes?: InputMaybe<Array<Story_Section_Type>>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 작품 장르 */
  genre?: InputMaybe<Challenge_Story_Genre>;
  /** 성인 작품 for admin */
  isAdult?: InputMaybe<Scalars['Boolean']>;
  /** 현재 작품 상태 (연재 완료인가?) */
  isFinished?: InputMaybe<Scalars['Boolean']>;
  /** 작품의 언어 필터 */
  langCode?: InputMaybe<Language_Code>;
  /** 작품의 언어 필터 */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 인터렉티브인지 웹소설인지 여부 (null 이면 전체) */
  playType?: InputMaybe<Story_Play_Type>;
  /** 출간여부 (노출여부) */
  published?: InputMaybe<Scalars['Boolean']>;
  /** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
  sectionType?: InputMaybe<Story_Section_Type>;
  /** 스튜디오에서 작품 목록 정렬 옵션 */
  sortOption?: InputMaybe<List_Story_For_Writer_Sort_Option>;
  /** 작품 리스트 정렬 기준 */
  sorting?: InputMaybe<Story_Sorting>;
  /** 클라이언트에서 요청 시 사용하는 section type */
  uiSectionType?: InputMaybe<Ui_Story_Section_Type>;
  /** cached stat version */
  version?: InputMaybe<Scalars['Int']>;
  /** 정식작품 연재요일 */
  weekday?: InputMaybe<Story_Weekday>;
  /** true면 출간 예정작 포함 */
  willPublish?: InputMaybe<Scalars['Boolean']>;
};

/** listStoryByAppHavingEndingOrAchievement 페이지네이션 input */
export type ListableStoryByAppHavingEndingOrAchievementInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryConversionRankCache = {
  __typename?: 'ListableStoryConversionRankCache';
  /** 정주행률 정보 */
  list: Array<StoryConversionRankCache>;
  /** 내 작품 랭킹 정보 */
  myRank?: Maybe<StoryConversionRankCache>;
};

/** 스튜디오 작품의 개수 조회에 필요한 입력값 */
export type ListableStoryCountsByStudioInput = {
  /** 인터렉티브인지 웹소설인지 여부 (null 이면 전체) */
  playType?: InputMaybe<Story_Play_Type>;
};

/** 웹소설 공모전 작품 섹션을 가져오기 위한 Input */
export type ListableStoryForEpubContestWinnerInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** cached stat version */
  version?: InputMaybe<Scalars['Int']>;
};

/** 스튜디오에서 작품 목록 조회 및 검색에 필요한 입력값 */
export type ListableStoryForWriterInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 작품의 장르 타입. 확장성을 위해 string으로 유지하고, 호환성을 위해 CHALLENGE_STORY_GENRE 유지 */
  genre?: InputMaybe<Scalars['String']>;
  /** 성인 작품 for admin */
  isAdult?: InputMaybe<Scalars['Boolean']>;
  /** 현재 작품 상태 (연재 완료인가?) */
  isFinished?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 타입 */
  playType?: InputMaybe<Story_Play_Type>;
  /** 출간여부 (노출여부) */
  published?: InputMaybe<Scalars['Boolean']>;
  /** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
  sectionType?: InputMaybe<Story_Section_Type>;
  /** 스튜디오에서 작품 목록 정렬 옵션 */
  sortOption?: InputMaybe<List_Story_For_Writer_Sort_Option>;
  /** 작품 리스트 정렬 기준 */
  sorting?: InputMaybe<Story_Sorting>;
  /** 클라이언트에서 요청 시 사용하는 section type */
  uiSectionType?: InputMaybe<Ui_Story_Section_Type>;
  /** cached stat version */
  version?: InputMaybe<Scalars['Int']>;
  /** 정식작품 연재요일 */
  weekday?: InputMaybe<Story_Weekday>;
};

export type ListableStoryHasBackground = {
  __typename?: 'ListableStoryHasBackground';
  /** List of StoryHasBackground data */
  list: Array<StoryHasBackground>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryHasBackground 목록 조회 및 검색 */
export type ListableStoryHasBackgroundInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryHasRecommendSection = {
  __typename?: 'ListableStoryHasRecommendSection';
  /** List of StoryHasRecommendSection data */
  list: Array<StoryHasRecommendSection>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryHasRecommendSection 목록을 가져오기 위한 input By Client */
export type ListableStoryHasRecommendSectionByClientInput = {
  /** 작품 회차 Id */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 추천 타입 */
  type?: InputMaybe<Story_Has_Recommendation_Type>;
};

/** StoryHasRecommendSection 목록을 가져오기 위한 input */
export type ListableStoryHasRecommendSectionInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 Id */
  storyId: Scalars['Int'];
};

export type ListableStoryHasStoryTag = {
  __typename?: 'ListableStoryHasStoryTag';
  /** List of StoryHasStoryTag data */
  list: Array<StoryHasStoryTag>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryHasStoryTag 목록을 가져오기 위한 input */
export type ListableStoryHasStoryTagInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryHasStudioFile = {
  __typename?: 'ListableStoryHasStudioFile';
  /** List of StoryHasStudioFile data */
  list: Array<StoryHasStudioFile>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryHasStudioFile 목록 조회 및 검색 */
export type ListableStoryHasStudioFileInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 ID */
  storyId: Scalars['Int'];
};

/** 사용자가 엔딩을 본 Story 목록 조회 및 검색 */
export type ListableStoryHavingEndingInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** Story 목록 조회 및 검색 */
export type ListableStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 작품의 장르 타입. 확장성을 위해 string으로 유지하고, 호환성을 위해 CHALLENGE_STORY_GENRE 유지 */
  genre?: InputMaybe<Scalars['String']>;
  /** 성인 작품 for admin */
  isAdult?: InputMaybe<Scalars['Boolean']>;
  /** 현재 작품 상태 (연재 완료인가?) */
  isFinished?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType?: InputMaybe<Story_Play_Type>;
  /** 출간여부 (노출여부) */
  published?: InputMaybe<Scalars['Boolean']>;
  /** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
  sectionType?: InputMaybe<Story_Section_Type>;
  /** 스튜디오에서 작품 목록 정렬 옵션 */
  sortOption?: InputMaybe<List_Story_For_Writer_Sort_Option>;
  /** 작품 리스트 정렬 기준 */
  sorting?: InputMaybe<Story_Sorting>;
  /** 클라이언트에서 요청 시 사용하는 section type */
  uiSectionType?: InputMaybe<Ui_Story_Section_Type>;
  /** cached stat version */
  version?: InputMaybe<Scalars['Int']>;
  /** 정식작품 연재요일 */
  weekday?: InputMaybe<Story_Weekday>;
};

export type ListableStoryItem = {
  __typename?: 'ListableStoryItem';
  /** List of StoryItem data */
  list: Array<StoryItem>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryItem 목록을 가져오기 위한 input */
export type ListableStoryItemInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 아이템이 속한 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryPreview = {
  __typename?: 'ListableStoryPreview';
  /** List of StoryPreview data */
  list: Array<StoryPreview>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryPreview 목록을 가져오기 위한 input */
export type ListableStoryPreviewInput = {
  /** 리소스 타입 (Image or Video) */
  fileType?: InputMaybe<Storygame_Preview_Resource_Type>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryPriceSetting = {
  __typename?: 'ListableStoryPriceSetting';
  /** List of StoryPriceSetting data */
  list: Array<StoryPriceSetting>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryPriceSetting 목록을 가져오기 위한 input */
export type ListableStoryPriceSettingInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품아이디 */
  storyId: Scalars['Int'];
};

export type ListableStoryProperty = {
  __typename?: 'ListableStoryProperty';
  /** 스토리 속성의 리스트 */
  list: Array<StoryProperty>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 스토리 속성 조회 및 검색 */
export type ListableStoryPropertyInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 스토리 ID */
  storyId?: InputMaybe<Scalars['Float']>;
};

export type ListableStoryRankCache = {
  __typename?: 'ListableStoryRankCache';
  /** 작품 랭킹 */
  list: Array<StoryRankCache>;
  /** 내 작품 랭킹정보 */
  myRank?: Maybe<StoryRankCache>;
};

/** 랭킹을 조회하기 위한 입력값 */
export type ListableStoryRankCacheInput = {
  /** 조회할 기간의 날짜 (YYYY-MM-DD) */
  endDate: Scalars['String'];
  /** 장르 */
  genre?: InputMaybe<Challenge_Story_Genre>;
  /** 도전스토리 랭킹인지? */
  isChallengeStory?: InputMaybe<Scalars['Boolean']>;
  /** 인터랙티브/웹소설 */
  playType?: InputMaybe<Story_Play_Type>;
  /** 랭킹종류 */
  rankType: Story_Rank_Type;
  /** 내 작품 아이디 */
  storyId: Scalars['Int'];
  /** 일간, 주간, 월간, null 이면 전체 */
  type?: InputMaybe<Story_Stat_Cache_Type>;
};

export type ListableStoryRanking = {
  __typename?: 'ListableStoryRanking';
  /** List of StoryRanking data */
  list: Array<StoryRanking>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryRanking 목록을 가져오기 위한 input */
export type ListableStoryRankingInput = {
  /** 작품의 장르 (ChallengeStoryGenre). null이면 전체 장르를 대상으로 집계 */
  genre?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryTag = {
  __typename?: 'ListableStoryTag';
  /** List of StoryTag data */
  list: Array<StoryTag>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryTag 목록을 가져오기 위한 input */
export type ListableStoryTagInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryToggleReaction = {
  __typename?: 'ListableStoryToggleReaction';
  /** List of StoryToggleReaction data */
  list: Array<StoryToggleReaction>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryToggleReaction 목록을 가져오기 위한 input */
export type ListableStoryToggleReactionInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStoryWeekday = {
  __typename?: 'ListableStoryWeekday';
  /** List of StoryWeekday data */
  list: Array<StoryWeekday>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 해당 작품의 StoryWeekday 목록을 가져오기 위한 input */
export type ListableStoryWeekdayInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Float'];
};

export type ListableStoryWeeklyRanking = {
  __typename?: 'ListableStoryWeeklyRanking';
  /** List of StoryWeeklyRanking data */
  list: Array<StoryWeeklyRanking>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryWeeklyRanking 목록을 가져오기 위한 input */
export type ListableStoryWeeklyRankingInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 playType(Interactive || EPUB). null이면 전체 타입을 대상으로 집계 */
  playType?: InputMaybe<Story_Play_Type>;
};

export type ListableStorygameBackground = {
  __typename?: 'ListableStorygameBackground';
  /** List of StorygameBackground data */
  list: Array<StorygameBackground>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StorygameBackground 목록을 가져오기 위한 input */
export type ListableStorygameBackgroundInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStorygameCharacterAdditionalPrompt = {
  __typename?: 'ListableStorygameCharacterAdditionalPrompt';
  list: Array<StorygameCharacterAdditionalPrompt>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AI 자캐 생성 프롬프트 목록 조회 입력값 */
export type ListableStorygameCharacterAdditionalPromptInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

export type ListableStorygameCharacterPrompt = {
  __typename?: 'ListableStorygameCharacterPrompt';
  list: Array<StorygameCharacterPrompt>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** AI 자캐 생성 기본 프롬프트 리스트 조회 입력값 */
export type ListableStorygameCharacterPromptInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

export type ListableStorygameCountry = {
  __typename?: 'ListableStorygameCountry';
  /** List of StorygameCountry data */
  list: Array<StorygameCountry>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StorygameCountry 목록을 가져오기 위한 input */
export type ListableStorygameCountryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStorygameSection = {
  __typename?: 'ListableStorygameSection';
  /** List of StorygameSection data */
  list: Array<StorygameSection>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableStorygameSectionCountry = {
  __typename?: 'ListableStorygameSectionCountry';
  /** List of StorygameSectionCountry data */
  list: Array<StorygameSectionCountry>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StorygameSectionCountry 목록을 가져오기 위한 input */
export type ListableStorygameSectionCountryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** StorygameSection 목록을 가져오기 위한 input */
export type ListableStorygameSectionInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 섹션의 아이디 */
  sectionId: Scalars['Int'];
};

export type ListableStorygameTab = {
  __typename?: 'ListableStorygameTab';
  /** List of StorygameTab data */
  list: Array<StorygameTab>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StorygameTab 목록을 가져오기 위한 input */
export type ListableStorygameTabInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStudioReleaseItem = {
  __typename?: 'ListableStudioReleaseItem';
  /** List of StudioReleaseItem */
  list: Array<StudioReleaseItem>;
  /** 현재 조회하고 있는 페이지 (1-based index) */
  page: Scalars['Int'];
  /** 현재 조회된 페이지의 크기 query. 클라이언트에서 전달한 값을 최대한 활용하나, 서버의 사정에 따라 변경되어 전달 가능. */
  pageSize: Scalars['Int'];
  /** 조건에 맞는 조회된 데이터의 총 갯수 */
  totalCount: Scalars['Int'];
};

/** 스튜디오 릴리즈 기능 목록 조회하는데 필요한 입력값 */
export type ListableStudioReleaseItemInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** StudioReleaseVersion 아이디 */
  versionId: Scalars['Int'];
};

export type ListableStudioReleaseVersion = {
  __typename?: 'ListableStudioReleaseVersion';
  /** List of StudioReleaseVersion */
  list: Array<StudioReleaseVersion>;
  /** 현재 조회하고 있는 페이지 (1-based index) */
  page: Scalars['Int'];
  /** 현재 조회된 페이지의 크기 query. 클라이언트에서 전달한 값을 최대한 활용하나, 서버의 사정에 따라 변경되어 전달 가능. */
  pageSize: Scalars['Int'];
  /** 조건에 맞는 조회된 데이터의 총 갯수 */
  totalCount: Scalars['Int'];
};

/** 어드민의 스튜디오 릴리즈 버전 목록을 조회하는데 필요한 입력값 */
export type ListableStudioReleaseVersionByAdminInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 스튜디오 릴리즈 버전 목록을 조회하는데 필요한 입력값 */
export type ListableStudioReleaseVersionInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableStudioTutorialCompletion = {
  __typename?: 'ListableStudioTutorialCompletion';
  /** List of StudioTutorialCompletion */
  list: Array<StudioTutorialCompletion>;
  /** 현재 조회하고 있는 페이지 (1-based index) */
  page: Scalars['Int'];
  /** 현재 조회된 페이지의 크기 query. 클라이언트에서 전달한 값을 최대한 활용하나, 서버의 사정에 따라 변경되어 전달 가능. */
  pageSize: Scalars['Int'];
  /** 조건에 맞는 조회된 데이터의 총 갯수 */
  totalCount: Scalars['Int'];
};

export type ListableTerms = {
  __typename?: 'ListableTerms';
  /** List of Terms data */
  list: Array<Terms>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** Terms 목록을 가져오기 위한 input */
export type ListableTermsInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 오늘의 도전 스토리 목록 조회 및 검색 */
export type ListableTodayChallengeStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 오늘의 도전 스토리 장르 인기작 목록 조회 및 검색 */
export type ListableTodayGenreUgcStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableTodaysHotUgc = {
  __typename?: 'ListableTodaysHotUGC';
  /** List of TodaysHotUGC data */
  list: Array<TodaysHotUgc>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** TodaysHotUGC 목록을 가져오기 위한 input */
export type ListableTodaysHotUgcInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** TossPayments 목록을 가져오기 위한 input */
export type ListableTossPaymentsInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableTtsSpeaker = {
  __typename?: 'ListableTtsSpeaker';
  /** List of TtsSpeaker data */
  list: Array<TtsSpeaker>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** TtsSpeaker 목록을 가져오기 위한 input */
export type ListableTtsSpeakerInput = {
  /** 나이 */
  age?: InputMaybe<Scalars['Float']>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 성별 */
  sexDistinction?: InputMaybe<Scalars['String']>;
};

/** 공모전 작품 섹션을 가져오기 위한 Input */
export type ListableUgcStoryForContestInput = {
  /** 작품의 장르 타입. 확장성을 위해 string으로 유지하고, 호환성을 위해 CHALLENGE_STORY_GENRE 유지 */
  genre?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType?: InputMaybe<Story_Play_Type>;
  /** 작품 리스트 정렬 기준 */
  sorting?: InputMaybe<Story_Sorting>;
  /** cached stat version */
  version?: InputMaybe<Scalars['Int']>;
};

/** 도전 스토리 목록 조회 및 검색 */
export type ListableUgcStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 도전 스토리 장르 */
  genre?: InputMaybe<Scalars['String']>;
  /** 성인 작품 탭 여부 */
  isAdultTab?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType?: InputMaybe<Story_Play_Type>;
  /** 도전 스토리 리스트 정렬 기준 */
  sorting?: InputMaybe<Challenge_Story_Sorting>;
  /** version */
  version?: InputMaybe<Scalars['Int']>;
};

export type ListableUser = {
  __typename?: 'ListableUser';
  /** List of user data */
  list: Array<User>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableUserCertificatedAdult = {
  __typename?: 'ListableUserCertificatedAdult';
  /** List of UserCertificatedAdult data */
  list: Array<UserCertificatedAdult>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserCertificatedAdult 목록을 가져오기 위한 input */
export type ListableUserCertificatedAdultInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserGotPushMessage = {
  __typename?: 'ListableUserGotPushMessage';
  /** List of UserGotPushMessage data */
  list: Array<UserGotPushMessage>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserGotPushMessage 목록을 가져오기 위한 input */
export type ListableUserGotPushMessageInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserHasBlacklist = {
  __typename?: 'ListableUserHasBlacklist';
  /** List of UserHasBlacklist data */
  list: Array<UserHasBlacklist>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserHasEnding 목록 조회 및 검색 */
export type ListableUserHasBlacklistInput = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserHasFcmToken = {
  __typename?: 'ListableUserHasFCMToken';
  /** List of UserHasFCMToken data */
  list: Array<UserHasFcmToken>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserHasFCMToken 목록을 가져오기 위한 input */
export type ListableUserHasFcmTokenInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

/** 관리자에 의한 유저 목록 검색 */
export type ListableUserInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 사용자 검색 키워드가 완전 일치/부분 일치인지 여부. */
  isEqualSearch?: InputMaybe<Scalars['Boolean']>;
  /** 탈퇴한 사용자만 검색할 것인가? */
  onlyLeftUser?: InputMaybe<Scalars['Boolean']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 검색할 사용자 권한레벨 (배열로 전달) */
  priv?: InputMaybe<Array<Scalars['Int']>>;
  /** 검색할 유저 이메일 */
  userEmail?: InputMaybe<Scalars['String']>;
  /** 사용자 ID */
  userId?: InputMaybe<Scalars['String']>;
};

export type ListableUserManagingStory = {
  __typename?: 'ListableUserManagingStory';
  /** List of UserManagingStory data */
  list: Array<UserManagingStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserManagingStory 목록 조회 및 검색 */
export type ListableUserManagingStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 검색할 사용자 권한레벨(STORY_USER_PRIV) (배열로 전달) */
  roles?: InputMaybe<Array<Scalars['Int']>>;
  /** 작품 Id */
  storyId: Scalars['Int'];
};

export type ListableUserPlayedStory = {
  __typename?: 'ListableUserPlayedStory';
  /** List of UserPlayedStory data */
  list: Array<UserPlayedStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 작품의 UserPlayedStory 목록을 조회하기 위한 입력값 */
export type ListableUserPlayedStoryForStudioInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** UserPlayedStory 목록 조회 및 검색 */
export type ListableUserPlayedStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserPlayerReport2 = {
  __typename?: 'ListableUserPlayerReport2';
  /** List of UserPlayerReport2 */
  list: Array<UserPlayerReport2>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 사용자의 플레이어 보고서 목록 조회에 필요한 api */
export type ListableUserPlayerReport2Input = {
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 플레이어 보고서 아이디 */
  reportId?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** uheId */
  uheId?: InputMaybe<Scalars['Int']>;
  /** 사용자 아이디 */
  userId?: InputMaybe<Scalars['Int']>;
};

export type ListableUserPurchasedChapter = {
  __typename?: 'ListableUserPurchasedChapter';
  /** List of UserPurchasedChapter data */
  list: Array<UserPurchasedChapter>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserPurchasedChapter 목록을 가져오기 위한 input */
export type ListableUserPurchasedChapterInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserPurchasedPackage = {
  __typename?: 'ListableUserPurchasedPackage';
  /** List of UserPurchasedPackage data */
  list: Array<UserPurchasedPackage>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserPurchasedPackage 목록을 가져오기 위한 input */
export type ListableUserPurchasedPackageInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserPurchasedSkipAds = {
  __typename?: 'ListableUserPurchasedSkipAds';
  /** List of UserPurchasedSkipAds data */
  list: Array<UserPurchasedSkipAds>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserPurchasedSkipAds 목록을 가져오기 위한 input */
export type ListableUserPurchasedSkipAdsInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserPurchasedStory = {
  __typename?: 'ListableUserPurchasedStory';
  /** List of UserPurchasedStory data */
  list: Array<UserPurchasedStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserPurchasedStory 목록을 가져오기 위한 input */
export type ListableUserPurchasedStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserRecommendationOnboarding = {
  __typename?: 'ListableUserRecommendationOnboarding';
  /** List of UserRecommendationOnboarding data */
  list: Array<UserRecommendationOnboarding>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserRecommendationOnboarding 목록을 가져오기 위한 input */
export type ListableUserRecommendationOnboardingInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserReportChallengeStory = {
  __typename?: 'ListableUserReportChallengeStory';
  /** List of UserReportChallengeStory data */
  list: Array<UserReportChallengeStory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserReportChallengeStory 목록을 가져오기 위한 input */
export type ListableUserReportChallengeStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserReportChapterComment = {
  __typename?: 'ListableUserReportChapterComment';
  /** List of UserReportChapterComment data */
  list: Array<UserReportChapterComment>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** 회차 댓글 신고를 위한 입력값 */
export type ListableUserReportChapterCommentInput = {
  /** 신고 당한 댓글의 아이디 */
  commentId?: InputMaybe<Scalars['Int']>;
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserRewardedAttendanceCoin = {
  __typename?: 'ListableUserRewardedAttendanceCoin';
  /** List of UserRewardedAttendanceCoin data */
  list: Array<UserRewardedAttendanceCoin>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserRewardedAttendanceCoin 목록을 가져오기 위한 input */
export type ListableUserRewardedAttendanceCoinInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserSelectedChoice = {
  __typename?: 'ListableUserSelectedChoice';
  /** List of UserSelectedChoice data */
  list: Array<UserSelectedChoice>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserSelectedChoice 목록을 가져오기 위한 input */
export type ListableUserSelectedChoiceInput = {
  /** 선택지 ID */
  choiceId: Scalars['Int'];
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListableUserStoryHistory = {
  __typename?: 'ListableUserStoryHistory';
  /** List of UserStoryHistory data */
  list: Array<UserStoryHistory>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** UserStoryHistory 목록을 가져오기 위한 input */
export type ListableUserStoryHistoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 플레이 타입 */
  playType?: InputMaybe<Story_Play_Type>;
};

export type ListableWeeklyStoryRanking = {
  __typename?: 'ListableWeeklyStoryRanking';
  /** List of WeeklyStoryRankingCache data */
  list: Array<WeeklyStoryRanking>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

export type ListableWeeklyStoryRankingCache = {
  __typename?: 'ListableWeeklyStoryRankingCache';
  /** List of WeeklyStoryRankingCache data */
  list: Array<WeeklyStoryRankingCache>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** WeeklyStoryRanking 목록을 가져오기 위한 input */
export type ListableWeeklyStoryRankingInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 작품의 플레이 타입. 비어있으면 모든 타입이 대상이 됨 */
  playType?: InputMaybe<Story_Play_Type>;
};

/** 글로벌 지원을 위한 로컬라라이즈 키맵 */
export type LocalizeKey = {
  __typename?: 'LocalizeKey';
  /** 로컬라이즈에 등록된 키값 */
  key: Scalars['String'];
  /** 한국어 */
  ko_KR: Scalars['String'];
};

/** 글로벌 지원을 위한 텍스트 맵 */
export type LocalizedText = {
  __typename?: 'LocalizedText';
  /** 영어(현재는 웹버전에서 필요한 텍스트만 적용) */
  en: Scalars['String'];
  /** 한국어 */
  ko: Scalars['String'];
};

/** Login Response */
export type LoginResponse = {
  __typename?: 'LoginResponse';
  /** 로그인한 유저 객체 */
  me: User;
  token: Scalars['String'];
};

/** 주인공 포함 핵심 등장인물 설정 정보 */
export type MainCharacter = {
  __typename?: 'MainCharacter';
  /** display name */
  displayName: Scalars['String'];
  /** first name */
  firstName?: Maybe<Scalars['String']>;
  /** 성별 */
  gender?: Maybe<Gender>;
  /** 등장인물 아이디, 주인공이면 -1 */
  id: Scalars['Int'];
  /** 이미지 (아이콘) */
  imageFile?: Maybe<File>;
  /** 기본 작가설정이면 false, 유저가 설정했다면 true */
  isCustom: Scalars['Boolean'];
  /** last name */
  lastName?: Maybe<Scalars['String']>;
  /** AI 캐릭터 생성 최초 여부 */
  latestCreatedAt?: Maybe<Scalars['DateTime']>;
  /** 이름 */
  name: Scalars['String'];
  /** 스토리게임용 상반신 이미지 */
  portraitFile?: Maybe<File>;
  /** AI 캐릭터 요청 ID */
  requestId?: Maybe<Scalars['Int']>;
  /** 광고로 AI 자캐 생성 횟수 */
  viewAdCount?: Maybe<Scalars['Int']>;
};

/** 현재 플레이중 챕터에서 선택을 내린다. */
export type MakeChoiceInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 선택 번호 (1-based) */
  choice: Scalars['Int'];
  /** 선택의 이름 */
  choiceName: Scalars['String'];
  /** 이벤트이름 업적 보낼 때 필수 */
  event?: InputMaybe<Scalars['String']>;
  /** 자연어 입력 선택지 상대방 말 */
  leftUserInputText?: InputMaybe<Scalars['String']>;
  /** numParam1 */
  numParam1?: InputMaybe<Scalars['Int']>;
  /** numParam2 */
  numParam2?: InputMaybe<Scalars['Int']>;
  /** 자연어 입력 선택지 주인공 말 */
  rightUserInputText?: InputMaybe<Scalars['String']>;
  /** 선택지 통계 결과 보임 여부 */
  showStatistics?: InputMaybe<Scalars['Boolean']>;
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 업데이트 된 아이템 이름 아이템 업데이트 시 필수 */
  updatedItems?: InputMaybe<Array<Scalars['String']>>;
  /** 업데이트 된 아이템 (JSON Object), null 이거나 없으면 업데이트하지 않음. 아이템 업데이트 시 필수 */
  updatedUserItems?: InputMaybe<Scalars['JSONObject']>;
  /** 선택 후 업데이트된 Props (JSON encoded), null 이거나 없으면 업데이트하지 않음. */
  updatedUserProps?: InputMaybe<Scalars['String']>;
};

/** 선택지 선택에 대한 output */
export type MakeChoiceOutput = {
  __typename?: 'MakeChoiceOutput';
  /** 업적 업데이트 정보 있을시, 업적 정보 */
  achievement?: Maybe<AchievementOutPut>;
  /** choiceID 나중에는 뺄거에요!!! */
  choiceId: Scalars['Int'];
  /** 선택지 통계 정보 */
  choiceResult?: Maybe<Choice>;
  /** 유저가 작품을 플레이하며 발견한 유저 프로퍼티 */
  discoveredProps?: Maybe<Scalars['String']>;
  ok: Scalars['Float'];
  /** 아이템 업데이트 정보가 있을 경우, 업데이트된 아이텀 정보 리스트 */
  storyItems?: Maybe<Array<StoryItem>>;
  /** 사용자가 선택한 선택지 index 정보 */
  userSelectedChoice?: Maybe<UserSelectedChoice>;
};

/** 월간 대표작을 번들단위로 관리하기 위한 엔티티 */
export type MonthlyBundle = {
  __typename?: 'MonthlyBundle';
  /** 월간 대표작이 노출될 국가 */
  country: Country;
  /** 월간 대표작이 노출될 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 월간 대표작으로 선정된 월 */
  month: Scalars['Int'];
  /** 아이디 */
  monthlyBundleId: Scalars['Int'];
  /** 번들 등록 시 선택한 작품 */
  story: Story;
  /** 월간 대표작으로 선정된 번들 */
  storyBundle: StoryBundle;
  /** 월단 대표작으로 선정된 번들 아이디 */
  storyBundleId: Scalars['Int'];
  /** 번들 등록 시 선택한 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 월간 대표작으로 선정된 연도 */
  year: Scalars['Int'];
  /** year, month를 Date type으로 리턴 */
  yyyymm: Scalars['DateTime'];
};

export type MonthlyBundleCache = {
  __typename?: 'MonthlyBundleCache';
  createdAt: Scalars['DateTime'];
  /** 캐싱키 값 */
  key: Scalars['String'];
  /** 아이디 */
  monthlyBundleCacheId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 값 (JSON string) */
  value: Scalars['String'];
  /** 캐싱 버전 */
  version: Scalars['Int'];
};

/** 월간 인기작 생성을 위한 input */
export type MonthlyBundleCreationInput = {
  /** 월간 인기작이 노출될 국가 코드 */
  countryCode: Scalars['String'];
  /** 월간 인기작으로 등록할 작품 */
  storyId: Scalars['Int'];
  /** 표시될 날짜 정보 */
  yyyymm: Scalars['DateTime'];
};

/** MonthlyBundle 업데이트를 위한 input */
export type MonthlyBundleUpdateInput = {
  /** 월간 인기작이 노출될 국가 코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 월간 인기작으로 등록할 작품 */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 표시될 날짜 정보 */
  yyyymm: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 스토어 약관에 동의합니다. 이미 동의되어 있으면 동의한 날짜, 국가만 업데이트
   * @deprecated acceptTermsV2로 대체되었습니다. data.serviceName에 StoryPlayStore를 넣고 호출해주세요. 대체 필수는 아님.
   */
  acceptStoreTerms: OkResponse;
  /**
   * 약관에 동의합니다. 현재는 재동의 시에만 사용합니다. 이미 동의되어 있으면 동의한 날짜, 국가만 업데이트
   * @deprecated 국가별 동의하는 약관이 달라져 acceptTermsV2로 대체되었습니다.
   */
  acceptTerms: OkResponse;
  /** 약관에 동의합니다. 현재는 재동의 시에만 사용합니다. 이미 동의되어 있으면 동의한 날짜, 국가만 업데이트 */
  acceptTermsV2: OkResponse;
  /** 특정 사용자에게 코인을 추가(또는 회수)합니다. */
  addCoinByAdmin: OkResponse;
  /** 광고를 본 후 코인을 충전. 보상받을 코인의 id를 넘겨줌 */
  addCoinWithAdsReward: AttendanceCoin;
  /** 새로운 FCMToken을 추가 등록합니다. */
  addFCMToken: OkResponse;
  /** Story 항목의 첨부파일을 추가/삭제합니다. */
  addOrRemoveStoryBackgroundFile: OkResponse;
  /** 스토어 장바구니에 스토어 번들을 추가합니다. */
  addStoreBundleToStoreCart: StoreCartItem;
  /** 스토어 장바구니에 스토리 번들(작품)을 추가합니다. */
  addStoryBundleToStoreCart: StoreCartItem;
  /** 스토리게임 섹션 내에 스토리게임이 추가될 때 호출해야 합니다. */
  addStorygameToSection: OkResponse;
  /** 보너스 티켓 코드를 사용해 지정한 유저의 티켓을 충전합니다 */
  addTicketToUserWithBonusTicketCode: AddBonusTicketOutput;
  /** 보너스 티켓 코드를 사용해 티켓을 충전합니다 */
  addTicketWithBonusTicketCode: AddBonusTicketOutput;
  /** 찜 목록에 추가합니다. */
  addToWishlist: StoreWishlistItem;
  /** 스토리에 [관리]시트의 내용을 적용합니다. */
  applyMgmtSheetToStory: Story;
  /** 해당 사용자의 계정을 정지합니다. */
  banWriterForStudio: OkResponse;
  /** 사용자를 차단합니다. */
  blockUser: UserHasBlacklist;
  /** 개별 구매한 회차를 제외한 나머지 회차를 구매합니다. without coinToUse */
  buyAllRemainingChapters: OkResponse;
  /** 개별 구매한 회차를 제외한 나머지 회차를 구매합니다. */
  buyRemainingChapters: OkResponse;
  /** 작품 번들 좋아요를 취소합니다. */
  cancelLikeBundle: OkResponse;
  /** 유저가 댓글의 좋아요를 취소합니다 */
  cancelLikeChapterComment: UserLikesChapterCommentHistory;
  /** 작품 좋아요 취소. data.id는 작품 아이디 */
  cancelLikeStory: OkResponse;
  /** 발행 또는 발행 예정인 Chapter 를 미발행 상태로 변경합니다. (해당 Chapter 이후 모든 Chapter 가 미발행상태로 변경, 1회차일 경우 작품도 미발행으로 변경됩니다.) */
  cancelPublishChapterForStudio: OkResponse;
  /** 현재 운영중인 플레이어 보고서를 운영 취소합니다. */
  cancelPublishPlayerReport2: OkResponse;
  /** 스토어 결제 취소 */
  cancelStorePayment: StorePayment;
  /** Chapter 의 무료 여부를 변경합니다. (deprecated) */
  changeChapterFree: OkResponse;
  /** Chapter 의 무료 설정을 변경합니다. changeChapterFree()의 기능을 대체합니다. */
  changeChapterFreeSetting: OkResponse;
  /** 특정 작품에 대한 구독 여부를 변경합니다. */
  changeStorySubscription: OkResponse;
  /** (관리자) 사용자의 권한을 변경하고 변경된 사용자를 반환합니다. */
  changeUserPriv: User;
  /** 해당 코드가 존재하는지 체크합니다. */
  checkBonusTicketCode: CheckBonusTicketCodeResponse;
  checkDailyRewardCoin: CheckDailyRewardCoinOutput;
  /** 캐릭터 상반신 이미지 기본 설정 */
  clearCharacterPortrait: OkResponse;
  /** 클라이언트에서 결제 완료 후 호출 */
  completeIMPPayments: IamportPayments;
  /** Failed 상태의 AI 캐릭터 생성 요청건을 보이지 않도록 처리합니다. */
  confirmFailedRequestAICharacter: OkResponse;
  /** 스토어 토스 페이먼츠 결제 승인 */
  confirmStoreTossPayment: StorePayment;
  /** 토스 페이먼츠 클라이언트에서 결제 완료 후 호출 */
  confirmTossPayments: TossPayments;
  /** 작품에 업적 프로젝트를 연동합니다. */
  connectStoryToAchievementProject: OkResponse;
  /**
   * 익명사용자에서 이메일을 이용하여 일반 사용자로 전환합니다.
   * @deprecated 약관 동의 추가로 convertUserWithEmailV2로 대체되었습니다.
   */
  convertUserWithEmail: LoginResponse;
  /**
   * 익명사용자에서 이메일을 이용하여 일반 사용자로 전환합니다.
   * @deprecated 한국, 그 외 국가에서 보여주는 약관 종류가 달라짐에 따라 convertUserWithEmailV3로 대체되었습니다.
   */
  convertUserWithEmailV2: LoginResponse;
  /** 익명사용자에서 이메일을 이용하여 일반 사용자로 전환합니다. */
  convertUserWithEmailV3: LoginResponse;
  /** 기존 있는 플레이어 보고서의 복사본을 생성합니다. */
  copyPlayerReport2: PlayerReport2;
  /** AdNetworkActivation을 생성합니다. */
  createAdNetworkActivationForStudio: AdNetworkActivation;
  /** AppSplashImage를 생성합니다. (어드민) */
  createAppSplashImage: AppSplashImage;
  /** AttendanceCoin을 생성합니다. */
  createAttendanceCoin: AttendanceCoin;
  /** 작품의 배경을 등록합니다. */
  createBackgroundForStudio: StoryHasBackground;
  /** Banner를 생성합니다. */
  createBanner: Banner;
  /** 보너스 티켓 코드를 생성합니다 */
  createBonusTicketCode: BonusTicketCode;
  /** 개별 BottomBanner를 생성합니다. */
  createBottomBanner: BottomBanner;
  /** BundleBanner를 생성합니다. */
  createBundleBanner: BundleBanner;
  /** 단일 Featured를 생성합니다. */
  createBundleFeatured?: Maybe<BundleFeatured>;
  /** 도전 스토리 신고 사유를 생성합니다 */
  createChallengeStoryReportingReasonByAdmin: ChallengeStoryReportingReason;
  /** Chapter 항목을 생성합니다. */
  createChapter: Chapter;
  /** ChapterDraftArchive 를 이용하여 새로운 Chapter 를 생성합니다. */
  createChapterByChapterDraftArchive: OkResponse;
  /** 유저가 댓글을 작성합니다 */
  createChapterComment: ChapterComment;
  /** 관리자가 댓글을 작성합니다 */
  createChapterCommentByAdmin: ChapterComment;
  /** 회차 댓글 신고 사유를 생성합니다 */
  createChapterCommentReportingReasonByAdmin: ChapterCommentReportingReason;
  /** 챕터 옵션을 등록합니다. */
  createChapterOption: ChapterOption;
  /** ChoiceFromPlayerClass의 정보를 생성합니다. */
  createChoiceFromPlayerClass: ChoiceFromPlayerClass;
  /** 개별 캐릭터를 생성합니다 */
  createChr: Chr;
  /** 개별 Color의 정보를 가져옵니다. */
  createColor: Color;
  /** 공모전에 참여합니다. */
  createContestHasStory: ContestHasStory;
  /** Country를 생성합니다. */
  createCountry: Country;
  createDailyRewardCoinByAdmin: DailyRewardCoin;
  /** 일간 작품 랭킹을 생성합니다. 매일 전날 11:50에 호출됩니다. */
  createDailyStoryRanking: OkResponse;
  /** 일간 작품 랭킹을 생성합니다. 매일 01시에 호출됩니다. */
  createDailyUGCStoryRanking: OkResponse;
  /** 다날 주문서를 생성합니다. */
  createDanalPayment: DanalPaymentResponse;
  /** DirectAds를 생성합니다. */
  createDirectAds: DirectAds;
  /** 엔딩 도착률 범위를 생성합니다. */
  createEndingCard: EndingCard;
  /** 엔딩 도착률 범위를 생성합니다. */
  createEndingRateRange: EndingRateRange;
  /** EndingShowsProperty 항목을 생성 */
  createEndingShowsPropertyByAdmin: EndingShowsProperty;
  /** 스토리게임 섹션에서 노출된 테스트 작품 정보를 기록합니다 */
  createExposedSectionStory: OkResponse;
  /** Featured를 하나 생성합니다. */
  createFeatured?: Maybe<Featured>;
  /** HomeSection을 생성합니다. */
  createHomeSection: HomeSection;
  /** 개별 HomeSectionStory를 생성합니다. 한 section 내에 storyId가 중복될 수 없습니다. */
  createHomeSectionStory: HomeSectionStory;
  /** ImageFile 항목을 생성합니다. */
  createImageFile: ImageFile;
  createInappCoinByAdmin: InappCoin;
  /** InappPackage를 생성합니다 */
  createInappPackage: InappPackage;
  /** 월간 인기작을 등록합니다. */
  createMonthlyBundleByAdmin: MonthlyBundle;
  /** 월간 스토리 캐싱을 생성합니다 */
  createMonthlyBundleCache: OkResponse;
  /** Notice 항목을 생성합니다. */
  createNotice: Notice;
  /** NoticePopup 항목을 생성합니다. */
  createNoticePopup: NoticePopup;
  /** 온보딩 팝업을 등록합니다. */
  createOnboardingPopup: OnboardingPopup;
  /** 온보딩 팝업을 등록합니다. */
  createOnboardingPopupChoice: OnboardingPopupChoice;
  /** 온보딩 팝업의 기본 프로필 이미지를 등록합니다. */
  createOnboardingPopupProfileImage: OnboardingPopupProfileImage;
  /** PackageCoin을 생성합니다. */
  createPackageCoin: PackageCoin;
  /** PackageSkipAds를 생성합니다. */
  createPackageSkipAds: PackageSkipAds;
  /** PackageStory를 생성한다. */
  createPackageStory: PackageStory;
  /** PackageTimeSale을 생성합니다. */
  createPackageTimeSale: PackageTimeSale;
  /** PlayerClass를 생성합니다. */
  createPlayerClass: PlayerClass;
  /** 플레이어 보고서 (v2) 항목을 생성합니다. */
  createPlayerReport2: PlayerReport2;
  /** 플레이어 보고서에서 사용가능한 함수 (v2) 항목을 생성합니다. */
  createPlayerReport2Function: PlayerReport2Function;
  /** 플레이어 보고서에서 사용가능한 렌더블록 (v2) 항목을 생성합니다. */
  createPlayerReport2RenderBlock: PlayerReport2RenderBlock;
  /** preview 엔딩 정보를 생성합니다. */
  createPreviewEnding: PreviewEnding;
  /** 공용리소스 카테고리를 생성합니다. */
  createPublicResourceCategory: PublicResourceCategory;
  /** 공용리소스 파일들을 업로드합니다. */
  createPublicResourceFiles: Array<PublicResourceFile>;
  /** 개별 RecommendSectionStory 생성합니다. */
  createRecommendSectionStory: RecommendSectionStory;
  /** RecommendationForFirstUser를 생성합니다. (어드민) */
  createRecommendationForFirstUser: RecommendationForFirstUser;
  /** 개별 RecommendationOnboarding의 정보를 생성합니다. */
  createRecommendationOnboarding: RecommendationOnboarding;
  /** oauth 를 위한 refresh token 을 발급합니다. */
  createRefreshTokenForOAuth: RefreshTokenCreationResponse;
  /** 개별 RemoteScriptProvider의 정보를 생성합니다. */
  createRemoteScriptProvider: RemoteScriptProvider;
  /** SFXFile 항목을 생성합니다. */
  createSFXFile: SfxFile;
  /** SkipAds를 생성합니다. */
  createSkipAds: SkipAds;
  /** StoreBundle을 생성합니다. */
  createStoreBundle: StoreBundle;
  /** 스토어 섹션을 생성합니다. */
  createStorePageSection: StorePageSection;
  /** 스토어 리뷰를 생성합니다. */
  createStoreReview: StoreReview;
  /** 스토어 리뷰 댓글을 생성합니다. */
  createStoreReviewComment: StoreReviewComment;
  /** 스토어 토스 페이먼츠 결제 요청 */
  createStoreTossPayment: StorePayment;
  /** 스토어 엑솔라 페이먼츠 결제 토큰 생성 */
  createStoreXsollaPaymentToken: XsollaPaymentToken;
  /** Story 항목을 생성합니다. */
  createStory: Story;
  /** 스토리 통계를 캐싱합니다. */
  createStoryBundleStatCache: OkResponse;
  /** 스토리 번들 스토어 가격 정보를 생성합니다. */
  createStoryBundleStorePrice: StoryBundleStorePrice;
  /** 스튜디오에서 작품을 생성합니다. */
  createStoryForStudio: Story;
  /** 개별 StoryHasRecommendSection을 생성합니다. */
  createStoryHasRecommendSection: StoryHasRecommendSection;
  /** StoryHasStudioFile 항목을 생성합니다. */
  createStoryHasStudioFile: StoryHasStudioFile;
  /** 개별 StoryItem을 생성합니다. */
  createStoryItem: StoryItem;
  /** 조건에 맞는 StoryNewSe */
  createStoryNewSectionCacheBulk: OkResponse;
  /** 개별 StoryPreview을 생성합니다. */
  createStoryPreviewForStudio: StoryPreview;
  /** 개별 StoryPriceSetting의 정보를 생성합니다. */
  createStoryPriceSetting: StoryPriceSetting;
  /** StoryProperty 항목을 생성 */
  createStoryPropertyByAdmin: StoryProperty;
  /** StoryProperty 항목을 생성 */
  createStoryPropertyForStudio: StoryProperty;
  /** 스토리 통계를 캐싱합니다. */
  createStoryStatCache: OkResponse;
  /** 개별 StoryTag을 생성합니다. */
  createStoryTagForStudio: StoryTag;
  /** 정식 작품의 연재 요일을 생성합니다. */
  createStoryWeekday: StoryWeekday;
  /**
   * 주간 작품 랭킹을 생성합니다. 매주 월요일 0시에 호출됩니다.(장르구분 없이 playType으로 구분하여)
   * @deprecated 글로벌 적용으로 createWeeklyStoryRankingV3로 대체 되었습니다.
   */
  createStoryWeeklyRanking: OkResponse;
  /** 스토리게임에 필요한 Mobile, PC용 이미지를 등록합니다 */
  createStorygameBackground: StorygameBackground;
  /** 프롬프트 생성 */
  createStorygameCharacterAdditionalPrompt: StorygameCharacterAdditionalPrompt;
  /** 프롬프트 생성 */
  createStorygameCharacterPrompt: StorygameCharacterPrompt;
  /** StorygameSection을 생성합니다 */
  createStorygameSection: StorygameSection;
  /** StudioReleaseItem 항목을 생성합니다. */
  createStudioReleaseItem: StudioReleaseItem;
  /** StudioReleaseVersion 항목을 생성합니다. */
  createStudioReleaseVersion: StudioReleaseVersion;
  createSubsCoinByAdmin: SubsCoin;
  /** 약관을 생성합니다 */
  createTerms: Terms;
  /** 인스턴트 테스팅을 위한 데이터를 생성합니다. */
  createTestSession: TestSession;
  /** 개별 TodaysHotUGC의 정보를 생성합니다. */
  createTodaysHotUGC: TodaysHotUgc;
  /** UGC 랜덤 버전을 캐싱합니다. */
  createUgcRandomStoryCache: OkResponse;
  /** 작품에 사용자가 접근할 수 있도록 권한을 할당합니다. */
  createUserManagingStory: UserManagingStory;
  /** 유저가 읽은 작품을 기록합니다 */
  createUserStoryHistory: UserStoryHistory;
  /**
   * 주간 작품 랭킹을 생성합니다. 매주 월요일 0시에 호출됩니다.
   * @deprecated createStoryWeeklyRanking로 대체 되었으니, 변경해주세요.
   */
  createWeeklyStoryRanking: OkResponse;
  /** 주간 작품 랭킹을 생성합니다. 매주 월요일 0시에 호출됩니다. */
  createWeeklyStoryRankingV3: OkResponse;
  /** 자신의 계정을 탈퇴시킵니다. 모든 SSO 계정 연동 정보가 삭제됩니다. */
  deactivateAccount: OkResponse;
  /** 특정 계정을 탈퇴시킵니다. 모든 SSO 계정 연동 정보가 삭제됩니다. */
  deactivateAccountByAdmin: OkResponse;
  /** 단일 AdNetworkActivation을 삭제합니다. */
  deleteAdNetworkActivationForStudio: OkResponse;
  /** 개별 스플래쉬 이미지를 삭제합니다. */
  deleteAppSplashImage: OkResponse;
  /** 개별 Banner를 삭제 합니다. */
  deleteBanner: OkResponse;
  /** 보너스 티켓 코드를 삭제합니다 */
  deleteBonusTicketCode: OkResponse;
  /** 개별 Bottom Banner를 삭제 합니다. */
  deleteBottomBanner: OkResponse;
  /** 개별 BundleBanner를 삭제 합니다. */
  deleteBundleBanner: OkResponse;
  /** BundleFeatured를 삭제합니다. data.id는 featured의 ID입니다. */
  deleteBundleFeatured: OkResponse;
  /** 도전 스토리 신고 사유를 삭제합니다 */
  deleteChallengeStoryReportingReasonByAdmin: OkResponse;
  /** 유저가 자신이 작성한 댓글을 삭제합니다 */
  deleteChapterComment: OkResponse;
  /** 관리자가 댓글을 삭제합니다. 댓글 작성자가 본인의 댓글을 삭제할 때와 동일하게 동작합니다. */
  deleteChapterCommentByAdmin: OkResponse;
  /** 회차 댓글 신고 사유를 삭제합니다 */
  deleteChapterCommentReportingReasonByAdmin: OkResponse;
  /** 개별 ChapterOption의 정보를 가져옵니다. */
  deleteChapterOption: OkResponse;
  /** Country를 삭제합니다 */
  deleteCountry: OkResponse;
  /** 엔딩 카드 정보를 삭제 합니다. */
  deleteEndingCard: OkResponse;
  /** Featured를 삭제합니다. data.id는 featured의 ID입니다. */
  deleteFeatured: OkResponse;
  /** HomeSection을 삭제 합니다. */
  deleteHomeSection: OkResponse;
  /** HomeSectionStory를 삭제합니다. */
  deleteHomeSectionStory: OkResponse;
  /** 개별 월간 스토리를 삭제합니다. */
  deleteMonthlyBundleByAdmin: OkResponse;
  /** 유저가 자신이 작성한 대댓글을 삭제합니다 */
  deleteNestedChapterComment: OkResponse;
  /** 온보딩 팝업과 선택지를 삭제합니다. */
  deleteOnboardingPopup: OkResponse;
  /** 온보딩 팝업과 선택지를 삭제합니다. */
  deleteOnboardingPopupChoice: OkResponse;
  /** 온보딩 팝업과 기본 프로필 이미지를 삭제합니다. */
  deleteOnboardingPopupProfileImage: OkResponse;
  /** 개별 RecommendSectionStory 삭제합니다. */
  deleteRecommendSectionStory: OkResponse;
  /** 개별 RecommendationForFirstUser를 삭제합니다. (어드민) */
  deleteRecommendationForFirstUser: OkResponse;
  /** StoreBundle을 생성합니다. */
  deleteStoreBundle: OkResponse;
  /** 스토어 섹션 삭제 */
  deleteStorePageSection: OkResponse;
  /** 스토어 리뷰를 소프트 삭제합니다. */
  deleteStoreReview: OkResponse;
  /** 스토어 리뷰 댓글을 소프트 삭제합니다. */
  deleteStoreReviewComment: OkResponse;
  /** 개별 StoryHasRecommendSection을 삭제합니다. */
  deleteStoryHasRecommendSection: OkResponse;
  /** 정식 작품의 연재 요일을 삭제합니다. */
  deleteStoryWeekday: OkResponse;
  /** 스토리게임의 등장인물 설정한 정보를 삭제합니다. */
  deleteStorygameCharacter: OkResponse;
  /** 프롬프트 삭제 */
  deleteStorygameCharacterAdditionalPrompt: OkResponse;
  /** 개별 Terms를 삭제합니다 */
  deleteTerms: OkResponse;
  /** ChapterDraft 항목을 삭제합니다. (Chapter Id 를 입력으로 받습니다.) */
  discardChapterDraft: OkResponse;
  /** 작품에 연동된 업적 프로젝트 정보를 제거합니다. */
  disconnectStoryToAchievementProject: OkResponse;
  /** 챕터의 초안을 이용하여 챕터를 복제합니다. */
  duplicateChapterDraft: OkResponse;
  /** 스토어 장바구니 아이템을 모두 삭제합니다. */
  emptyStoreCart: OkResponse;
  /** 작품 업데이트 현황을 excel로 export합니다. */
  exportStoryInfoAsSheet: OkResponse;
  /** 구글 스프레드시트로 스튜디오 데이터를 write 합니다. 챕터 1개만 export 합니다. */
  exportStudioChapterAsSheet: OkResponse;
  /** 구글 스프레드시트로 스튜디오 데이터를 write 합니다. 여러개의 챕터를 동시에 export 합니다. */
  exportStudioStoryAsSheet: OkResponse;
  /** 작품들의 통계수치를 export 합니다. */
  exportStudioStoryStat: OkResponse;
  /** 정해진 시간만큼의 expire 시각을 가진 토큰을 재발급합니다. 서버의 최대값을 넘어갈 수는 없습니다. */
  extendAdminLoginToken: LoginResponse;
  /** OTP key 를 발급합니다. */
  generateAnOtpKey: OtpKeyResponse;
  /** 오늘의 장르별 인기작을 생성합니다 */
  generateTodaysHotUGCGenreStory: OkResponse;
  /** 참가된 공모전 정보를 조회합니다. */
  getContestHasStory: ContestHasStory;
  /** 개별 PackageSkipAds의 정보를 가져옵니다. */
  getPackageSkipAds: PackageSkipAds;
  /**
   * 작품을 import 하기 위한 presigned url을 요청합니다.
   * @deprecated 번들 생성을 대응한 V3로 변경합니다.
   */
  getPresignedUrlForStoryImport: PresignedUrlForStoryImport;
  /** 작품을 import 하기 위한 presigned url을 요청합니다. 번들대응 버전 */
  getPresignedUrlForStoryImportV3: PresignedUrlForStoryImport;
  /** tts 서버를 2차 요청합니다. */
  getTtsUrl: GetTtsUrlResponse;
  /** export 한 작품을 import 합니다. */
  importStory: ImportStoryResponse;
  /** 모든 약관에 대한 동의 해제 */
  initAcceptedTerms: OkResponse;
  initAdultVerification: OkResponse;
  /** 사용자의 유효한 시스템 언어 값을 반환받습니다. */
  initUserSystemLanguage: User;
  /** Country 캐싱을 무효화합니다. */
  invalidateCountryCache: OkResponse;
  /** 서비스에서 탈퇴합니다. */
  leaveService: OkResponse;
  /** 작품 번들을 좋아요합니다. */
  likeBundle: OkResponse;
  /** 유저가 댓글을 좋아합니다 */
  likeChapterComment: UserLikesChapterCommentHistory;
  /** 스토어 리뷰 like */
  likeStoreReview: StoreReview;
  /** 스토어 리뷰 댓글 like */
  likeStoreReviewComment: StoreReviewComment;
  /** 작품 좋아요. data.id는 작품 아이디 */
  likeStory: OkResponse;
  /**
   * 소셜 로그인을 사용자에게 추가합니다. 익명사용자의 경우 최초 SSO 연동시 일반 사용자로 변경됩니다.
   * @deprecated linkUserWithSSOV2로 대체되었습니다.
   */
  linkUserWithSSO: LoginResponse;
  /** 소셜 로그인을 사용자에게 추가합니다. 익명사용자의 경우 최초 SSO 연동시 일반 사용자로 변경됩니다. */
  linkUserWithSSOV2: LoginResponse;
  /** 이메일을 이용하여 일반 사용자 로그인을 진행합니다. 작가 이상인 경우에만 접근이 가능합니다. */
  loginAdmin: OtpLoginResponse;
  /** OTP token 이 정상인지 체크합니다. */
  loginAdminWithOTP: LoginResponse;
  /** 이메일을 이용하여 일반 사용자 로그인을 진행합니다. */
  loginUser: LoginResponse;
  /** SSO 를 이용하여 일반 사용자 로그인을 진행합니다. */
  loginUserWithSSO: LoginResponse;
  /** OTP 없이 스튜디오용 권한을 제공하는 로그인. */
  loginWriterWithEmailForStudio: LoginResponse;
  /** 로그아웃을 하여 쿠키를 제거합니다. */
  logoutUser: OkResponse;
  /** 현재 플레이 중인 챕터에서 선택을 수행한다. */
  makeChoice: MakeChoiceOutput;
  /** 비밀번호 변경을 다음에 변경하기 */
  markPasswordAsChanged: User;
  /** 사용자의 특정 튜토리얼 완료 처리합니다. (이미 처리완료된 튜토리얼은 처리하지 않습니다.) */
  markUserStudioTutorialProgress: StudioTutorialCompletion;
  /** Story 스크립트를 migration 합니다. */
  migrateStory: OkResponse;
  /** 장바구니로 이동합니다. */
  moveToStoreCart: OkResponse;
  /** 찜 목록으로 이동합니다. */
  moveToStoreWishlist: OkResponse;
  /** Chapter 를 Publish 상태로 변경합니다. */
  publishChapter: OkResponse;
  /** 스튜디오에서 Chapter 를 Publish 상태로 변경합니다. */
  publishChapterForStudio: OkResponse;
  /** 해당 플레이어 보고서를 운영중 상태로 변경합니다. */
  publishPlayerReport2: PlayerReport2;
  /** 스토어 섹션 발행 */
  publishStorePageSections: Array<StorePageSection>;
  /** 스토어 페이지 탭 목록을 발행합니다. */
  publishStorePageTabs: Array<StorePageTab>;
  /** 스토어 검색 설정 발행 */
  publishStoreSearchConfig: StoreSearchConfig;
  /** Story 를 Publish 합니다. 존재하지 않는 작품이거나 이미 공개된 경우 ok=0 을 반환합니다. */
  publishStory: OkResponse;
  /** StoryBundle을 store에 출시합니다. 존재하지 않는 작품이거나 이미 공개된 경우 ok=0을 반환합니다. */
  publishStoryBundleInStore: StoryBundle;
  /** 현재 설정한 전체 섹션 설정을 클라이언트에 반영 */
  publishStorygameSections: OkResponse;
  /** @deprecated 글로벌 적용으로 purchaseCoinV3로 대체되었습니다. */
  purchaseCoin: UserPurchasedCoin;
  purchaseCoinV3: UserPurchasedCoin;
  purchaseInappProduct: UserPurchasedCoin;
  purchasePackage: UserPurchasedPackage;
  /**
   * 광고 스킵 상품을 구매한다. skipAdsId를 id로 전달한다.
   * @deprecated purchaseSkipAdsV3로 대체되었습니다.
   */
  purchaseSkipAds: UserPurchasedSkipAds;
  /** 광고 스킵 상품을 구매한다. skipAdsId를 id로 전달한다. */
  purchaseSkipAdsV3: UserPurchasedSkipAds;
  /** 스토리를 통구매 한다 */
  purchaseStory: OkResponse;
  /** 현재 플레이중인 웹소설 회차 엔딩에 도달했을때 호출한다. */
  reachedEPUBChapterEnding: OkResponse;
  /** 현재 플레이 중인 챕터에서 엔딩을 만났다. */
  reachedEnding: ReachedEndingOutput;
  /** 토큰을 refresh 합니다. 과정에서 유저의 현재 접속 국가를 토큰 내에 추가합다 */
  refreshToken: LoginResponse;
  /** 패키지를 반품. 테스트용 */
  refundPackage: OkResponse;
  /** 스토리를 반품. 테스트용 */
  refundStory: OkResponse;
  /** 익명으로 일반 사용자 가입을 진행합니다. */
  registerAnonymousUser: LoginResponse;
  /** OTP key 를 등록합니다. */
  registerOtpKey: LoginResponse;
  /** 이메일을 이용하여 일반 사용자 가입을 진행합니다. */
  registerUser: LoginResponse;
  /** 슈퍼 어드민에 의해 신규 사용자를 추가합니다. 이메일 로그인 사용자만 가능합니다. */
  registerUserByAdmin: User;
  /** SSO 를 이용하여 일반 사용자 가입을 진행합니다. */
  registerUserWithSSO: LoginResponse;
  /** Chapter 항목을 삭제합니다. */
  removeChapter: OkResponse;
  /** Chapter 항목을 삭제합니다. publish 된 적이 없는 경우에만 삭제가 가능합니다. */
  removeChapterForStudio: OkResponse;
  /** 캐릭터 항목을 삭제합니다. */
  removeChr: OkResponse;
  /** 공모전 참여를 취소합니다. */
  removeContestHasStory: OkResponse;
  /** DirectAds를 삭제합니다. */
  removeDirectAds: OkResponse;
  /** EndingShowsProperty 항목을 삭제 */
  removeEndingShowPropertyByAdmin: OkResponse;
  /** 엔딩에 연결된 스토리 속성 정보 */
  removeEndingShowsPropertyByAdmin: OkResponse;
  /** 아이템을 스토어 장바구니에서 삭제합니다. */
  removeFromStoreCart: OkResponse;
  /** 찜 목록에서 삭제합니다. */
  removeFromWishlist: OkResponse;
  /** ImageFile 항목을 삭제합니다. */
  removeImageFile: OkResponse;
  /** Notice 항목을 삭제합니다. */
  removeNotice: OkResponse;
  /** NoticePopup 항목을 삭제합니다. */
  removeNoticePopup: OkResponse;
  /** 플레이어 보고서 (v2) 항목을 삭제합니다. */
  removePlayerReport2: OkResponse;
  /** 플레이어 보고서에서 사용가능한 함수 (v2) 항목을 삭제합니다. */
  removePlayerReport2Function: OkResponse;
  /** 플레이어 보고서에서 사용가능한 렌더블록 (v2) 항목을 삭제합니다. */
  removePlayerReport2RenderBlock: OkResponse;
  /** 개별 preview 엔딩을 삭제합니다. */
  removePreviewEnding: OkResponse;
  /** 공용리소스 카테고리를 삭제합니다. */
  removePublicResourceCategory: OkResponse;
  /** 공용리소스 파일을 삭제합니다. */
  removePublicResourceFile: OkResponse;
  removePushLogsForTest: OkResponse;
  /** 개별 PushNotification을 삭제합니다. */
  removePushNotification: OkResponse;
  /** RemoteScriptProvider의 항목을 삭제합니다. */
  removeRemoteScriptProvider: OkResponse;
  /** SFXFile 항목을 삭제합니다. */
  removeSFXFile: OkResponse;
  /** 스토어 번들을 스토어 장바구니에서 삭제합니다. */
  removeStoreBundleFromStoreCart: OkResponse;
  /** Story 항목을 삭제합니다. */
  removeStory: OkResponse;
  /** 스토리 번들(작품)을 스토어 장바구니에서 삭제합니다. */
  removeStoryBundleFromStoreCart: OkResponse;
  /** StoryHasStudioFile 항목을 삭제합니다. */
  removeStoryHasStudioFile: OkResponse;
  /** 개별 StoryItem을 삭제합니다 */
  removeStoryItem: OkResponse;
  /** 특정 작품의 플레이 기록을 모두 삭제합니다. */
  removeStoryPlayHistory: OkResponse;
  /** 개별 StoryPreview을 삭제합니다 */
  removeStoryPreviewForStudio: StoryPreview;
  /** StoryProperty 항목을 수정 */
  removeStoryPropertyByAdmin: OkResponse;
  /** StoryProperty 항목을 수정 */
  removeStoryPropertyForStudio: OkResponse;
  /** 하나의 섹션을 아이디로 삭제합니다. */
  removeStorygameSection: OkResponse;
  /** StudioReleaseItem 항목을 삭제합니다. */
  removeStudioReleaseItem: OkResponse;
  /** StudioReleaseVersion 항목을 삭제합니다. */
  removeStudioReleaseVersion: OkResponse;
  removeTodayRewardForTest: OkResponse;
  /** UserManagingStory 항목을 제거합니다. */
  removeUserManagingStory: OkResponse;
  /** 특정유저의 모든 구매 기록을 삭제한다. 테스트용 API */
  removeUserPurchasedCoinsForTest: OkResponse;
  /** 유저가 읽은 단일 작품 기록을 삭제합니다. */
  removeUserStoryHistory: OkResponse;
  /** 도전 스토리를 신고합니다 */
  reportChallengeStory: UserReportChallengeStory;
  /** 유저가 댓글 신고를 합니다 */
  reportChapterComment: UserReportChapterComment;
  /** 이미지를 업로드하여 ai 캐릭터 이미지를 생성 요청합니다. */
  requestAICharacter: RequestAiSessionResponse;
  requestDailyRewardCoin?: Maybe<UserDailyRewardCoin>;
  /** 결제요청 */
  requestIMPPayments: IamportPayments;
  /** 토스 페이먼츠 결제요청 */
  requestTossPayments: TossPayments;
  /** tts 서버를 1차 요청 합니다. */
  requestTts: RequestTtsResponse;
  /** 이메일 인증을 위하여 이메일을 재전송합니다. */
  resendEmailVerification: OkResponse;
  /** 사용자가 완료한 튜토리얼 정보를 모두 삭제합니다. */
  resetUserStudioTutorialProgress: OkResponse;
  /** AI 자캐 지원 스토리게임 타임리프시 호출하는 API */
  restartAICharacterStorygameChapter: OkResponse;
  /** 각종 cron task 를 수행합니다. 보통 사용할 일이 없지만, 테스트 등을 위해 사용할 수 있습니다. */
  runPeriodicTasks: OkResponse;
  /** PublicResourceFile 을 StoryHasStudioFile 로 복제합니다. */
  savePublicResourceToStudioFile: StoryHasStudioFile;
  /** 작품에 연동된 업적 이벤트를 전송합니다. */
  sendAchievementEvent: AEventCreationResponse;
  /** 앱 이벤트를 전송한다 */
  sendAppEvent: OkResponse;
  /** 웹에서 contact 메일을 보냅니다. */
  sendContactMail: OkResponse;
  /** 가입된 이메일 주소로 비밀번호 리셋 링크를 전송합니다. */
  sendResetLinkWithEmail: OkResponse;
  /** 이메일 가입 단계 전에 이메일 인증을 위한 이메일을 발송합니다. */
  sendVerificationEmailBeforeRegistration: OkResponse;
  /** 휴면계정으로 만듭니다. 서버에서 휴면계정 테스트를 위한 api이므로 클라이언트는 사용하지 않습니다. */
  setDormantUserForTest: LoginResponse;
  /** StoryBundle의 StoreServiceCountry를 설정합니다. */
  setStoryBundleStoreCountries: OkResponse;
  /** AI 자캐 생성 프롬프트를 작품에 등록합니다. */
  setStorygamePrompts: OkResponse;
  /** 현재 플레이 중인 스토리에서 다음 챕터를 플레이한다. */
  startChapter: UserPlayedChapter;
  /** 현재 플레이 중인 웹소설 스토리에서 다음 챕터를 플레이한다. */
  startEPUBChapter: UserPlayedChapter;
  /** 사용자의 작품 진행 항목을 생성합니다. */
  startStory: UserPlayedStory;
  subscribeCoin: UserSubsCoin;
  /** 사용자 입력 텍스트 룰베이스를 정의합니다. */
  syncChoiceAffectionRule: ChoiceAffectionRuleResponse;
  /** StoreBundle정보와 Xsolla정보(번들,프로모션)를 동기화합니다. */
  syncStoreBundleWithXsolla: StoreBundle;
  /** StoryBlockedCountry 새로운 정보로 갱신합니다. */
  syncStoryBlockedCountry: OkResponse;
  /** StoryBundle정보와 Xsolla정보(아이템,프로모션)를 동기화합니다. */
  syncStoryBundleWithXsolla: StoryBundle;
  /** 개별 StoryPreview를 previewIds 순서대로 정렬하고, 나머지는 삭제합니다. */
  syncStoryPreviewForStudio: Array<StoryPreview>;
  /** 전달된 스토리의 태그를 ID 순으로 정렬 후, 포함되지 않은 ID의 데이터는 삭제합니다 */
  syncStoryTagForStudio: Array<StoryHasStoryTag>;
  /** 현재 플레이 중인 챕터의 아이템을 싱크맞추는 역할을 한다. ok response를 리턴한다. */
  syncUserItems?: Maybe<OkResponse>;
  /** 휴면계정을 해지합니다. 클라이언트는 다시 받은 토큰을 사용해야합니다. */
  terminateDormantUser: LoginResponse;
  /** 섹션 내 스토리게임의 국가 노출 정보를 수정합니다 */
  toggleStorygameCountry: OkResponse;
  /** 섹션 내 스토리게임의 언어 노출 정보를 수정합니다 */
  toggleStorygameLanguage: OkResponse;
  /** 스토리게임 섹션의 국가 노출 정보를 수정합니다 */
  toggleStorygameSectionCountry: OkResponse;
  /** 섹션 내 스토리게임의 노출 정보를 수정합니다 */
  toggleStorygameVisibility: OkResponse;
  /** 해당 사용자의 계정정지 해제합니다. */
  unbanWriterForStudio: OkResponse;
  /** 사용자 차단을 해제합니다. */
  unblockUser: OkResponse;
  /** 스토어 리뷰 unlike */
  unlikeStoreReview: StoreReview;
  /** 스토어 리뷰 댓글 unlike */
  unlikeStoreReviewComment: StoreReviewComment;
  /** 특정 작품을 unpublish 하고 대기중으로 돌립니다. */
  unpublishStory: OkResponse;
  /** StoryBundle을 store에 미출시 상태로 변경합니다. */
  unpublishStoryBundleInStore: StoryBundle;
  unsubscribeCoinForTest: OkResponse;
  /** AdNetworkActivation을 업데이트합니다. */
  updateAdNetworkActivationForStudio: AdNetworkActivation;
  /** AppConfig 항목을 생성합니다. */
  updateAppConfig: AppConfig;
  /** 개별 스플래쉬 이미지를 업데이트 합니다. */
  updateAppSplashImage: AppSplashImage;
  /** 일반 사용자를 작가로 등록합니다. 또는 작가 프로필을 업데이트합니다. */
  updateAuthor: User;
  /** 어드민이 작가 정보를 수정합니다. */
  updateAuthorByAdmin: User;
  /** 배경 정보를 업데이트합니다. */
  updateBackground: StoryHasBackground;
  /** 개별 Banner를 업데이트 합니다. */
  updateBanner: Banner;
  /** 사용자가 진행한 채팅 블록 정보를 저장합니다. */
  updateBlockHistory: OkResponse;
  /** 보너트 티켓 코드 정보를 업데이트 합니다 */
  updateBonusTicketCode: BonusTicketCode;
  /** 개별 BottomBanner를 수정합니다. */
  updateBottomBanner: OkResponse;
  /** 개별 BundleBanner를 업데이트 합니다. */
  updateBundleBanner: BundleBanner;
  /** BundleFeatured를 수정합니다 */
  updateBundleFeatured: BundleFeatured;
  /** 도전 스토리 신고 사유를 업데이트합니다 */
  updateChallengeStoryReportingReasonByAdmin: ChallengeStoryReportingReason;
  /** Chapter 정보를 업데이트합니다. */
  updateChapter: Chapter;
  /** 특정날의 이탈행 통계 정보를 업데이트합니다. */
  updateChapterBounceStat: OkResponse;
  /** 유저가 자기자신이 작성한 댓글을 수정합니다 */
  updateChapterComment: ChapterComment;
  /** 관리자가 댓글 내용을 제외한 데이터를 수정합니다 */
  updateChapterCommentByAdmin: ChapterComment;
  /** 회차 댓글 신고 사유를 업데이트합니다 */
  updateChapterCommentReportingReasonByAdmin: ChapterCommentReportingReason;
  /** Chapter 정보를 업데이트합니다. */
  updateChapterForStudio: Chapter;
  /** 특정 작품의 챕터 인덱스를 1부터 순차적으로 정렬합니다. */
  updateChapterIndexes: OkResponse;
  /** 챕터 옵션을 수정합니다. */
  updateChapterOption: ChapterOption;
  /** 회차의 가격정보를 수정합니다. (웹소설) */
  updateChapterPriceForStudio: Chapter;
  /** 전체 통계의 어드민 조정 값을 수정합니다. */
  updateChoice: Choice;
  /** ChoiceFromPlayerClass의 정보를 수정합니다. */
  updateChoiceFromPlayerClass: ChoiceFromPlayerClass;
  /** 캐릭터 정보를 업데이트합니다. */
  updateChr: Chr;
  /** 신청한 공모전 정보를 수정합니다. */
  updateContestHasStory: ContestHasStory;
  /** 전환율 통계 데이터를 갱신합니다 */
  updateConversionStatOfStory: OkResponse;
  /** Country를 수정합니다 */
  updateCountry: Country;
  /** DirectAds를 업데이트합니다. */
  updateDirectAds: DirectAds;
  /** 엔딩 정보를 업데이트합니다. */
  updateEnding: Ending;
  /** 엔딩 카드 정보를 없데이트 합니다. */
  updateEndingCard: EndingCard;
  /** EndingShowsProperty 항목을 수정 */
  updateEndingShowsPropertyByAdmin: EndingShowsProperty;
  /** Featured를 수정합니다 */
  updateFeatured: Featured;
  /** HomeSection을 업데이트 합니다. */
  updateHomeSection: HomeSection;
  /** 월간 인기작을 수정합니다. */
  updateMonthlyBundleByAdmin: MonthlyBundle;
  /** Notice 항목을 업데이트합니다. */
  updateNotice: Notice;
  /** NoticePopup 항목을 업데이트합니다. */
  updateNoticePopup: NoticePopup;
  /** 특정 날짜의 회차별 댓글 통계 정보를 업데이트합니다. */
  updateNumCommentStatOfStory: OkResponse;
  /** 특정 날짜의 작품 좋아요 통계 정보를 업데이트합니다. */
  updateNumLikeStatOfStory: OkResponse;
  /** 작품 및 회차 조회수 통계 정보를 업데이트합니다.. */
  updateNumPlayStatOfStory: OkResponse;
  /** 온보딩 팝업을 수정합니다. */
  updateOnboardingPopup: OnboardingPopup;
  /** 온보딩 팝업을 수정합니다. */
  updateOnboardingPopupChoice: OnboardingPopupChoice;
  /** 온보딩 팝업의 기본 프로필 이미지를 수정합니다. */
  updateOnboardingPopupProfileImage: OnboardingPopupProfileImage;
  /** PlayerClass를 수정합니다. */
  updatePlayerClass: PlayerClass;
  /** 플레이어 보고서 (v2) 정보를 업데이트합니다. */
  updatePlayerReport2: PlayerReport2;
  /** 플레이어 보고서에서 사용가능한 함수 (v2) 정보를 업데이트합니다. */
  updatePlayerReport2Function: PlayerReport2Function;
  /** 플레이어 보고서에서 사용가능한 렌더블록 (v2) 정보를 업데이트합니다. */
  updatePlayerReport2RenderBlock: PlayerReport2RenderBlock;
  /** preview 엔딩 정보를 업데이트합니다. */
  updatePreviewEnding: PreviewEnding;
  /** 공용리소스 카테고리 정보를 수정합니다. */
  updatePublicResourceCategory: PublicResourceCategory;
  /** 공용리소스 파일 정보를 수정합니다. */
  updatePublicResourceFile: PublicResourceFile;
  /** 개별 RecommendationForFirstUser의 정보를 업데이트 합니다. (어드민) */
  updateRecommendationForFirstUser: RecommendationForFirstUser;
  /** 개별 RemoteScriptProvider의 정보를 수정합니다. */
  updateRemoteScriptProvider: RemoteScriptProvider;
  /** StoreBundle을 수정합니다. */
  updateStoreBundle: StoreBundle;
  /** 스토어 섹션 수정 */
  updateStorePageSection: StorePageSection;
  /** 스토어 케러셀형 섹션 수정 */
  updateStorePageSectionCarousel: StorePageSection;
  /** 스토어 케러셀 번들형 섹션 수정 */
  updateStorePageSectionCarouselBundle: StorePageSection;
  /** 스토어 케러셀 진행형 섹션 수정 */
  updateStorePageSectionCarouselProgress: StorePageSection;
  /** 스토어 케러셀 순위형 섹션 수정 */
  updateStorePageSectionCarouselRank: StorePageSection;
  /** 스토어 섹션 국가 업데이트 */
  updateStorePageSectionCountry: StorePageSectionCountry;
  /** 스토어 리스트 순위형 섹션 수정 */
  updateStorePageSectionRankList: StorePageSection;
  /** 스토어 슬라이더형 섹션 수정 */
  updateStorePageSectionSlider: StorePageSection;
  /** 스토어 리뷰를 수정합니다. */
  updateStoreReview: StoreReview;
  /** 스토어 리뷰 댓글을 수정합니다. */
  updateStoreReviewComment: StoreReviewComment;
  /** 스토어 검색 설정 수정 */
  updateStoreSearchConfig: StoreSearchConfig;
  /** Story 정보를 업데이트합니다. */
  updateStory: Story;
  /** StoryBundle의 ServiceCountry의 정보를 수정합니다. */
  updateStoryBundleCountries: OkResponse;
  /** StoryBundle의 store관련 정보를 수정합니다. */
  updateStoryBundleStore: StoryBundle;
  /** 스토리 번들 스토어 가격 정보를 수정합니다. */
  updateStoryBundleStorePrice: StoryBundleStorePrice;
  /** 개별 StoryHasRecommendSection을 수정합니다. */
  updateStoryHasRecommendSection: StoryHasRecommendSection;
  /** StoryHasStudioFile 정보를 업데이트합니다. */
  updateStoryHasStudioFile: StoryHasStudioFile;
  /** 개별 StoryItem을 수정합니다. */
  updateStoryItem: StoryItem;
  /** StoryPreview의 이미지나 영상을 업데이트 합니다 */
  updateStoryPreviewFile: StoryPreview;
  /** 개별 StoryPriceSetting의 정보를 수정합니다. */
  updateStoryPriceSetting: StoryPriceSetting;
  /** 작품의 가격정보를 설정합니다. (웹소설) */
  updateStoryPriceSettingForStudio: Story;
  /** StoryProperty 항목을 수정 */
  updateStoryPropertyByAdmin: StoryProperty;
  /** StoryProperty 항목을 수정 */
  updateStoryPropertyForStudio: StoryProperty;
  /** 특정 날짜의 랭킹을 갱신합니다. */
  updateStoryRankCache: OkResponse;
  /** 특정 작품의 통계 수치를 재계산하여 업데이트합니다. */
  updateStoryStatsForStudio: OkResponse;
  /** 스토리게임의 상세 정보를 업데이트 합니다. */
  updateStorygame: Story;
  /** 개별 StorygameBackground 정보를 업데이트합니다. */
  updateStorygameBackground: StorygameBackground;
  /** 프롬프트 수정 */
  updateStorygameCharacterAdditionalPrompt: StorygameCharacterAdditionalPrompt;
  /** 프롬프트 수정 */
  updateStorygameCharacterPrompt: StorygameCharacterPrompt;
  /** 스토리게임 섹션을 수정합니다. */
  updateStorygameSection: StorygameSection;
  /** 지정한 탭 내 섹션의 순서를 바꿉니다 */
  updateStorygameSectionOrder: Array<StorygameSection>;
  /** StorygameTab의 순서를 변경합니다 */
  updateStorygameTabOrder: Array<StorygameTab>;
  /** StudioReleaseItem 항목을 업데이트합니다. */
  updateStudioReleaseItem: StudioReleaseItem;
  /** StudioReleaseVersion 항목을 업데이트합니다. */
  updateStudioReleaseVersion: StudioReleaseVersion;
  /** Terms를 업데이트 합니다 */
  updateTerms: Terms;
  /** 본인의 사용자 정보를 업데이트합니다. 비밀번호 변경에도 사용할 수 있습니다. */
  updateUser: User;
  /** 한국에 있는 유저 iamport로 성인인증 할때 호출하는 API */
  updateUserAdultCertification: UpdateUserAdultCertificationResponse;
  /** 한국외의 국가에 있는 유저 성인인증 했을때 API */
  updateUserAdultCertificationForGlobal: OkResponse;
  /** 한국외의 국가에 있는 유저 성인인증 했을때 API(스토어용) */
  updateUserAdultCertificationForStoreGlobal: OkResponse;
  /** 특정 사용자의 사용자 정보를 업데이트합니다. 비밀번호 변경에도 사용할 수 있습니다. */
  updateUserByAdmin: User;
  /** 사용자의 세부 푸시 알림 설정을 업데이트 합니다. */
  updateUserDetailPushSetting: User;
  /** 현재 플레이 중인 챕터에서 아이템을 업데이트 후, 획득한 아이템을 리턴한다. */
  updateUserItems?: Maybe<StoryItem>;
  /** 작품-사용자정보를 업데이트합니다. */
  updateUserManagingStory: UserManagingStory;
  /** 현재 플레이 중인 챕터에서 유저 속성을 업데이트 한다. 현재 유저 속성을 클라이언트로 부터 받은 값으로 덮어쓴다. */
  updateUserProps: UpdateUserPropsOutput;
  /** 사용자의 푸시 동의 여부를 업데이트합니다. */
  updateUserPushInfo: User;
  /** 개별 UserRecommendationOnboarding의 정보를 업데이트 하고, 추천 작품 목록을 내려줍니다. */
  updateUserRecommendationOnboardingForClient: OkResponse;
  /** 사용자의 시스템 언어 설정을 업데이트 합니다. */
  updateUserSystemLanguage: User;
  /** ChapterDraft 최신버전을 저장하며, 챕터를 적용하는 것도 진행할 수 있습니다. */
  upsertChapterDraft: ChapterDraftUpsertOutput;
  /** 주인공 온보딩 팝업을 수정합니다. */
  upsertMainChrOnboardingPopup: OnboardingPopup;
  /** 스토리게임의 등장인물 설정을 저장합니다. */
  upsertStorygameCharacter: StorygameCharacter;
};


export type MutationAcceptStoreTermsArgs = {
  data: AcceptTermsInputV2;
};


export type MutationAcceptTermsArgs = {
  data: AcceptTermsInput;
};


export type MutationAcceptTermsV2Args = {
  data: AcceptTermsInputV2;
};


export type MutationAddCoinByAdminArgs = {
  data: AddCoinInput;
};


export type MutationAddCoinWithAdsRewardArgs = {
  data: SingleIdInput;
};


export type MutationAddFcmTokenArgs = {
  data: AddFcmTokenInput;
};


export type MutationAddOrRemoveStoryBackgroundFileArgs = {
  data: StoryAttachmentModificationInput;
};


export type MutationAddStoreBundleToStoreCartArgs = {
  data: StoreCartStoreBundleItemInput;
};


export type MutationAddStoryBundleToStoreCartArgs = {
  data: StoreCartStoryBundleItemInput;
};


export type MutationAddStorygameToSectionArgs = {
  data: AddStorygameToSectionInput;
};


export type MutationAddTicketToUserWithBonusTicketCodeArgs = {
  data: AddBonusTicketToUserInput;
};


export type MutationAddTicketWithBonusTicketCodeArgs = {
  data: AddBonusTicketInput;
};


export type MutationAddToWishlistArgs = {
  data: SingleIdInput;
};


export type MutationApplyMgmtSheetToStoryArgs = {
  data: ApplyMgmtSheetToStoryInput;
};


export type MutationBanWriterForStudioArgs = {
  data: BanWriterForStudioInput;
};


export type MutationBlockUserArgs = {
  data: SingleIdInput;
};


export type MutationBuyAllRemainingChaptersArgs = {
  data: BuyAllRemainingChaptersInput;
};


export type MutationBuyRemainingChaptersArgs = {
  data: BuyRemainingChaptersInput;
};


export type MutationCancelLikeBundleArgs = {
  data: CancelLikeBundleInput;
};


export type MutationCancelLikeChapterCommentArgs = {
  data: UserLikesChapterCommentHistoryInput;
};


export type MutationCancelLikeStoryArgs = {
  data: SingleIdInput;
};


export type MutationCancelPublishChapterForStudioArgs = {
  data: SingleIdInput;
};


export type MutationCancelPublishPlayerReport2Args = {
  data: SingleIdInput;
};


export type MutationCancelStorePaymentArgs = {
  data: SingleIdInput;
};


export type MutationChangeChapterFreeArgs = {
  data: ChangeChapterBooleanInput;
};


export type MutationChangeChapterFreeSettingArgs = {
  data: ChangeChapterFreeSettingInput;
};


export type MutationChangeStorySubscriptionArgs = {
  data: ChangeStorySubscriptionInput;
};


export type MutationChangeUserPrivArgs = {
  data: InputChangeUserPriv;
};


export type MutationCheckBonusTicketCodeArgs = {
  data: SingleCodeInput;
};


export type MutationClearCharacterPortraitArgs = {
  data: ForceMigrateChrProfileInput;
};


export type MutationCompleteImpPaymentsArgs = {
  data: CompleteImpPaymentsInput;
};


export type MutationConfirmFailedRequestAiCharacterArgs = {
  data: SingleIdInput;
};


export type MutationConfirmStoreTossPaymentArgs = {
  data: StorePaymentConfirmTossInput;
};


export type MutationConfirmTossPaymentsArgs = {
  data: ConfirmTossPaymentsInput;
};


export type MutationConnectStoryToAchievementProjectArgs = {
  data: ConnectStoryToAchievementProjectInput;
};


export type MutationConvertUserWithEmailArgs = {
  data: EmailSignup;
};


export type MutationConvertUserWithEmailV2Args = {
  data: EmailSignupV2;
};


export type MutationConvertUserWithEmailV3Args = {
  data: EmailSignupV3;
};


export type MutationCopyPlayerReport2Args = {
  data: CopyPlayerReport2Input;
};


export type MutationCreateAdNetworkActivationForStudioArgs = {
  data: AdNetworkActivationCreationInput;
};


export type MutationCreateAppSplashImageArgs = {
  data: AppSplashImageCreationInput;
};


export type MutationCreateAttendanceCoinArgs = {
  data: AttendanceCoinCreationInput;
};


export type MutationCreateBackgroundForStudioArgs = {
  data: StoryHasBackgroundCreationInput;
};


export type MutationCreateBannerArgs = {
  data: BannerCreationInput;
};


export type MutationCreateBonusTicketCodeArgs = {
  data: BonusTicketCodeCreationInput;
};


export type MutationCreateBottomBannerArgs = {
  data: BottomBannerCreationInput;
};


export type MutationCreateBundleBannerArgs = {
  data: BundleBannerCreationInput;
};


export type MutationCreateBundleFeaturedArgs = {
  data: BundleFeaturedCreationInput;
};


export type MutationCreateChallengeStoryReportingReasonByAdminArgs = {
  data: ChallengeStoryReportingReasonCreationInput;
};


export type MutationCreateChapterArgs = {
  data: ChapterCreationInput;
};


export type MutationCreateChapterByChapterDraftArchiveArgs = {
  data: SingleIdInput;
};


export type MutationCreateChapterCommentArgs = {
  data: ChapterCommentCreationInput;
};


export type MutationCreateChapterCommentByAdminArgs = {
  data: ChapterCommentCreationByAdminInput;
};


export type MutationCreateChapterCommentReportingReasonByAdminArgs = {
  data: ChapterCommentReportingReasonCreationInput;
};


export type MutationCreateChapterOptionArgs = {
  data: ChapterOptionCreationInput;
};


export type MutationCreateChoiceFromPlayerClassArgs = {
  data: ChoiceFromPlayerClassCreationInput;
};


export type MutationCreateChrArgs = {
  data: ChrPropsInput;
};


export type MutationCreateColorArgs = {
  data: ColorCreationInput;
};


export type MutationCreateContestHasStoryArgs = {
  data: ContestHasStoryCreationInput;
};


export type MutationCreateCountryArgs = {
  data: CountryCreationInput;
};


export type MutationCreateDailyRewardCoinByAdminArgs = {
  data: DailyRewardCoinInput;
};


export type MutationCreateDanalPaymentArgs = {
  data: CreateDanalPaymentInput;
};


export type MutationCreateDirectAdsArgs = {
  data: DirectAdsCreationInput;
};


export type MutationCreateEndingCardArgs = {
  data: EndingCardCreationInput;
};


export type MutationCreateEndingRateRangeArgs = {
  data: EndingRateRangeCreationInput;
};


export type MutationCreateEndingShowsPropertyByAdminArgs = {
  data: EndingShowsPropertyCreationInput;
};


export type MutationCreateExposedSectionStoryArgs = {
  data: ExposedSectionStoryCreationInput;
};


export type MutationCreateFeaturedArgs = {
  data: FeaturedCreationInput;
};


export type MutationCreateHomeSectionArgs = {
  data: HomeSectionCreationInput;
};


export type MutationCreateHomeSectionStoryArgs = {
  data: HomeSectionStoryCreationInput;
};


export type MutationCreateImageFileArgs = {
  data: ImageFileCreationInput;
};


export type MutationCreateInappCoinByAdminArgs = {
  data: InappCoinInput;
};


export type MutationCreateInappPackageArgs = {
  data: InappPackageCreationInput;
};


export type MutationCreateMonthlyBundleByAdminArgs = {
  data: MonthlyBundleCreationInput;
};


export type MutationCreateNoticeArgs = {
  data: NoticeCreationInput;
};


export type MutationCreateNoticePopupArgs = {
  data: NoticePopupCreationInput;
};


export type MutationCreateOnboardingPopupArgs = {
  data: CreateOnboardingPopupInput;
};


export type MutationCreateOnboardingPopupChoiceArgs = {
  data: CreateOnboardingPopupChoiceInput;
};


export type MutationCreateOnboardingPopupProfileImageArgs = {
  data: CreateOnboardingPopupProfileImageInput;
};


export type MutationCreatePackageCoinArgs = {
  data: PackageCoinCreationInput;
};


export type MutationCreatePackageSkipAdsArgs = {
  data: PackageSkipAdsCreationInput;
};


export type MutationCreatePackageStoryArgs = {
  data: PackageStoryCreationInput;
};


export type MutationCreatePackageTimeSaleArgs = {
  data: PackageTimeSaleCreationInput;
};


export type MutationCreatePlayerClassArgs = {
  data: PlayerClassCreationInput;
};


export type MutationCreatePlayerReport2Args = {
  data: PlayerReport2CreationInput;
};


export type MutationCreatePlayerReport2FunctionArgs = {
  data: PlayerReport2FunctionCreationInput;
};


export type MutationCreatePlayerReport2RenderBlockArgs = {
  data: PlayerReport2RenderBlockCreationInput;
};


export type MutationCreatePreviewEndingArgs = {
  data: PreviewEndingPropsInput;
};


export type MutationCreatePublicResourceCategoryArgs = {
  data: PublicResourceCategoryCreationInput;
};


export type MutationCreatePublicResourceFilesArgs = {
  data: PublicResourceFilesCreationInput;
};


export type MutationCreateRecommendSectionStoryArgs = {
  data: RecommendSectionStoryCreationInput;
};


export type MutationCreateRecommendationForFirstUserArgs = {
  data: RecommendationForFirstUserCreationInput;
};


export type MutationCreateRecommendationOnboardingArgs = {
  data: RecommendationOnboardingCreationInput;
};


export type MutationCreateRefreshTokenForOAuthArgs = {
  data: RefreshTokenCreationInput;
};


export type MutationCreateRemoteScriptProviderArgs = {
  data: RemoteScriptProviderCreationInput;
};


export type MutationCreateSfxFileArgs = {
  data: SfxFileCreationInput;
};


export type MutationCreateSkipAdsArgs = {
  data: SkipAdsCreationInput;
};


export type MutationCreateStoreBundleArgs = {
  data: StoreBundleCreateInput;
};


export type MutationCreateStorePageSectionArgs = {
  data: StorePageSectionCreateInput;
};


export type MutationCreateStoreReviewArgs = {
  data: StoreReviewCreateInput;
};


export type MutationCreateStoreReviewCommentArgs = {
  data: StoreReviewCommentCreateInput;
};


export type MutationCreateStoreTossPaymentArgs = {
  data: StorePaymentCreateTossInput;
};


export type MutationCreateStoreXsollaPaymentTokenArgs = {
  data: StorePaymentCreateXsollaTokenInput;
};


export type MutationCreateStoryArgs = {
  data: StoryPropsInput;
};


export type MutationCreateStoryBundleStorePriceArgs = {
  data: StoryBundleStorePriceCreateInput;
};


export type MutationCreateStoryForStudioArgs = {
  data: StoryPropsInput;
};


export type MutationCreateStoryHasRecommendSectionArgs = {
  data: StoryHasRecommendSectionCreationInput;
};


export type MutationCreateStoryHasStudioFileArgs = {
  data: StoryHasStudioFileCreationInput;
};


export type MutationCreateStoryItemArgs = {
  data: StoryItemCreationInput;
};


export type MutationCreateStoryPreviewForStudioArgs = {
  data: StoryPreviewCreationInput;
};


export type MutationCreateStoryPriceSettingArgs = {
  data: StoryPriceSettingCreationInput;
};


export type MutationCreateStoryPropertyByAdminArgs = {
  data: StoryPropertyCreationInput;
};


export type MutationCreateStoryPropertyForStudioArgs = {
  data: StoryPropertyCreationInput;
};


export type MutationCreateStoryTagForStudioArgs = {
  data: StoryTagCreationInput;
};


export type MutationCreateStoryWeekdayArgs = {
  data: StoryWeekdayCreationInput;
};


export type MutationCreateStorygameBackgroundArgs = {
  data: StorygameBackgroundCreationInput;
};


export type MutationCreateStorygameCharacterAdditionalPromptArgs = {
  data: StorygameCharacterAdditionalPromptPropsInput;
};


export type MutationCreateStorygameCharacterPromptArgs = {
  data: StorygameCharacterPromptPropsInput;
};


export type MutationCreateStorygameSectionArgs = {
  data: StorygameSectionCreationInput;
};


export type MutationCreateStudioReleaseItemArgs = {
  data: StudioReleaseItemCreationInput;
};


export type MutationCreateStudioReleaseVersionArgs = {
  data: StudioReleaseVersionCreationInput;
};


export type MutationCreateSubsCoinByAdminArgs = {
  data: SubsCoinInput;
};


export type MutationCreateTermsArgs = {
  data: TermsCreationInput;
};


export type MutationCreateTestSessionArgs = {
  data: TestSessionCreationInput;
};


export type MutationCreateTodaysHotUgcArgs = {
  data: TodaysHotUgcCreationInput;
};


export type MutationCreateUserManagingStoryArgs = {
  data: UserManagingStoryCreationInput;
};


export type MutationCreateUserStoryHistoryArgs = {
  data: UserStoryHistoryCreationInput;
};


export type MutationDeactivateAccountByAdminArgs = {
  data: SingleIdInput;
};


export type MutationDeleteAdNetworkActivationForStudioArgs = {
  data: SingleIdInput;
};


export type MutationDeleteAppSplashImageArgs = {
  data: SingleIdInput;
};


export type MutationDeleteBannerArgs = {
  data: SingleIdInput;
};


export type MutationDeleteBonusTicketCodeArgs = {
  data: SingleIdInput;
};


export type MutationDeleteBottomBannerArgs = {
  data: SingleIdInput;
};


export type MutationDeleteBundleBannerArgs = {
  data: SingleIdInput;
};


export type MutationDeleteBundleFeaturedArgs = {
  data: SingleIdInput;
};


export type MutationDeleteChallengeStoryReportingReasonByAdminArgs = {
  data: SingleIdInput;
};


export type MutationDeleteChapterCommentArgs = {
  data: SingleIdInput;
};


export type MutationDeleteChapterCommentByAdminArgs = {
  data: SingleIdInput;
};


export type MutationDeleteChapterCommentReportingReasonByAdminArgs = {
  data: SingleIdInput;
};


export type MutationDeleteChapterOptionArgs = {
  data: SingleIdInput;
};


export type MutationDeleteCountryArgs = {
  data: SingleIdInput;
};


export type MutationDeleteEndingCardArgs = {
  data: SingleIdInput;
};


export type MutationDeleteFeaturedArgs = {
  data: SingleIdInput;
};


export type MutationDeleteHomeSectionArgs = {
  data: SingleIdInput;
};


export type MutationDeleteHomeSectionStoryArgs = {
  data: SingleIdInput;
};


export type MutationDeleteMonthlyBundleByAdminArgs = {
  data: SingleIdInput;
};


export type MutationDeleteNestedChapterCommentArgs = {
  data: SingleIdInput;
};


export type MutationDeleteOnboardingPopupArgs = {
  data: SingleIdInput;
};


export type MutationDeleteOnboardingPopupChoiceArgs = {
  data: SingleIdInput;
};


export type MutationDeleteOnboardingPopupProfileImageArgs = {
  data: SingleIdInput;
};


export type MutationDeleteRecommendSectionStoryArgs = {
  data: SingleIdInput;
};


export type MutationDeleteRecommendationForFirstUserArgs = {
  data: SingleIdInput;
};


export type MutationDeleteStoreBundleArgs = {
  data: SingleIdInput;
};


export type MutationDeleteStorePageSectionArgs = {
  data: SingleIdInput;
};


export type MutationDeleteStoreReviewArgs = {
  data: SingleIdInput;
};


export type MutationDeleteStoreReviewCommentArgs = {
  data: SingleIdInput;
};


export type MutationDeleteStoryHasRecommendSectionArgs = {
  data: SingleIdInput;
};


export type MutationDeleteStoryWeekdayArgs = {
  data: StoryWeekdayDeleteInput;
};


export type MutationDeleteStorygameCharacterArgs = {
  data: StorygameCharacterDeleteInput;
};


export type MutationDeleteStorygameCharacterAdditionalPromptArgs = {
  data: SingleIdInput;
};


export type MutationDeleteTermsArgs = {
  data: SingleIdInput;
};


export type MutationDiscardChapterDraftArgs = {
  data: SingleIdInput;
};


export type MutationDisconnectStoryToAchievementProjectArgs = {
  data: SingleIdInput;
};


export type MutationDuplicateChapterDraftArgs = {
  data: SingleIdInput;
};


export type MutationExportStoryInfoAsSheetArgs = {
  data: SingleStringIdInput;
};


export type MutationExportStudioChapterAsSheetArgs = {
  data: ExportStudioChapterAsSheetInput;
};


export type MutationExportStudioStoryAsSheetArgs = {
  data: ExportStudioStoryAsSheetInput;
};


export type MutationExportStudioStoryStatArgs = {
  data: SingleStringIdInput;
};


export type MutationExtendAdminLoginTokenArgs = {
  data: ExtendAdminLoginTokenInput;
};


export type MutationGetContestHasStoryArgs = {
  data: SingleIdInput;
};


export type MutationGetPackageSkipAdsArgs = {
  data: SingleIdInput;
};


export type MutationGetPresignedUrlForStoryImportV3Args = {
  data: GetPresignedUrlForStoryImportV3Input;
};


export type MutationGetTtsUrlArgs = {
  data: ConvertTextToVoiceInput;
};


export type MutationImportStoryArgs = {
  data: ImportStoryInput;
};


export type MutationInitAcceptedTermsArgs = {
  data?: InputMaybe<SingleIdInput>;
};


export type MutationInitAdultVerificationArgs = {
  data: SingleIdInput;
};


export type MutationInitUserSystemLanguageArgs = {
  data: InitUserSystemLanguageInput;
};


export type MutationLeaveServiceArgs = {
  data: LeaveServiceReasonInput;
};


export type MutationLikeBundleArgs = {
  data: LikeBundleInput;
};


export type MutationLikeChapterCommentArgs = {
  data: UserLikesChapterCommentHistoryInput;
};


export type MutationLikeStoreReviewArgs = {
  data: SingleIdInput;
};


export type MutationLikeStoreReviewCommentArgs = {
  data: SingleIdInput;
};


export type MutationLikeStoryArgs = {
  data: SingleIdInput;
};


export type MutationLinkUserWithSsoArgs = {
  data: SsoSignup;
};


export type MutationLinkUserWithSsov2Args = {
  data: SsoSignupV2;
};


export type MutationLoginAdminArgs = {
  data: EmailLogin;
};


export type MutationLoginAdminWithOtpArgs = {
  data: OtpKeyCheckInput;
};


export type MutationLoginUserArgs = {
  data: EmailLogin;
};


export type MutationLoginUserWithSsoArgs = {
  data: SsoLogin;
};


export type MutationLoginWriterWithEmailForStudioArgs = {
  data: EmailLogin;
};


export type MutationMakeChoiceArgs = {
  data: MakeChoiceInput;
};


export type MutationMarkUserStudioTutorialProgressArgs = {
  data: StudioTutorialCompletionCreationInput;
};


export type MutationMigrateStoryArgs = {
  data: SingleIdInput;
};


export type MutationMoveToStoreCartArgs = {
  data: SingleIdInput;
};


export type MutationMoveToStoreWishlistArgs = {
  data: SingleIdInput;
};


export type MutationPublishChapterArgs = {
  data: SingleIdInput;
};


export type MutationPublishChapterForStudioArgs = {
  data: PublishChapterForStudioInput;
};


export type MutationPublishPlayerReport2Args = {
  data: SingleIdInput;
};


export type MutationPublishStorePageSectionsArgs = {
  data: StorePageSectionPublishInput;
};


export type MutationPublishStoryArgs = {
  data: SingleIdInput;
};


export type MutationPublishStoryBundleInStoreArgs = {
  data: SingleIdInput;
};


export type MutationPurchaseCoinArgs = {
  data: UserPurchasedCoinInput;
};


export type MutationPurchaseCoinV3Args = {
  data: UserPurchasedCoinInput;
};


export type MutationPurchaseInappProductArgs = {
  data: UserPurchasedCoinInput;
};


export type MutationPurchasePackageArgs = {
  data: PurchasePackageInput;
};


export type MutationPurchaseSkipAdsArgs = {
  data: PurchaseSkipAdsInput;
};


export type MutationPurchaseSkipAdsV3Args = {
  data: PurchaseSkipAdsInput;
};


export type MutationPurchaseStoryArgs = {
  data: PurchaseStoryInput;
};


export type MutationReachedEpubChapterEndingArgs = {
  data: ReachedEpubChapterEndingInput;
};


export type MutationReachedEndingArgs = {
  data: ReachedEndingInput;
};


export type MutationRefundPackageArgs = {
  data: SingleIdInput;
};


export type MutationRefundStoryArgs = {
  data: SingleIdInput;
};


export type MutationRegisterOtpKeyArgs = {
  data: OtpKeyRegisterInput;
};


export type MutationRegisterUserArgs = {
  data: EmailSignup;
};


export type MutationRegisterUserByAdminArgs = {
  data: EmailSignupByAdmin;
};


export type MutationRegisterUserWithSsoArgs = {
  data: SsoSignup;
};


export type MutationRemoveChapterArgs = {
  data: SingleIdInput;
};


export type MutationRemoveChapterForStudioArgs = {
  data: SingleIdInput;
};


export type MutationRemoveChrArgs = {
  data: SingleIdInput;
};


export type MutationRemoveContestHasStoryArgs = {
  data: SingleIdInput;
};


export type MutationRemoveDirectAdsArgs = {
  data: SingleIdInput;
};


export type MutationRemoveEndingShowPropertyByAdminArgs = {
  data: SingleIdInput;
};


export type MutationRemoveEndingShowsPropertyByAdminArgs = {
  data: SingleIdInput;
};


export type MutationRemoveFromStoreCartArgs = {
  data: SingleIdInput;
};


export type MutationRemoveFromWishlistArgs = {
  data: SingleIdInput;
};


export type MutationRemoveImageFileArgs = {
  data: SingleIdInput;
};


export type MutationRemoveNoticeArgs = {
  data: SingleIdInput;
};


export type MutationRemoveNoticePopupArgs = {
  data: SingleIdInput;
};


export type MutationRemovePlayerReport2Args = {
  data: SingleIdInput;
};


export type MutationRemovePlayerReport2FunctionArgs = {
  data: SingleIdInput;
};


export type MutationRemovePlayerReport2RenderBlockArgs = {
  data: SingleIdInput;
};


export type MutationRemovePreviewEndingArgs = {
  data: SingleIdInput;
};


export type MutationRemovePublicResourceCategoryArgs = {
  data: SingleIdInput;
};


export type MutationRemovePublicResourceFileArgs = {
  data: SingleIdInput;
};


export type MutationRemovePushNotificationArgs = {
  data: SingleIdInput;
};


export type MutationRemoveRemoteScriptProviderArgs = {
  data: SingleIdInput;
};


export type MutationRemoveSfxFileArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoreBundleFromStoreCartArgs = {
  data: StoreCartStoreBundleItemInput;
};


export type MutationRemoveStoryArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoryBundleFromStoreCartArgs = {
  data: StoreCartStoryBundleItemInput;
};


export type MutationRemoveStoryHasStudioFileArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoryItemArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoryPlayHistoryArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoryPreviewForStudioArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoryPropertyByAdminArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStoryPropertyForStudioArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStorygameSectionArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStudioReleaseItemArgs = {
  data: SingleIdInput;
};


export type MutationRemoveStudioReleaseVersionArgs = {
  data: SingleIdInput;
};


export type MutationRemoveTodayRewardForTestArgs = {
  data: SingleIdInput;
};


export type MutationRemoveUserManagingStoryArgs = {
  data: UserManagingStoryRemoveInput;
};


export type MutationRemoveUserPurchasedCoinsForTestArgs = {
  data: SingleIdInput;
};


export type MutationRemoveUserStoryHistoryArgs = {
  data: SingleIdInput;
};


export type MutationReportChallengeStoryArgs = {
  data: UserReportChallengeStoryCreationInput;
};


export type MutationReportChapterCommentArgs = {
  data: UserReportChapterCommentCreationInput;
};


export type MutationRequestAiCharacterArgs = {
  data: RequestAiCharacterCreationInput;
};


export type MutationRequestImpPaymentsArgs = {
  data: RequestImpPaymentsInput;
};


export type MutationRequestTossPaymentsArgs = {
  data: RequestTossPaymentsInput;
};


export type MutationRequestTtsArgs = {
  data: ConvertTextToVoiceInput;
};


export type MutationResetUserStudioTutorialProgressArgs = {
  data: SingleIdInput;
};


export type MutationRestartAiCharacterStorygameChapterArgs = {
  data: SingleIdInput;
};


export type MutationSavePublicResourceToStudioFileArgs = {
  data: SavePublicResourceFileToStudioFileInput;
};


export type MutationSendAchievementEventArgs = {
  data: SendAchievementEventInput;
};


export type MutationSendAppEventArgs = {
  data: SendAppEventInput;
};


export type MutationSendContactMailArgs = {
  data: SendContactMailInput;
};


export type MutationSendResetLinkWithEmailArgs = {
  data: SingleEmailInput;
};


export type MutationSendVerificationEmailBeforeRegistrationArgs = {
  data: SingleEmailInput;
};


export type MutationSetDormantUserForTestArgs = {
  data: SingleIdInput;
};


export type MutationSetStoryBundleStoreCountriesArgs = {
  data: SetStoryBundleStoreCountriesInput;
};


export type MutationSetStorygamePromptsArgs = {
  data: SetStorygamePromptInput;
};


export type MutationStartChapterArgs = {
  data: StartChapterInput;
};


export type MutationStartEpubChapterArgs = {
  data: StartEpubChapterInput;
};


export type MutationStartStoryArgs = {
  data: StartStoryInput;
};


export type MutationSubscribeCoinArgs = {
  data: UserSubsCoinInput;
};


export type MutationSyncChoiceAffectionRuleArgs = {
  data: SyncChoiceAffectionRuleInput;
};


export type MutationSyncStoreBundleWithXsollaArgs = {
  data: SingleIdInput;
};


export type MutationSyncStoryBlockedCountryArgs = {
  data: SyncStoryBlockedCountryInput;
};


export type MutationSyncStoryBundleWithXsollaArgs = {
  data: SingleIdInput;
};


export type MutationSyncStoryPreviewForStudioArgs = {
  data: SyncStoryPreviewInput;
};


export type MutationSyncStoryTagForStudioArgs = {
  data: StoryTagSyncInput;
};


export type MutationSyncUserItemsArgs = {
  data: SyncUserItemsInput;
};


export type MutationTerminateDormantUserArgs = {
  data: TerminateDormantUserInput;
};


export type MutationToggleStorygameCountryArgs = {
  data: ToggleStorygameCountry;
};


export type MutationToggleStorygameLanguageArgs = {
  data: ToggleStorygameLanguage;
};


export type MutationToggleStorygameSectionCountryArgs = {
  data: ToggleStorygameSectionCountry;
};


export type MutationToggleStorygameVisibilityArgs = {
  data: ToggleStorygameVisibilityInput;
};


export type MutationUnbanWriterForStudioArgs = {
  data: SingleIdInput;
};


export type MutationUnblockUserArgs = {
  data: SingleIdInput;
};


export type MutationUnlikeStoreReviewArgs = {
  data: SingleIdInput;
};


export type MutationUnlikeStoreReviewCommentArgs = {
  data: SingleIdInput;
};


export type MutationUnpublishStoryArgs = {
  data: SingleIdInput;
};


export type MutationUnpublishStoryBundleInStoreArgs = {
  data: SingleIdInput;
};


export type MutationUnsubscribeCoinForTestArgs = {
  data: SingleIdInput;
};


export type MutationUpdateAdNetworkActivationForStudioArgs = {
  data: AdNetworkActivationUpdateInput;
};


export type MutationUpdateAppConfigArgs = {
  data: AppConfigUpdateInput;
};


export type MutationUpdateAppSplashImageArgs = {
  data: AppSplashImageUpdateInput;
};


export type MutationUpdateAuthorArgs = {
  data: UpdateAuthorInput;
};


export type MutationUpdateAuthorByAdminArgs = {
  data: UpdateAuthorByAdminInput;
};


export type MutationUpdateBackgroundArgs = {
  data: StoryHasBackgroundPropsInput;
};


export type MutationUpdateBannerArgs = {
  data: BannerUpdateInput;
};


export type MutationUpdateBlockHistoryArgs = {
  data: UpdateBlockHistoryInput;
};


export type MutationUpdateBonusTicketCodeArgs = {
  data: BonusTicketCodeUpdateInput;
};


export type MutationUpdateBottomBannerArgs = {
  data: BottomBannerUpdateInput;
};


export type MutationUpdateBundleBannerArgs = {
  data: BundleBannerUpdateInput;
};


export type MutationUpdateBundleFeaturedArgs = {
  data: BundleFeaturedUpdateInput;
};


export type MutationUpdateChallengeStoryReportingReasonByAdminArgs = {
  data: ChallengeStoryReportingReasonUpdateInput;
};


export type MutationUpdateChapterArgs = {
  data: ChapterPropsInput;
};


export type MutationUpdateChapterBounceStatArgs = {
  data: RunStatBatchInput;
};


export type MutationUpdateChapterCommentArgs = {
  data: ChapterCommentUpdateInput;
};


export type MutationUpdateChapterCommentByAdminArgs = {
  data: ChapterCommentUpdateByAdminInput;
};


export type MutationUpdateChapterCommentReportingReasonByAdminArgs = {
  data: ChapterCommentReportingReasonUpdateInput;
};


export type MutationUpdateChapterForStudioArgs = {
  data: ChapterPropsInput;
};


export type MutationUpdateChapterIndexesArgs = {
  data: UpdateChapterIndexesInput;
};


export type MutationUpdateChapterOptionArgs = {
  data: ChapterOptionUpdateInput;
};


export type MutationUpdateChapterPriceForStudioArgs = {
  data: UpdateChapterPriceForStudioInput;
};


export type MutationUpdateChoiceArgs = {
  data: UpdateChoiceInput;
};


export type MutationUpdateChoiceFromPlayerClassArgs = {
  data: ChoiceFromPlayerClassUpdateInput;
};


export type MutationUpdateChrArgs = {
  data: ChrPropsInput;
};


export type MutationUpdateContestHasStoryArgs = {
  data: ContestHasStoryUpdateInput;
};


export type MutationUpdateConversionStatOfStoryArgs = {
  data: RunConversionBatchInput;
};


export type MutationUpdateCountryArgs = {
  data: CountryUpdateInput;
};


export type MutationUpdateDirectAdsArgs = {
  data: DirectAdsUpdateInput;
};


export type MutationUpdateEndingArgs = {
  data: EndingPropsInput;
};


export type MutationUpdateEndingCardArgs = {
  data: EndingCardUpdateInput;
};


export type MutationUpdateEndingShowsPropertyByAdminArgs = {
  data: EndingShowsPropertyUpdateInput;
};


export type MutationUpdateFeaturedArgs = {
  data: FeaturedUpdateInput;
};


export type MutationUpdateHomeSectionArgs = {
  data: HomeSectionUpdateInput;
};


export type MutationUpdateMonthlyBundleByAdminArgs = {
  data: MonthlyBundleUpdateInput;
};


export type MutationUpdateNoticeArgs = {
  data: NoticeUpdateInput;
};


export type MutationUpdateNoticePopupArgs = {
  data: NoticePopupUpdateInput;
};


export type MutationUpdateNumCommentStatOfStoryArgs = {
  data: RunStatBatchInput;
};


export type MutationUpdateNumLikeStatOfStoryArgs = {
  data: RunStatBatchInput;
};


export type MutationUpdateNumPlayStatOfStoryArgs = {
  data: RunStatBatchInput;
};


export type MutationUpdateOnboardingPopupArgs = {
  data: UpdateOnboardingPopupInput;
};


export type MutationUpdateOnboardingPopupChoiceArgs = {
  data: UpdateOnboardingPopupChoiceInput;
};


export type MutationUpdateOnboardingPopupProfileImageArgs = {
  data: UpdateOnboardingPopupProfileImageInput;
};


export type MutationUpdatePlayerClassArgs = {
  data: PlayerClassUpdateInput;
};


export type MutationUpdatePlayerReport2Args = {
  data: PlayerReport2UpdateInput;
};


export type MutationUpdatePlayerReport2FunctionArgs = {
  data: PlayerReport2FunctionUpdateInput;
};


export type MutationUpdatePlayerReport2RenderBlockArgs = {
  data: PlayerReport2RenderBlockUpdateInput;
};


export type MutationUpdatePreviewEndingArgs = {
  data: PreviewEndingPropsInput;
};


export type MutationUpdatePublicResourceCategoryArgs = {
  data: PublicResourceCategoryUpdateInput;
};


export type MutationUpdatePublicResourceFileArgs = {
  data: PublicResourceFileUpdateInput;
};


export type MutationUpdateRecommendationForFirstUserArgs = {
  data: RecommendationForFirstUserUpdateInput;
};


export type MutationUpdateRemoteScriptProviderArgs = {
  data: RemoteScriptProviderUpdateInput;
};


export type MutationUpdateStoreBundleArgs = {
  data: StoreBundleUpdateInput;
};


export type MutationUpdateStorePageSectionArgs = {
  data: StorePageSectionUpdateInput;
};


export type MutationUpdateStorePageSectionCarouselArgs = {
  data: StorePageSectionCarouselUpdateInput;
};


export type MutationUpdateStorePageSectionCarouselBundleArgs = {
  data: StorePageSectionCarouselBundleUpdateInput;
};


export type MutationUpdateStorePageSectionCarouselProgressArgs = {
  data: StorePageSectionCarouselProgressUpdateInput;
};


export type MutationUpdateStorePageSectionCarouselRankArgs = {
  data: StorePageSectionCarouselRankUpdateInput;
};


export type MutationUpdateStorePageSectionCountryArgs = {
  data: StorePageSectionCountryUpdateInput;
};


export type MutationUpdateStorePageSectionRankListArgs = {
  data: StorePageSectionRankListUpdateInput;
};


export type MutationUpdateStorePageSectionSliderArgs = {
  data: StorePageSectionSliderUpdateInput;
};


export type MutationUpdateStoreReviewArgs = {
  data: StoreReviewUpdateInput;
};


export type MutationUpdateStoreReviewCommentArgs = {
  data: StoreReviewCommentUpdateInput;
};


export type MutationUpdateStoreSearchConfigArgs = {
  data: StoreSearchConfigUpdateInput;
};


export type MutationUpdateStoryArgs = {
  data: StoryPropsInput;
};


export type MutationUpdateStoryBundleCountriesArgs = {
  data: UpdateStoryBundleCountriesInput;
};


export type MutationUpdateStoryBundleStoreArgs = {
  data: StoryBundleStoreUpdateInput;
};


export type MutationUpdateStoryBundleStorePriceArgs = {
  data: StoryBundleStorePriceUpdateInput;
};


export type MutationUpdateStoryHasRecommendSectionArgs = {
  data: StoryHasRecommendSectionUpdateInput;
};


export type MutationUpdateStoryHasStudioFileArgs = {
  data: StoryHasStudioFileUpdateInput;
};


export type MutationUpdateStoryItemArgs = {
  data: StoryItemUpdateInput;
};


export type MutationUpdateStoryPreviewFileArgs = {
  data: UpdateStoryPreviewFileInput;
};


export type MutationUpdateStoryPriceSettingArgs = {
  data: StoryPriceSettingUpdateInput;
};


export type MutationUpdateStoryPriceSettingForStudioArgs = {
  data: UpdateStoryPriceSettingForStudioInput;
};


export type MutationUpdateStoryPropertyByAdminArgs = {
  data: StoryPropertyUpdateInput;
};


export type MutationUpdateStoryPropertyForStudioArgs = {
  data: StoryPropertyUpdateInput;
};


export type MutationUpdateStoryRankCacheArgs = {
  data: UpdateStoryRankCacheInput;
};


export type MutationUpdateStoryStatsForStudioArgs = {
  data: SingleIdInput;
};


export type MutationUpdateStorygameArgs = {
  data: UpdateStorygameInput;
};


export type MutationUpdateStorygameBackgroundArgs = {
  data: StorygameBackgroundUpdateInput;
};


export type MutationUpdateStorygameCharacterAdditionalPromptArgs = {
  data: StorygameCharacterAdditionalPromptPropsInput;
};


export type MutationUpdateStorygameCharacterPromptArgs = {
  data: StorygameCharacterPromptPropsInput;
};


export type MutationUpdateStorygameSectionArgs = {
  data: StorygameSectionUpdateInput;
};


export type MutationUpdateStorygameSectionOrderArgs = {
  data: UpdateStorygameSectionOrderInput;
};


export type MutationUpdateStorygameTabOrderArgs = {
  data: UpdateStorygameTabOrderInput;
};


export type MutationUpdateStudioReleaseItemArgs = {
  data: StudioReleaseItemUpdateInput;
};


export type MutationUpdateStudioReleaseVersionArgs = {
  data: StudioReleaseVersionUpdateInput;
};


export type MutationUpdateTermsArgs = {
  data: TermsUpdateInput;
};


export type MutationUpdateUserArgs = {
  data: UserPropsInput;
};


export type MutationUpdateUserAdultCertificationArgs = {
  data: UpdateUserAdultCertificationInput;
};


export type MutationUpdateUserAdultCertificationForStoreGlobalArgs = {
  data: UpdateUserAdultCertificationForStoreGlobalInput;
};


export type MutationUpdateUserByAdminArgs = {
  data: UserPropsInputByAdmin;
};


export type MutationUpdateUserDetailPushSettingArgs = {
  data: DetailPushSetting;
};


export type MutationUpdateUserItemsArgs = {
  data: UpdateUserItemsInput;
};


export type MutationUpdateUserManagingStoryArgs = {
  data: UserManagingStoryUpdateInput;
};


export type MutationUpdateUserPropsArgs = {
  data: UpdateUserPropsInput;
};


export type MutationUpdateUserPushInfoArgs = {
  data: PushAgreementInfo;
};


export type MutationUpdateUserRecommendationOnboardingForClientArgs = {
  data: UpdateUserRecommendationOnboardingForClientInput;
};


export type MutationUpdateUserSystemLanguageArgs = {
  data: UpdateUserSystemLanguageInput;
};


export type MutationUpsertChapterDraftArgs = {
  data: ChapterDraftUpsertInput;
};


export type MutationUpsertMainChrOnboardingPopupArgs = {
  data: UpsertMainChrOnboardingPopupInput;
};


export type MutationUpsertStorygameCharacterArgs = {
  data: StorygameCharacterUpsertInput;
};

/** 내가 작성한 작품에 대한 정보 */
export type MyStory = {
  __typename?: 'MyStory';
  /** 내가 작성한 작품에 대한 통계 */
  stat: MyStoryStat;
};

/** 내가 작성한 작품에 대한 통계 */
export type MyStoryStat = {
  __typename?: 'MyStoryStat';
  /** 총 회차 수 (더보기: 회차) */
  numChapter: Scalars['Int'];
  /** 총 댓글 수 (더보기: 댓글) */
  numComment: Scalars['Int'];
  /** 총 좋아요 수 (더보기: 좋아요) */
  numLikes: Scalars['Int'];
  /** 총 작품 수 (더보기: 작품) */
  numStory: Scalars['Int'];
  /** 총 조회수 (더보기: 조회수) */
  numViews: Scalars['Int'];
};

/** 재동의가 필요한 약관 */
export type NewTermsToAccept = {
  __typename?: 'NewTermsToAccept';
  /** 개인정보 수집 및 이용 */
  CollectionAndUseOfPrivacy: Terms;
  /** 서비스 이용 약관 */
  TOS: Terms;
};

/** 재동의가 필요한 약관 */
export type NewTermsToAcceptV2 = {
  __typename?: 'NewTermsToAcceptV2';
  /** 개인정보 수집 및 이용. 한국에만 동의 필요. 그 외 국가에는 null이어야 함 */
  CollectionAndUseOfPrivacy?: Maybe<Terms>;
  /** 개인정보 처리방침. 그 외 국가에만 동의 필요. 한국은 null이어야 함 */
  PP?: Maybe<Terms>;
  /** 서비스 이용 약관 */
  TOS: Terms;
};

/** 재동의가 필요한 약관 */
export type NewTermsToAcceptV3 = {
  __typename?: 'NewTermsToAcceptV3';
  /** 개인정보 수집 및 이용. 한국에만 동의 필요. 그 외 국가에는 null이어야 함 */
  CollectionAndUseOfPrivacy?: Maybe<Terms>;
  /** 개인정보 처리방침. 그 외 국가에만 동의 필요. 한국은 null이어야 함 */
  PP?: Maybe<Terms>;
  /** 서비스 이용 약관 */
  TOS: Terms;
};

/** 공지사항 */
export type Notice = {
  __typename?: 'Notice';
  /** 공지사항 내용 */
  contents: Scalars['String'];
  /** 공지사항 국가코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 공지사항 언어코드 */
  langCode: Scalars['String'];
  /** 공지사항 일련번호 */
  noticeId: Scalars['Int'];
  /** 공지사항 제목 */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Notice 생성을 위한 입력값 */
export type NoticeCreationInput = {
  /** 공지사항 내용 */
  contents: Scalars['String'];
  /** 공지사항 국가코드 */
  countryCode: Scalars['String'];
  /** 공지사항 언어코드 */
  langCode: Scalars['String'];
  /** 공지사항 제목 */
  title: Scalars['String'];
};

/** 앱 시작시 이미지로 보여지는 공지사항 */
export type NoticePopup = {
  __typename?: 'NoticePopup';
  /** 공지사항 국가코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 딥 링크 */
  deepLink?: Maybe<Scalars['String']>;
  /** 공지사항 반영 종료 시각 */
  endAt?: Maybe<Scalars['DateTime']>;
  /** 이미지 파일 */
  imageFile?: Maybe<File>;
  /** 이미지 파일의 Id */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 공지사항 언어코드 */
  langCode: Scalars['String'];
  /** 일반 링크 */
  link?: Maybe<Scalars['String']>;
  /** 팝업 공지사항 일련번호 */
  noticePopupId: Scalars['Int'];
  /** 공지사항 반영 시작 시각 */
  startAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

/** NoticePopup 생성을 위한 입력값 */
export type NoticePopupCreationInput = {
  /** 공지사항 국가코드 */
  countryCode: Scalars['String'];
  /** Deep 링크 */
  deepLink?: InputMaybe<Scalars['String']>;
  /** 공지사항 반영 종료 시각 */
  endAt?: InputMaybe<Scalars['DateTime']>;
  /** 이미지 파일 */
  imageFile: Scalars['Upload'];
  /** 공지사항 언어코드 */
  langCode: Scalars['String'];
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 공지사항 반영 시작 시각 */
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** NoticePopup 업데이트를 위한 입력값 */
export type NoticePopupUpdateInput = {
  /** 공지사항 국가코드 */
  countryCode: Scalars['String'];
  /** Deep 링크 */
  deepLink?: InputMaybe<Scalars['String']>;
  /** 공지사항 반영 종료 시각 */
  endAt?: InputMaybe<Scalars['DateTime']>;
  /** 업데이트할 NoticePopup 의 Primary Key */
  id: Scalars['Int'];
  /** 이미지 파일 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 공지사항 언어코드 */
  langCode: Scalars['String'];
  /** 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 공지사항 반영 시작 시각 */
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** 앱/웹에서 보여줄 공지용 약관 */
export type NoticeTerms = {
  __typename?: 'NoticeTerms';
  /** 개인정보 처리방침 */
  PP: Terms;
  /** 서비스 이용 약관 */
  TOS: Terms;
};

/** Notice 업데이트를 위한 입력값 */
export type NoticeUpdateInput = {
  /** 공지사항 내용 */
  contents?: InputMaybe<Scalars['String']>;
  /** 공지사항 국가코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 업데이트할 Notice 의 Primary Key */
  id: Scalars['Int'];
  /** 공지사항 언어코드 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 공지사항 제목 */
  title?: InputMaybe<Scalars['String']>;
};

export type NumCommentOfChapterResponse = {
  __typename?: 'NumCommentOfChapterResponse';
  /** 회차 댓글수 목록 */
  list: Array<NumCommentOfChapterRow>;
};

export type NumCommentOfChapterRow = {
  __typename?: 'NumCommentOfChapterRow';
  /** 회차 아이디 */
  chapterId: Scalars['Int'];
  /** 회차 */
  chapterIndex: Scalars['Int'];
  /** 댓글수 */
  count: Scalars['Int'];
};

export type NumCommentStatOfStoryResponse = {
  __typename?: 'NumCommentStatOfStoryResponse';
  /** 조회기간 바로 전 기간과의 변경 수치 */
  changedCount: Scalars['Int'];
  /** 조회기간 바로 전 기간과의 변경률 */
  changedRate: Scalars['Float'];
  /** 조회기간의 댓글수 */
  latestCount: Scalars['Int'];
  /** 상세내역 */
  list: Array<StoryStatCache>;
  /** 전체 댓글 수 */
  total: Scalars['Int'];
};

export type NumLikeStatOfStoryResponse = {
  __typename?: 'NumLikeStatOfStoryResponse';
  /** 조회기간 바로 전 기간과의 변경 수치 */
  changedCount: Scalars['Int'];
  /** 조회기간 바로 전 기간과의 변경률 */
  changedRate: Scalars['Float'];
  /** 조회기간의 좋아요수 */
  latestCount: Scalars['Int'];
  /** 상세내역 */
  list: Array<StoryStatCache>;
  /** 누적 좋아요 수 */
  total: Scalars['Int'];
};

export type NumPlayOfChapterResponse = {
  __typename?: 'NumPlayOfChapterResponse';
  /** 회차 조회수 목록 */
  list: Array<NumPlayOfChapterRow>;
};

export type NumPlayOfChapterRow = {
  __typename?: 'NumPlayOfChapterRow';
  /** 회차 아이디 */
  chapterId: Scalars['Int'];
  /** 회차 */
  chapterIndex: Scalars['Int'];
  /** 조회수 */
  count: Scalars['Int'];
};

export type NumPlayStatOfStoryResponse = {
  __typename?: 'NumPlayStatOfStoryResponse';
  /** 조회기간 바로 전 기간과의 변경 수치 */
  changedCount: Scalars['Int'];
  /** 조회기간 바로 전 기간과의 변경률 */
  changedRate: Scalars['Float'];
  /** 조회기간의 조회수 */
  latestCount: Scalars['Int'];
  /** 상세항목 */
  list: Array<StoryStatCache>;
  /** 누적 조회수 */
  total: Scalars['Int'];
};

/** 팝업 조건 부등호 */
export enum Onboarding_Popup_Inequality {
  Equal = 'Equal',
  EqualOrLessThan = 'EqualOrLessThan',
  EqualOrMoreThan = 'EqualOrMoreThan',
  LessThan = 'LessThan',
  MoreThan = 'MoreThan',
  NotEqual = 'NotEqual'
}

/** 온보딩 팝업 이벤트 타입 */
export enum Onboarding_Popup_Type {
  Choice = 'Choice',
  Name = 'Name',
  OtherName = 'OtherName',
  OtherProfile = 'OtherProfile',
  Profile = 'Profile',
  StorygameProfile = 'StorygameProfile',
  Text = 'Text'
}

/** 정렬 기준 방향. DESC/ASC */
export enum Order_By_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** OS name */
export enum Os {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

/** otp token 체크를 위한 입력값 */
export type OtpKeyCheckInput = {
  /** otp 토큰 6자리 */
  token: Scalars['String'];
};

/** otp key 등록을 위한 입력값 */
export type OtpKeyRegisterInput = {
  /** otp 고유 키 */
  otpKey: Scalars['String'];
  /** otp 토큰 6자리 */
  token: Scalars['String'];
};

/** otp key 발급 결과 */
export type OtpKeyResponse = {
  __typename?: 'OTPKeyResponse';
  /** 유저 이메일 */
  email: Scalars['String'];
  /** otp secret code */
  secretCode: Scalars['String'];
  /** otp 등록 url */
  url: Scalars['String'];
};

/** OTPLogin Response */
export type OtpLoginResponse = {
  __typename?: 'OTPLoginResponse';
  /** OTP 등록 여부 */
  isRegisteredOTP: Scalars['Boolean'];
  /** otp 관련 api에 접속가능한 jwt 토큰 */
  token: Scalars['String'];
};

/** Simple ok response */
export type OkResponse = {
  __typename?: 'OkResponse';
  ok: Scalars['Float'];
};

/** 온보딩 팝업 */
export type OnboardingPopup = {
  __typename?: 'OnboardingPopup';
  /** 등장인물 AI 캐릭터로 활성화 여부 */
  canAICharacter?: Maybe<Scalars['Boolean']>;
  /** 주인공 이름/상대방 이름 팝업에 프로필 이미지 업로드 가능 여부 */
  canUploadProfileImage: Scalars['Boolean'];
  /** 팝업이 속한 챕터 */
  chapter: Chapter;
  /** 팝업이 속한 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 기타 인물 원래 캐릭터 정보 */
  character?: Maybe<Chr>;
  /** 기타 인물 원래 캐릭터 ID */
  characterId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 팝업의 지문/질문 */
  description: Scalars['String'];
  /** 해당 팝업에 대해 최대 광고 가능 횟수 */
  maxAdCount?: Maybe<Scalars['Int']>;
  /** 이름 팝업 이름만 입력할지 여/부 다른 팝업은 default false */
  onlyName: Scalars['Boolean'];
  /** 웹 원화 결제나, 인앱 결제 상품 정보. 같은 정보를 참조한다 */
  paymentProduct?: Maybe<InappCoin>;
  /** 팝업 선택지 리스트 */
  popupChoices?: Maybe<Array<OnboardingPopupChoice>>;
  /** 아이디 */
  popupId: Scalars['Int'];
  /** OtherName | Name 타입의 팝업 이면서, 기본으로 등록된 이미지 리스트 */
  popupProfileImages?: Maybe<Array<OnboardingPopupProfileImage>>;
  /** 팝업에 대한 coin 비용 */
  price?: Maybe<Scalars['Int']>;
  /** 팝업 순서 숫자가 높으면 먼저 노출 */
  showOrder: Scalars['Int'];
  /** Active: 활성, Inactive: 비활성 */
  status: Status;
  /** 팝업이 속한 스토리 */
  story: Story;
  /** 팝업이 속한 스토리 아이디 */
  storyId: Scalars['Int'];
  /** Text: 텍스트, Choice: 선택, Profile: 프로필, Name: 주인공 이름, OtherName: 상대역 이름 */
  type: Onboarding_Popup_Type;
  updatedAt: Scalars['DateTime'];
  /** 온보딩 파업 노출 조건(속성 값(number)으로만 설정 가능) */
  visibleConditions?: Maybe<Array<Scalars['JSONObject']>>;
};

/** 온보딩 팝업 선택지 */
export type OnboardingPopupChoice = {
  __typename?: 'OnboardingPopupChoice';
  /** 선책지 속성값 display: 사용자에게 보여지는 초기 속성값, effect: 사용자에게 보여지지 않는 초기 속성값 */
  choiceProps?: Maybe<Scalars['JSONObject']>;
  /** 선택지 텍스트 */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 팝업 타입이 Profile 일때는 프로필 이미지, Choice 일때는 선택지 이미지 */
  imageFile?: Maybe<File>;
  /** 이미지 파일 ID: 팝업 타입이 Profile 일때는 프로필 이미지, Choice 일때는 선택지 이미지 */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 유저 타입(playerClass) 정보 */
  playerClass?: Maybe<PlayerClass>;
  /** 유저 타입(playerClass) ID */
  playerClassId?: Maybe<Scalars['Int']>;
  /** 온보딩 팝업 */
  popup: OnboardingPopup;
  /** 아이디 */
  popupChoiceId: Scalars['Int'];
  /** 온보딩 팝업 ID */
  popupId: Scalars['Int'];
  /** 선택지 순서 숫자가 높으면 먼저 노출 */
  showOrder: Scalars['Int'];
  /** 선택지 상태값 */
  status: Status;
  updatedAt: Scalars['DateTime'];
};

/** 온보딩에서 기본 프로필로 설정할 수 있는 이미지 정보 */
export type OnboardingPopupProfileImage = {
  __typename?: 'OnboardingPopupProfileImage';
  createdAt: Scalars['DateTime'];
  /** 프로필 이미지 설명 */
  description?: Maybe<Scalars['String']>;
  /** 온보딩 팝업 */
  popup: OnboardingPopup;
  /** 온보딩 팝업 ID */
  popupId: Scalars['Int'];
  /** 아이디 */
  popupProfileImageId: Scalars['Int'];
  /** 프로필 이미지 파일 */
  profileImageFile: File;
  /** 프로필 이미지 파일 ID */
  profileImageFileId: Scalars['Int'];
  /** 숫자가 높으면 먼저 노출 */
  showOrder: Scalars['Int'];
  /** 프로필 이미지 상태값 */
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type OnboardingPopupVisibleConditionsInput = {
  /** 속성 값 조건 부등호 */
  inequality: Onboarding_Popup_Inequality;
  /** 속성명 */
  property: Scalars['String'];
  /** 속성 값 */
  value: Scalars['Int'];
};

/** 상대역 사용자 설정 정보 */
export type OtherCharacterInfo = {
  /** 상대역 사용자 설정 이름 */
  customFirstName?: InputMaybe<Scalars['String']>;
  /** 상대력 사용자 설정 성 */
  customLastName?: InputMaybe<Scalars['String']>;
  /** 사용자가 업로드한 프로필 이미지 정보 */
  customProfileFileInfo?: InputMaybe<CustomProfileFileInfo>;
  /** 상대역 어드민 설정 origin 성+이름 */
  originName: Scalars['String'];
  /** 상대력 사용자 프로필 이미지 ID */
  profileImageFileId?: InputMaybe<Scalars['Int']>;
};

/** 공용리소스 타입 */
export enum Public_Resource_Type {
  BackgroundImage = 'BACKGROUND_IMAGE',
  Bgm = 'BGM',
  CharImage = 'CHAR_IMAGE',
  FullWidthImage = 'FULL_WIDTH_IMAGE',
  ItemImage = 'ITEM_IMAGE',
  Sfx = 'SFX'
}

/** 수신한 푸쉬 메시지의 카테고리 */
export enum Push_Category {
  MyChapterComment = 'MY_CHAPTER_COMMENT',
  MyFavoriteStory = 'MY_FAVORITE_STORY',
  MyStory = 'MY_STORY'
}

/** 패키지에 속한 코인 정보 */
export type PackageCoin = {
  __typename?: 'PackageCoin';
  /** 구매시 획득하는 코인 갯수 */
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 설명 */
  desc: Scalars['String'];
  /** 메뉴 이미지 */
  menuImageFile?: Maybe<ImageFile>;
  /** 메뉴 이미지 아이디 */
  menuImageFileId?: Maybe<Scalars['Int']>;
  /** 코인이 속한 패키지 */
  package: InappPackage;
  /** 아이디 */
  packageCoinId: Scalars['Int'];
  /** 코인이 속한 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 표시되는 가격(원) */
  price: Scalars['Int'];
  /** 타이틀 */
  title: Scalars['String'];
  /** 주제 (자유입력) */
  topic: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** PackageCoin 생성을 위한 input */
export type PackageCoinCreationInput = {
  /** 구매시 획득하는 코인 갯수 */
  amount: Scalars['Int'];
  /** 설명 */
  desc: Scalars['String'];
  /** 메뉴 이미지 */
  menuImageFile?: InputMaybe<Scalars['Upload']>;
  /** 코인이 속한 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 표시되는 가격(원) */
  price: Scalars['Int'];
  /** 타이틀 */
  title: Scalars['String'];
  /** 주제 (자유입력) */
  topic: Scalars['String'];
};

/** PackageCoin 업데이트를 위한 input */
export type PackageCoinUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

export type PackageSkipAds = {
  __typename?: 'PackageSkipAds';
  createdAt: Scalars['DateTime'];
  /** 광고 스킵 상품이 속한 패키지 */
  package: InappPackage;
  /** 광고 스킵 상품이 속한 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 아이디 */
  packageSkipAdsId: Scalars['Int'];
  /** 패키지에 속한 광고 스킵 상품 */
  skipAds: SkipAds;
  /** 패키지에 속한 광고 스킵 상품의 아이디 */
  skipAdsId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** PackageSkipAds 생성을 위한 input */
export type PackageSkipAdsCreationInput = {
  /** 광고 스킵 상품이 속한 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 패키지에 속한 광고 스킵 상품의 아이디 */
  skipAdsId: Scalars['Int'];
};

/** PackageSkipAds 업데이트를 위한 input */
export type PackageSkipAdsUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 패키지에 속한 스토리 정보 */
export type PackageStory = {
  __typename?: 'PackageStory';
  createdAt: Scalars['DateTime'];
  /** 스토리가 속한 패키지 */
  package: InappPackage;
  /** 스토리가 속한 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 아이디 */
  packageStoryId: Scalars['Int'];
  /** 패키지에 속한 스토리 간의 보여지는 순서. 클수록 상단에 노출되어야 함 */
  showOrder: Scalars['Int'];
  /** 패키지에 속한 스토리 */
  story: Story;
  /** 패키지에 속한 스토리 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** PackageStory 생성을 위한 input */
export type PackageStoryCreationInput = {
  /** 스토리가 속한 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 패키지에 속한 스토리 간의 보여지는 순서. 클수록 상단에 노출되어야 함 */
  showOrder: Scalars['Int'];
  /** 패키지에 속한 스토리 아이디 */
  storyId: Scalars['Int'];
};

/** PackageStory 업데이트를 위한 input */
export type PackageStoryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 패키지 상품에 적용되는 타임세일 정보 */
export type PackageTimeSale = {
  __typename?: 'PackageTimeSale';
  createdAt: Scalars['DateTime'];
  /** 타임세일이 적용된 패키지 */
  package: InappPackage;
  /** 타임세일이 적용된 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 아이디 */
  packageTimeSaleId: Scalars['Int'];
  /** 타임세일 기간. UntilFixedEndDate 타입일 때는 null이 됨 */
  period?: Maybe<Scalars['Int']>;
  /** 타임세일 타입 */
  timeSaleType: Time_Sale_Type;
  updatedAt: Scalars['DateTime'];
  /** 타임세일 종료 시각. OnlyOnceSinceFirstVisitStore 타입일 때는 null이 됨 */
  willFinishAt?: Maybe<Scalars['DateTime']>;
};

/** PackageTimeSale 생성을 위한 input */
export type PackageTimeSaleCreationInput = {
  /** 타임세일이 적용된 패키지의 아이디 */
  packageId: Scalars['Int'];
  /** 타임세일 기간. UntilFixedEndDate 타입일 때는 null이 됨 */
  period?: InputMaybe<Scalars['Int']>;
  /** 타임세일 타입 */
  timeSaleType: Time_Sale_Type;
  /** 타임세일 종료 시각. OnlyOnceSinceFirstVisitStore 타입일 때는 null이 됨 */
  willFinishAt?: InputMaybe<Scalars['DateTime']>;
};

/** PackageTimeSale 업데이트를 위한 input */
export type PackageTimeSaleUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 파우스트 서버 사용량 체크하는 테이블 */
export type PaustSeverUsage = {
  __typename?: 'PaustSeverUsage';
  createdAt: Scalars['DateTime'];
  /** 서버 접속 일 token */
  dateToken: Scalars['String'];
  /** 아이디 */
  paustSeverUsageId: Scalars['Int'];
  /** 파우스트 서버 접속 횟수 */
  requestCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** player class 정보 */
export type PlayerClass = {
  __typename?: 'PlayerClass';
  createdAt: Scalars['DateTime'];
  /** player class name */
  name: Scalars['String'];
  /** 아이디 */
  playerClassId: Scalars['Int'];
  /** 맵핑된 스토리 */
  story: Story;
  /** 맵핑된 스토리 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** PlayerClass 생성을 위한 input */
export type PlayerClassCreationInput = {
  /** 속성명 */
  name: Scalars['String'];
  /** 맵핑된 스토리 ID */
  storyId: Scalars['Int'];
};

/** PlayerClass 수정 위한 input */
export type PlayerClassUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** player class name */
  name?: InputMaybe<Scalars['String']>;
};

export type PlayerDataForPlayerReport2 = {
  __typename?: 'PlayerDataForPlayerReport2';
  /** 사용자의 플레이어 데이터 */
  sampleData: Scalars['String'];
};

/** 플레이어 분석 보고서 v2 */
export type PlayerReport2 = {
  __typename?: 'PlayerReport2';
  /** 템플릿 코드 */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 분석보고서 이름 */
  name: Scalars['String'];
  /** 보고서의 og:description */
  ogDesc?: Maybe<Scalars['String']>;
  /** 보고서의 og:image */
  ogImageFile?: Maybe<ImageFile>;
  /** 보고서의 og:image */
  ogImageFileId?: Maybe<Scalars['Int']>;
  /** 보고서의 og:title */
  ogTitle?: Maybe<Scalars['String']>;
  /** 아이디 */
  prId: Scalars['Int'];
  /** 테스트에 사용할 샘플 데이터 */
  sampleData: Scalars['String'];
  /** 이 분석 보고서가 활용되는 작품 */
  story: Story;
  /** 이 분석 보고서가 활용되는 작품 Id */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** PlayerReport2 항목을 생성할 때 필요한 데이터 */
export type PlayerReport2CreationInput = {
  /** 템플릿 코드 */
  code: Scalars['String'];
  /** 분석보고서 이름 */
  name: Scalars['String'];
  /** 보고서의 og:description */
  ogDesc?: InputMaybe<Scalars['String']>;
  /** 보고서의 og:image */
  ogImageFile?: InputMaybe<Scalars['Upload']>;
  /** 보고서의 og:title */
  ogTitle?: InputMaybe<Scalars['String']>;
  /** 테스트에 사용할 샘플 데이터 */
  sampleData: Scalars['String'];
  /** 이 분석 보고서가 활용되는 작품 Id */
  storyId: Scalars['Int'];
};

/** 플레이어 분석 보고서 v2 용 함수 조각 */
export type PlayerReport2Function = {
  __typename?: 'PlayerReport2Function';
  /** 실제 동작할 코드 */
  code: Scalars['String'];
  /** 복붙할 코드 샘플 */
  codeSample: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 설명 */
  desc: Scalars['String'];
  /** 함수명 */
  name: Scalars['String'];
  /** 파라메터 설명 */
  paramDesc: Scalars['String'];
  /** 아이디 */
  prfId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** PlayerReport2Function 항목을 생성할 때 필요한 데이터 */
export type PlayerReport2FunctionCreationInput = {
  /** 실제 동작할 코드 */
  code: Scalars['String'];
  /** 복붙할 코드 샘플 */
  codeSample: Scalars['String'];
  /** 설명 */
  desc: Scalars['String'];
  /** 함수명 */
  name: Scalars['String'];
  /** 파라메터 설명 */
  paramDesc: Scalars['String'];
};

/** PlayerReport2Function 항목을 업데이트할 때 필요한 데이터 */
export type PlayerReport2FunctionUpdateInput = {
  /** 실제 동작할 코드 */
  code?: InputMaybe<Scalars['String']>;
  /** 복붙할 코드 샘플 */
  codeSample?: InputMaybe<Scalars['String']>;
  /** 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 함수명 */
  name?: InputMaybe<Scalars['String']>;
  /** 파라메터 설명 */
  paramDesc?: InputMaybe<Scalars['String']>;
};

export type PlayerReport2Info = {
  __typename?: 'PlayerReport2Info';
  /** 플레이어 보고서 함수 (모든 플레이어보고서 함수) */
  functions: Array<PlayerReport2Function>;
  /** 작품에 운영 중인 플레이어 보고서 */
  playerReport2?: Maybe<PlayerReport2>;
  /** 플레이어 보고서 렌더블럭 (모든 플레이어 보고서 렌더블럭) */
  renderBlocks: Array<PlayerReport2RenderBlock>;
};

/** 플레이어 분석 보고서 v2 용 렌더블록 HTML(nunjucks) 조각 */
export type PlayerReport2RenderBlock = {
  __typename?: 'PlayerReport2RenderBlock';
  /** HTML Code template */
  code: Scalars['String'];
  /** 복붙할 코드 샘플 */
  codeSample: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 설명 */
  desc: Scalars['String'];
  /** 외부로 보여지지 않는 렌더블록 */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** 실제 사용할 렌더블록의 문자열 아이디 */
  name: Scalars['String'];
  /** 내부 아이디 */
  prbId: Scalars['Int'];
  /** code 에 적용하여 보여줄 수 있는 디폴트 샘플 데이터 */
  sampleData: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** PlayerReport2RenderBlock 항목을 생성할 때 필요한 데이터 */
export type PlayerReport2RenderBlockCreationInput = {
  /** HTML Code template */
  code: Scalars['String'];
  /** 복붙할 코드 샘플 */
  codeSample: Scalars['String'];
  /** 설명 */
  desc: Scalars['String'];
  /** 외부로 보여지지 않는 렌더블록 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 실제 사용할 렌더블록의 문자열 아이디 */
  name: Scalars['String'];
  /** code 에 적용하여 보여줄 수 있는 디폴트 샘플 데이터 */
  sampleData: Scalars['String'];
};

/** PlayerReport2RenderBlock 항목을 업데이트할 때 필요한 데이터 */
export type PlayerReport2RenderBlockUpdateInput = {
  /** HTML Code template */
  code?: InputMaybe<Scalars['String']>;
  /** 복붙할 코드 샘플 */
  codeSample?: InputMaybe<Scalars['String']>;
  /** 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 내부 아이디 */
  id: Scalars['Int'];
  /** 외부로 보여지지 않는 렌더블록 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 실제 사용할 렌더블록의 문자열 아이디 */
  name?: InputMaybe<Scalars['String']>;
  /** code 에 적용하여 보여줄 수 있는 디폴트 샘플 데이터 */
  sampleData?: InputMaybe<Scalars['String']>;
};

/** PlayerReport2 항목을 업데이트할 때 필요한 데이터 */
export type PlayerReport2UpdateInput = {
  /** 템플릿 코드 */
  code?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 분석보고서 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 보고서의 og:description */
  ogDesc?: InputMaybe<Scalars['String']>;
  /** 보고서의 og:image */
  ogImageFile?: InputMaybe<Scalars['Upload']>;
  /** 보고서의 og:title */
  ogTitle?: InputMaybe<Scalars['String']>;
  /** 테스트에 사용할 샘플 데이터 */
  sampleData?: InputMaybe<Scalars['String']>;
};

/** 사용자가 설정한 기타 인물 정보 */
export type PlayingCharacter = {
  __typename?: 'PlayingCharacter';
  /** 기타 인물 원래 캐릭터 정보 */
  character: Chr;
  /** 기타 인물 원래 캐릭터 ID */
  characterId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 사용자가 설정한 기타 인물 이름 */
  firstName?: Maybe<Scalars['String']>;
  /** 사용자가 설정한 기타 인물 성 */
  lastName?: Maybe<Scalars['String']>;
  /** 사용자가 play 하고 있는 userPlayedChapter */
  playingChapter: UserPlayedChapter;
  /** 사용자가 play 하고 있는 userPlayedChapter ID */
  playingChapterId: Scalars['Int'];
  /** 아이디 */
  playingCharacterId: Scalars['Int'];
  /** 스토리게임 상반신 이미지 */
  portraitImageFile?: Maybe<File>;
  /** 사용자가 선택한 프로필 이미지 파일 */
  profileImageFile?: Maybe<File>;
  /** 사용자가 선택한 프로필 이미지 파일 ID */
  profileImageFileId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

/** 작품 zip 파일 업로드를 위한 presigned url */
export type PresignedUrlForStoryImport = {
  __typename?: 'PresignedUrlForStoryImport';
  /** hash */
  hash: Scalars['String'];
  /** presigned Url */
  presignedUrl: Scalars['String'];
};

/** 각 스토리에 속한 preview 엔딩. 유저가 볼 수 없다. */
export type PreviewEnding = {
  __typename?: 'PreviewEnding';
  createdAt: Scalars['DateTime'];
  /** 설정한 엔딩 도착률 범위 ID */
  endingRateRangeId?: Maybe<Scalars['Int']>;
  /** preview 엔딩 이미지 */
  imageFile?: Maybe<File>;
  /** preview 엔딩 이미지 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 사용자에게 보여주지 않고 숨겨둘 것인가? */
  isHidden: Scalars['Boolean'];
  /** preview 엔딩 이름 */
  name?: Maybe<Scalars['String']>;
  /** 아이디 */
  previewEndingId: Scalars['Int'];
  /** 설정한 엔딩 도착률 범위 레벨 보통, 희소, 매우 희소, 최고 희소 */
  rateLevel?: Maybe<Ending_Rate_Range_Level>;
  /** preview 엔딩이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** preview 엔딩이 속한 스토리 */
  story: Story;
  /** preview 엔딩이 속한 스토리 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** preview 엔딩 정보 */
export type PreviewEndingOutput = {
  __typename?: 'PreviewEndingOutput';
  /** 작품내에 모든 preview 엔딩의 갯수 */
  numTotalPreviewEnding: Scalars['Int'];
  /** preview 엔딩 */
  previewEndings: Array<PreviewEnding>;
};

/** preview 엔딩 항목을 추가 및 업데이트 할 때 필요한 데이터 */
export type PreviewEndingPropsInput = {
  /** 설정한 엔딩 도착률 범위 ID */
  endingRateRangeId?: InputMaybe<Scalars['Int']>;
  /** 아이디 */
  id?: InputMaybe<Scalars['Int']>;
  /** preview 엔딩 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 사용자에게 보여주지 않고 숨겨둘 것인가? */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** preview 엔딩 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** preview 엔딩이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** preview 엔딩이 속한 스토리 ID */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 가격 설정 input 값 */
export type PriceSettingInput = {
  /** 광고 설정 여부 */
  adPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 광고 설정 (IAdPerChapterSetting stringify) */
  adPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 발행 후 기간 무료 여부 */
  afterSomePeriodFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 발행 후 기간 무료 설정 (IAfterSomePeriodFreePerChapterSetting stringify) */
  afterSomePeriodFreePerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 기간 무료 여부 */
  freedAtPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 기간 무료 설정 (IFreedAtPerChapterSetting stringify) */
  freedAtPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 개별 회차 구매 여부 */
  paidPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 개별 회차 구매 설정 (IPaidPerChapterSetting stringify) */
  paidPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 통구매 판매 여부 */
  paidPerStoryActivated?: InputMaybe<Scalars['Boolean']>;
  /** 통구매 판매 설정 (IPaidPerStorySetting stringify) */
  paidPerStorySetting?: InputMaybe<Scalars['String']>;
  /** 타임리프 무료 여부 */
  timeLeapFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 타임리프 무료 설정 (ITimeLeapFreePerChapterSetting stringify) */
  timeLeapFreePerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 기스무 여부 */
  waitFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 기스무 설정 (IWaitFreePerChapterSetting stringify) */
  waitFreePerChapterSetting?: InputMaybe<Scalars['String']>;
};

/** 유저 히스토리 데이터 5년 보관: Ledger */
export type PrivacyHistoryLedger = {
  __typename?: 'PrivacyHistoryLedger';
  /** 코인의 증감 */
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  ledgerId: Scalars['Int'];
  /** 클라이언트 플랫폼 종류 (iOS, Android, Web) */
  platform?: Maybe<Scalars['String']>;
  /** 코인 사용/획득 로그 */
  reason: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: PrivacyHistoryUser;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 유저 히스토리 데이터 5년 보관: SSO */
export type PrivacyHistorySso = {
  __typename?: 'PrivacyHistorySSO';
  createdAt: Scalars['DateTime'];
  /** 해당 SSO Provider 가 전달한 프로필 정보 */
  profile: Scalars['String'];
  /** 편의용 내부 아이디 */
  sId: Scalars['Int'];
  /** ID from SSO Provider */
  ssoId: Scalars['String'];
  /** SSO Provider */
  ssoProvider: Sso_Provider;
  updatedAt: Scalars['DateTime'];
  /** 연동된 사용자 */
  user: PrivacyHistoryUser;
  /** 연동된 사용자 Id */
  userId: Scalars['Int'];
};

/** 유저 히스토리 데이터 5년 보관: User */
export type PrivacyHistoryUser = {
  __typename?: 'PrivacyHistoryUser';
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 탈퇴한 사용자의 탈퇴 이유 */
  deactivateReason?: Maybe<Scalars['String']>;
  /** 로그인용 이메일 주소 */
  email: Scalars['String'];
  /** 사용자가 익명 사용자인가? */
  isAnonymous: Scalars['Boolean'];
  /** 사용자가 푸시 동의를 받아야 하는 상황인가? */
  isPushInfoRequired: Scalars['Boolean'];
  /** 탈퇴한 경우 탈퇴시각 */
  leftAt?: Maybe<Scalars['DateTime']>;
  /** 마케팅 푸시가 허용된 경우, 허용시각 */
  marketingPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: Maybe<Scalars['String']>;
  /** 야간푸시가 허용된 경우, 허용시각 */
  nightPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 푸시가 허용된 경우, 허용시각 */
  pushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 익명 사용자가 일반 사용자로 변경된 시각 */
  registeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
  /** 이메일 인증시, 인증된 시각 */
  verifiedAt?: Maybe<Scalars['DateTime']>;
};

/** 유저 히스토리 데이터 5년 보관: UserPurchasedCoin */
export type PrivacyHistoryUserPurchasedCoin = {
  __typename?: 'PrivacyHistoryUserPurchasedCoin';
  createdAt: Scalars['DateTime'];
  /** 인앱 상품명 */
  inappProductName: Scalars['String'];
  /** 구매 ID. AOS는 orderId, iOS는 transactionId */
  purchaseId: Scalars['String'];
  /** 구매한 코인의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: Maybe<Scalars['String']>;
  /** 아이디 */
  upcId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 코인을 구매한 유저 정보 */
  user: PrivacyHistoryUser;
  /** 코인을 구매한 유저의 ID */
  userId: Scalars['Int'];
};

/** 유저 히스토리 데이터 5년 보관: UserSubsCoin */
export type PrivacyHistoryUserSubsCoin = {
  __typename?: 'PrivacyHistoryUserSubsCoin';
  createdAt: Scalars['DateTime'];
  /** 정액권 만료 여부 */
  expired: Scalars['Boolean'];
  /** 정액권이 만료된 날짜 */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** 정액권의 구독 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: Maybe<Scalars['String']>;
  /** 구독 상품명 */
  subsProductName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  uscId: Scalars['Int'];
  /** 구매한 유저 정보 */
  user: PrivacyHistoryUser;
  /** 구매한 유저의 ID */
  userId: Scalars['Int'];
  /** 정액권 만료 예정 날짜 */
  willExpireAt: Scalars['DateTime'];
};

/** 유저 히스토리 데이터 5년 보관: 아임포트 결제 */
export type PrivacyIamportPayments = {
  __typename?: 'PrivacyIamportPayments';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  iamportPaymentsId: Scalars['Int'];
  /** 매 결제 요청마다 서버에서 생성하는 merchant_uid */
  merchantUID: Scalars['String'];
  /** 아임포트 결제 결과 데이터 */
  paymentData?: Maybe<Scalars['String']>;
  /** 상품을 구분하는 고유 아이디 */
  productId: Scalars['String'];
  /** 결제 진행 상태 */
  state: Imp_State;
  updatedAt: Scalars['DateTime'];
  /** 결제를 요청한 유저 */
  user: PrivacyHistoryUser;
  /** 결제를 요청한 유저의 아이디 */
  userId: Scalars['Int'];
};

/** 유저 히스토리 데이터 5년 보관: 토스페이먼츠 결제 */
export type PrivacyTossPayments = {
  __typename?: 'PrivacyTossPayments';
  createdAt: Scalars['DateTime'];
  /** 인앱코인 상품 ID InappCoin 테이블 */
  inappCoinId: Scalars['Int'];
  /** 매 결제 요청마다 서버에서 생성하는 merchant_uid */
  merchantUID: Scalars['String'];
  /** 결제 타입 OVERSEAS: 해외(해와카드사 + 페이팔) DOMESTIC: 국내(국내카드사 + 휴대폰 등등) */
  payType: Toss_Payments_Pay_Type;
  /** 토스페이먼츠 결제 결과 데이터 */
  paymentData?: Maybe<Scalars['String']>;
  /** 상품을 구분하는 고유 아이디 */
  productId: Scalars['String'];
  /** 결제 진행 상태 */
  state: Toss_Payments_Status;
  /** 아이디 */
  tossPaymentsId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 결제를 요청한 유저 */
  user: PrivacyHistoryUser;
  /** 결제를 요청한 유저의 아이디 */
  userId: Scalars['Int'];
};

/** 유저 히스토리 데이터 5년 보관: 사용자 package 구매이력 */
export type PrivacyUserPurchasedPackage = {
  __typename?: 'PrivacyUserPurchasedPackage';
  createdAt: Scalars['DateTime'];
  /** 유저가 구매한 패키지의 타이틀 */
  inappPackageTitle: Scalars['String'];
  /** 구매 ID. AOS는 orderId, iOS는 transactionId */
  purchaseId: Scalars['String'];
  /** 구매한 패키지의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 패키지를 구매한 유저 정보 */
  user: PrivacyHistoryUser;
  /** 패키지를 구매한 유저의 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userPurchasedPackageId: Scalars['Int'];
};

/** 유저 히스토리 데이터 5년 보관: 사용자 SkipAds 구매이력 */
export type PrivacyUserPurchasedSkipAds = {
  __typename?: 'PrivacyUserPurchasedSkipAds';
  createdAt: Scalars['DateTime'];
  /** 만료가 되거나 추가 구매로 deactivated 된 시각 */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  /** 구매한 광고 스킵 상품의 타이틀 */
  skipAdsTitle: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 구매한 유저 */
  user: PrivacyHistoryUser;
  /** 구매한 유저 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userPurchasedSkipAdsId: Scalars['Int'];
  /** 만료 예정 시각. 구매 시 계산되어 적용 됨 */
  willExpireAt: Scalars['DateTime'];
};

/** 공유용 엔딩 정보 (챕터) */
export type PublicChapterEnding = {
  __typename?: 'PublicChapterEnding';
  /** 작품 회차 index */
  chapterIndex: Scalars['Int'];
  /** 챕터명 */
  chapterName: Scalars['String'];
  /** 엔딩 도착률 */
  endingArrivalRate?: Maybe<Scalars['Float']>;
  /** 엔딩 카드 리스트 */
  endingCardList: Array<PublicEndingCardInfo>;
  /** 엔딩 획득 날짜 */
  endingCreatedAt: Scalars['DateTime'];
  /** 엔딩 설명 */
  endingDescription: Scalars['String'];
  /** 엔딩 이미지 링크 */
  endingImageLink?: Maybe<Scalars['String']>;
  /** 엔딩명 */
  endingName: Scalars['String'];
  /** 엔딩 도착률 범위 */
  endingRateLevel?: Maybe<Ending_Rate_Range_Level>;
  /** 해당 챕터의 모든 엔딩의 갯수 */
  numTotalEndingInChapter: Scalars['Int'];
  /** 엔딩 퍼센티지 */
  percentage: Scalars['Float'];
  /** 작품 ID */
  storyId: Scalars['Int'];
  /** 작품명 */
  storyName: Scalars['String'];
  /** 유저 닉네임 */
  userName: Scalars['String'];
};

/** 공유용 엔딩 카드 정보 */
export type PublicEndingCardInfo = {
  __typename?: 'PublicEndingCardInfo';
  /** 엔딩 카드 도착률 */
  arrivalRate?: Maybe<Scalars['Float']>;
  /** 캐릭터 이미지 링크 */
  characterImageLink?: Maybe<Scalars['String']>;
  /** 엔딩 선택 기로 횟수 */
  choiceCount?: Maybe<Scalars['Int']>;
  /** 엔딩 컬렉션 수집률 */
  endingCollectRate?: Maybe<Scalars['Float']>;
  /** 엔딩 도착률 범위 */
  rateLevel?: Maybe<Ending_Rate_Range_Level>;
  /** 엔딩 카드 타입 */
  type: Ending_Card_Type;
};

/** 공용 리소스 카테고리 */
export type PublicResourceCategory = {
  __typename?: 'PublicResourceCategory';
  createdAt: Scalars['DateTime'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** id */
  pcrId: Scalars['Int'];
  /** 노출 우선순위 (값이 클수록 노출순위 높음) */
  showOrder: Scalars['Int'];
  /** 공용리소스 타입 (삽화이미지, 배경이미지, 효과음, 배경음악, 인물, 아이템 */
  type: Public_Resource_Type;
  updatedAt: Scalars['DateTime'];
};

/** 공용리소스 카테고리 생성하는데 필요한 입력값 */
export type PublicResourceCategoryCreationInput = {
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 노출 우선순위 */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 리소스 타입 */
  type: Public_Resource_Type;
};

/** 공용리소스 카테고리 수정하는데 필요한 입력값 */
export type PublicResourceCategoryUpdateInput = {
  /** 공용리소스 아이디 */
  id: Scalars['Int'];
  /** 카테고리 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 노출 우선순위 */
  showOrder?: InputMaybe<Scalars['Int']>;
};

/** 공용리소스 파일 */
export type PublicResourceFile = {
  __typename?: 'PublicResourceFile';
  /** 공용리소스 카테고리 */
  category: PublicResourceCategory;
  /** 공용리소스 카테고리 아이디 */
  categoryId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  height: Scalars['Int'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  /** 해당 리소스에 대한 이름 (관리용) - 키값. 변경되면 안됨. */
  resName: Scalars['String'];
  /** 공용리소스 타입 */
  resourceType: Public_Resource_Type;
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

/** 공용리소스 파일 정보를 수정하는데 필요한 데이터 */
export type PublicResourceFileUpdateInput = {
  /** 카테고리 아이디 */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** 공용리소스 파일 아이디 */
  id: Scalars['Int'];
};

/** 공용리소스 파일 생성하는데 필요한 데이터 */
export type PublicResourceFilesCreationInput = {
  /** 카테고리 아이디 */
  categoryId: Scalars['Int'];
  /** 파일 */
  files: Array<Scalars['Upload']>;
  /** 리소스 타입 */
  resourceType?: InputMaybe<Public_Resource_Type>;
};

/** 공유용 작품 정보 */
export type PublicStory = {
  __typename?: 'PublicStory';
  /** 연령대 */
  ageTarget: Scalars['String'];
  /** 작가명 */
  authorName: Scalars['String'];
  /** 커버 이미지 링크 */
  coverImageLink?: Maybe<Scalars['String']>;
  /** 작품 설명 */
  desc: Scalars['String'];
  /** 작품명 */
  name: Scalars['String'];
  /** 작품 ID */
  storyId: Scalars['Int'];
  /** 토픽 */
  topic: Scalars['String'];
};

/** 스튜디오에서 Chapter 발행하는데 필요한 입력값 */
export type PublishChapterForStudioInput = {
  /** Chapter Id */
  chapterId: Scalars['Int'];
  /** 예약 날짜 */
  willPublishAt?: InputMaybe<Scalars['DateTime']>;
};

/** 발행된 스토어 페이지 섹션 관련 데이터를 관리하는 엔티티. 한번 발행될 때마다 버전이 증가한다. */
export type PublishedStorePageSection = {
  __typename?: 'PublishedStorePageSection';
  createdAt: Scalars['DateTime'];
  /** 캐싱키 값 */
  key: Scalars['String'];
  /** 아이디 */
  publishedStorePageSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 값 (JSON string) */
  value: Scalars['String'];
  /** 캐싱 버전 */
  version: Scalars['Int'];
};

/** 발행된 스토어 탭 관련 데이터를 관리하는 엔티티. 한번 발행될 때마다 버전이 증가한다. */
export type PublishedStorePageTab = {
  __typename?: 'PublishedStorePageTab';
  createdAt: Scalars['DateTime'];
  /** 캐싱키 값 */
  key: Scalars['String'];
  /** 아이디 */
  publishedStorePageTabId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 값 (JSON string) */
  value: Scalars['String'];
  /** 캐싱 버전 */
  version: Scalars['Int'];
};

/** 스튜디오에서 발행된 스토리게임 섹션 및 관련된 데이터를 관리하는 엔티티. 한번 발행될 때마다 버전이 1씩 증가한다. */
export type PublishedStorygameSection = {
  __typename?: 'PublishedStorygameSection';
  createdAt: Scalars['DateTime'];
  /** 캐싱키 값 */
  key: Scalars['String'];
  /** 아이디 */
  publishedStorygameSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 값 (JSON string) */
  value: Scalars['String'];
  /** 캐싱 버전 */
  version: Scalars['Int'];
};

/** (deprecated) PublishedStorygameSection으로 통합되었습니다. 추후 삭제 가능성이 높습니다. */
export type PublishedStorygameSectionCountry = {
  __typename?: 'PublishedStorygameSectionCountry';
  createdAt: Scalars['DateTime'];
  /** 캐싱키 값 */
  key: Scalars['String'];
  /** 아이디 */
  publishedStorygameSectionCountryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 값 (JSON string) */
  value: Scalars['String'];
  /** 캐싱 버전 */
  version: Scalars['Int'];
};

/** InappPackage 업데이트를 위한 input */
export type PurchasePackageInput = {
  /** mocking을 위한 필드로 영수증 검증 무조건 통과 */
  inappProductId: Scalars['String'];
  /** 모바일 기기의 os */
  os: Os;
  /** 구매한 코인의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: InputMaybe<Scalars['String']>;
};

/** SkipAds 구매에 필요한 input */
export type PurchaseSkipAdsInput = {
  /** 최종 구매 가격으로 유저가 본 가격. 가격 수정등으로 유저가 예상치 못한 가격으로 결제되는 것을 막기 위해 필요 */
  price: Scalars['Int'];
  /** 아이디 */
  skipAdsId: Scalars['Int'];
};

/** 스토리 통구매를 위한 input  */
export type PurchaseStoryInput = {
  /** 통구매에 필요한 티켓 수 */
  price: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
};

/** 푸시 동의 여부 */
export type PushAgreementInfo = {
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled: Scalars['Boolean'];
  /** 야간 푸시 수신 여부 */
  nightPushEnabled: Scalars['Boolean'];
  /** 일반 푸시 수신 여부 */
  pushEnabled: Scalars['Boolean'];
};

/** 푸쉬 알림리스트를 관리 */
export type PushNotification = {
  __typename?: 'PushNotification';
  /** 푸쉬 카테고리 */
  category: Push_Category;
  /** 푸쉬 내용 */
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 알림을 목록에서 삭제한 시각. 디비에서 삭제되지는 않고 리스트에 보이지 않음 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** (deprecated) 푸쉬 메시지의 언어 코드 */
  langCode: Scalars['String'];
  /** 푸쉬 메시지의 언어 */
  language?: Maybe<Language>;
  /** 푸쉬 메시지의 언어 코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 메뉴 이미지 */
  menuImageFile?: Maybe<File>;
  /** 메뉴 이미지 아이디 */
  menuImageFileId?: Maybe<Scalars['Int']>;
  /** 아이디 */
  pushNotificationId: Scalars['Int'];
  /** 목록을 눌렀을 때 이동하는 app scheme uri */
  scheme?: Maybe<Scalars['String']>;
  /** content에 style이 적용되는 텍스트 */
  styleText?: Maybe<Scalars['String']>;
  /** 목록을 눌렀을 때 이동하는 web url */
  targetURL?: Maybe<Scalars['String']>;
  /** 제목 */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 푸쉬를 받은 유저 */
  user: User;
  /** 푸쉬를 받은 유저 아이디 */
  userId: Scalars['Int'];
};

/** PushNotification 생성을 위한 input */
export type PushNotificationCreationInput = {
  /** 푸쉬 카테고리 */
  category: Push_Category;
  /** 푸쉬 내용 */
  content: Scalars['String'];
  /** (deprecated) 푸쉬 메시지의 언어 코드 */
  langCode: Scalars['String'];
  /** 푸쉬 메시지의 언어 코드 */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 메뉴 이미지 아이디 */
  menuImageFileId?: InputMaybe<Scalars['Int']>;
  /** 목록을 눌렀을 때 이동하는 app scheme uri */
  scheme?: InputMaybe<Scalars['String']>;
  /** content에 style이 적용되는 텍스트 */
  styleText?: InputMaybe<Scalars['String']>;
  /** 목록을 눌렀을 때 이동하는 web url */
  targetURL?: InputMaybe<Scalars['String']>;
  /** 제목 */
  title: Scalars['String'];
  /** 푸쉬를 받은 유저 아이디 */
  userId: Scalars['Int'];
};

/** PushNotification 업데이트를 위한 input */
export type PushNotificationUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** 현재 서비스 설정을 반환합니다. */
  appConfig: AppConfig;
  /** 해당 이메일로 가입된 사용자가 존재하는지 체크합니다. */
  checkEmail: CheckEmailResponse;
  copyChapterComments: OkResponse;
  /** 토스 페이먼츠 결제 완료 여부 확인 */
  ensureTossPaymentsDone: OkResponse;
  /**
   * 웹소설 바텀탭의 장르를 리턴합니다
   * @deprecated 글로벌 대응으로 epubStoryGenresV3를 사용해주세요.
   */
  epubStoryGenres: Array<ChallengeStoryGenre>;
  /** 웹소설 바텀탭의 장르를 리턴합니다 V3 */
  epubStoryGenresV3: Array<StoryGenre>;
  /** 현재 플레이 중인 챕터에서의 모든 아이템을 가져온다 */
  fetchAllUserItems: Array<InventoryItem>;
  forceMigrateChrProfile: Scalars['String'];
  /** 클라이언트에서 활성화된 BottomBanner 리스트를 반환합니다. */
  getActiveBottomBannersForClient: Array<BottomBanner>;
  /** 개별 AdNetworkActivation의 정보를 가져옵니다. */
  getAdNetworkActivationForStudio: AdNetworkActivation;
  /** 개별 AdminLog 의 정보를 가져옵니다. */
  getAdminLog: AdminLog;
  /** 주어진 날짜 사이의 서비스 통계치를 반환합니다. */
  getAdminStatDailyInRange: Array<StatDaily>;
  /** [어드민 기능] 파우스트 서버에서 자연어 애정도 응답값을 리턴합니다. */
  getAffectionByAdmin: GetAffectionByAdminOutput;
  /** 모든 스플래쉬 이미지를 반환합니다. */
  getAllAppSplashImages: Array<AppSplashImage>;
  /** 조건에 맞는 모든 배너를 리턴합니다.(앱 API) */
  getAllBannersByApp: Array<Banner>;
  /** 조건에 맞는 모든 배너를 리턴합니다.(웹 API) */
  getAllBannersByWeb: Array<Banner>;
  /** 조건에 맞는 모든 배너를 리턴합니다.(앱 API) */
  getAllBundleBannersByApp: Array<BundleBanner>;
  /** 조건에 맞는 모든 배너를 리턴합니다.(웹 API) */
  getAllBundleBannersByWeb: Array<BundleBanner>;
  /** 서비스 중인 콘텐츠 언어 목록을 리턴합니다. */
  getAllContentsLanguagesByAdmin: Array<Language>;
  /** 스토어 페이지 탭 목록을 가져옵니다. */
  getAllStorePageTabs: Array<StorePageTab>;
  /** 스토어 페이지 탭 목록을 가져옵니다.(어드민용) */
  getAllStorePageTabsForAdmin: Array<StorePageTab>;
  /** 스토어 페이지 탭 목록을 가져옵니다.(미리보기용) */
  getAllStorePageTabsForPreview: Array<StorePageTab>;
  /** 특정 스토리의 지정한 파일 타입의 모든 미리보기 리소스를 가져옵니다. */
  getAllStoryPreviews: Array<StoryPreview>;
  /** 작품에 속한 모든 StoryTag를 스튜디오에서 설정한 순서대로 반환합니다. */
  getAllStoryTags: Array<StoryTag>;
  /** 스튜디오에서 섹션 미리보기 데이터를 가져옵니다 */
  getAllStorygameSectionPreviews: Array<StorygameSectionView>;
  /** 스튜디오에서 발행한 섹션에 대한 데이터를 가져옵니다 */
  getAllStorygameSectionViews: Array<StorygameSectionView>;
  /** 스토리게임 탭 내 모든 섹션 설정 정보를 가져옵니다. */
  getAllStorygameSections: Array<StorygameSection>;
  /** 스토리게임의 모든 탭 정보를 순서대로 가져옵니다. */
  getAllStorygameTabs: Array<StorygameTab>;
  /** 앱 이벤트를 가져온다 */
  getAppEvent: AppEvent;
  /** 개별 AppSplashImage의 정보를 가져옵니다. */
  getAppSplashImage?: Maybe<AppSplashImage>;
  /** 개별 AttendanceCoin의 정보를 가져옵니다. */
  getAttendanceCoin: AttendanceCoin;
  /** 개별 Banner의 정보를 가져옵니다. */
  getBanner: Banner;
  /** 특정 보너스 티켓 코드를 가져옵니다 */
  getBonusTicketCode: BonusTicketCode;
  /** 개별 BottomBanner의 정보를 가져옵니다. */
  getBottomBanner: BottomBanner;
  /** 개별 BundleBanner의 정보를 가져옵니다. */
  getBundleBanner: BundleBanner;
  /** isHidden이 false인 BundleFeatured 중 랜덤으로 하나를 가져옵니다 */
  getBundleFeatured?: Maybe<BundleFeatured>;
  /** featuredId에 해당하는 featured를 가져옵니다 */
  getBundleFeaturedByAdmin: BundleFeatured;
  /** 개별 Chapter 의 정보를 가져옵니다. */
  getChapter: Chapter;
  /** 클라이언트에서 조건에 맞는 챕터 댓글을 반환합니다. */
  getChapterComment: ChapterComment;
  /** 어드민에서 조건에 맞는 챕터 댓글을 반환합니다. */
  getChapterCommentByAdmin: ChapterComment;
  /** 개별 ChapterDraft 의 정보를 가져옵니다. */
  getChapterDraft: ChapterDraft;
  /** 초안 백업 데이터를 반환합니다. */
  getChapterDraftArchive: ChapterDraftArchive;
  /** 개별 ChapterOption의 정보를 가져옵니다. */
  getChapterOption: ChapterOption;
  /** 개별 선택분기 의 정보를 가져옵니다. */
  getChoice: Choice;
  /** 설정된 입력 텍스트 룰베이스를 조회합니다. */
  getChoiceAffectionRule: ChoiceAffectionRuleResponse;
  /** 개별 ChoiceFromPlayerClass의 정보를 가져옵니다. */
  getChoiceFromPlayerClass: ChoiceFromPlayerClass;
  /** 클라이언트가 채팅에서 이어보기 할 때, 선택지 총 댓글 개수와 top1 댓글 가져오기 */
  getChoicesForContinue: Array<Choice>;
  /** 개별 캐릭터의 정보를 가져옵니다. */
  getChr: Chr;
  /**
   * 개별 구매한 회차를 제외한 나머지 회차를 구매를 위해 필요한 코인 개수를 내려줍니다.
   * @deprecated 호출 방식변경으로 사용하지 않습니다. 삭제후 story > enabledPurchasedChapter 값을 참고해주세요.
   */
  getCoinAmountForBuyRemainingChapters: GetCoinAmountForBuyRemainingChaptersOutput;
  getCoinProduct: CoinProduct;
  /** 개별 Color의 정보를 가져옵니다. */
  getColor: Color;
  /** 작품의 전환율 통계 정보를 반환합니다. */
  getConversionStatOfStory: ConversionOfStoryResponse;
  /** 현재 진행중인 인터랙티브 공모전 정보를 반환합니다. */
  getCurrentContest?: Maybe<ContestInfo>;
  /** 현재 진행중인 공모전의 배너 정보를 반환합니다. */
  getCurrentContestBanner?: Maybe<ContestBannerInfo>;
  /** 현재 진행중인 인터랙티브 공모전 정보를 반환합니다. ForStudio */
  getCurrentContestForStudio?: Maybe<ContestInfo>;
  /** 현재 진행중인 웹소설 공모전 정보를 반환합니다. 현재 공모전 기간이 아니면 null을 반환합니다 */
  getCurrentEpubContest?: Maybe<ContestInfo>;
  /** 현재 진행중인 웹소설 공모전 정보를 반환합니다. For Studio */
  getCurrentEpubContestForStudio?: Maybe<ContestInfo>;
  /** 다날 주문 상태를 확인합니다. */
  getDanalPayment: DanalPayment;
  /** 타입으로 단일 DirectAds의 정보를 가져옵니다. (앱) */
  getDirectAds?: Maybe<DirectAds>;
  /** 아이디로 단일 DirectAds의 정보를 가져옵니다. (어드민) */
  getDirectAdsByAdmin?: Maybe<DirectAds>;
  /** 개별 엔딩의 정보를 가져옵니다. */
  getEnding: Ending;
  /** 개별 챕터의 엔딩 정보를, 공개로 가지고 옵니다. */
  getEndingByShareKey: PublicChapterEnding;
  /** 조건에 맞는 엔딩 카드를 반환합니다. */
  getEndingCard: EndingCard;
  /** chapterId와 ending > name 파라미터로 엔딩의 정보를 가져옵니다. */
  getEndingInfo: Ending;
  /** 개별 EndingShowsProperty 정보를 가져옵니다. */
  getEndingShowsPropertyByAdmin: EndingShowsProperty;
  /** 특정 작품의 엔딩 통계 정보를 조회합니다. */
  getEndingStats: EndingsStatOutput;
  /** 특정 챕터의 엔딩 통계 정보를 조회합니다. */
  getEndingStatsPerChapter: EndingsStatOutput;
  /** 개별 ExposedSectionStory의 정보를 가져옵니다. */
  getExposedSectionStory: ExposedSectionStory;
  /** 개별 UserHasFCMToken의 정보를 가져옵니다. */
  getFCMTokenByAdmin: UserHasFcmToken;
  /** 원격 스크립트 실패시, 실행되는 구문 저장소를 조회합니다. */
  getFallbackRemoteScriptByStudio: FallbackRemoteScript;
  /** isHidden이 false인 Featured 중 랜덤으로 하나를 가져옵니다 */
  getFeatured?: Maybe<Featured>;
  /** featuredId에 해당하는 featured를 가져옵니다 */
  getFeaturedByAdmin: Featured;
  /** 단일 HomeSection을 반환합니다. */
  getHomeSection: HomeSection;
  /** 개별 HomeSectionStory의 정보를 가져옵니다. */
  getHomeSectionStory: HomeSectionStory;
  /** 타입에 따른 HomeSection 리스트를 반환합니다. */
  getHomeSections: Array<HomeSection>;
  /** 개별 ImageFile 의 정보를 가져옵니다. */
  getImageFile: ImageFile;
  /** 구글 스프레드시트 Import를 위한 값을 받습니다. */
  getImportStudioGoogleInfo: GetImportStudioGoogleInfoOutput;
  getInappCoin: InappCoin;
  /** 개별 InappPackage의 정보를 가져옵니다. */
  getInappPackage: InappPackage;
  /** 개별 IpSourcing의 정보를 가져옵니다. */
  getIpSourcing: IpSourcing;
  /** 작품 기반 추천 스토리를 반환합니다. */
  getItemBasedRecommendation: Array<Story>;
  /** 개별 Language의 정보를 가져옵니다. */
  getLanguage: Language;
  /** 특정 챕터의 가장 최신 인스턴트 테스팅 데이더를 반환힙니다. (chapterId) */
  getLastTestSession?: Maybe<TestSession>;
  /** 마지막으로 설정된 웹소설 공모전 정보를 반환합니다. */
  getLatestEpubContest: ContestInfo;
  /** 특정 유저의 가장 최근에 등록된 UserHasFCMToken을 가져옵니다 */
  getLatestFCMTokenByAdmin: UserHasFcmToken;
  /** 개별 Ledger (장부) 의 정보를 가져옵니다. */
  getLedger: Ledger;
  /** 연재중, 완결, 미공개, 샘플 작품 목록의 개수를 조회합니다. */
  getListStoryCountsByStudio: ListStoryCounts;
  /** 연재중, 완결, 미공개, 샘플 작품 목록의 개수를 조회합니다. (version 2) */
  getListStoryCountsByStudioV2: ListStoryCounts;
  /** 주인공 온보딩 팝업을 조회합니다. */
  getMainChrOnboardingPopup?: Maybe<OnboardingPopup>;
  /** 개별 MonthlyBundle의 정보를 가져옵니다. */
  getMonthlyBundleByAdmin: MonthlyBundle;
  /** HelloBotServer 마이봇 시퀀스로 최근 7일간 못알아 먹는말 목록을 가져옵니다. */
  getMyBotMissRules: GetMyBotMissRulesOutput;
  /** HelloBotServer 마이봇의 시퀀스를 이름으로 찾아옵니다. */
  getMyBotSequenceByName: GetMyBotSequenceByNameOutput;
  /** Notice 항목을 일련번호로 읽어옵니다. */
  getNotice: Notice;
  /** NoticePopup 항목을 일련번호로 읽어옵니다. */
  getNoticePopup: NoticePopup;
  /** 앱/웹 내에서 보여지는 스토리플레이 약관을 반환합니다. */
  getNoticeTerms: NoticeTerms;
  /** 작품 및 회차의 댓글 통계를 조회하기 위한 입력값 */
  getNumCommentStatOfStory: NumCommentStatOfStoryResponse;
  /** 작품의 좋아요수 통계 정보를 반환합니다. */
  getNumLikeStatOfStory: NumLikeStatOfStoryResponse;
  /** 작품 및 회차의 조회수(플레이수) 통계 정보를 반환합니다. */
  getNumPlayStatOfStory: NumPlayStatOfStoryResponse;
  /** 온보딩 팝업을 가져옵니다. */
  getOnboardingPopup: OnboardingPopup;
  /** 온보딩 팝업을 가져옵니다. */
  getOnboardingPopupChoice: OnboardingPopupChoice;
  /** 개별 OnboardingPopupProfileImage의 정보를 가져옵니다. */
  getOnboardingPopupProfileImage: OnboardingPopupProfileImage;
  /** 개별 PackageCoin의 정보를 가져옵니다. */
  getPackageCoin: PackageCoin;
  /** 개별 PackageStory의 정보를 가져옵니다. */
  getPackageStory: PackageStory;
  /** 개별 PackageTimeSale의 정보를 가져옵니다. */
  getPackageTimeSale: PackageTimeSale;
  /** 개별 PlayerClass의 정보를 가져옵니다. */
  getPlayerClass: PlayerClass;
  /** 현재까지 진행된 플레이어 정보를 반환합니다. */
  getPlayerData: PlayerDataForPlayerReport2;
  /** 플레이어 보고서를 위한 플레이 데이터를 반환합니다. */
  getPlayerDataForPlayerReport2: PlayerDataForPlayerReport2;
  /** 개별 플레이어 보고서 (v2) 의 정보를 가져옵니다. */
  getPlayerReport2: PlayerReport2;
  /** 개별 플레이어 보고서에서 사용가능한 함수 (v2) 의 정보를 가져옵니다. */
  getPlayerReport2Function: PlayerReport2Function;
  /** 개별 플레이어 보고서에서 사용가능한 렌더블록 (v2) 의 정보를 가져옵니다. */
  getPlayerReport2RenderBlock: PlayerReport2RenderBlock;
  /** 개별 PlayingCharacter의 정보를 가져옵니다. */
  getPlayingCharacter: PlayingCharacter;
  /** s3의 presigned url을 받아옵니다. */
  getPresignedUrl: GetPresignedUrlOutput;
  /** s3의 presigned url을 받아옵니다. */
  getPresignedUrls: Array<GetPresignedUrlsOutput>;
  /** 개별 preview 엔딩의 정보를 가져옵니다. */
  getPreviewEnding: PreviewEnding;
  /** 공용리소스 카테고리 항목을 조회합니다. */
  getPublicResourceCategory: PublicResourceCategory;
  /** 공용리소스 파일을 조회합니다. */
  getPublicResourceFile: PublicResourceFile;
  /** 개별 PushNotification의 정보를 가져옵니다. */
  getPushNotification: PushNotification;
  /** 현재 보여주어야 하는 팝업 공지사항을 1개 보여줍니다. 반환값이 없을 수도 있으며, 이 경우 null 이 반환됩니다. */
  getRecentNoticePopup?: Maybe<NoticePopup>;
  /** 개별 RecommendSectionStory 정보를 가져옵니다. */
  getRecommendSectionStory: RecommendSectionStory;
  /** 개별 RecommendationForFirstUser의 정보를 가져옵니다. (어드민) */
  getRecommendationForFirstUser: RecommendationForFirstUser;
  /** 개별 RecommendationOnboarding의 정보를 가져옵니다. */
  getRecommendationOnboarding: RecommendationOnboarding;
  /** 개별 RecommendationOnboarding의 정보를 가져옵니다.(for client) */
  getRecommendationOnboardingsForClient: GetRecommendationOnboardingForClientOutput;
  /** 개별 RemoteScriptProvider의 정보를 가져옵니다. */
  getRemoteScriptProvider: RemoteScriptProvider;
  /** 원격 스크립트를 가져오는 api */
  getRemoteScriptStatements: GetRemoteScriptStatementsOutput;
  /** s3의 파일 url을 리턴해줍니다. (클라이언트 s3 파일 업로드 성공 확인용 api) */
  getS3UploadedFileUrl: GetS3UploadedFileUrlOutput;
  /** 개별 SFXFile 의 정보를 가져옵니다. */
  getSFXFile: SfxFile;
  /** 등장인물 설정된 AI 캐릭터 요청정보를 조회합니다. */
  getSelectedRequestAICharacter?: Maybe<RequestAiCharacter>;
  /** 서비스 중인 국가 목록을 리턴합니다. */
  getServiceCountriesByAdmin: Array<Country>;
  /** 서비스 중인 시스템 언어 목록을 리턴합니다. */
  getServiceSystemLanguagesByAdmin: Array<Language>;
  /** @deprecated getSignUpTermsV2로 대체되었습니다. */
  getSignUpTerms: SignUpTerms;
  /** 회원가입 시 보여줄 약관을 반환합니다. 한국, 그 외 국가에 따라 반환되는 약관이 다릅니다. */
  getSignUpTermsV2: SignUpTermsV2;
  /** 유사한 작품을 리턴합니다. 태그가 많이 겹치고, 플레이 수가 많은 순서로 정렬됩니다. */
  getSimilarStoryBundles: Array<StoryBundle>;
  /** 개별 SkipAds의 정보를 가져옵니다. */
  getSkipAds: SkipAds;
  /** 개별 StoreBundle 정보를 가져옵니다. */
  getStoreBundle: StoreBundle;
  /** 미노출을 포함한 개별 StoreBundle 정보를 가져옵니다.) */
  getStoreBundleForAdmin: StoreBundle;
  /** 개별 스토어 섹션 */
  getStorePageSection: StorePageSection;
  /** 개별 스토어 섹션(어드민용) */
  getStorePageSectionForAdmin: StorePageSection;
  /** 스토어 섹션 목록 */
  getStorePageSections: Array<StorePageSection>;
  /** 스토어 섹션 목록(어드민용) */
  getStorePageSectionsForAdmin: Array<StorePageSection>;
  /** 스토어 섹션 목록(미리보기용) */
  getStorePageSectionsForPreview: Array<StorePageSection>;
  /** 개별 스토어 결제(어드민용) */
  getStorePayment: StorePayment;
  /** 개별 스토어 결제(어드민용) */
  getStorePaymentForAdmin: StorePayment;
  /** 스토어 검색 설정 */
  getStoreSearchConfig: StoreSearchConfig;
  /** 스토어 검색 설정(어드민용) */
  getStoreSearchConfigForAdmin: StoreSearchConfig;
  /** 스토어 검색 설정(미리보기용) */
  getStoreSearchConfigForPreview: StoreSearchConfig;
  /** 스토어 SKU 목록 */
  getStoreSkus: StoreSkus;
  /**
   * 개별 Story 의 정보를 가져옵니다.
   * @deprecated 글로벌 적용으로 인해 getStoryV3로 대체되었습니다
   */
  getStory: Story;
  /** 개별 StoryBlockedCountry의 정보를 가져옵니다. */
  getStoryBlockedCountry: StoryBlockedCountry;
  /** 개별 StoryBundle의 정보를 가져옵니다. */
  getStoryBundle: StoryBundle;
  /** StoryBundle의 ServiceCountry의 정보와 Activated 된 국가 정보를 리턴합니다. */
  getStoryBundleCountriesInfoForStudio: GetStoryBundleCountriesInfoForStudioOutput;
  /** 개별 Story 의 정보를, 공개로 가지고 옵니다. */
  getStoryByShareKey: PublicStory;
  /** 작품 타입별 작품 수 */
  getStoryCountsByPlayType: StoryCounts;
  /** 작품 정보 추출을 위한 작품을 조회합니다. 서버에서 호출합니다. */
  getStoryForExport: Story;
  /** 개별 배경 정보를 가져옵니다. */
  getStoryHasBackground: StoryHasBackground;
  /** 개별 StoryHasRecommendSection의 정보를 가져옵니다. */
  getStoryHasRecommendSection: StoryHasRecommendSection;
  /** 개별 StoryHasStoryTag의 정보를 가져옵니다. */
  getStoryHasStoryTag: StoryHasStoryTag;
  /** 개별 StoryHasStudioFile 의 정보를 가져옵니다. */
  getStoryHasStudioFile: StoryHasStudioFile;
  /** 개별 StoryItem의 정보를 가져옵니다. */
  getStoryItem: StoryItem;
  /** 개별 StoryNewSectionCache의 정보를 가져옵니다. */
  getStoryNewSectionCache: StoryNewSectionCache;
  /** 개별 StoryPreview의 정보를 가져옵니다. */
  getStoryPreviewForStudio: StoryPreview;
  /** 개별 StoryPriceSetting의 정보를 가져옵니다. */
  getStoryPriceSetting: StoryPriceSetting;
  /** 개별 StoryProperty 항목을 가져옵니다. */
  getStoryProperty: StoryProperty;
  /** 개별 StoryProperty 항목을 가져옵니다. */
  getStoryPropertyForStudio: StoryProperty;
  /** 개별 StoryTag의 정보를 가져옵니다. */
  getStoryTag: StoryTag;
  /** 개별 StoryToggleReaction의 정보를 가져옵니다. */
  getStoryToggleReaction: StoryToggleReaction;
  /** 개별 Story 의 정보를 가져옵니다. */
  getStoryV3: Story;
  getStorygameAdditionalPrompts: StorygameCharacterAdditionalPrompts;
  /** 개별 StorygameBackground의 정보를 가져옵니다. */
  getStorygameBackground: StorygameBackground;
  /** 프롬프트 리스트 조회 */
  getStorygameCharacterAdditionalPrompt: StorygameCharacterAdditionalPrompt;
  /** 프롬프트 조회 */
  getStorygameCharacterPrompt: StorygameCharacterPrompt;
  /** 개별 StorygameCountry의 정보를 가져옵니다. */
  getStorygameCountry: StorygameCountry;
  getStorygamePrompts: StorygameCharacterPrompt;
  /** 개별 StorygameSection의 정보를 가져옵니다. */
  getStorygameSection: StorygameSection;
  /** 개별 StorygameSectionCountry의 정보를 가져옵니다. */
  getStorygameSectionCountry: StorygameSectionCountry;
  /** 스튜디오에서 발행한 섹션의 더보기 데이터를 가져옵니다 */
  getStorygameSectionViewMore?: Maybe<GetStorygameSectionViewMoreOutput>;
  /** 개별 StorygameTab의 정보를 가져옵니다. */
  getStorygameTab: StorygameTab;
  /** StudioReleaseItem 항목을 조회합니다. */
  getStudioReleaseItem: StudioReleaseItem;
  /** StudioReleaseVersion 항목을 조회합니다. */
  getStudioReleaseVersion: StudioReleaseVersion;
  /** 개별 Terms의 정보를 가져옵니다. */
  getTermsByAdmin: Terms;
  /** 특정 인스턴트 테스팅 데이터를 반환합니다. (testSessionId) */
  getTestSession: TestSession;
  /** 개별 TodaysHotUGC의 정보를 가져옵니다. */
  getTodaysHotUGC: TodaysHotUgc;
  /** 로그인한 사용자의 프로필을 반환합니다. */
  getUser?: Maybe<User>;
  /** 유저 기반 추천 스토리를 반환합니다. */
  getUserBasedRecommendation: Array<Story>;
  /** 특정 사용자의 프로필을 반환합니다. */
  getUserByAdmin: User;
  /** 개별 UserCertificatedAdult의 정보를 가져옵니다. */
  getUserCertificatedAdult: UserCertificatedAdult;
  /** 개별 선택분기의 유저의 선택지 결과를 가져옵니다. */
  getUserChoiceResult: GetUserChoiceResultOutput;
  /** 개별 UserGotPushMessage의 정보를 가져옵니다. */
  getUserGotPushMessage: UserGotPushMessage;
  /** 파우스트 서버에서 자연어 응답값을 바탕으로 선택지를 가져옵니다. */
  getUserInputChoice: GetUserInputChoiceOutput;
  /** hisotyId에 해당하는 좋아요/취소 데이터를 가져옵니다 */
  getUserLikesChapterCommentByAdmin: UserLikesChapterCommentHistory;
  /** 현재 사용자의 UserMetric 의 정보를 가져옵니다. */
  getUserMetric: UserMetric;
  /** 현재 사용자의 UserMetric 의 정보를 가져옵니다. */
  getUserMetricById: UserMetric;
  /** 특정 사용자의 프로필을 반환합니다. */
  getUserOfId: User;
  /** 개별 사용자의 작품 진행 의 정보를 가져옵니다. */
  getUserPlayedStory: UserPlayedStory;
  /** 사용자의 플레이어 보고서 링크를 보냅니다. id는 user_has_ending의 uheId를 보내세요. */
  getUserPlayerReport: GetUserPlayerReportOutput;
  /** 사용자의 플레이어 보고서로 자세한 정보를 조합니다. */
  getUserPlayerReport2DetailInfo: UserPlayerReport2DetailInfo;
  /** 개별 UserPurchasedChapter의 정보를 가져옵니다. */
  getUserPurchasedChapter: UserPurchasedChapter;
  /** 개별 UserPurchasedPackage의 정보를 가져옵니다. */
  getUserPurchasedPackage: UserPurchasedPackage;
  /** 개별 UserPurchasedSkipAds의 정보를 가져옵니다. */
  getUserPurchasedSkipAds: UserPurchasedSkipAds;
  /** 개별 UserRecommendationOnboarding의 정보를 가져옵니다. */
  getUserRecommendationOnboarding: UserRecommendationOnboarding;
  /** 개별 UserReportChallengeStory의 정보를 가져옵니다. */
  getUserReportChallengeStory: UserReportChallengeStory;
  /** 개별 UserRewardedAttendanceCoin의 정보를 가져옵니다. */
  getUserRewardedAttendanceCoin: UserRewardedAttendanceCoin;
  /** 개별 WeeklyStoryRankingCache의 정보를 가져옵니다. */
  getWeeklyStoryRankingCache: WeeklyStoryRankingCache;
  /**
   * 홈 바텀탭의 장르를 리턴합니다
   * @deprecated 글로벌 대응으로 homeStoryGenresV3를 사용해주세요.
   */
  homeStoryGenres: Array<ChallengeStoryGenre>;
  /** 홈 바텀탭의 장르를 리턴합니다 V3 */
  homeStoryGenresV3: Array<StoryGenre>;
  /**
   * 선택형소설 바텀탭의 장르를 리턴합니다
   * @deprecated 글로벌 대응으로 interactiveStoryGenresV3를 사용해주세요.
   */
  interactiveStoryGenres: Array<ChallengeStoryGenre>;
  /** 선택형소설 바텀탭의 장르를 리턴합니다 V3 */
  interactiveStoryGenresV3: Array<StoryGenre>;
  /** 조건에 맞는 AEventDef 목록을 반환합니다. */
  listAEventDef: ListableAEventDef;
  /** 사용자의 업적 목록을 반환합니다. */
  listAchievements: ListableABadgeWithAcquisition;
  /** 조건에 맞는 AdNetworkActivation 목록을 반환합니다. */
  listAdNetworkActivationForStudio: ListableAdNetworkActivation;
  /** 조건에 맞는 AdminLog 목록을 반환합니다. */
  listAdminLog: ListableAdminLog;
  /** 조건에 맞는 AppConfig 목록을 반환합니다. */
  listAppConfig: ListableAppConfig;
  /** 조건에 맞는 AppSplashImage 목록을 반환합니다. */
  listAppSplashImage: ListableAppSplashImage;
  /** 조건에 맞는 AttendanceCoin 목록을 반환합니다. */
  listAttendanceCoinByApp: ListableAttendanceCoin;
  /** 조건에 맞는 작가 목록을 반환합니다. */
  listAuthorForStudio: ListableUser;
  /** 조건에 맞는 Banner 목록을 반환합니다. */
  listBanner: ListableBanner;
  /** 이탈순 높은 블록 순으로 목록을 반환합니다. */
  listBlockBounceStatOfChapter: ListableBlockBounceStatOfChapter;
  /** 보너스 티켓 코드를 조회합니다 */
  listBonusTicketCode: ListableBonusTicketCode;
  /** 조건에 맞는 BottomBanner 목록을 반환합니다. */
  listBottomBannerForAdmin: ListableBottomBanner;
  /** 조건에 맞는 BundleBanner 목록을 반환합니다. */
  listBundleBanner: ListableBundleBanner;
  /** 조건에 맞는 BundleFeatured 목록을 반환합니다. */
  listBundleFeatured: ListableBundleFeatured;
  /** 도전 스토리 신고 사유 리스트를 반환합니다 */
  listChallengeStoryReportingReason: ListChallengeStoryReportingReason;
  /** 조건에 맞는 Chapter 목록을 반환합니다. */
  listChapter: ListableChapter;
  /** 조건에 맞는 Chapter 목록을 클라이언트에 반환합니다. */
  listChapterByClient: ListableChapter;
  /**
   * 조건에 맞는 챕터 댓글이나 대댓글 목록을 반환합니다.
   * @deprecated 글로벌 적용으로 listChapterCommentV3로 대체 되었습니다.
   */
  listChapterComment: ListableChapterComment;
  /** 조건에 맞는 챕터 댓글이나 대댓글 목록을 반환합니다. */
  listChapterCommentByAdmin: ListableChapterComment;
  /** 댓글 신고 내역을 가져옵니다 */
  listChapterCommentReportByAdmin: ListableUserReportChapterComment;
  /** 회차 댓글 신고 사유 리스트를 반환합니다 */
  listChapterCommentReportingReason: ListChapterCommentReportingReason;
  /** 조건에 맞는 챕터 댓글이나 대댓글 목록을 반환합니다. */
  listChapterCommentV3: ListableChapterComment;
  /** 조건에 맞는 chapt 목록을 반환합니다. */
  listChapterDraft: ListableChapterDraft;
  /** 초안 백업 데이터 목록을 반환합니다. */
  listChapterDraftArchive: ListableChapterDraftArchive;
  /** 휴지통에 있는 초안 백업 데이터 복록을 반환합니다. */
  listChapterDraftArchiveInTrash: ListableChapterDraftArchive;
  /** 조건에 맞는 Chapter 목록을 반환합니다. (storyId 필수) */
  listChapterForStudio: ListableChapter;
  /** 조건에 맞는 ChapterOption 목록을 반환합니다. */
  listChapterOption: ListableChapterOption;
  /** 조건에 맞는 선택분기 목록을 반환합니다. */
  listChoice: ListableChoice;
  /** 조건에 맞는 ChoiceFromPlayerClass 목록을 반환합니다. */
  listChoiceFromPlayerClass: ListableChoiceFromPlayerClass;
  /** 조건에 맞는 캐릭터 목록을 반환합니다. */
  listChr: ListableChr;
  /** 조건에 맞는 Color 목록을 반환합니다. */
  listColor: ListableColor;
  /** 신청된 공모전 작품 목록을 조회합니다. */
  listContestHasStory: ListableContestHasStory;
  /** 조건에 맞는 Country 목록을 반환합니다. */
  listCountry: ListableCountry;
  /** 현재 진행중인 공모전 목록을 조회합니다. */
  listCurrentContest: ListableContestInfo;
  /**
   * 현재 진행중인 공모전에 출품한 작품들을 가져옵니다.(웹소설 + 인터랙티브)
   * @deprecated 웹소설/인터랙티브 리스트가 분리되어야해서 deprecated 시킵니다. listCurrentInteractiveContestStoryByApp/listCurrentEPUBContestStoryByApp 호출해주세요.
   */
  listCurrentContestStoryByAppV2: ListableCachedStory;
  /** 현재 진행중인 웹소설 공모전에 출품한 작품들을 가져옵니다. */
  listCurrentEPUBContestStoryByApp: ListableCachedStory;
  /** 현재 진행중인 인터랙티브 공모전에 출품한 작품들을 가져옵니다. */
  listCurrentInteractiveContestStoryByApp: ListableCachedStory;
  /** 현재 진행중인 웹소설 공모전에 출품한 작품들의 일간 인기 목록을 리턴합니다. */
  listDailyCurrentEPUBContestStoryByApp: ListableCachedStory;
  /** 현재 진행중인 인터랙티브 공모전에 출품한 작품들의 일간 인기 목록을 리턴합니다. */
  listDailyCurrentInteractiveContestStoryByApp: ListableCachedStory;
  /** 도전 스토리 일간 인기 목록을 리턴합니다. */
  listDailyUGCStoryByAppV2: ListableCachedStory;
  /** 조건에 맞는 DirectAds 목록을 반환합니다. (어드민) */
  listDirectAdsByAdmin: ListableDirectAds;
  /** 조건에 맞는 엔딩 목록을 반환합니다. */
  listEnding: ListableEnding;
  /** 조건에 맞는 엔딩 카드 목록을 반환합니다. */
  listEndingCardByAdmin: ListableEndingCard;
  /** 엔딩 도착률 범위 리스트를 불러옵니다. */
  listEndingRateRange: ListableEndingRateRange;
  /** 엔딩에 연결된 스토리 속성 정보 */
  listEndingShowsPropertyByAdmin: ListableEndingShowsProperty;
  /** 조건에 맞는 ExposedSectionStory 목록을 반환합니다. */
  listExposedSectionStory: ListableExposedSectionStory;
  /** 조건에 맞는 UserHasFCMToken 목록을 반환합니다. */
  listFCMTokenByAdmin: ListableUserHasFcmToken;
  /** 조건에 맞는 Featured 목록을 반환합니다. */
  listFeatured: ListableFeatured;
  /** 조건에 맞는 HomeSection 리스트를 반환합니다. */
  listHomeSectionByAdmin: ListableHomeSection;
  /** 조건에 맞는 HomeSectionStory 목록을 반환합니다. */
  listHomeSectionStory: ListableStory;
  /** 조건에 맞는 HomeSectionStory 목록을 반환합니다. */
  listHomeSectionStoryByAdmin: ListableHomeSectionStory;
  /** 조건에 맞는 ImageFile 목록을 반환합니다. */
  listImageFile: ListableImageFile;
  /** @deprecated 글로벌 적용으로 listInappCoinV3로 대체되었습니다. */
  listInappCoin: Array<InappCoin>;
  /** @deprecated OS에 웹이 추가되면서 listInappCoin으로 대체되었습니다. */
  listInappCoinByApp: Array<InappCoin>;
  /** 각 플랫폼 별 코인 리스트를 반환합니다. */
  listInappCoinV3: Array<InappCoin>;
  /** 조건에 맞는 InappPackage 목록을 반환합니다. */
  listInappPackage: ListableInappPackage;
  listInappProducts: Array<InappProduct>;
  /** 조건에 맞는 IpSourcing 목록을 반환합니다. */
  listIpSourcing: ListableIpSourcing;
  /** 조건에 맞는 Language 목록을 반환합니다. */
  listLanguage: ListableLanguage;
  /** 조건에 맞는 Ledger (장부) 목록을 반환합니다. */
  listLedger: ListableLedger;
  /** 어드민에 조건에 맞는 Ledger (장부) 목록을 반환합니다. */
  listLedgerByAdmin: ListableLedger;
  /** 주인공 포함 핵심 등장인물 목록을 조회합니다. */
  listMainCharacters: ListableMainCharacter;
  /** 조건에 맞는 MonthlyBundle 목록을 반환합니다. */
  listMonthlyBundleByAdmin: ListableMonthlyBundle;
  /** Notice 항목의 목록을 조회합니다. */
  listNotice: ListableNotice;
  /** Notice 항목의 목록을 조회합니다. */
  listNoticeByAdmin: ListableNotice;
  /** NoticePopup 항목의 목록을 조회합니다. */
  listNoticePopup: ListableNoticePopup;
  /** 작품의 회차들의 댓글수를 조회합니다. (startIndex ~ endIndex) */
  listNumCommentOfChapter: NumCommentOfChapterResponse;
  /** 작품의 회차들의 조회수를 조회합니다. (startIndex ~ endIndex) */
  listNumPlayOfChapter: NumPlayOfChapterResponse;
  /** 온보딩 팝업을 삭제합니다. */
  listOnboardingPopup: ListableOnboardingPopup;
  /** 온보딩 팝업을 삭제합니다. */
  listOnboardingPopupChoice: ListableOnboardingPopupChoice;
  /** 조건에 맞는 OnboardingPopupProfileImage 목록을 반환합니다. */
  listOnboardingPopupProfileImage: ListableOnboardingPopupProfileImage;
  /** 조건에 맞는 PackageCoin 목록을 반환합니다. */
  listPackageCoin: ListablePackageCoin;
  /** 조건에 맞는 PackageSkipAds 목록을 반환합니다. */
  listPackageSkipAds: ListablePackageSkipAds;
  /** 조건에 맞는 PackageStory 목록을 반환합니다. */
  listPackageStory: ListablePackageStory;
  /** 조건에 맞는 PackageTimeSale 목록을 반환합니다. */
  listPackageTimeSale: ListablePackageTimeSale;
  /** 조건에 맞는 PlayerClass 목록을 반환합니다. */
  listPlayerClass: ListablePlayerClass;
  /** 조건에 맞는 플레이어 보고서 (v2) 목록을 반환합니다. */
  listPlayerReport2: ListablePlayerReport2;
  /** 조건에 맞는 플레이어 보고서에서 사용가능한 함수 (v2) 목록을 반환합니다. */
  listPlayerReport2Function: ListablePlayerReport2Function;
  /** 조건에 맞는 플레이어 보고서에서 사용가능한 렌더블록 (v2) 목록을 반환합니다. */
  listPlayerReport2RenderBlock: ListablePlayerReport2RenderBlock;
  /** 조건에 맞는 preview 엔딩 목록을 반환합니다. */
  listPreviewEnding: ListablePreviewEnding;
  /** 조건에 맞는 preview 엔딩 목록을 반환합니다. (어드민 전용 API) */
  listPreviewEndingByAdmin: ListablePreviewEnding;
  /** 공용리소스 카테고리 목록을 조회합니다. */
  listPublicResourceCategory: ListablePublicResourceCategory;
  /** 공용리소스 파일 목록을 조회합니다. */
  listPublicResourceFile: ListablePublicResourceFile;
  /** 통구매 가능한 작품을 리턴 (앱 API) */
  listPurchasableStory: ListableStory;
  /** 통구매 가능한 작품을 리턴. (웹 API) */
  listPurchasableStoryByWeb: ListableStory;
  /** 조건에 맞는 PushNotification 목록을 반환합니다. */
  listPushNotification: ListablePushNotification;
  /** 조건에 맞는 RecommendSectionStory 목록을 반환합니다. */
  listRecommendSectionStory: ListableRecommendSectionStory;
  /** 조건에 맞는 RecommendationForFirstUser 목록을 반환합니다. (어드민) */
  listRecommendationForFirstUser: ListableRecommendationForFirstUser;
  /** 조건에 맞는 RecommendationOnboarding 목록을 반환합니다. */
  listRecommendationOnboarding: ListableRecommendationOnboarding;
  /** 조건에 맞는 RemoteScriptProvider 목록을 반환합니다. */
  listRemoteScriptProvider: ListableRemoteScriptProvider;
  /** ai 캐릭터 생성 결과 목록을 조회합니다. */
  listRequestAICharacter: ListableRequestAiCharacter;
  /** 조건에 맞는 SFXFile 목록을 반환합니다. */
  listSFXFile: ListableSfxFile;
  /** 샘플 인터랙티브 작품 목록을 반환합니다. (deprecated) */
  listSampleStoryForStudio: ListableStory;
  /** 샘플 작품 목록을 반환합니다. */
  listSampleStoryForStudioV2: ListableStory;
  /** 공모전에 선택된 심사위원 번호 통계치를 반환합니다. */
  listSelectedNumberForContest: ListableSelectedNumber;
  /** 유사한 작품을 리턴합니다. 태그가 많이 겹치고, 플레이 수가 많은 순서로 정렬됩니다. */
  listSimilarStory: ListableSimilarStory;
  /**
   * 조건에 맞는 SkipAds 목록을 반환합니다.
   * @deprecated 글로벌 적용으로 listSkipAdsV3로 대체되었습니다.
   */
  listSkipAds: ListableSkipAds;
  /** 조건에 맞는 SkipAds 목록을 반환합니다. */
  listSkipAdsV3: ListableSkipAds;
  /** 조건에 맞는 StoreBundle 목록을 반환합니다. */
  listStoreBundle: ListableStoreBundle;
  /** 개인별 장바구니 아이템 목록을 반환합니다. */
  listStoreCartItem: ListableStoreCartItem;
  /** 스토어 결제 목록 */
  listStorePayment: ListableStorePayment;
  /** 스토어 결제 목록(어드민용) */
  listStorePaymentForAdmin: ListableStorePayment;
  /** 스토어 리뷰 목록을 반환합니다. */
  listStoreReview: ListableStoreReview;
  /** 스토어 리뷰 댓글 목록을 반환합니다. */
  listStoreReviewComment: ListableStoreReviewComment;
  /** 개인별 StoreWishlistItem 목록을 반환합니다. */
  listStoreWishlistItem: ListableStoreWishlistItem;
  /** 조건에 맞는 Story 목록을 반환합니다. (by Admin) */
  listStory: ListableStory;
  /** 조건에 맞는 StoryBlockedCountry 목록을 반환합니다. */
  listStoryBlockedCountry: ListableStoryBlockedCountry;
  /** 조건에 맞는 StoryBundle 목록을 반환합니다. */
  listStoryBundle: ListableStoryBundle;
  /** 조건에 맞는 StoryBundle 목록을 반환합니다. */
  listStoryBundleForStore: ListableStoryBundle;
  /** 조건에 맞는 StoryBundle 목록을 반환합니다. */
  listStoryBundleForStoreAdmin: ListableStoryBundle;
  /** 스튜디오 어드민이 작품 목록을 조회합니다. */
  listStoryByAdminForStudio: ListableStory;
  /** 사용자가 엔딩을 가지고 있는 작품을 반환합니다. (by App) */
  listStoryByAppHavingEnding: ListableStory;
  /**
   * 사용자가 엔딩을 가지고 있거나 업적 시스템과 연동이 되어있는 작품을 반환합니다. (by App)
   * @deprecated 글로벌 대응으로 listStoryByAppHavingEndingOrAchievementV3로 변경해주세요.
   */
  listStoryByAppHavingEndingOrAchievement: ListableStory;
  /** 사용자가 엔딩을 가지고 있거나 업적 시스템과 연동이 되어있는 작품을 반환합니다. (by App) V3 */
  listStoryByAppHavingEndingOrAchievementV3: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by App) */
  listStoryByAppV2: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by App) */
  listStoryByAppV3: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by App) cacheTest */
  listStoryByAppV3CacheTest: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by Web) */
  listStoryByWebV2: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by Web) */
  listStoryByWebV3: ListableStory;
  /** 작품의 정주행율 랭킹을 조회합니다. */
  listStoryConversionRankCache: ListableStoryConversionRankCache;
  /** 웹소설 공모전 당선 작품 리스트를 반환합니다. */
  listStoryForEpubContestWinner: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by Author from studio.) */
  listStoryForWriter: ListableStory;
  /** 조건에 맞는 Story 목록을 반환합니다. (by Author from studio. v2) */
  listStoryForWriterV2: ListableStory;
  /** 조건에 맞는 배경 목록을 반환합니다. */
  listStoryHasBackground: ListableStoryHasBackground;
  /** 조건에 맞는 StoryHasRecommendation 목록을 반환합니다. */
  listStoryHasRecommendSection: ListableStoryHasRecommendSection;
  /** 조건에 맞는 StoryHasRecommendation 목록을 반환합니다. */
  listStoryHasRecommendSectionByClient: Array<StoryHasRecommendSection>;
  /** 조건에 맞는 StoryHasStoryTag 목록을 반환합니다. */
  listStoryHasStoryTag: ListableStoryHasStoryTag;
  /** 조건에 맞는 StoryHasStudioFile 목록을 반환합니다. */
  listStoryHasStudioFile: ListableStoryHasStudioFile;
  /** 조건에 맞는 StoryItem 목록을 반환합니다. */
  listStoryItem: ListableStoryItem;
  /** 조건에 맞는 StoryPriceSetting 목록을 반환합니다. */
  listStoryPriceSetting: ListableStoryPriceSetting;
  /** StoryProperty 항목을 조회 */
  listStoryPropertyByAdmin: ListableStoryProperty;
  /** StoryProperty 항목을 조회 */
  listStoryPropertyForStudio: ListableStoryProperty;
  /** 작품 랭킹을 조회합니다. */
  listStoryRankCache: ListableStoryRankCache;
  /**
   * 조건에 맞는 StoryRanking 목록을 반환합니다.
   * @deprecated listStoryWeeklyRanking로 대체 되었으니, 변경해주세요.
   */
  listStoryRanking: ListableStoryRanking;
  /** 조건에 맞는 StoryTag 목록을 반환합니다. */
  listStoryTag: ListableStoryTag;
  /** 조건에 맞는 StoryToggleReaction 목록을 반환합니다. */
  listStoryToggleReaction: ListableStoryToggleReaction;
  /** 조건에 맞는 StoryWeekday 목록을 반환합니다. */
  listStoryWeekday: ListableStoryWeekday;
  /**
   * 조건에 맞는 StoryRanking 목록을 반환합니다.
   * @deprecated 글로벌 적용으로 listWeeklyStoryRanking으로 대체 되었습니다.
   */
  listStoryWeeklyRanking: ListableStoryWeeklyRanking;
  /** 조건에 맞는 StoryPreview 목록을 반환합니다. */
  listStoryePreviewForStudio: ListableStoryPreview;
  /** 조건에 맞는 StorygameBackground 목록을 반환합니다. */
  listStorygameBackground: ListableStorygameBackground;
  /** 프롬프트 리스트 조회 */
  listStorygameCharacterAdditionalPrompt: ListableStorygameCharacterAdditionalPrompt;
  /** 프롬프트 리스트 조회 */
  listStorygameCharacterPrompt: ListableStorygameCharacterPrompt;
  /** 조건에 맞는 StorygameCountry 목록을 반환합니다. */
  listStorygameCountry: ListableStorygameCountry;
  /** 조건에 맞는 StorygameSection 목록을 반환합니다. */
  listStorygameSection: ListableStorygameSection;
  /** 조건에 맞는 StorygameSectionCountry 목록을 반환합니다. */
  listStorygameSectionCountry: ListableStorygameSectionCountry;
  /** 조건에 맞는 StorygameTab 목록을 반환합니다. */
  listStorygameTab: ListableStorygameTab;
  /** StudioReleaseItem 목록을 조회합니다. */
  listStudioReleaseItem: ListableStudioReleaseItem;
  /** StudioReleaseVersion 목록을 조회합니다. */
  listStudioReleaseVersion: ListableStudioReleaseVersion;
  /** 어드민이 StudioReleaseVersion 목록을 조회합니다. */
  listStudioReleaseVersionByAdmin: ListableStudioReleaseVersion;
  /** 사용자 본인이 완료한 튜토리얼 정보를 모두 반환합니다. (no pagination) */
  listStudioTutorialCompletion: ListableStudioTutorialCompletion;
  listSubsCoinByApp: Array<SubsCoin>;
  /** 조건에 맞는 Terms 목록을 반환합니다. */
  listTerms: ListableTerms;
  /** (도전 스토리) 오늘의 장르별 인기작을 리턴합니다. (by App) */
  listTodayGenreUGCStoryByApp: ListableStory;
  /** 조건에 맞는 TodaysHotUGC 목록을 반환합니다. */
  listTodaysHotUGC: ListableTodaysHotUgc;
  /** 조건에 맞는 TtsSpeaker 목록을 반환합니다. */
  listTtsSpeaker: ListableTtsSpeaker;
  /** 도전 스토리 목록을 리턴합니다. */
  listUGCStoryByApp: ListableCachedStory;
  /** 지정된 공모전 당선 UGC 작품 리스트를 반환합니다. (by App) */
  listUGCStoryForContestByApp: ListableStory;
  /** 지정된 공모전 당선 UGC 작품 리스트를 반환합니다. (by Web) */
  listUGCStoryForContestByWeb: ListableStory;
  /** 조건에 맞는 사용자 목록을 반환합니다. */
  listUser: ListableUser;
  /** 조건에 맞는 UserCertificatedAdult 목록을 반환합니다. */
  listUserCertificatedAdult: ListableUserCertificatedAdult;
  /** 조건에 맞는 UserGotPushMessage 목록을 반환합니다. */
  listUserGotPushMessage: ListableUserGotPushMessage;
  /** 조건에 맞는 사용자차단 목록을 반환합니다. */
  listUserHasBlacklist: ListableUserHasBlacklist;
  /** 사용자가 좋아요한 스토리 목록을 가져옵니다. */
  listUserLikeStoryBundle: ListableStory;
  /** 조건에 맞는 작품-사용자 목록을 반환합니다. */
  listUserManagingStory: ListableUserManagingStory;
  /** 조건에 맞는 사용자의 작품 진행 목록을 반환합니다. */
  listUserPlayedStory: ListableUserPlayedStory;
  /** 작품의 플레이어 기록 목록을 조회합니다. */
  listUserPlayedStoryForStudio: ListableUserPlayedStory;
  /** 생성된 사용자의 플레이어 보고서 목록을 조회합니다. */
  listUserPlayerReport2: ListableUserPlayerReport2;
  /** 조건에 맞는 UserPurchasedChapter 목록을 반환합니다. */
  listUserPurchasedChapter: ListableUserPurchasedChapter;
  /** 조건에 맞는 UserPurchasedPackage 목록을 반환합니다. */
  listUserPurchasedPackage: ListableUserPurchasedPackage;
  /** 조건에 맞는 UserPurchasedSkipAds 목록을 반환합니다. */
  listUserPurchasedSkipAds: ListableUserPurchasedSkipAds;
  /** 조건에 맞는 UserPurchasedStory 목록을 반환합니다. */
  listUserPurchasedStory: ListableUserPurchasedStory;
  /** 조건에 맞는 통구매한 Story 목록을 반환합니다. (앱 API) */
  listUserPurchasedStoryByApp: ListableStory;
  /** 조건에 맞는 통구매한 Story 목록을 반환합니다. (웹 API) */
  listUserPurchasedStoryByWeb: ListableStory;
  /** 조건에 맞는 UserRecommendationOnboarding 목록을 반환합니다. */
  listUserRecommendationOnboarding: ListableUserRecommendationOnboarding;
  /** 조건에 맞는 UserReportChallengeStory 목록을 반환합니다. */
  listUserReportChallengeStory: ListableUserReportChallengeStory;
  /** 조건에 맞는 UserRewardedAttendanceCoin 목록을 반환합니다. */
  listUserRewardedAttendanceCoin: ListableUserRewardedAttendanceCoin;
  listUserSelectedChoice: ListableUserSelectedChoice;
  /**
   * 조건에 맞는 UserStoryHistory 목록을 반환합니다.
   * @deprecated 글로벌 대응한 listUserStoryHistoryV3로 바꿔주세요.
   */
  listUserStoryHistory: ListableUserStoryHistory;
  /** 조건에 맞는 UserStoryHistory 목록을 반환합니다. */
  listUserStoryHistoryV3: ListableUserStoryHistory;
  /** 이번 주 급 상승 작품을 가져옵니다. */
  listWeeklyStoryRankingV3: ListableWeeklyStoryRanking;
  ok: OkResponse;
  /** 스토리게임 온보딩 화면 session Id를 발급합니다. */
  requestSession: RequestAiSessionResponse;
  /**
   * 작품명 / 작가명으로 검색한 작품 목록을 반환합니다. by App
   * @deprecated 글로벌 대응된 searchStoryByAppV3로 교체해주세요.
   */
  searchStoryByApp: SearchStoryOutput;
  /** 작품명 / 작가명으로 검색한 작품 목록을 반환합니다. by App */
  searchStoryByAppV3: SearchStoryOutput;
  /**
   * 작품명 / 작가명으로 검색한 작품 목록을 반환합니다. by Web
   * @deprecated 글로벌 대응된 searchStoryByWebV3로 교체해주세요.
   */
  searchStoryByWeb: SearchStoryOutput;
  /** 작품명 / 작가명으로 검색한 작품 목록을 반환합니다. by Web */
  searchStoryByWebV3: SearchStoryOutput;
  /** 이름이 포함된 StoryTag 100개를 반환합니다. */
  searchStoryTagForStudio: Array<StoryTag>;
  /** 조건에 맞는 사용자의 보고서를 조회합니다. */
  searchUserPlayerReport2: ListableUserPlayerReport2;
  /** 홈, 인터랙티브, 웹소설 탭의 각 섹션의 장르를 리턴합니다. */
  sectionStoryGenres: Array<StoryGenre>;
  /** 작품 실시간 통계 값 */
  storyStat: StoryStatOutput;
  /** 전체 시스템의 통계 */
  systemStat: SystemStat;
  t: OkResponse;
  t2: OkResponse;
  t3: OkResponse;
  /**
   * 도전스토리 바텀탭의 장르를 리턴합니다
   * @deprecated 글로벌 적용으로 ugcStoryGenresV3로 대체되었습니다.
   */
  ugcStoryGenres: Array<UgcStoryGenre>;
  /** 도전스토리 바텀탭의 장르를 리턴합니다 */
  ugcStoryGenresV3: Array<UgcStoryGenreV3>;
};


export type QueryCheckEmailArgs = {
  data: SingleEmailInput;
};


export type QueryCopyChapterCommentsArgs = {
  data: StoryCommentCopyInput;
};


export type QueryEnsureTossPaymentsDoneArgs = {
  data: SingleIdInput;
};


export type QueryFetchAllUserItemsArgs = {
  data: FetchAllUserItemsInput;
};


export type QueryForceMigrateChrProfileArgs = {
  data: ForceMigrateChrProfileInput;
};


export type QueryGetAdNetworkActivationForStudioArgs = {
  data: SingleIdInput;
};


export type QueryGetAdminLogArgs = {
  data: SingleIdInput;
};


export type QueryGetAdminStatDailyInRangeArgs = {
  data: StatDateRange;
};


export type QueryGetAffectionByAdminArgs = {
  data: GetAffectionByAdminInput;
};


export type QueryGetAllBannersByAppArgs = {
  data: GetAllBannersInput;
};


export type QueryGetAllBannersByWebArgs = {
  data: GetAllBannersInput;
};


export type QueryGetAllBundleBannersByAppArgs = {
  data: GetAllBundleBannersInput;
};


export type QueryGetAllBundleBannersByWebArgs = {
  data: GetAllBundleBannersInput;
};


export type QueryGetAllStoryPreviewsArgs = {
  data: GetAllStoryPreviewsInput;
};


export type QueryGetAllStoryTagsArgs = {
  data: GetAllStoryTagsInput;
};


export type QueryGetAllStorygameSectionPreviewsArgs = {
  data: GetAllStorygameSectionPreviewsInput;
};


export type QueryGetAllStorygameSectionViewsArgs = {
  data: GetAllStorygameSectionViewsInput;
};


export type QueryGetAllStorygameSectionsArgs = {
  data: SingleIdInput;
};


export type QueryGetAppEventArgs = {
  data: SingleIdInput;
};


export type QueryGetAppSplashImageArgs = {
  data: SingleIdInput;
};


export type QueryGetAttendanceCoinArgs = {
  data: SingleIdInput;
};


export type QueryGetBannerArgs = {
  data: SingleIdInput;
};


export type QueryGetBonusTicketCodeArgs = {
  data: SingleIdInput;
};


export type QueryGetBottomBannerArgs = {
  data: SingleIdInput;
};


export type QueryGetBundleBannerArgs = {
  data: SingleIdInput;
};


export type QueryGetBundleFeaturedByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetChapterArgs = {
  data: SingleIdInput;
};


export type QueryGetChapterCommentArgs = {
  data: SingleIdInput;
};


export type QueryGetChapterCommentByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetChapterDraftArgs = {
  data: SingleIdInput;
};


export type QueryGetChapterDraftArchiveArgs = {
  data: SingleIdInput;
};


export type QueryGetChapterOptionArgs = {
  data: SingleIdInput;
};


export type QueryGetChoiceArgs = {
  data: SingleIdInput;
};


export type QueryGetChoiceAffectionRuleArgs = {
  data: GetChoiceAffectionRuleInput;
};


export type QueryGetChoiceFromPlayerClassArgs = {
  data: SingleIdInput;
};


export type QueryGetChoicesForContinueArgs = {
  data: GetChoicesForContinueInput;
};


export type QueryGetChrArgs = {
  data: SingleIdInput;
};


export type QueryGetCoinAmountForBuyRemainingChaptersArgs = {
  data: GetCoinAmountForBuyRemainingChaptersInput;
};


export type QueryGetCoinProductArgs = {
  data: SingleIdInput;
};


export type QueryGetColorArgs = {
  data: SingleIdInput;
};


export type QueryGetConversionStatOfStoryArgs = {
  data: GetConversionStatOfStoryInput;
};


export type QueryGetDanalPaymentArgs = {
  data: SingleStringIdInput;
};


export type QueryGetDirectAdsArgs = {
  data: DirectAdsTypeInput;
};


export type QueryGetDirectAdsByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetEndingArgs = {
  data: SingleIdInput;
};


export type QueryGetEndingByShareKeyArgs = {
  data: SingleStringIdInput;
};


export type QueryGetEndingCardArgs = {
  data: SingleIdInput;
};


export type QueryGetEndingInfoArgs = {
  data: GetEndingInfo;
};


export type QueryGetEndingShowsPropertyByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetEndingStatsArgs = {
  data: SingleIdInput;
};


export type QueryGetEndingStatsPerChapterArgs = {
  data: SingleIdInput;
};


export type QueryGetExposedSectionStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetFcmTokenByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetFallbackRemoteScriptByStudioArgs = {
  data: GetFallbackRemoteScriptByStudioInput;
};


export type QueryGetFeaturedByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetHomeSectionArgs = {
  data: SingleIdInput;
};


export type QueryGetHomeSectionStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetHomeSectionsArgs = {
  data: HomeSectionTypeInput;
};


export type QueryGetImageFileArgs = {
  data: SingleIdInput;
};


export type QueryGetInappCoinArgs = {
  data: SingleIdInput;
};


export type QueryGetInappPackageArgs = {
  data: SingleIdInput;
};


export type QueryGetIpSourcingArgs = {
  data: SingleIdInput;
};


export type QueryGetItemBasedRecommendationArgs = {
  data: ItemBasedRecommendationInput;
};


export type QueryGetLanguageArgs = {
  data: SingleIdInput;
};


export type QueryGetLastTestSessionArgs = {
  data: SingleIdInput;
};


export type QueryGetLatestFcmTokenByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetLedgerArgs = {
  data: SingleIdInput;
};


export type QueryGetListStoryCountsByStudioV2Args = {
  data: ListableStoryCountsByStudioInput;
};


export type QueryGetMainChrOnboardingPopupArgs = {
  data: SingleIdInput;
};


export type QueryGetMonthlyBundleByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetMyBotMissRulesArgs = {
  data: GetMyBotMissRulesInput;
};


export type QueryGetMyBotSequenceByNameArgs = {
  data: GetMyBotSequenceByNameInput;
};


export type QueryGetNoticeArgs = {
  data: SingleIdInput;
};


export type QueryGetNoticePopupArgs = {
  data: SingleIdInput;
};


export type QueryGetNumCommentStatOfStoryArgs = {
  data: GetNumCommentStatOfStoryInput;
};


export type QueryGetNumLikeStatOfStoryArgs = {
  data: GetNumLikeStatOfStoryInput;
};


export type QueryGetNumPlayStatOfStoryArgs = {
  data: GetNumPlayStatOfStoryInput;
};


export type QueryGetOnboardingPopupArgs = {
  data: SingleIdInput;
};


export type QueryGetOnboardingPopupChoiceArgs = {
  data: SingleIdInput;
};


export type QueryGetOnboardingPopupProfileImageArgs = {
  data: SingleIdInput;
};


export type QueryGetPackageCoinArgs = {
  data: SingleIdInput;
};


export type QueryGetPackageStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetPackageTimeSaleArgs = {
  data: SingleIdInput;
};


export type QueryGetPlayerClassArgs = {
  data: SingleIdInput;
};


export type QueryGetPlayerDataArgs = {
  data: SingleIdInput;
};


export type QueryGetPlayerDataForPlayerReport2Args = {
  data: SingleIdInput;
};


export type QueryGetPlayerReport2Args = {
  data: SingleIdInput;
};


export type QueryGetPlayerReport2FunctionArgs = {
  data: SingleIdInput;
};


export type QueryGetPlayerReport2RenderBlockArgs = {
  data: SingleIdInput;
};


export type QueryGetPlayingCharacterArgs = {
  data: SingleIdInput;
};


export type QueryGetPresignedUrlArgs = {
  data: GetPresignedUrlInput;
};


export type QueryGetPresignedUrlsArgs = {
  data: GetPresignedUrlsInput;
};


export type QueryGetPreviewEndingArgs = {
  data: SingleIdInput;
};


export type QueryGetPublicResourceCategoryArgs = {
  data: SingleIdInput;
};


export type QueryGetPublicResourceFileArgs = {
  data: SingleIdInput;
};


export type QueryGetPushNotificationArgs = {
  data: SingleIdInput;
};


export type QueryGetRecommendSectionStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetRecommendationForFirstUserArgs = {
  data: SingleIdInput;
};


export type QueryGetRecommendationOnboardingArgs = {
  data: SingleIdInput;
};


export type QueryGetRemoteScriptProviderArgs = {
  data: SingleIdInput;
};


export type QueryGetRemoteScriptStatementsArgs = {
  data: GetRemoteScriptStatementsInput;
};


export type QueryGetS3UploadedFileUrlArgs = {
  data: GetS3UploadedFileUrlInput;
};


export type QueryGetSfxFileArgs = {
  data: SingleIdInput;
};


export type QueryGetSelectedRequestAiCharacterArgs = {
  data: GetSelectedRequestAiCharacterInput;
};


export type QueryGetSimilarStoryBundlesArgs = {
  data: SingleIdInput;
};


export type QueryGetSkipAdsArgs = {
  data: SingleIdInput;
};


export type QueryGetStoreBundleArgs = {
  data: SingleIdInput;
};


export type QueryGetStoreBundleForAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetStorePageSectionArgs = {
  data: SingleIdInput;
};


export type QueryGetStorePageSectionForAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetStorePageSectionsArgs = {
  data: GetStorePageSectionsInput;
};


export type QueryGetStorePageSectionsForAdminArgs = {
  data: GetStorePageSectionsForAdminInput;
};


export type QueryGetStorePageSectionsForPreviewArgs = {
  data: GetStorePageSectionsForPreviewInput;
};


export type QueryGetStorePaymentArgs = {
  data: GetStorePaymentInput;
};


export type QueryGetStorePaymentForAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetStoreSkusArgs = {
  data: GetStoreSkusInput;
};


export type QueryGetStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryBlockedCountryArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryBundleArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryBundleCountriesInfoForStudioArgs = {
  data: GetStoryBundleCountriesInfoForStudioInput;
};


export type QueryGetStoryByShareKeyArgs = {
  data: SingleStringIdInput;
};


export type QueryGetStoryForExportArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryHasBackgroundArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryHasRecommendSectionArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryHasStoryTagArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryHasStudioFileArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryItemArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryNewSectionCacheArgs = {
  data: StoryNewSectionCacheGetInput;
};


export type QueryGetStoryPreviewForStudioArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryPriceSettingArgs = {
  data: GetStoryPriceSettingInput;
};


export type QueryGetStoryPropertyArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryPropertyForStudioArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryTagArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryToggleReactionArgs = {
  data: SingleIdInput;
};


export type QueryGetStoryV3Args = {
  data: SingleIdInput;
};


export type QueryGetStorygameAdditionalPromptsArgs = {
  data: GetStorygameAdditionalPromptsInput;
};


export type QueryGetStorygameBackgroundArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygameCharacterAdditionalPromptArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygameCharacterPromptArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygameCountryArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygamePromptsArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygameSectionArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygameSectionCountryArgs = {
  data: SingleIdInput;
};


export type QueryGetStorygameSectionViewMoreArgs = {
  data: GetStorygameSectionViewMoreInput;
};


export type QueryGetStorygameTabArgs = {
  data: SingleIdInput;
};


export type QueryGetStudioReleaseItemArgs = {
  data: SingleIdInput;
};


export type QueryGetStudioReleaseVersionArgs = {
  data: SingleIdInput;
};


export type QueryGetTermsByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetTestSessionArgs = {
  data: SingleIdInput;
};


export type QueryGetTodaysHotUgcArgs = {
  data: SingleIdInput;
};


export type QueryGetUserBasedRecommendationArgs = {
  data: UserBasedRecommendationInput;
};


export type QueryGetUserByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetUserCertificatedAdultArgs = {
  data: SingleIdInput;
};


export type QueryGetUserChoiceResultArgs = {
  data: GetUserChoiceResultInput;
};


export type QueryGetUserGotPushMessageArgs = {
  data: SingleIdInput;
};


export type QueryGetUserInputChoiceArgs = {
  data: GetUserInputChoiceInput;
};


export type QueryGetUserLikesChapterCommentByAdminArgs = {
  data: SingleIdInput;
};


export type QueryGetUserMetricByIdArgs = {
  data: SingleIdInput;
};


export type QueryGetUserOfIdArgs = {
  data: SingleIdInput;
};


export type QueryGetUserPlayedStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetUserPlayerReportArgs = {
  data: SingleIdInput;
};


export type QueryGetUserPlayerReport2DetailInfoArgs = {
  data: SingleIdInput;
};


export type QueryGetUserPurchasedChapterArgs = {
  data: SingleIdInput;
};


export type QueryGetUserPurchasedPackageArgs = {
  data: SingleIdInput;
};


export type QueryGetUserPurchasedSkipAdsArgs = {
  data: SingleIdInput;
};


export type QueryGetUserRecommendationOnboardingArgs = {
  data: SingleIdInput;
};


export type QueryGetUserReportChallengeStoryArgs = {
  data: SingleIdInput;
};


export type QueryGetUserRewardedAttendanceCoinArgs = {
  data: SingleIdInput;
};


export type QueryGetWeeklyStoryRankingCacheArgs = {
  data: SingleIdInput;
};


export type QueryListAEventDefArgs = {
  data: ListableAEventDefInput;
};


export type QueryListAchievementsArgs = {
  data: ListAchievementInput;
};


export type QueryListAdNetworkActivationForStudioArgs = {
  data: ListableAdNetworkActivationInput;
};


export type QueryListAdminLogArgs = {
  data: ListableAdminLogInput;
};


export type QueryListAppConfigArgs = {
  data: ListableAppConfigInput;
};


export type QueryListAppSplashImageArgs = {
  data: ListableAppSplashImageInput;
};


export type QueryListAttendanceCoinByAppArgs = {
  data: ListableAttendanceCoinInput;
};


export type QueryListAuthorForStudioArgs = {
  data: ListableAuthorForStudioInput;
};


export type QueryListBannerArgs = {
  data: ListableBannerInput;
};


export type QueryListBlockBounceStatOfChapterArgs = {
  data: ListableBlockBounceStatOfChapterInput;
};


export type QueryListBonusTicketCodeArgs = {
  data: ListableBonusTicketCodeInput;
};


export type QueryListBottomBannerForAdminArgs = {
  data: ListableBottomBannerInput;
};


export type QueryListBundleBannerArgs = {
  data: ListableBundleBannerInput;
};


export type QueryListBundleFeaturedArgs = {
  data: ListableBundleFeaturedInput;
};


export type QueryListChapterArgs = {
  data: ListableChapterInput;
};


export type QueryListChapterByClientArgs = {
  data: ListableChapterByClientInput;
};


export type QueryListChapterCommentArgs = {
  data: ListableChapterCommentInput;
};


export type QueryListChapterCommentByAdminArgs = {
  data: ListableChapterCommentInput;
};


export type QueryListChapterCommentReportByAdminArgs = {
  data: ListableUserReportChapterCommentInput;
};


export type QueryListChapterCommentV3Args = {
  data: ListableChapterCommentInputV3;
};


export type QueryListChapterDraftArgs = {
  data: ListableChapterDraftInput;
};


export type QueryListChapterDraftArchiveArgs = {
  data: ListableChapterDraftArchiveInput;
};


export type QueryListChapterDraftArchiveInTrashArgs = {
  data: ListableChapterDraftArchiveInTrashInput;
};


export type QueryListChapterForStudioArgs = {
  data: ListableChapterInput;
};


export type QueryListChapterOptionArgs = {
  data: ListableChapterOptionInput;
};


export type QueryListChoiceArgs = {
  data: ListableChoiceInput;
};


export type QueryListChoiceFromPlayerClassArgs = {
  data: ListableChoiceFromPlayerClassInput;
};


export type QueryListChrArgs = {
  data: ListableChrInput;
};


export type QueryListColorArgs = {
  data: ListableColorInput;
};


export type QueryListContestHasStoryArgs = {
  data: ListableContestHasStoryInput;
};


export type QueryListCountryArgs = {
  data: ListableCountryInput;
};


export type QueryListCurrentContestArgs = {
  data: ListableContestInfoInput;
};


export type QueryListCurrentContestStoryByAppV2Args = {
  data: ListableChallengeStoryInput;
};


export type QueryListCurrentEpubContestStoryByAppArgs = {
  data: ListableChallengeStoryInput;
};


export type QueryListCurrentInteractiveContestStoryByAppArgs = {
  data: ListableChallengeStoryInput;
};


export type QueryListDailyCurrentEpubContestStoryByAppArgs = {
  data: ListableChallengeStoryInput;
};


export type QueryListDailyCurrentInteractiveContestStoryByAppArgs = {
  data: ListableChallengeStoryInput;
};


export type QueryListDailyUgcStoryByAppV2Args = {
  data: ListableChallengeStoryInput;
};


export type QueryListDirectAdsByAdminArgs = {
  data: ListableDirectAdsInput;
};


export type QueryListEndingArgs = {
  data: ListableEndingInput;
};


export type QueryListEndingCardByAdminArgs = {
  data: ListableEndingCardByAdminInput;
};


export type QueryListEndingRateRangeArgs = {
  data: ListableInput;
};


export type QueryListEndingShowsPropertyByAdminArgs = {
  data: ListableEndingShowsPropertyInput;
};


export type QueryListExposedSectionStoryArgs = {
  data: ListableExposedSectionStoryInput;
};


export type QueryListFcmTokenByAdminArgs = {
  data: ListableUserHasFcmTokenInput;
};


export type QueryListFeaturedArgs = {
  data: ListableFeaturedInput;
};


export type QueryListHomeSectionByAdminArgs = {
  data: ListableHomeSectionInput;
};


export type QueryListHomeSectionStoryArgs = {
  data: ListableHomeSectionStoryInput;
};


export type QueryListHomeSectionStoryByAdminArgs = {
  data: ListableHomeSectionStoryInput;
};


export type QueryListImageFileArgs = {
  data: ListableImageFileInput;
};


export type QueryListInappCoinArgs = {
  data: SingleOsInput;
};


export type QueryListInappCoinByAppArgs = {
  data: SingleOsInput;
};


export type QueryListInappCoinV3Args = {
  data: SingleOsInput;
};


export type QueryListInappPackageArgs = {
  data: ListableInappPackageInput;
};


export type QueryListInappProductsArgs = {
  data: SingleOsInput;
};


export type QueryListIpSourcingArgs = {
  data: ListableInput;
};


export type QueryListLanguageArgs = {
  data: ListableLanguageInput;
};


export type QueryListLedgerArgs = {
  data: ListableLedgerInput;
};


export type QueryListLedgerByAdminArgs = {
  data: ListableLedgerByAdminInput;
};


export type QueryListMainCharactersArgs = {
  data: ListableMainCharacterInput;
};


export type QueryListMonthlyBundleByAdminArgs = {
  data: ListableMonthlyBundleInput;
};


export type QueryListNoticeArgs = {
  data: ListableNoticeInput;
};


export type QueryListNoticeByAdminArgs = {
  data: ListableNoticeInput;
};


export type QueryListNoticePopupArgs = {
  data: ListableNoticePopupInput;
};


export type QueryListNumCommentOfChapterArgs = {
  data: ListableNumCommentOfChapterInput;
};


export type QueryListNumPlayOfChapterArgs = {
  data: ListableNumPlayOfChapterInput;
};


export type QueryListOnboardingPopupArgs = {
  data: ListableOnboardingPopupInput;
};


export type QueryListOnboardingPopupChoiceArgs = {
  data: ListableOnboardingPopupChoiceInput;
};


export type QueryListOnboardingPopupProfileImageArgs = {
  data: ListableOnboardingPopupProfileImageInput;
};


export type QueryListPackageCoinArgs = {
  data: ListablePackageCoinInput;
};


export type QueryListPackageSkipAdsArgs = {
  data: ListablePackageSkipAdsInput;
};


export type QueryListPackageStoryArgs = {
  data: ListablePackageStoryInput;
};


export type QueryListPackageTimeSaleArgs = {
  data: ListablePackageTimeSaleInput;
};


export type QueryListPlayerClassArgs = {
  data: ListablePlayerClassInput;
};


export type QueryListPlayerReport2Args = {
  data: ListablePlayerReport2Input;
};


export type QueryListPlayerReport2FunctionArgs = {
  data: ListablePlayerReport2FunctionInput;
};


export type QueryListPlayerReport2RenderBlockArgs = {
  data: ListablePlayerReport2RenderBlockInput;
};


export type QueryListPreviewEndingArgs = {
  data: ListablePreviewEndingInput;
};


export type QueryListPreviewEndingByAdminArgs = {
  data: ListablePreviewEndingInput;
};


export type QueryListPublicResourceCategoryArgs = {
  data: ListablePublicResourceCategoryInput;
};


export type QueryListPublicResourceFileArgs = {
  data: ListablePublicResourceFileInput;
};


export type QueryListPurchasableStoryArgs = {
  data: ListablePurchasableStoryInput;
};


export type QueryListPurchasableStoryByWebArgs = {
  data: ListablePurchasableStoryInput;
};


export type QueryListPushNotificationArgs = {
  data: ListablePushNotificationInput;
};


export type QueryListRecommendSectionStoryArgs = {
  data: ListableRecommendSectionStoryInput;
};


export type QueryListRecommendationForFirstUserArgs = {
  data: ListableRecommendationForFirstUserInput;
};


export type QueryListRecommendationOnboardingArgs = {
  data: ListableRecommendationOnboardingInput;
};


export type QueryListRemoteScriptProviderArgs = {
  data: ListableRemoteScriptProviderInput;
};


export type QueryListRequestAiCharacterArgs = {
  data: ListableRequestAiCharacterInput;
};


export type QueryListSfxFileArgs = {
  data: ListableSfxFileInput;
};


export type QueryListSampleStoryForStudioArgs = {
  data: ListableStoryInput;
};


export type QueryListSampleStoryForStudioV2Args = {
  data: ListableStoryForWriterInput;
};


export type QueryListSimilarStoryArgs = {
  data: ListableSimilarStoryInput;
};


export type QueryListSkipAdsArgs = {
  data: ListableSkipAdsInput;
};


export type QueryListSkipAdsV3Args = {
  data: ListableSkipAdsInput;
};


export type QueryListStoreBundleArgs = {
  data: ListableStoreBundleInput;
};


export type QueryListStoreCartItemArgs = {
  data: ListableInput;
};


export type QueryListStorePaymentArgs = {
  data: ListableInput;
};


export type QueryListStorePaymentForAdminArgs = {
  data: ListableStorePaymentForAdminInput;
};


export type QueryListStoreReviewArgs = {
  data: ListableStoreReviewInput;
};


export type QueryListStoreReviewCommentArgs = {
  data: ListableStoreReviewCommentInput;
};


export type QueryListStoreWishlistItemArgs = {
  data: ListableInput;
};


export type QueryListStoryArgs = {
  data: ListableStoryInput;
};


export type QueryListStoryBlockedCountryArgs = {
  data: ListableStoryBlockedCountryInput;
};


export type QueryListStoryBundleArgs = {
  data: ListableStoryBundleInput;
};


export type QueryListStoryBundleForStoreArgs = {
  data: ListableStoryBundleForStoreInput;
};


export type QueryListStoryBundleForStoreAdminArgs = {
  data: ListableStoryBundleForStoreAdminInput;
};


export type QueryListStoryByAdminForStudioArgs = {
  data: ListableStoryByAdminForStudioInput;
};


export type QueryListStoryByAppHavingEndingOrAchievementArgs = {
  data?: InputMaybe<ListableStoryByAppHavingEndingOrAchievementInput>;
};


export type QueryListStoryByAppHavingEndingOrAchievementV3Args = {
  data?: InputMaybe<ListableStoryByAppHavingEndingOrAchievementInput>;
};


export type QueryListStoryByAppV2Args = {
  data: ListableStoryInput;
};


export type QueryListStoryByAppV3Args = {
  data: ListableStoryInput;
};


export type QueryListStoryByAppV3CacheTestArgs = {
  data: ListableStoryInput;
};


export type QueryListStoryByWebV2Args = {
  data: ListableStoryInput;
};


export type QueryListStoryByWebV3Args = {
  data: ListableStoryInput;
};


export type QueryListStoryConversionRankCacheArgs = {
  data: ListableStoryRankCacheInput;
};


export type QueryListStoryForEpubContestWinnerArgs = {
  data: ListableStoryForEpubContestWinnerInput;
};


export type QueryListStoryForWriterArgs = {
  data: ListableStoryInput;
};


export type QueryListStoryForWriterV2Args = {
  data: ListableStoryForWriterInput;
};


export type QueryListStoryHasBackgroundArgs = {
  data: ListableStoryHasBackgroundInput;
};


export type QueryListStoryHasRecommendSectionArgs = {
  data: ListableStoryHasRecommendSectionInput;
};


export type QueryListStoryHasRecommendSectionByClientArgs = {
  data: ListableStoryHasRecommendSectionByClientInput;
};


export type QueryListStoryHasStoryTagArgs = {
  data: ListableStoryHasStoryTagInput;
};


export type QueryListStoryHasStudioFileArgs = {
  data: ListableStoryHasStudioFileInput;
};


export type QueryListStoryItemArgs = {
  data: ListableStoryItemInput;
};


export type QueryListStoryPriceSettingArgs = {
  data: ListableStoryPriceSettingInput;
};


export type QueryListStoryPropertyByAdminArgs = {
  data: ListableStoryPropertyInput;
};


export type QueryListStoryPropertyForStudioArgs = {
  data: ListableStoryPropertyInput;
};


export type QueryListStoryRankCacheArgs = {
  data: ListableStoryRankCacheInput;
};


export type QueryListStoryRankingArgs = {
  data: ListableStoryRankingInput;
};


export type QueryListStoryTagArgs = {
  data: ListableStoryTagInput;
};


export type QueryListStoryToggleReactionArgs = {
  data: ListableStoryToggleReactionInput;
};


export type QueryListStoryWeekdayArgs = {
  data: ListableStoryWeekdayInput;
};


export type QueryListStoryWeeklyRankingArgs = {
  data: ListableStoryWeeklyRankingInput;
};


export type QueryListStoryePreviewForStudioArgs = {
  data: ListableStoryPreviewInput;
};


export type QueryListStorygameBackgroundArgs = {
  data: ListableStorygameBackgroundInput;
};


export type QueryListStorygameCharacterAdditionalPromptArgs = {
  data: ListableStorygameCharacterAdditionalPromptInput;
};


export type QueryListStorygameCharacterPromptArgs = {
  data: ListableStorygameCharacterPromptInput;
};


export type QueryListStorygameCountryArgs = {
  data: ListableStorygameCountryInput;
};


export type QueryListStorygameSectionArgs = {
  data: ListableStorygameSectionInput;
};


export type QueryListStorygameSectionCountryArgs = {
  data: ListableStorygameSectionCountryInput;
};


export type QueryListStorygameTabArgs = {
  data: ListableStorygameTabInput;
};


export type QueryListStudioReleaseItemArgs = {
  data: ListableStudioReleaseItemInput;
};


export type QueryListStudioReleaseVersionArgs = {
  data: ListableStudioReleaseVersionInput;
};


export type QueryListStudioReleaseVersionByAdminArgs = {
  data: ListableStudioReleaseVersionByAdminInput;
};


export type QueryListSubsCoinByAppArgs = {
  data: SingleOsInput;
};


export type QueryListTermsArgs = {
  data: ListableTermsInput;
};


export type QueryListTodayGenreUgcStoryByAppArgs = {
  data: ListableTodayGenreUgcStoryInput;
};


export type QueryListTodaysHotUgcArgs = {
  data: ListableTodaysHotUgcInput;
};


export type QueryListTtsSpeakerArgs = {
  data: ListableTtsSpeakerInput;
};


export type QueryListUgcStoryByAppArgs = {
  data: ListableUgcStoryInput;
};


export type QueryListUgcStoryForContestByAppArgs = {
  data: ListableUgcStoryForContestInput;
};


export type QueryListUgcStoryForContestByWebArgs = {
  data: ListableUgcStoryForContestInput;
};


export type QueryListUserArgs = {
  data: ListableUserInput;
};


export type QueryListUserCertificatedAdultArgs = {
  data: ListableUserCertificatedAdultInput;
};


export type QueryListUserGotPushMessageArgs = {
  data: ListableUserGotPushMessageInput;
};


export type QueryListUserHasBlacklistArgs = {
  data: ListableUserHasBlacklistInput;
};


export type QueryListUserLikeStoryBundleArgs = {
  data: ListUserLikeStoryBundleInput;
};


export type QueryListUserManagingStoryArgs = {
  data: ListableUserManagingStoryInput;
};


export type QueryListUserPlayedStoryArgs = {
  data: ListableUserPlayedStoryInput;
};


export type QueryListUserPlayedStoryForStudioArgs = {
  data: ListableUserPlayedStoryForStudioInput;
};


export type QueryListUserPlayerReport2Args = {
  data: ListableUserPlayerReport2Input;
};


export type QueryListUserPurchasedChapterArgs = {
  data: ListableUserPurchasedChapterInput;
};


export type QueryListUserPurchasedPackageArgs = {
  data: ListableUserPurchasedPackageInput;
};


export type QueryListUserPurchasedSkipAdsArgs = {
  data: ListableUserPurchasedSkipAdsInput;
};


export type QueryListUserPurchasedStoryArgs = {
  data: ListableUserPurchasedStoryInput;
};


export type QueryListUserPurchasedStoryByAppArgs = {
  data: ListableUserPurchasedStoryInput;
};


export type QueryListUserPurchasedStoryByWebArgs = {
  data: ListableUserPurchasedStoryInput;
};


export type QueryListUserRecommendationOnboardingArgs = {
  data: ListableUserRecommendationOnboardingInput;
};


export type QueryListUserReportChallengeStoryArgs = {
  data: ListableUserReportChallengeStoryInput;
};


export type QueryListUserRewardedAttendanceCoinArgs = {
  data: ListableUserRewardedAttendanceCoinInput;
};


export type QueryListUserSelectedChoiceArgs = {
  data: ListableUserSelectedChoiceInput;
};


export type QueryListUserStoryHistoryArgs = {
  data: ListableUserStoryHistoryInput;
};


export type QueryListUserStoryHistoryV3Args = {
  data: ListableUserStoryHistoryInput;
};


export type QueryListWeeklyStoryRankingV3Args = {
  data: ListableWeeklyStoryRankingInput;
};


export type QuerySearchStoryByAppArgs = {
  data: SearchStoryInput;
};


export type QuerySearchStoryByAppV3Args = {
  data: SearchStoryInput;
};


export type QuerySearchStoryByWebArgs = {
  data: SearchStoryInput;
};


export type QuerySearchStoryByWebV3Args = {
  data: SearchStoryInput;
};


export type QuerySearchStoryTagForStudioArgs = {
  data: SearchStoryTagInput;
};


export type QuerySearchUserPlayerReport2Args = {
  data: SearchUserPlayerReport2Input;
};


export type QuerySectionStoryGenresArgs = {
  data: SectionStoryGenresInput;
};


export type QueryTArgs = {
  data: SingleIdInput;
};


export type QueryT2Args = {
  data: SingleIdInput;
};


export type QueryT3Args = {
  data: SingleIdInput;
};

/** 스플 추천 온보딩 타입 */
export enum Recommendation_Onboarding_Type {
  Gender = 'Gender',
  Generation = 'Generation',
  Genre = 'Genre'
}

/** 원격 스크립트 타입 */
export enum Remote_Script_Type {
  ChatGpt = 'ChatGPT',
  HelloBot = 'HelloBot',
  StoryPlay = 'StoryPlay'
}

/** 웹소설 회차 엔딩 도착에 대한 input */
export type ReachedEpubChapterEndingInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
};

/** 엔딩 도착에 대한 input */
export type ReachedEndingInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 엔딩의 커스텀 설명 (템플릿 형식일 경우, 적용한 이름) */
  customEndingDescription?: InputMaybe<Scalars['String']>;
  /** 엔딩의 커스텀 이름 (템플릿 형식일 경우, 적용한 이름) */
  customEndingName?: InputMaybe<Scalars['String']>;
  /** 엔딩의 원래 이름 */
  endingName: Scalars['String'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
};

/** 엔딩 도착에 대한 output */
export type ReachedEndingOutput = {
  __typename?: 'ReachedEndingOutput';
  /** 환불액 (0인 경우 환불한 금액이 없음을 의미) */
  coinRefunded: Scalars['Int'];
  /** 현재 수집된 엔딩 정보 */
  collectedEnding?: Maybe<UserHasEnding>;
  /** 엔딩의 정보 */
  ending: Ending;
  /** 다음 챕터가 존재하는가? (공개 예정인 경우에도 반환됨) */
  nextChapter?: Maybe<Chapter>;
};

/** 추천 섹션에 작품 라스트를 저장하는 테이블 */
export type RecommendSectionStory = {
  __typename?: 'RecommendSectionStory';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  recommendSectionStoryId: Scalars['Int'];
  /** 이 챕터가 포함된 책 */
  recommendStory: Story;
  /** 이 챕터가 포함된 책 Id */
  recommendStoryId: Scalars['Int'];
  /** 섹션 */
  section: StoryHasRecommendSection;
  /** 섹션 Id */
  sectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** RecommendSectionStory 생성을 위한 input */
export type RecommendSectionStoryCreationInput = {
  /** 이 챕터가 포함된 책 Id */
  recommendStoryId: Scalars['Int'];
  /** 섹션 Id */
  sectionId: Scalars['Int'];
};

/** 첫방문 유저에게 추천할 작품을 관리하는 엔티티 */
export type RecommendationForFirstUser = {
  __typename?: 'RecommendationForFirstUser';
  createdAt: Scalars['DateTime'];
  /** 해당 추천 작품 숨김 여부 (true: 숨김) */
  isHidden: Scalars['Boolean'];
  /** 아이디 */
  rfuId: Scalars['Int'];
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** RecommendationForFirstUser 생성을 위한 input */
export type RecommendationForFirstUserCreationInput = {
  /** 해당 추천 작품 숨김 여부 (true: 숨김) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** RecommendationForFirstUser 업데이트를 위한 input */
export type RecommendationForFirstUserUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 해당 추천 작품 숨김 여부 (true: 숨김) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 정렬 우선 순서. (높을 수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 사용자 추천 온보딩 정보 */
export type RecommendationOnboarding = {
  __typename?: 'RecommendationOnboarding';
  createdAt: Scalars['DateTime'];
  /** 온보딩 데이터 */
  data: Array<RecommendationOnboardingData>;
  /** 온보딩 데이터 원 JSONString 데이터 */
  dataRaw: Scalars['String'];
  /** 추천 온보딩 숨김여부 */
  isHidden: Scalars['Boolean'];
  /** 온보딩 굵은 글씨 질문 (로컬라이즈 키) */
  mainText: Scalars['String'];
  /** 아이디 */
  recommendationOnboardingId: Scalars['Int'];
  /** 온보딩 순서 */
  step: Scalars['Int'];
  /** 온보딩 부가 설명 (로컬라이즈 키) */
  subText: Scalars['String'];
  /** 추천 온보딩 타입 */
  type: Recommendation_Onboarding_Type;
  updatedAt: Scalars['DateTime'];
};

/** RecommendationOnboarding 생성을 위한 input */
export type RecommendationOnboardingCreationInput = {
  /** 온보딩 데이터 */
  data: Scalars['String'];
  /** 추천 온보딩 숨김여부 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 온보딩 굵은 글씨 질문 (로컬라이즈 키) */
  mainText: Scalars['String'];
  /** 온보딩 순서 */
  step: Scalars['Int'];
  /** 온보딩 부가 설명 (로컬라이즈 키) */
  subText: Scalars['String'];
  /** 추천 온보딩 타입 */
  type: Recommendation_Onboarding_Type;
};

export type RecommendationOnboardingData = {
  __typename?: 'RecommendationOnboardingData';
  /** 온보딩 선택지 성별 */
  gender?: Maybe<Gender>;
  /** 온보딩 선택지 세대 */
  generation?: Maybe<Scalars['Int']>;
  /** 온보딩 선택지 장르 */
  genre?: Maybe<Scalars['String']>;
  /** 온보딩 선택지 이미지 링크 */
  imageLink: Scalars['String'];
  /** 온보딩 선택지 로컬라이즈 키 */
  textKey: Scalars['String'];
};

/** RecommendationOnboarding 업데이트를 위한 input */
export type RecommendationOnboardingUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** refreshToken 생성을 위한 입력값 */
export type RefreshTokenCreationInput = {
  /** clientId */
  clientId: Scalars['String'];
  /** redirectUrl */
  redirectUrl: Scalars['String'];
  /** scopes */
  scopes: Array<Scalars['String']>;
};

/** refreshToken 생성 응답 */
export type RefreshTokenCreationResponse = {
  __typename?: 'RefreshTokenCreationResponse';
  /** refreshToken */
  refreshToken: Scalars['String'];
};

export type RemoteScriptProvider = {
  __typename?: 'RemoteScriptProvider';
  createdAt: Scalars['DateTime'];
  /** 원격 스크립트 프로바이더 설명 */
  description?: Maybe<Scalars['String']>;
  /** 원격 스크립트 파람 list */
  paramsDescription?: Maybe<Scalars['String']>;
  /** 원격 스크립트 프로바이더 */
  provider: Scalars['String'];
  /** 아이디 */
  remoteScriptProviderId: Scalars['Int'];
  /** 원격 스크립트 타입 */
  remoteScriptType: Remote_Script_Type;
  updatedAt: Scalars['DateTime'];
};

/** RemoteScriptProvider 생성을 위한 input */
export type RemoteScriptProviderCreationInput = {
  /** 원격 스크립트 프로바이더 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 원격 스크립트 파람 list */
  paramsDescription?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  provider: Scalars['String'];
  /** 원격 스크립트 타입 */
  remoteScriptType: Remote_Script_Type;
};

/** RemoteScriptProvider 업데이트를 위한 input */
export type RemoteScriptProviderUpdateInput = {
  /** 원격 스크립트 프로바이더 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 원격 스크립트 파람 list */
  paramsDescription?: InputMaybe<Scalars['String']>;
};

/** 속한 세션의 AI 자케 생성 요청 및 결과 정보 */
export type RequestAiCharacter = {
  __typename?: 'RequestAICharacter';
  /** 원본 캐릭터 ID (-1이면 주인공) */
  chrId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  id: Scalars['Int'];
  /** AI로 생성된 이미지 파일 */
  imageFile?: Maybe<File>;
  /** AI로 생성된 이미지 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** negative 프롬프트 */
  negativePrompt: Scalars['String'];
  /** 프롬프트 */
  prompt: Scalars['String'];
  /** 세션 ID (uuid) */
  sessionId: Scalars['String'];
  /** 해당 요청의 상태 */
  status: AiRequestStatus;
  /** 등장인물이 속한 작품 ID */
  storyId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  /** 사용자가 업로드한 이미지 파일 */
  uploadImage: AiUserUploadFile;
  /** 사용자가 업로드한 이미지 파일 ID */
  uploadImageId: Scalars['Int'];
  /** 유저 */
  user: User;
  /** 유저 ID */
  userId: Scalars['Int'];
};

/** AI 캐릭터 생성 요청시 필요한 데이터 */
export type RequestAiCharacterCreationInput = {
  /** 보정 프롬프트의 아이디들 */
  additionalPromptIds?: InputMaybe<Array<Scalars['Int']>>;
  /** character ID */
  chrId: Scalars['Int'];
  /** 유저 이미지 파일 */
  image: Scalars['Upload'];
  /** sessionId */
  sessionId: Scalars['String'];
  /** story ID */
  storyId: Scalars['Int'];
  /** 토스페이먼츠로 결제 후 생성 */
  tossPaymentsId?: InputMaybe<Scalars['Int']>;
  /** 인앱 결제 후 생성 */
  upcId?: InputMaybe<Scalars['Int']>;
  /** 코인 소비해서 생성 */
  useCoin?: InputMaybe<Scalars['Int']>;
  /** 광고 보고 생성 */
  viewAd?: InputMaybe<Scalars['Boolean']>;
};

/** ai 캐릭터 생성 session 정보 */
export type RequestAiSessionResponse = {
  __typename?: 'RequestAISessionResponse';
  /** AI 자케 생성 요청한 정보 */
  request?: Maybe<RequestAiCharacter>;
  /** sessionId */
  sessionId: Scalars['String'];
};

/** 결제 요청을 위한 input */
export type RequestImpPaymentsInput = {
  /** 결제 대상 상품의 고유 아이디 */
  productId: Scalars['String'];
};

/** 토스 페이먼츠 결제 요청을 위한 input */
export type RequestTossPaymentsInput = {
  /** 결제 대상 상품의 고유 아이디(번호 프라이머리키) */
  inappCoinId: Scalars['Int'];
  /** 결제 타입 OVERSEAS: 해외(해와카드사 + 페이팔) DOMESTIC: 국내(국내카드사 + 휴대폰 등등) */
  payType: Toss_Payments_Pay_Type;
  /** 결제 대상 상품의 고유 아이디(text) */
  productId: Scalars['String'];
};

export type RequestTtsResponse = {
  __typename?: 'RequestTtsResponse';
  /** TTS 서버 access token */
  accessToken: Scalars['String'];
};

/** 작품 전환율 통계 정보를 갱신하기 위한 입력값 */
export type RunConversionBatchInput = {
  /** 전환율 타입 */
  conversionType: Story_Conversion_Stat_Type;
  /** YYYY-MM-DD */
  date: Scalars['String'];
  /** 일간, 주간 */
  type: Story_Stat_Cache_Type;
};

/** 수동으로 배치 작업을 수행하기 위한 입력값 */
export type RunStatBatchInput = {
  /** YYYY-MM-DD */
  date: Scalars['String'];
};

/** 성별 */
export enum Sex_Distinction {
  Man = 'Man',
  Neutrality = 'Neutrality',
  Woman = 'Woman'
}

/** 사운드 이펙트 파일이 등록되는 테이블 */
export type SfxFile = {
  __typename?: 'SFXFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** SFXFile 항목을 생성할 때 필요한 데이터 */
export type SfxFileCreationInput = {
  /** 사운드 이펙트 파일 */
  file: Scalars['Upload'];
};

/** SSO 정보를 담고 있는 테이블 */
export type Sso = {
  __typename?: 'SSO';
  createdAt: Scalars['DateTime'];
  /** 해당 SSO Provider 가 전달한 프로필 정보 */
  profile: Scalars['String'];
  /** 편의용 내부 아이디 */
  sId: Scalars['Int'];
  /** ID from SSO Provider */
  ssoId: Scalars['String'];
  /** SSO Provider */
  ssoProvider: Sso_Provider;
  updatedAt: Scalars['DateTime'];
  /** 연동된 사용자 */
  user: User;
  /** 연동된 사용자 Id */
  userId: Scalars['Int'];
};

/** SSO 이용한 로그인 정보 */
export type SsoLogin = {
  /** 연동할 SSO 서버에 API 요청을 위한 인증코드. 현재는 iOS refresh 토큰을 받는 용도로만 사용되고 있음 */
  authorizationCode?: InputMaybe<Scalars['String']>;
  /** 연동 SSO 에서의 ID */
  ssoId: Scalars['String'];
  /** 연동 SSO 의 Payload */
  ssoPayload: Scalars['String'];
  /** 연동 SSO */
  ssoProvider?: InputMaybe<Sso_Provider>;
};

/** SSO 이용한 가입 정보 */
export type SsoSignup = {
  /** 연동할 SSO 서버에 API 요청을 위한 인증코드. 현재는 iOS refresh 토큰을 받는 용도로만 사용되고 있음 */
  authorizationCode?: InputMaybe<Scalars['String']>;
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 연동 SSO 에서의 ID */
  ssoId: Scalars['String'];
  /** 연동 SSO 의 Payload */
  ssoPayload: Scalars['String'];
  /** 연동 SSO 에서 전달한 Profile JSON */
  ssoProfile?: InputMaybe<Scalars['String']>;
  /** 연동 SSO 의 Provider */
  ssoProvider: Sso_Provider;
};

/** SSO 이용한 가입 정보 */
export type SsoSignupV2 = {
  /** 연동할 SSO 서버에 API 요청을 위한 인증코드. 현재는 iOS refresh 토큰을 받는 용도로만 사용되고 있음 */
  authorizationCode?: InputMaybe<Scalars['String']>;
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 연동 SSO 에서의 ID */
  ssoId: Scalars['String'];
  /** 연동 SSO 의 Payload */
  ssoPayload: Scalars['String'];
  /** 연동 SSO 에서 전달한 Profile JSON */
  ssoProfile?: InputMaybe<Scalars['String']>;
  /** 연동 SSO 의 Provider */
  ssoProvider: Sso_Provider;
  /** 유저가 동의한 약관. 국가별로 약관 종류 다름 */
  termsToAccept: AcceptTermsInputV2;
};

/** SSO Provider */
export enum Sso_Provider {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Kakao = 'KAKAO'
}

/** 컬럼 상태값 */
export enum Status {
  Active = 'Active',
  Deleted = 'Deleted',
  Disabled = 'Disabled',
  Inactive = 'Inactive'
}

/** 스토리게임에 필요한 추가 이미지 타입 정의 */
export enum Storygame_Image_Platform_Type {
  Mobile = 'Mobile',
  Pc = 'PC'
}

/** 스토리게임의 상세 페이지에서 보이는 리소스 타입 (image or video) */
export enum Storygame_Preview_Resource_Type {
  Image = 'Image',
  Video = 'Video'
}

/** 스토리 번들의 접속 국가 제한 */
export enum Story_Bundle_Country_Access {
  Allow = 'allow',
  Deny = 'deny'
}

/** 전환율 타입 (신규 유저, 처음 플레이한 유저, 전체) */
export enum Story_Conversion_Stat_Type {
  All = 'All',
  NewPlayer = 'NewPlayer',
  NewUser = 'NewUser'
}

/** 스토리 엔딩 도착 확률 표시 타입 설계값, 실제값, 미표시 */
export enum Story_Ending_Arrival_Rate_Type {
  InVisible = 'InVisible',
  RealValue = 'RealValue',
  SettingValue = 'SettingValue',
  Unknown = 'Unknown'
}

/** 스토리 추천 타입 */
export enum Story_Has_Recommendation_Type {
  AdminSelect = 'AdminSelect'
}

/** 작품 플레이 타입 앱용 */
export enum Story_Play_Type {
  Epub = 'EPUB',
  Interactive = 'Interactive',
  Storygame = 'Storygame'
}

/** 스토리 속성의 데이터 타입 */
export enum Story_Prop_Type {
  Number = 'NUMBER',
  String = 'STRING'
}

/** 작품 랭킹 타입 (플레이수, 정주행률, 좋아요, 댓글) */
export enum Story_Rank_Type {
  Conversion = 'Conversion',
  NumComment = 'NumComment',
  NumLike = 'NumLike',
  NumPlay = 'NumPlay'
}

/** 스토리 리액션 */
export enum Story_Reaction_Type {
  Like = 'Like'
}

/** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
export enum Story_Section_Type {
  Adult = 'Adult',
  Challenge = 'Challenge',
  Dropped = 'Dropped',
  Global = 'Global',
  Monthly = 'Monthly',
  OnStage = 'OnStage',
  Ugc = 'UGC'
}

/** 작품 정렬 타입. 도전스토리는 CHALLENGE_STORY_SORTING 참조. */
export enum Story_Sorting {
  DailyRankAsc = 'DAILY_RANK_ASC',
  NumLikesDesc = 'NUM_LIKES_DESC',
  NumPlayDesc = 'NUM_PLAY_DESC',
  NumViewsDesc = 'NUM_VIEWS_DESC',
  PublishedDesc = 'PUBLISHED_DESC',
  Random = 'RANDOM',
  ShowOrderDesc = 'SHOW_ORDER_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

/** 작품 통계 캐시 타입(일간, 주간, 월간) */
export enum Story_Stat_Cache_Type {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week'
}

/** 작품 타입 */
export enum Story_Type {
  Chat = 'Chat',
  Novel = 'Novel'
}

/** 정식작품 연재요일 */
export enum Story_Weekday {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

/** 스튜디오를 통해서 업로드 가능한 파일의 타입 */
export enum Studio_File_Type {
  Image = 'Image',
  Sfx = 'SFX'
}

/** 스튜디오에서 작품 가격정보 설정 타입 */
export enum Studio_Story_Price_Setting_Type {
  Free = 'FREE',
  FreeAd = 'FREE_AD',
  Paid = 'PAID'
}

/** PublicResourceFile 을 사용할 때 필요한 데이터 */
export type SavePublicResourceFileToStudioFileInput = {
  /** 공용리소스 아이디 */
  publicResourceFileId: Scalars['Int'];
  /** 작품의 ID */
  storyId: Scalars['Int'];
};

/** 작품명 / 작가명에 맞는 스토리 목록 검색 */
export type SearchStoryInput = {
  /** 검색어 */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type SearchStoryOutput = {
  __typename?: 'SearchStoryOutput';
  /** List of story data */
  list: Array<Story>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};

/** StoryTag 검색을 위한 input */
export type SearchStoryTagInput = {
  /** 태그명 */
  name?: InputMaybe<Scalars['String']>;
};

/** 사용자의 플레이어 보고서 정보를 검색하는데 필요한 데이터 */
export type SearchUserPlayerReport2Input = {
  /** 검색어 (유저아이디, 사용자이름, 필명) */
  filter?: InputMaybe<Scalars['String']>;
  /** 조회할 페이지 */
  page?: InputMaybe<Scalars['Int']>;
  /** 조회할 페이지당 아이템 수 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** 보고서 아이디 */
  reportId?: InputMaybe<Scalars['Int']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** sectionStoryGenres의 input */
export type SectionStoryGenresInput = {
  tab: Scalars['String'];
};

export type SelectedNumber = {
  __typename?: 'SelectedNumber';
  /** 선택 횟수 */
  count: Scalars['Int'];
  /** 번호 */
  selectedNumber: Scalars['Int'];
};

/** 작품에 이벤트를 발생시킬 때 필요한 입력값 */
export type SendAchievementEventInput = {
  /** 이벤트이름 */
  event: Scalars['String'];
  /** numParam1 */
  numParam1?: InputMaybe<Scalars['Int']>;
  /** numParam2 */
  numParam2?: InputMaybe<Scalars['Int']>;
  /** storyId */
  storyId: Scalars['Int'];
};

/** 이벤트 전송을 위한 input */
export type SendAppEventInput = {
  /** 앱 이벤트의 raw 파라미터 (JSON object) */
  param?: InputMaybe<Scalars['JSONObject']>;
  /** 앱 이벤트 종류 */
  type: App_Event_Type;
};

/** NoticePopup 생성을 위한 입력값 */
export type SendContactMailInput = {
  /** 문의내용 */
  content: Scalars['String'];
  /** 문의하는 사용자 email */
  email: Scalars['String'];
  /** 문의 이메일 타입 */
  type: Contact_Mail_Type;
};

/** StoryBundleStoreCountry 설정을 위한 input */
export type SetStoryBundleStoreCountriesInput = {
  /** 대상 번들 아이디 */
  bundleId: Scalars['Int'];
  /** 해당 번들(작품)이 스토어에서 서비스 되어야하는 국가 코드 목록 */
  storeServiceCountryCodes: Array<Scalars['String']>;
};

/** 작품에 프롬프트 세팅하기 위한 input */
export type SetStorygamePromptInput = {
  /** 프롬프트 세트 이름 */
  name: Scalars['String'];
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 공유링크로 받은 토큰 Input */
export type ShareTokenInput = {
  /** share Token */
  shareToken: Scalars['String'];
};

/** (deprecated) 회원가입 시 보여줄 약관. SignUpTermsV2로 대체되었습니다. */
export type SignUpTerms = {
  __typename?: 'SignUpTerms';
  /** 개인정보 수집 및 이용 */
  CollectionAndUseOfPrivacy: Terms;
  /** 서비스 이용 약관 */
  TOS: Terms;
  /** true이면 회원가입 시 보여주는 레거시 약관(개인정보 위탁제공, 안드로이드 UGC 정책)을 숨긴다 */
  hideLegacyTerms: Scalars['Boolean'];
};

/** 회원가입 시 보여줄 약관 */
export type SignUpTermsV2 = {
  __typename?: 'SignUpTermsV2';
  /** 개인정보 수집 및 이용. 한국에서만 노출, 그 외 국가에서는 null */
  CollectionAndUseOfPrivacy?: Maybe<Terms>;
  /** 개인정보 처리방침. 한국 외 모든 국가에서만 노출, 한국에서는 null */
  PP?: Maybe<Terms>;
  /** 서비스 이용 약관 */
  TOS: Terms;
  /** true이면 회원가입 시 보여주는 레거시 약관(개인정보 위탁제공, 안드로이드 UGC 정책)을 숨긴다 */
  hideLegacyTerms: Scalars['Boolean'];
};

/** 코드를 전달하기 위한 입력값 */
export type SingleCodeInput = {
  /** 보너스 티켓 코드 */
  code: Scalars['String'];
};

/** 가입 전 이메일 인증을 위해 이메일을 전송하거나, 이메일 주소가 이미 가입되었는지 체크하기 위한 입력값으로 사용합니다. */
export type SingleEmailInput = {
  /** 이메일 */
  email: Scalars['String'];
};

/** 단일 아이디 Input */
export type SingleIdInput = {
  /** ID */
  id: Scalars['Int'];
};

/** 단일 모바일 기기 os Input */
export type SingleOsInput = {
  /** 클라이언트의 os */
  os: Os;
};

/** 단일 문자열 아이디 Input */
export type SingleStringIdInput = {
  /** ID */
  id: Scalars['String'];
};

/** 광고스킵 상품. 코인으로 구매할 수 있음 */
export type SkipAds = {
  __typename?: 'SkipAds';
  createdAt: Scalars['DateTime'];
  /** 상품에 대한 설명 */
  desc: Scalars['String'];
  /** 할인율 */
  discountRate: Scalars['Int'];
  /** 스킵 지속 기간. 단위는 초(second) */
  duration: Scalars['Int'];
  /** 해당 상품 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** (deprecated: languageCode로 대체) 상품의 언어코드 */
  langCode: Scalars['String'];
  /** 상품의 언어 정보 */
  language?: Maybe<Language>;
  /** 상품의 언어 코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 상품의 메뉴 아이콘 */
  menuIconFile?: Maybe<ImageFile>;
  /** 상품 메뉴 아이콘의 아이디 */
  menuIconFileId?: Maybe<Scalars['Int']>;
  /** 할인 적용 전 구매에 필요한 코인 수 */
  originalPrice: Scalars['Int'];
  /** 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 보여지는 순서. 클수록 상단에 보여짐 */
  showOrder: Scalars['Int'];
  /** 아이디 */
  skipAdsId: Scalars['Int'];
  /** 메뉴 타이틀 */
  title: Scalars['String'];
  /** 주제 (자유입력) */
  topic: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** SkipAds 생성을 위한 input */
export type SkipAdsCreationInput = {
  /** 상품에 대한 설명 */
  desc: Scalars['String'];
  /** 스킵 지속 기간. 단위는 초(second) */
  duration: Scalars['Int'];
  /** 해당 상품 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** (deprecated: languageCode로 대체) 상품의 언어코드 */
  langCode: Scalars['String'];
  /** 상품의 메뉴 아이콘 */
  menuIconFile?: InputMaybe<Scalars['Upload']>;
  /** 할인 적용 전 구매에 필요한 코인 수 */
  originalPrice: Scalars['Int'];
  /** 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 보여지는 순서. 클수록 상단에 보여짐 */
  showOrder: Scalars['Int'];
  /** 메뉴 타이틀 */
  title: Scalars['String'];
  /** 주제 (자유입력) */
  topic: Scalars['String'];
};

/** SkipAds 업데이트를 위한 input */
export type SkipAdsUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 스토리에서 다음 챕터 플레이를 하기 위한 input */
export type StartChapterInput = {
  /** 챕터 ID */
  chapterId: Scalars['Int'];
  /** 재시작여부. 과거 챕터부터 재시작하는 경우, true 를 전달. 코인 소모가 필요할 것이다. */
  isRestarting: Scalars['Boolean'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 플레이 할 작품이 속한 스토리게임 섹션 아이디. 스토리게임만 유효 */
  storygameSectionId?: InputMaybe<Scalars['Int']>;
  /** 무료가 아닌 챕터의 경우 코인을 사용한다는 것을 확인받았나? */
  willUseCoin: Scalars['Boolean'];
};

/** 웹소설 스토리의 챕터를 플레이 하기 위한 input */
export type StartEpubChapterInput = {
  /** 챕터 ID */
  chapterId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 무료가 아닌 챕터의 경우 코인을 사용한다는 것을 확인받았나? */
  willUseCoin: Scalars['Boolean'];
};

/** 신규로 작품을 시작할 때 필요한 데이터 */
export type StartStoryInput = {
  /** 사용자가 업로드한 주인공 프로필 이미지 정보 */
  customProfileFileInfo?: InputMaybe<CustomProfileFileInfo>;
  /** 유저가 입력한 이름 */
  firstName: Scalars['String'];
  /** 코인을 사용하여 재시작하는 것인가? */
  isRestarting: Scalars['Boolean'];
  /** 유저가 입력한 성씨 */
  lastName?: InputMaybe<Scalars['String']>;
  /** 사용자가 설정한 상대역 정보 리스트 */
  otherCharacterInfoList?: InputMaybe<Array<OtherCharacterInfo>>;
  /** 유저 타입(playerClass) ID */
  playerClassId?: InputMaybe<Scalars['Int']>;
  /** 선택지로 결정된 속성값 */
  popupProps?: InputMaybe<Scalars['JSONObject']>;
  /** 로필 이미지 파일 ID */
  profileImageId?: InputMaybe<Scalars['Int']>;
  /** 시작할 작품의 storyId */
  storyId?: InputMaybe<Scalars['Int']>;
  /** 플레이 할 작품이 속한 스토리게임 섹션 아이디. 스토리게임만 유효 */
  storygameSectionId?: InputMaybe<Scalars['Int']>;
  /** 무료가 아닌 챕터의 경우 코인을 사용한다는 것을 확인받았나? - 단, 재시작과는 별개, 재시작은 무조건 코인 소모. (1챕터가 유료이며, 구매기록이 없는 경우) */
  willUseCoin: Scalars['Boolean'];
};

/** 통계 수치 저장 - 매일의 기록 (전체 서비스) */
export type StatDaily = {
  __typename?: 'StatDaily';
  /** 날짜 토큰 (YYYY-MM-DD) */
  dateToken: Scalars['String'];
  /** 당일 가입 수 */
  signup: Scalars['Int'];
  /** 통계 내부 아이디 */
  statDailyId: Scalars['Float'];
  /** 사용자 순방문자 수 */
  uniqueVisit: Scalars['Int'];
  /** 정식 사용자 전환 수 */
  userConversion: Scalars['Int'];
};

/** 통계 조회를 위한 입력 데이터 */
export type StatDateRange = {
  /** 종료 날짜 (inclusive, YYYY-MM-DD) */
  end: Scalars['String'];
  /** 시작 날짜 (inclusive, YYYY-MM-DD) */
  start: Scalars['String'];
};

/** 통계 조회를 위한 입력 데이터 */
export type StatDateRangeWithId = {
  /** 종료 날짜 (inclusive, YYYY-MM-DD) */
  end: Scalars['String'];
  /** ID */
  id: Scalars['Int'];
  /** 시작 날짜 (inclusive, YYYY-MM-DD) */
  start: Scalars['String'];
};

/** 스토리지 타입 */
export enum Storages {
  AiUserUploadFile = 'AiUserUploadFile',
  BannerImageFile = 'BannerImageFile',
  ChapterCommentImageFile = 'ChapterCommentImageFile',
  ChapterScriptFile = 'ChapterScriptFile',
  EpubFile = 'EPUBFile',
  ImageFile = 'ImageFile',
  PublicResourceFile = 'PublicResourceFile',
  SfxFile = 'SFXFile',
  StoryFiles = 'StoryFiles',
  StoryZipFile = 'StoryZipFile',
  StudioFile = 'StudioFile',
  UserProfileFile = 'UserProfileFile'
}

/** 스토어 번들 */
export type StoreBundle = {
  __typename?: 'StoreBundle';
  /** 스토어 번들 노출 제한 국가 목록 */
  blockedCountries: Array<Country>;
  /** 스토어 번들 언어별 정보 목록 */
  contents: Array<StoreBundleContent>;
  createdAt: Scalars['DateTime'];
  /** 해당 기록 삭제 여부 (soft delete) */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 내가 장바구니에 추가했는지 여부 */
  isAddedToStoreCartByMe?: Maybe<Scalars['Boolean']>;
  /** 출시(노출) 여부 */
  isPublished: Scalars['Boolean'];
  /** 내가 구매했는지 여부 */
  isPurchasedByMeInStore: Scalars['Boolean'];
  /** 스토어 번들의 특정 언어 정보 */
  localContent?: Maybe<StoreBundleContent>;
  /** 스토어 번들의 특정 국가 가격 */
  localPrice?: Maybe<StoreBundlePrice>;
  /** 스토어 번들 국가별 가격 목록 */
  prices: Array<StoreBundlePrice>;
  /** 노출 국가 목록 */
  serviceCountries: Array<Country>;
  /** 아이디 */
  storeBundleId: Scalars['Int'];
  /** 스토어 번들에 포함된 스토리 번들 목록 */
  storyBundles: Array<StoryBundle>;
  /** 작품 언어 목록 */
  storyLanguages: Array<Language>;
  updatedAt: Scalars['DateTime'];
  /** 엑솔라 프로모션 아이디 */
  xsollaPromotionId?: Maybe<Scalars['Int']>;
  /** 엑솔라 sku */
  xsollaSku?: Maybe<Scalars['String']>;
  /** 엑솔라 수정 시간 */
  xsollaUpdatedAt?: Maybe<Scalars['DateTime']>;
};


/** 스토어 번들 */
export type StoreBundleLocalContentArgs = {
  fallback?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
};


/** 스토어 번들 */
export type StoreBundleLocalPriceArgs = {
  countryCode: Scalars['String'];
  fallback?: InputMaybe<Scalars['String']>;
};

/** 스토어 번들 노출 제한 국가 */
export type StoreBundleBlockedCountry = {
  __typename?: 'StoreBundleBlockedCountry';
  /** 노출 제한 국가 */
  country: Country;
  /** 노출 제한 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 스토어 번들 */
  storeBundle: StoreBundle;
  /** 아이디 */
  storeBundleBlockedCountryId: Scalars['Int'];
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토어 번들 언어별 정보 */
export type StoreBundleContent = {
  __typename?: 'StoreBundleContent';
  /** 커버 이미지 파일 */
  coverImageFile: ImageFile;
  /** 커버 이미지 파일 아이디 */
  coverImageFileId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 스토어 번들 설명 */
  description: Scalars['String'];
  /** 스토어 번들 정보의 언어 */
  language?: Maybe<Language>;
  /** 스토어 번들 정보의 언어 코드 */
  languageCode: Scalars['String'];
  /** 스토어 번들명 */
  name: Scalars['String'];
  /** 스토어 번들 언어별 정보 아이디 */
  storeBundleContentId: Scalars['Int'];
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StoreBundleContent 생성을 위한 input */
export type StoreBundleContentCreateInput = {
  /** 스토어번들 가로형 표지(16:9) */
  coverImageFile: Scalars['Upload'];
  /** 스토어 번들 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 스토어 번들 정보의 언어 코드 */
  languageCode: Scalars['String'];
  /** 스토어 번들명 */
  name: Scalars['String'];
};

/** StoreBundleContent 수정을 위한 input */
export type StoreBundleContentUpdateInput = {
  /** 스토어번들 가로형 표지(16:9) */
  coverImageFile?: InputMaybe<Scalars['Upload']>;
  /** 스토어 번들 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 스토어 번들명 */
  name?: InputMaybe<Scalars['String']>;
  /** 스토어 번들 언어별 정보 아이디 */
  storeBundleContentId: Scalars['Int'];
};

/** 스토어 번들 서비스 국가 */
export type StoreBundleCountry = {
  __typename?: 'StoreBundleCountry';
  /** 서비스 국가 */
  country: Country;
  /** 서비스 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 스토어 번들 */
  storeBundle: StoreBundle;
  /** 아이디 */
  storeBundleCountryId: Scalars['Int'];
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StoreBundle 생성을 위한 input */
export type StoreBundleCreateInput = {
  /** 노출 제한 국가 코드 목록 */
  blockedCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  /** 스토어 번들 정보 목록 */
  contents: Array<StoreBundleContentCreateInput>;
  /** 출시(노출) 여부 */
  isPublished: Scalars['Boolean'];
  /** 스토어 번들 가격 목록 */
  prices: Array<StoreBundlePriceCreateInput>;
  /** 노출 국가 코드 목록 */
  serviceCountryCodes: Array<Scalars['String']>;
  /** 스토어 번들에 포함할 스토리 번들 아이디 목록 */
  storyBundleIds: Array<Scalars['Int']>;
  /** 스토어 번들에 포함할 작품의 언어 코드 목록 */
  storyLanguageCodes: Array<Scalars['String']>;
};

/** 스토어 번들 작품 언어 */
export type StoreBundleLanguage = {
  __typename?: 'StoreBundleLanguage';
  /** 스토어 번들 작품 언어 */
  language: Language;
  /** 스토어 번들 작품 언어 코드 */
  languageCode: Scalars['String'];
  /** 스토어 번들 */
  storeBundle: StoreBundle;
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  /** 아이디 */
  storeBundleLanguageId: Scalars['Int'];
};

/** 스토어 번들 국가별 가격 */
export type StoreBundlePrice = {
  __typename?: 'StoreBundlePrice';
  /** 판매 국가 */
  country?: Maybe<Country>;
  /** 판매 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 수동으로 입력 가능한 할인율(0.0~1.0) */
  discountRate?: Maybe<Scalars['Int']>;
  /** 원가 */
  originalPrice?: Maybe<Scalars['Int']>;
  /** 판매 가격, 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 판매 종료 날짜 */
  saleEndDate: Scalars['DateTime'];
  /** 판매 시작 날짜 */
  saleStartDate: Scalars['DateTime'];
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  /** 스토어 번들 국가별 가격 아이디 */
  storeBundlePriceId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StoreBundlePrice 생성을 위한 input */
export type StoreBundlePriceCreateInput = {
  /** 판매 국가 코드 */
  countryCode: Scalars['String'];
  /** 원가 */
  originalPrice?: InputMaybe<Scalars['Int']>;
  /** 판매 가격, 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 판매 시작 날짜 */
  saleEndDate: Scalars['DateTime'];
  /** 판매 시작 날짜 */
  saleStartDate: Scalars['DateTime'];
};

/** 스토리 번들 in 스토어 번들 */
export type StoreBundleStoryBundle = {
  __typename?: 'StoreBundleStoryBundle';
  /** 스토어 번들 */
  storeBundle: StoryBundle;
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  /** 아이디 */
  storeBundleStoryBundleId: Scalars['Int'];
  /** 스토어 번들 */
  storyBundle: StoryBundle;
  /** 스토리 번들 아이디 */
  storyBundleId: Scalars['Int'];
};

/** StoreBundle 수정을 위한 input */
export type StoreBundleUpdateInput = {
  /** 노출 제한 국가 코드 목록 */
  blockedCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  /** 스토어 번들 정보 목록 */
  contents?: InputMaybe<Array<StoreBundleContentUpdateInput>>;
  /** 출시(노출) 여부 */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** 아이디 */
  storeBundleId: Scalars['Int'];
};

/** 스토어 장바구니 목록 */
export type StoreCartItem = {
  __typename?: 'StoreCartItem';
  createdAt: Scalars['DateTime'];
  /** 제품(StoryBundle or StoreBundle) */
  product: StoreCartItemProduct;
  /** 제품 아이디(type:id) ex)Storygame:1 */
  productId: Scalars['String'];
  /** 아이디 */
  storeCartItemId: Scalars['Int'];
  /** 아이템 타입(Storygame, StoreBundle) */
  type: StoreCartItemType;
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

export type StoreCartItemProduct = StoreBundle | StoryBundle;

/** 스토어 장바구니 아이템 타입 */
export enum StoreCartItemType {
  StoreBundle = 'StoreBundle',
  StoryBundle = 'StoryBundle'
}

/** 스토어 장바구니 스토어 번들 추가 또는 삭제를 위한 input */
export type StoreCartStoreBundleItemInput = {
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
};

/** 스토어 장바구니 스토리 번들(작품) 추가 또는 삭제를 위한 input */
export type StoreCartStoryBundleItemInput = {
  /** 스토리 번들(작품) 아이디 */
  storyBundleId: Scalars['Int'];
};

/** 스토어 페이지 i18n */
export type StorePageI18n = {
  __typename?: 'StorePageI18n';
  /** 영어 */
  en_US: Scalars['String'];
  /** 한국어 */
  ko_KR: Scalars['String'];
};

/** 스토어 페이지 i18n */
export type StorePageI18nInput = {
  /** 영어 */
  en_US: Scalars['String'];
  /** 한국어 */
  ko_KR: Scalars['String'];
};

/** 스토어 페이지 섹션 */
export type StorePageSection = {
  __typename?: 'StorePageSection';
  createdAt: Scalars['DateTime'];
  /** 추가 작품 목록 */
  extraItems?: Maybe<Array<StoryBundle>>;
  /** 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** 테스트 지면 여부 */
  isTestSection?: Maybe<Scalars['Boolean']>;
  /** UI 타입, 옵션에 따른 아이템 목록 */
  items: Array<StorePageSectionItem>;
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 발행 시간 */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** 노출 국가 목록 */
  serviceCountries: Array<StorePageSectionCountry>;
  /** 클라이언트에서 보여질 순서. 낮을수록 먼저 등장 */
  showOrder: Scalars['Int'];
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
  /** 스토어 페이지 탭 타입  */
  storePageTabType: StorePageTabType;
  /** 테스트 지면의 노출 비율(isTestSection이 true일 때만 사용) */
  testSectionPercentage?: Maybe<Scalars['Int']>;
  /** 언어별 제목 */
  title?: Maybe<StorePageI18n>;
  /** UI 타입에 따른 옵션 */
  uiOptions?: Maybe<StorePageSectionUiOptions>;
  /** UI 타입 */
  uiType: StorePageSectionUiType;
  updatedAt: Scalars['DateTime'];
};


/** 스토어 페이지 섹션 */
export type StorePageSectionItemsArgs = {
  genre?: InputMaybe<Challenge_Story_Genre>;
  isAdult?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<Scalars['String']>;
};

/** 스토어 섹션 알고리즘 */
export enum StorePageSectionAlgorithm {
  HighAppu = 'HighAppu',
  HighArpu = 'HighArpu',
  HighestPositiveRatio = 'HighestPositiveRatio',
  Manual = 'Manual',
  MostPositiveReviews = 'MostPositiveReviews',
  MostPreordered = 'MostPreordered',
  MostSold = 'MostSold',
  MostViewed = 'MostViewed',
  MostWishlisted = 'MostWishlisted',
  RecentlyPlayed = 'RecentlyPlayed',
  UpcomingRelease = 'UpcomingRelease'
}

export type StorePageSectionBanner = StorePageSectionBannerLinkOptions | StorePageSectionBannerStorygameOptions;

/** 스토어 슬라이더 섹션 배너 input */
export type StorePageSectionBannerInput = {
  /** 내용 */
  description?: InputMaybe<StorePageI18nInput>;
  /** 이미지 파일 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 이미지 파일 아이디(새로운 파일이 아닌 경우) */
  imageFileId?: InputMaybe<Scalars['Int']>;
  /** 스토리 번들 아이디 */
  storyBundleId?: InputMaybe<Scalars['Int']>;
  /** 제목 */
  title?: InputMaybe<StorePageI18nInput>;
  /** 타입 */
  type: Scalars['String'];
  /** URL */
  url?: InputMaybe<Scalars['String']>;
};

/** 스토어 섹션 배너 링크 */
export type StorePageSectionBannerLink = {
  __typename?: 'StorePageSectionBannerLink';
  /** 내용 */
  description: StorePageI18n;
  /** 이미지 파일 */
  imageFile: ImageFile;
  /** 제목 */
  title: StorePageI18n;
  /** URL */
  url: Scalars['String'];
};

/** 스토어 섹션 배너 링크 옵션 */
export type StorePageSectionBannerLinkOptions = {
  __typename?: 'StorePageSectionBannerLinkOptions';
  /** 내용 */
  description: StorePageI18n;
  /** 이미지 파일 아이디 */
  imageFileId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18n;
  /** 링크 타입 */
  type: Scalars['String'];
  /** URL */
  url: Scalars['String'];
};

/** 스토어 섹션 배너 스토리 게임 옵션  */
export type StorePageSectionBannerStorygameOptions = {
  __typename?: 'StorePageSectionBannerStorygameOptions';
  /** 스토리 번들 아이디 */
  storyBundleId: Scalars['Int'];
  /** 스토리게임 타입 */
  type: Scalars['String'];
};

/** 스토어 섹션 캐러셀 번들형 옵션 */
export type StorePageSectionCarouselBundleOptions = {
  __typename?: 'StorePageSectionCarouselBundleOptions';
  /** 노출 번들 수 */
  itemSize: Scalars['Int'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 캐러셀 진행형 ui 타입 */
  uiType: Scalars['String'];
};

/** 스토어 케러셀 번들형 섹션 수정을 위한 input */
export type StorePageSectionCarouselBundleUpdateInput = {
  /** 리스트당 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18nInput;
};

/** 스토어 섹션 캐러셀 옵션 */
export type StorePageSectionCarouselOptions = {
  __typename?: 'StorePageSectionCarouselOptions';
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 추가 작품 아이디 목록 */
  extraStoryBundleIds: Array<Scalars['Int']>;
  /** 더보기 표시 여부 */
  hasViewMore: Scalars['Boolean'];
  /** 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 캐러셀 기본형 ui 타입 */
  uiType: Scalars['String'];
};

/** 스토어 섹션 캐러셀 진행형 옵션 */
export type StorePageSectionCarouselProgressOptions = {
  __typename?: 'StorePageSectionCarouselProgressOptions';
  /** 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 캐러셀 진행형 ui 타입 */
  uiType: Scalars['String'];
};

/** 스토어 케러셀 진행형 섹션 수정을 위한 input */
export type StorePageSectionCarouselProgressUpdateInput = {
  /** 리스트당 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18nInput;
};

/** 스토어 섹션 캐러셀 순위 옵션 */
export type StorePageSectionCarouselRankOptions = {
  __typename?: 'StorePageSectionCarouselRankOptions';
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 캐러셀 순위형 ui 타입 */
  uiType: Scalars['String'];
};

/** 스토어 케러셀 순위형 섹션 수정을 위한 input */
export type StorePageSectionCarouselRankUpdateInput = {
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 리스트당 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18nInput;
};

/** 스토어 케러셀 섹션 수정을 위한 input */
export type StorePageSectionCarouselUpdateInput = {
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 추가 작품 아이디 목록 */
  extraStoryBundleIds: Array<Scalars['Int']>;
  /** 더보기 표시 여부 */
  hasViewMore: Scalars['Boolean'];
  /** 리스트당 노출 작품 수 */
  itemSize: Scalars['Int'];
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18nInput;
  /** UI 타입 */
  uiType: StorePageSectionUiType;
};

/** 스토어 페이지 섹션 국가 */
export type StorePageSectionCountry = {
  __typename?: 'StorePageSectionCountry';
  /** 국가 */
  country: Country;
  /** 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 노출 여부 */
  isHidden: Scalars['Boolean'];
  /** 스토어 페이지 섹션 */
  storePageSection: StorePageSection;
  /** 아이디 */
  storePageSectionCountryId: Scalars['Int'];
  /** 스토어 페이지 섹션 아이디 */
  storePageSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토어 섹션 국가 업데이트 input */
export type StorePageSectionCountryUpdateInput = {
  /** 노출 여부 */
  isHidden: Scalars['Boolean'];
  /** 아이디 */
  storePageSectionCountryId: Scalars['Int'];
};

/** 스토어 섹션 생성을 위한 input */
export type StorePageSectionCreateInput = {
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 스토어 페이지 탭 타입  */
  storePageTabType: StorePageTabType;
  /** UI 타입 */
  uiType: StorePageSectionUiType;
};

export type StorePageSectionItem = StoreBundle | StorePageSectionBannerLink | StorePageSectionRank | StoryBundle;

/** 스토어 섹션 기간 기준 */
export enum StorePageSectionPeriod {
  All = 'All',
  Month = 'Month',
  Month2 = 'Month2',
  Month3 = 'Month3',
  Week = 'Week',
  Year = 'Year'
}

/** 스토어 섹션 발행 input */
export type StorePageSectionPublishInput = {
  /** 스토어 탭 타입 */
  storePageTabType: StorePageTabType;
};

/** 스토어 섹션 리스트 순위 */
export type StorePageSectionRank = {
  __typename?: 'StorePageSectionRank';
  /** 더보기 표시 여부 */
  hasViewMore: Scalars['Boolean'];
  /** 작품 목록 */
  items: Array<StoryBundle>;
  /** 제목 */
  title: StorePageI18n;
};

/** 스토어 섹션 순위 input */
export type StorePageSectionRankInput = {
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 더보기 표시 여부 */
  hasViewMore: Scalars['Boolean'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 제목 */
  title: StorePageI18nInput;
};

/** 스토어 섹션 리스트 순위형 옵션 */
export type StorePageSectionRankListOptions = {
  __typename?: 'StorePageSectionRankListOptions';
  /** 리스트당 노출 작품 수 */
  rankSize: Scalars['Int'];
  /** 랭크 옵션 목록 */
  ranks: Array<StorePageSectionRankOptions>;
  /** 캐러셀 진행형 ui 타입 */
  uiType: Scalars['String'];
};

/** 스토어 리스트 순위형 섹션 수정을 위한 input */
export type StorePageSectionRankListUpdateInput = {
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 리스트당 노출 작품 수 */
  rankSize: Scalars['Int'];
  /** 랭크 input 목록 */
  ranks: Array<StorePageSectionRankInput>;
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
};

/** 스토어 섹션 순위 옵션 */
export type StorePageSectionRankOptions = {
  __typename?: 'StorePageSectionRankOptions';
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 더보기 표시 여부 */
  hasViewMore: Scalars['Boolean'];
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 제목 */
  title: StorePageI18n;
};

/** 스토어 섹션 슬라이더형 옵션 */
export type StorePageSectionSliderOptions = {
  __typename?: 'StorePageSectionSliderOptions';
  /** 배너 목록 */
  banners: Array<StorePageSectionBanner>;
  /** 슬라이더형 ui 타입 */
  uiType: Scalars['String'];
};

/** 스토어 슬라이더형 섹션 수정을 위한 input */
export type StorePageSectionSliderUpdateInput = {
  /** 배너 목록 */
  banners: Array<StorePageSectionBannerInput>;
  /** 이름(어드민 표시용) */
  name: Scalars['String'];
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18nInput;
};

export type StorePageSectionUiOptions = StorePageSectionCarouselBundleOptions | StorePageSectionCarouselOptions | StorePageSectionCarouselProgressOptions | StorePageSectionCarouselRankOptions | StorePageSectionRankListOptions | StorePageSectionSliderOptions;

/** 스토어 섹션 UI 타입 */
export enum StorePageSectionUiType {
  CarouselBundle = 'CarouselBundle',
  CarouselLarge = 'CarouselLarge',
  CarouselMedium = 'CarouselMedium',
  CarouselProgress = 'CarouselProgress',
  CarouselRank = 'CarouselRank',
  CarouselSmall = 'CarouselSmall',
  RankList = 'RankList',
  Slider = 'Slider'
}

/** 스토어 섹션 수정 input */
export type StorePageSectionUpdateInput = {
  /** 숨김 여부 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 아이디 */
  storePageSectionId: Scalars['Int'];
};

/** 스토어 페이지 탭 */
export type StorePageTab = {
  __typename?: 'StorePageTab';
  createdAt: Scalars['DateTime'];
  /** 장르 코드. 탭의 타입이 genre일 때만 값이 존재 */
  genre?: Maybe<Scalars['String']>;
  /** 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** 이름 */
  name: StorePageI18n;
  /** 발행 시간 */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** 클라이언트에서 보여질 순서. 낮을수록 먼저 등장 */
  showOrder: Scalars['Int'];
  /** 아이디 */
  storePageTabId: Scalars['Int'];
  /** 탭의 타입 */
  type: StorePageTabType;
  updatedAt: Scalars['DateTime'];
};

/** 스토어 탭 종류 */
export enum StorePageTabType {
  Genre = 'Genre',
  Nsfw = 'NSFW',
  Recommended = 'Recommended'
}

/** 스토어 결제 */
export type StorePayment = {
  __typename?: 'StorePayment';
  /** 구매자 연락처 */
  buyerContact: Scalars['String'];
  /** 구매자 이메일 */
  buyerEmail: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 화폐 */
  currency: Scalars['String'];
  /** 결제 요청마다 생성되는 아이디 */
  orderId: Scalars['String'];
  /** 주문명 */
  orderName: Scalars['String'];
  /** 결제 플랫폼 */
  platform: StorePaymentPlatform;
  /** 가격 */
  price: Scalars['Int'];
  /** 구매 아이템 목록 */
  purchasedItems: Array<StorePurchasedItem>;
  /** 결제 진행 상태 */
  status: StorePaymentStatus;
  /** 아이디 */
  storePaymentId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용 코인 */
  usedCoin?: Maybe<Scalars['Int']>;
  /** 결제를 요청한 유저 */
  user: User;
  /** 결제를 요청한 유저의 아이디 */
  userId: Scalars['Int'];
};


/** 스토어 결제 */
export type StorePaymentOrderNameArgs = {
  languageCode: Scalars['String'];
};

/** 스토어 토스 페이먼츠 결제 승인을 위한 input */
export type StorePaymentConfirmTossInput = {
  /** 결제한 금액 */
  amount: Scalars['Int'];
  /** 서버에서 생성해준 결제 orderId */
  orderId: Scalars['String'];
  /** 토스 페이먼츠에서 결제후 발급해준 paymentsKey */
  paymentKey: Scalars['String'];
};

/** 스토어 토스 페이먼츠 결제 생성 input */
export type StorePaymentCreateTossInput = {
  /** 구매자 연락처 */
  buyerContact: Scalars['String'];
  /** 구매자 이메일 */
  buyerEmail: Scalars['String'];
  /** 사용할 코인 갯수 */
  coin?: InputMaybe<Scalars['Int']>;
  /** 구매할 스토어 번들 목록 */
  storeBundleIds: Array<Scalars['Int']>;
  /** 구매할 작품(스토리 번들) 목록 */
  storyBundleIds: Array<Scalars['Int']>;
};

/** 스토어 엑솔라 결제 토큰 생성 input */
export type StorePaymentCreateXsollaTokenInput = {
  /** 구매자 연락처 */
  buyerContact: Scalars['String'];
  /** 구매자 이메일 */
  buyerEmail: Scalars['String'];
  /** 엑솔라 return_url */
  returnUrl: Scalars['String'];
  /** 구매할 스토어 번들 목록 */
  storeBundleIds: Array<Scalars['Int']>;
  /** 구매할 작품(스토리 번들) 목록 */
  storyBundleIds: Array<Scalars['Int']>;
};

/** 스토어 결제 플랫폼(Toss or Xsolla) */
export enum StorePaymentPlatform {
  Toss = 'Toss',
  Xsolla = 'Xsolla'
}

/** 스토어 결제 상태 */
export enum StorePaymentStatus {
  Aborted = 'ABORTED',
  Canceled = 'CANCELED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Forgery = 'FORGERY',
  InProgress = 'IN_PROGRESS',
  PartialCanceled = 'PARTIAL_CANCELED',
  Ready = 'READY',
  WaitingForDeposit = 'WAITING_FOR_DEPOSIT'
}

/** 스토어 결제에 포함되는 스토어 번들 */
export type StorePaymentStoreBundle = {
  __typename?: 'StorePaymentStoreBundle';
  createdAt: Scalars['DateTime'];
  /** 화폐 */
  currency: Scalars['String'];
  /** 결제 가격 */
  price: Scalars['Int'];
  /** 스토어 번들 */
  storeBundle: StoreBundle;
  /** 스토어 번들 아이디 */
  storeBundleId: Scalars['Int'];
  /** 스토어 결제 아이디 */
  storePayment: StorePayment;
  /** 스토어 결제 아이디 */
  storePaymentId: Scalars['Int'];
  /** 아이디 */
  storePaymentStoreBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토어 결제에 포함되는 작품 */
export type StorePaymentStoryBundle = {
  __typename?: 'StorePaymentStoryBundle';
  createdAt: Scalars['DateTime'];
  /** 화폐 */
  currency: Scalars['String'];
  /** 결제 가격 */
  price: Scalars['Int'];
  /** 스토어 결제 아이디 */
  storePayment: StorePayment;
  /** 스토어 결제 아이디 */
  storePaymentId: Scalars['Int'];
  /** 아이디 */
  storePaymentStoryBundleId: Scalars['Int'];
  /** 작품 번들 */
  storyBundle: StoryBundle;
  /** 작품 번들 아이디 */
  storyBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type StorePurchasedItem = StoreBundle | StoryBundle;

/** 스토어 리뷰 */
export type StoreReview = {
  __typename?: 'StoreReview';
  /** 작성자 */
  author?: Maybe<User>;
  /** 작성자 아이디 */
  authorId: Scalars['Int'];
  /** 댓글 수 */
  commentCount: Scalars['Int'];
  /** 리뷰 내용 */
  content: Scalars['String'];
  /** 리뷰 내용 수정 시각 */
  contentUpdatedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** 삭제된 시각 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 나의 좋아요 여부 */
  isLikedByMe?: Maybe<Scalars['Boolean']>;
  /** 추천 여부 */
  isRecommended: Scalars['Boolean'];
  /** 좋아요 수 */
  likeCount: Scalars['Int'];
  /** 아이디 */
  storeReviewId: Scalars['Int'];
  /** 스토리 번들(작품) */
  storyBundle?: Maybe<StoryBundle>;
  /** 스토리 번들(작품) 아이디 */
  storyBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토어 리뷰 댓글 */
export type StoreReviewComment = {
  __typename?: 'StoreReviewComment';
  /** 작성자 */
  author?: Maybe<User>;
  /** 작성자 아이디 */
  authorId: Scalars['Int'];
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 리뷰 내용 수정 시각 */
  contentUpdatedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** 삭제된 시각 */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 나의 좋아요 여부 */
  isLikedByMe?: Maybe<Scalars['Boolean']>;
  /** 내가 작성한 댓글 여부 */
  isWrittenByMe?: Maybe<Scalars['Boolean']>;
  /** 좋아요 수 */
  likeCount: Scalars['Int'];
  /** 스토어 리뷰 */
  storeReview?: Maybe<StoryBundle>;
  /** 아이디 */
  storeReviewCommentId: Scalars['Int'];
  /** 스토어 리뷰 아이디 */
  storeReviewId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토어 리뷰 댓글 생성 input */
export type StoreReviewCommentCreateInput = {
  /** 댓글 내용 */
  content: Scalars['String'];
  /** 스토어 리뷰 아이디 */
  storeReviewId: Scalars['Int'];
};

/** 스토어 리뷰 댓글 좋아요 */
export type StoreReviewCommentLike = {
  __typename?: 'StoreReviewCommentLike';
  createdAt: Scalars['DateTime'];
  /** 스토어 리뷰 댓글 */
  storeReviewComment?: Maybe<StoreReviewComment>;
  /** 스토어 리뷰 댓글 아이디 */
  storeReviewCommentId: Scalars['Int'];
  /** 아이디 */
  storeReviewCommentLikeId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 스토어 리뷰 댓글 수정 input */
export type StoreReviewCommentUpdateInput = {
  /** 댓글 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  storeReviewCommentId: Scalars['Int'];
};

/** 스토어 리뷰 생성 input */
export type StoreReviewCreateInput = {
  /** 리뷰 내용 */
  content: Scalars['String'];
  /** 추천 여부 */
  isRecommended: Scalars['Boolean'];
  /** 스토리 번들(작품) 아이디 */
  storyBundleId: Scalars['Int'];
};

/** 스토어 리뷰 좋아요 */
export type StoreReviewLike = {
  __typename?: 'StoreReviewLike';
  createdAt: Scalars['DateTime'];
  /** 스토어 리뷰 */
  storeReview?: Maybe<StoreReview>;
  /** 스토어 리뷰 아이디 */
  storeReviewId: Scalars['Int'];
  /** 아이디 */
  storeReviewLikeId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 스토어 리뷰 등급 */
export enum StoreReviewRating {
  Mixed = 'Mixed',
  MostlyNegative = 'MostlyNegative',
  MostlyPositive = 'MostlyPositive',
  Negative = 'Negative',
  OverwhelminglyNegative = 'OverwhelminglyNegative',
  OverwhelminglyPositive = 'OverwhelminglyPositive',
  Positive = 'Positive',
  VeryNegative = 'VeryNegative',
  VeryPositive = 'VeryPositive'
}

/** 스토어 리뷰 수정 input */
export type StoreReviewUpdateInput = {
  /** 리뷰 내용 */
  content?: InputMaybe<Scalars['String']>;
  /** 추천 여부 */
  isRecommended?: InputMaybe<Scalars['Boolean']>;
  /** 아이디 */
  storeReviewId: Scalars['Int'];
};

/** 스토어 검색 설정 */
export type StoreSearchConfig = {
  __typename?: 'StoreSearchConfig';
  /** 노출기준 */
  algorithm: StorePageSectionAlgorithm;
  createdAt: Scalars['DateTime'];
  /** 추가 작품 목록 */
  extraStoryBundles: Array<StoryBundle>;
  /** 기간기준 */
  period: StorePageSectionPeriod;
  /** 발행시간 */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** 상태(Editing(1), Discarded(n), Active(1)) */
  status: StoreSearchConfigStatus;
  /** 아이디 */
  storeSearchConfigId: Scalars['Int'];
  /** 작품 목록 */
  storyBundles: Array<StoryBundle>;
  /** 언어별 제목 */
  title: StorePageI18n;
  updatedAt: Scalars['DateTime'];
};

/** 스토어 검색 설정 상태 */
export enum StoreSearchConfigStatus {
  Active = 'Active',
  Discarded = 'Discarded',
  Editing = 'Editing'
}

/** 스토어 검색 설정 수정 */
export type StoreSearchConfigUpdateInput = {
  /** 알고리즘 */
  algorithm: StorePageSectionAlgorithm;
  /** 추가 작품 아이디 목록 */
  extraStoryBundleIds: Array<Scalars['Int']>;
  /** 기간 기준 */
  period: StorePageSectionPeriod;
  /** 스토어 검색 설정 아이디 */
  storeSearchConfigId: Scalars['Int'];
  /** 제목 */
  title: StorePageI18nInput;
};

export type StoreSkus = {
  __typename?: 'StoreSkus';
  /** 스토어 번들 목록 */
  storeBundles?: Maybe<Array<StoreBundle>>;
  /** 작품(스토리 번들) 목록 */
  storyBundles?: Maybe<Array<StoryBundle>>;
};

/** 스토어 찜목록 */
export type StoreWishlistItem = {
  __typename?: 'StoreWishlistItem';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  storeWishlistItemId: Scalars['Int'];
  /** 스토리 번들(작품 */
  storyBundle?: Maybe<StoryBundle>;
  /** 스토리 번들(작품) 아이디 */
  storyBundleId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user?: Maybe<User>;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 저장된 스토리 */
export type Story = {
  __typename?: 'Story';
  /** 업적 서버의 해당 프로젝트 API Key */
  aApiKey?: Maybe<Scalars['String']>;
  /** 업적 서버의 해당 프로젝트 API Key ID (추후 삭제시) */
  aApiKeyId?: Maybe<Scalars['Int']>;
  /** 연동된 업적 이벤트 정보 */
  aEventDefs: Array<AEventDef>;
  /** 업적 서버에 매핑되는 해당 프로젝트의 id */
  aProjId?: Maybe<Scalars['String']>;
  /** 스토리의 모든 챕터들 (미공개 포함, 어드민만 호출 가능) */
  allChapters: Array<Chapter>;
  /** 스토리 작성자 */
  author: User;
  /** 스토리 작성자 ID */
  authorId: Scalars['Int'];
  /** 스토리 작성자 명 */
  authorName: Scalars['String'];
  /** 배경들 */
  backgrounds: Array<StoryHasBackground>;
  /** 웹소설, 인터랙티브 작품용 배너 이미지 파일 */
  bannerImageFile?: Maybe<File>;
  /** 웹소설, 인터랙티브 작품용 배너 이미지 파일 ID */
  bannerImageFileId?: Maybe<Scalars['Int']>;
  /** 작품 번들 */
  bundle?: Maybe<StoryBundle>;
  /** 작품 번들 ID */
  bundleId?: Maybe<Scalars['Int']>;
  /** 해당 작품의 번들에 속한 작품들 */
  bundleStories: Array<Story>;
  /** 스튜디오에서 발행가능한 작품인지 여부 */
  canPublish?: Maybe<Scalars['Boolean']>;
  /** 작품에 업적 기능을 사용할 수 있는지 여부 */
  canUseAchievement?: Maybe<Scalars['Boolean']>;
  /** 작품에 플레이어 보고서를 설정할 수 있는지 여부 (PlayerReport2) */
  canUserPlayerReport?: Maybe<Scalars['Boolean']>;
  /**
   * 작품의 장르 정보
   * @deprecated 글로벌 대응으로 genreKey를 사용해 주세요.
   */
  challengeStoryGenre?: Maybe<ChallengeStoryGenre>;
  /**
   * 현재 유효한 챕터 구매 정보(deprecated)
   * @deprecated 더 이상 해당 데이터를 서버에서 업데이트 하고 있지 않습니다. chapter > userPurchasedChapter 값을 참고하세요.
   */
  chapterPurchases: Array<ChapterPurchase>;
  /** 스토리의 챕터들 */
  chapters: Array<Chapter>;
  /** 스토리에 등장하는 캐릭터들 */
  characters: Array<Chr>;
  /** 작품을 리플레이하는데 필요한 코인 갯수, 첫번째 챕터의 price 와 같습니다. 단, 해당 챕터가 무료로 풀려있는 경우에는 무료로 재시작이 가능합니다. */
  coinForRestart: Scalars['Int'];
  /** 통구매를 원화로 결제할 수 있는 상품. NULL이면 원화 결제 불가 */
  coinProduct?: Maybe<CoinProduct>;
  /** 작품 띠지에 나올 텍스트 */
  coverText: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 스토리의 플레이어 디폴트 이름의 이름 */
  defaultFirstName: Scalars['String'];
  /** 스토리의 플레이어 디폴트 이름의 성 */
  defaultLastName?: Maybe<Scalars['String']>;
  /** 통구매 할인율 */
  discountRate: Scalars['Int'];
  /** JSON string. 유저가 작품에서 발견한 프로퍼티 */
  discoveredUserProps: Scalars['String'];
  /** AI 자캐 생성 설정된 작품인지 여부 */
  enabledAICharacter: Scalars['Boolean'];
  /** 전체구매로 구매할 수 있는 챕터 정보 */
  enabledPurchasedChapter: EnabledPurchasedChapter;
  /** 스토리에 통계 예측을 위한 값 범위 ex) 10% */
  endingArrivalRateRange: Scalars['Int'];
  /** 스토리 엔딩 도착 확률 표시 타입 설계값, 실제값, 미표시 */
  endingArrivalRateType: Story_Ending_Arrival_Rate_Type;
  /** 스토리의 엔딩정보 + 사용자의 엔딩 수집 정보 */
  endingInfo: CollectibleEndingOutput;
  /** 엔딩별 도달 통계 정보 */
  endingStats: EndingsStatOutput;
  exportForStudio: ExportStoryForStudio;
  /** 완결 설정한 날짜 */
  finishedAt?: Maybe<Scalars['DateTime']>;
  /** 작품이 최초로 발행된 시각 */
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  /** 타임리프 무료 종료 일자 */
  freeTimeLeapEndAt?: Maybe<Scalars['DateTime']>;
  /** 타임리프 무료 시작 일자 */
  freeTimeLeapStartAt?: Maybe<Scalars['DateTime']>;
  /** 작품의 장르 타입. 확장성을 위해 string으로 유지하고, 호환성을 위해 CHALLENGE_STORY_GENRE 유지 */
  genre: Challenge_Story_Genre;
  /** 작품의 장르 정보 */
  genreKey?: Maybe<StoryGenre>;
  /** 스토리에 업적이 존재하는지 여부. 현재는 스토리가 업적 시스템 프로젝트와 연동이 되어 있으면 true */
  hasAchievement: Scalars['Boolean'];
  /** 스토리에 아이템이 존재하는지 여부. isHidden이 false인 아이템을 카운팅하여 판단 */
  hasActiveStoryItem: Scalars['Boolean'];
  /** 출간된 회차 중 19금 회차가 하나라도 포함되어 있으면 true */
  hasAdultChapter: Scalars['Boolean'];
  /** 작품 소개 이미지 */
  introImageFile?: Maybe<File>;
  /** 작품 소개 이미지 ID */
  introImageFileId?: Maybe<Scalars['Int']>;
  /** ip sourcing */
  ipSourcing?: Maybe<IpSourcing>;
  /** ip sourcing ID */
  ipSourcingId?: Maybe<Scalars['Int']>;
  /** 성인인증 필요작품 여부 */
  isAdult: Scalars['Boolean'];
  /**
   * 해당 작품이 홈 배너에 등록되어 있는가?
   * @deprecated 배너의 종류가 늘어남에 따라 Banner 엔티티로 이관됨. 하위 호환성을 위해 컬럼과 데이터는 유지.
   */
  isBanner: Scalars['Boolean'];
  /** 공모전기간에 해당 작품이 공모전 참여 작품인지 여부 */
  isContest: Scalars['Boolean'];
  /** 더미 데이터 여부 */
  isDummy: Scalars['Boolean'];
  /** 현재 작품 상태 (연재 완료인가?) */
  isFinished: Scalars['Boolean'];
  /** 메인 캐릭터 이름 고정 여부 */
  isFixMainChrName?: Maybe<Scalars['Boolean']>;
  /** 해당 작품이 MWS인가? 현재는 Story.sectionType이 Challenge거나 Banner이면 MWS */
  isMWS: Scalars['Boolean'];
  /** 신작 노출 여부 (기준 1회차 발행 시점 기준 28일 미만) */
  isNew: Scalars['Boolean'];
  /** 스플 오리지널 작품 여부 */
  isOriginal: Scalars['Boolean'];
  /** 읽기 모드인지 여부 */
  isReadOnly: Scalars['Boolean'];
  /** 해당 작품이 샘플로 제공되는지 여부 */
  isSample?: Maybe<Scalars['Boolean']>;
  /** 19금 작품 홈화면 노출 여부 */
  isShowHome: Scalars['Boolean'];
  /** true이면 출간 상태와 상관없이 작품 미노출 */
  isStorygameHidden?: Maybe<Scalars['Boolean']>;
  /** 이 작품에 대한 알림 설정을 했는가? */
  isSubscribed: Scalars['Boolean'];
  /** 타임리프 무료 여부 true: 무료, false: 유료 */
  isTimeLeapFree: Scalars['Boolean'];
  /** 새로운 회차 업로드 기준 24시간 동안 표시 */
  isUp: Scalars['Boolean'];
  /** 이 작품이 공개 대기중인가? */
  isWaiting: Scalars['Boolean'];
  /** 작품의 언어 */
  langCode: Scalars['String'];
  /** 작품 언어 */
  language?: Maybe<Language>;
  /** 작품 언어 코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 스튜디오용 (발행되지 않은 회차 포함) 작품의 가장 마지막 회차 인덱스 */
  lastChapterIndex?: Maybe<Scalars['Int']>;
  /** 마지막으로 회차 발행 날짜: 회차 발행시 마다 업데이트 */
  lastChapterPublishedAt?: Maybe<Scalars['DateTime']>;
  /** 가장 최근에 시작한 플레이 정보 */
  lastPlay?: Maybe<UserPlayedStory>;
  /** 통구매 가능한 작품일 때 이 챕터 인덱스까지 무료로 볼 수 있다 */
  lastPreviewChapterIndex?: Maybe<Scalars['Int']>;
  /** 해당 작품의 발행한 마지막 회차 */
  lastPublishedChapterIndex: Scalars['Int'];
  /** 메인 캐릭터의 성별 */
  mainCharacterGender?: Maybe<Gender>;
  /** 메인 캐릭터 이미지 */
  mainCharacterImageFile?: Maybe<File>;
  /** 메인 캐릭터 이미지 */
  mainCharacterImageFileId?: Maybe<Scalars['Int']>;
  /** 메인 캐릭터 레이블 */
  mainCharacterLabel?: Maybe<Scalars['String']>;
  /** 스토리게임용 캐릭터 상반신 이미지 */
  mainCharacterPortraitFile?: Maybe<File>;
  /** 스토리게임용 캐릭터 상반신 이미지 ID */
  mainCharacterPortraitFileId?: Maybe<Scalars['Int']>;
  /** AI 생성시 메인케릭터의 기본 prompt */
  mainCharacterPrompt?: Maybe<Scalars['String']>;
  /** 메인 스토리 이미지 */
  mainImageFile?: Maybe<File>;
  /** 메인 스토리 이미지 */
  mainImageFileId?: Maybe<Scalars['Int']>;
  /** 스토리에서 한회차에 소비되는 최대 티켓 가격 */
  maxTicket: Scalars['Int'];
  /** 스토리게임 모바일 배경들 */
  mobileBackgrounds: Array<StorygameBackground>;
  /** 스토리 월 텍스트 */
  monthText?: Maybe<Scalars['String']>;
  /** 월간 공모전 라벨. (n월 월간공모전) */
  monthlyContestPublishedLabel?: Maybe<Scalars['String']>;
  /** 내가 좋아요를 누른 작품인가? */
  myFavorite: Scalars['Boolean'];
  /** 스토리의 이름 */
  name: Scalars['String'];
  /** 공지사항 */
  notice?: Maybe<Notice>;
  /** 공지사항 ID */
  noticeId?: Maybe<Scalars['Int']>;
  /** 웹소설 공모전 라벨 */
  novelContestPublishedLabel?: Maybe<Scalars['String']>;
  /** 모든 챕터의 블럭 수 */
  numBlocks?: Maybe<Scalars['Int']>;
  /** 모든 챕터 draft(초안)의 블럭 수 */
  numBlocksDraft?: Maybe<Scalars['Int']>;
  /** 모든 챕터의 글자 수 */
  numChars?: Maybe<Scalars['Int']>;
  /** 모든 챕터 draft(초안)의 글자 수 */
  numCharsDraft?: Maybe<Scalars['Int']>;
  /** 해당 작품에 달린 댓글 수 (대댓글 포함) */
  numComment: Scalars['Int'];
  /** 해당 작품의 좋아요 수 */
  numLikes: Scalars['Int'];
  /** 모든 챕터의 문장 수 */
  numSens?: Maybe<Scalars['Int']>;
  /** 모든 챕터 draft(초안)의 문장 수 */
  numSensDraft?: Maybe<Scalars['Int']>;
  /** 해당 작품의 상세화면 조회수 */
  numViews: Scalars['Int'];
  /** 해당 작품이 휴재중인가? */
  onHiatus: Scalars['Boolean'];
  /** 작품소개 */
  oneLineDesc: Scalars['String'];
  /** 스토리게임의 원작 인터랙티브 스토리. 스토리게임이 아니면 null */
  originalInteractiveStory?: Maybe<Story>;
  /** 할인 적용 전 스토리 통구매에 필요한 코인 수 */
  originalPrice: Scalars['Int'];
  /** 스토리게임 PC 배경들 */
  pcBackgrounds: Array<StorygameBackground>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType: Story_Play_Type;
  /** 운영중인 플레이어 보고서 */
  playerReport2?: Maybe<PlayerReport2>;
  /** 운영중인 플레이어 보고서 아이디 (PlayReport2.prId) */
  playerReportId?: Maybe<Scalars['Int']>;
  /** 스토리의 preview 엔딩 정보 */
  previewEndingInfo: PreviewEndingOutput;
  /** 미공개시 스토리 이미지 */
  previewImageFile?: Maybe<File>;
  /** 미공개시 스토리 이미지 ID */
  previewImageFileId?: Maybe<Scalars['Int']>;
  /** 할인이 적용된 originalPrice */
  price: Scalars['Int'];
  /** 스토리에 연결된 모든 속성값들 */
  properties: Array<StoryProperty>;
  /** 출간 후 추가된 속성에 대한 챕터별 디폴트값 정보(json) */
  propsMigration?: Maybe<Scalars['String']>;
  /** propsMigration이 마지막으로 업데이트 된 시각 */
  propsMigrationUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** 노출 시각 (출간시각) */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** 작품을 발행한 국가 */
  publishedCountry: Country;
  /** 작품을 발행한 국가 코드 */
  publishedCountryCode?: Maybe<Scalars['String']>;
  /** 통구매가 가능한 작품인가? (purchasableRaw가 true이고, 완결, 출간 된 작품) */
  purchasable: Scalars['Boolean'];
  /** 통구매 가능 설정 */
  purchasableRaw: Scalars['Boolean'];
  /** 회차 구매 무료 시작일(회차 구매 무료 시작일 ~ 타임리프 무료 종료 일자 -> 구매건은 티켓 소비 없이 볼 수 있다.) */
  purchaseFreeStartAt?: Maybe<Scalars['DateTime']>;
  /** 유저가 해당 작품을 통구매했나? */
  purchased: Scalars['Boolean'];
  /** 해당 작품과 연관된 작품 */
  refStory?: Maybe<Story>;
  /** 해당 작품과 연관된 작품 아이디 */
  refStoryId?: Maybe<Scalars['Int']>;
  /** 월간 대표작의 월을 포함하고 있습니다.(일자는 무시해주세요) */
  representedAt?: Maybe<Scalars['DateTime']>;
  /** 스토리 스크립트, json encoded. */
  script: Scalars['String'];
  /** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
  sectionType: Story_Section_Type;
  /** 해당 작품이 서비스 되는 국가 목록 */
  serviceCountries: Array<Country>;
  /** 공유용 해시 */
  shareKey: Scalars['String'];
  /** 한줄소개(15자이내) */
  shortDesc?: Maybe<Scalars['String']>;
  /** 스토리게임 섹션 내 작품 노출 여부 */
  showInStorygameSection: Scalars['Boolean'];
  /** 작품이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 모든 챕터의 기타 통계 수치 json stringify (IStats format) */
  stats?: Maybe<Scalars['String']>;
  /** 모든 챕터 draft(초안)의 기타 통계 수치 json stringify (IStats format) */
  statsDraft?: Maybe<Scalars['String']>;
  /** 스토리 더보기 정보 JSON */
  storyDetailInfo?: Maybe<Scalars['JSONObject']>;
  /** 작품 만료일 */
  storyEndsAt?: Maybe<Scalars['DateTime']>;
  /**
   * 작품의 장르 정보
   * @deprecated 글로벌 대응으로 genreKey를 사용해 주세요.
   */
  storyGenre?: Maybe<ChallengeStoryGenre>;
  /** ID of the story */
  storyId: Scalars['Int'];
  /** 스토리에 포함되어 있는 모든 아이템 목록 */
  storyItems: Array<StoryItem>;
  /** 해당 작품 가격 정책 정보 */
  storyPriceSetting?: Maybe<StoryPriceSetting>;
  /** 스토리에 연결된 태그들 */
  storyTags: Array<StoryTag>;
  /** 정식작품 연재 요일 리스트 */
  storyWeekdays?: Maybe<Array<StoryWeekday>>;
  /** 인터랙티브에 연결된 스토리게임 */
  storygame?: Maybe<Story>;
  /** 스토리게임 배경들 */
  storygameBackgrounds: Array<StorygameBackground>;
  /** 스토리게임 커버 이미지 파일(가로형 16:9) */
  storygameCoverImageFile?: Maybe<File>;
  /** 스토리게임 커버 이미지 파일 ID */
  storygameCoverImageFileId?: Maybe<Scalars['Int']>;
  /** 인터랙티브에 연결된 스토리게임의 id. 이 값은 playType이 인터랙티브인 작품만 가질 수 있어야 한다. */
  storygameId?: Maybe<Scalars['Int']>;
  /** 스토리게임 미리보기 이미지, 영상. 스튜디오에서 저장완료한 미리보기만 보임 */
  storygamePreviews: Array<StoryPreview>;
  /** 스튜디오용 스토리게임 미리보기 이미지, 영상 */
  storygamePreviewsForStudio: Array<StoryPreview>;
  /** 스토리게임 랭킹 정보. 인터랙티브는 null */
  storygameRanking?: Maybe<StorygameRanking>;
  /** 스토리게임 출간일. 과거로 수정되면 바로 출간, 미래로 수정되면 출간 예약 */
  storygameReleaseDate?: Maybe<Scalars['DateTime']>;
  /** 스튜디오 확장. 스튜디오에서만 호출해야 합니다. */
  studio: StoryForStudio;
  /** 이 작품에 포함된 스튜디오 파일들 */
  studioFiles: Array<StoryHasStudioFile>;
  /** 스튜디오에서 설정한 작품의 가격정보 설정 옵션 (STUDIO_STORY_PRICE_OPTION 의 stringify) */
  studioPriceSetting?: Maybe<Scalars['String']>;
  /** 섹션 내 스토리게임 노출 국가 설정 데이터 목록 */
  supportedStorygameSectionCountries: Array<SupportedStorygameCountryOutput>;
  /** 섹션 내 스토리게임 노출 언어 설정 데이터 목록 */
  supportedStorygameSectionLanguages: Array<SupportedStorygameLanguageOutput>;
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  /** 연령대 (자유입력) */
  targetAge: Scalars['String'];
  /** 티저 영상 */
  teaserVideoFile?: Maybe<File>;
  /** 티저 영상 ID */
  teaserVideoFileId?: Maybe<Scalars['Int']>;
  /** 주제 (자유입력) */
  topic: Scalars['String'];
  /** 모든 회차에 달린 댓글 수의 합 */
  totalNumOfChapterComments: Scalars['Int'];
  /** 작품 타입 Novel: 소설형 스토리, Chat: 채팅형 스토리 */
  type: Story_Type;
  updatedAt: Scalars['DateTime'];
  /** 유저의 기본 아이템 정보 (json) */
  userItems: Scalars['String'];
  /** 사용자의 기본 props 정보 (json) */
  userProps: Scalars['String'];
  /** 이 필드에 접근하면 상세페이지 조회수가 1 증가. 작품 아이디를 리턴함 */
  view: Scalars['Int'];
  /** 가로형 썸네일(4:3) 이미지 */
  wideImageFile?: Maybe<File>;
  /** 가로형 썸네일(4:3) 이미지 ID */
  wideImageFileId?: Maybe<Scalars['Int']>;
  /** (미래) 노출 예정 시각 */
  willPublishAt?: Maybe<Scalars['DateTime']>;
};


/** 저장된 스토리 */
export type StoryShowInStorygameSectionArgs = {
  data: SingleIdInput;
};


/** 저장된 스토리 */
export type StorySupportedStorygameSectionCountriesArgs = {
  data: SingleIdInput;
};


/** 저장된 스토리 */
export type StorySupportedStorygameSectionLanguagesArgs = {
  data: SingleIdInput;
};

/** 스토리의 첨부파일 관련 수정 입력 데이터 */
export type StoryAttachmentModificationInput = {
  /** name of the attachment */
  name: Scalars['String'];
  /** storyId */
  storyId: Scalars['Int'];
  /** File. can be null when removing */
  upload?: InputMaybe<Scalars['Upload']>;
};

/** 작품별 제한 국가를 관리하는 엔티티 */
export type StoryBlockedCountry = {
  __typename?: 'StoryBlockedCountry';
  /** 작품을 제한할 지역 */
  country: Country;
  /** 작품을 제한할 지역의 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 작품 */
  story: Story;
  /** 아이디 */
  storyBlockedCountryId: Scalars['Int'];
  /** 작품의 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StoryBlockedCountry 생성을 위한 input */
export type StoryBlockedCountryCreationInput = {
  /** 작품을 제한할 지역의 코드 */
  countryCode: Scalars['String'];
  /** 작품의 아이디 */
  storyId: Scalars['Int'];
};

/** StoryBlockedCountry 업데이트를 위한 input */
export type StoryBlockedCountryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 스토리 번들 테이블 */
export type StoryBundle = {
  __typename?: 'StoryBundle';
  /** 이 작품을 포함하는 판매 중인 스토어 번들 목록 */
  availableStoreBundles: Array<StoreBundle>;
  createdAt: Scalars['DateTime'];
  /** 번들의 장르 타입. 확장성을 위해 string으로 유지하고, 호환성을 위해 CHALLENGE_STORY_GENRE 유지 */
  genre: Challenge_Story_Genre;
  /** 내가 장바구니에 추가했는지 여부 */
  isAddedToStoreCartByMe?: Maybe<Scalars['Boolean']>;
  /** 성인인증 필요작품 여부 */
  isAdult: Scalars['Boolean'];
  /** 스플 오리지널 번들 여부 */
  isOriginal: Scalars['Boolean'];
  /** 내가 플레이했는지 여부 */
  isPlayedByMe: Scalars['Boolean'];
  /** 내가 구매했는지 여부 */
  isPurchasedByMeInStore: Scalars['Boolean'];
  /** 내가 리뷰했는지 여부 */
  isReviewedByMe: Scalars['Boolean'];
  /** 스토어 사전 예약 설정 여부 */
  isStorePreorderEnabled: Scalars['Boolean'];
  /** 스토어 출시 여부 */
  isStorePublished: Scalars['Boolean'];
  /** 내가 찜 목록에 추가했는지 여부 */
  isStoreWishlistedByMe?: Maybe<Scalars['Boolean']>;
  /** 스토어 특정 국가 가격 */
  localStorePrice?: Maybe<StoryBundleStorePrice>;
  /** 번들에 속한 특정 언어 작품 */
  localStory?: Maybe<Story>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType: Story_Play_Type;
  /** 번들의 종류 (정식연재, UGC, 월간스토리, 보물상자) */
  sectionType: Story_Section_Type;
  /** 번들 허용 국가 목록 */
  serviceCountries: Array<Country>;
  /** 스토어 번들 스토어 국가 목록 */
  storeCountries: Array<Country>;
  /** 스토어 사전 예약일. 사전 예약일 ~ 정식 출시일 기간동안 사전 예약 가능 */
  storePreorderDate?: Maybe<Scalars['DateTime']>;
  /** 스토어 국가별 가격 목록 */
  storePrices: Array<StoryBundleStorePrice>;
  /** 스토어 최근 한달 리뷰 수 */
  storeRecentReviewCount: Scalars['Int'];
  /** 스토어 최근 한달 리뷰 등급 */
  storeRecentReviewRating?: Maybe<StoreReviewRating>;
  /** 스토어 정식 출시일 */
  storeReleaseDate?: Maybe<Scalars['DateTime']>;
  /** 스토어 리뷰 수 */
  storeReviewCount: Scalars['Int'];
  /** 스토어 리뷰 등급 */
  storeReviewRating?: Maybe<StoreReviewRating>;
  /** 번들에 속한 모든 작품 */
  stories: Array<Story>;
  /** 아이디 */
  storyBundleId: Scalars['Int'];
  /** 번들에 속한 모든 작품에 해당하는 언어코드 */
  storyLanguages: Array<Language>;
  updatedAt: Scalars['DateTime'];
  /** 엑솔라 프로모션 아이디 */
  xsollaPromotionId?: Maybe<Scalars['Int']>;
  /** 엑솔라 sku */
  xsollaSku?: Maybe<Scalars['String']>;
  /** 엑솔라 수정 시간 */
  xsollaUpdatedAt?: Maybe<Scalars['DateTime']>;
};


/** 스토리 번들 테이블 */
export type StoryBundleLocalStorePriceArgs = {
  countryCode: Scalars['String'];
  fallback?: InputMaybe<Scalars['String']>;
};


/** 스토리 번들 테이블 */
export type StoryBundleLocalStoryArgs = {
  fallback?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
};

/** 번들 접속 국가 제한을 위한 엔티티 */
export type StoryBundleCountry = {
  __typename?: 'StoryBundleCountry';
  /** allow: 허용, deny: 비허용 */
  access: Story_Bundle_Country_Access;
  /** 대상 번들 */
  bundle: StoryBundle;
  /** 대상 번들 아이디 */
  bundleId?: Maybe<Scalars['Int']>;
  /** 대상 국가 */
  country: Country;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  storyBundleCountryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 실시간으로 쌓이는 번들 통계값 */
export type StoryBundleStat = {
  __typename?: 'StoryBundleStat';
  /** 대상 국가 */
  country: Country;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 대상 번들의 현재까지 달린 모든 댓글 수 */
  numComment: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 좋아요 수 */
  numLikes: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 회차 플레이 수 */
  numPlay: Scalars['Int'];
  /** 대상 번들의 현재까지의 총 작품상세 조회수 */
  numViews: Scalars['Int'];
  /** 통계 대상 번들 */
  storyBundle: StoryBundle;
  /** 통계 대상 번들 아이디 */
  storyBundleId: Scalars['Int'];
  /** 아이디 */
  storyBundleStatId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 번들 스토어 접속 국가 제한을 위한 엔티티 */
export type StoryBundleStoreCountry = {
  __typename?: 'StoryBundleStoreCountry';
  /** allow: 허용, deny: 비허용 */
  access: Story_Bundle_Country_Access;
  /** 대상 번들 */
  bundle: StoryBundle;
  /** 대상 번들 아이디 */
  bundleId: Scalars['Int'];
  /** 대상 국가 */
  country: Country;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  storyBundleStoreCountryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토리 번들 스토어 가격(스토리 번들-국가) */
export type StoryBundleStorePrice = {
  __typename?: 'StoryBundleStorePrice';
  /** 적용 국가 */
  country: Country;
  /** 적용 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 할인 종료일 */
  discountEndDate?: Maybe<Scalars['DateTime']>;
  /** 수동으로 입력 가능한 할인율(0.0~1.0) */
  discountRate?: Maybe<Scalars['Float']>;
  /** 할인 시작일 */
  discountStartDate?: Maybe<Scalars['DateTime']>;
  /** 할인 적용 전 가격 */
  originalPrice?: Maybe<Scalars['Int']>;
  /** 가격, 할인이 적용된 originalPrice, 0이라면 무료 */
  price: Scalars['Int'];
  /** 스토리 번들 */
  storyBundle: StoryBundle;
  /** 스토리 번들 ID */
  storyBundleId: Scalars['Int'];
  /** 아이디 */
  storyBundleStorePriceId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토리 번들 스토어 가격 생성 input */
export type StoryBundleStorePriceCreateInput = {
  /** 적용 국가 코드 */
  countryCode: Scalars['String'];
  /** 할인 종료일 */
  discountEndDate?: InputMaybe<Scalars['DateTime']>;
  /** 수동으로 입력 가능한 할인율(0.0~1.0) */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 할인 시작일 */
  discountStartDate?: InputMaybe<Scalars['DateTime']>;
  /** 할인 적용 전 가격 */
  originalPrice?: InputMaybe<Scalars['Int']>;
  /** 할인 적용 전 가격 */
  price: Scalars['Int'];
  /** 스토리 번들 ID */
  storyBundleId: Scalars['Int'];
};

/** 스토리 번들 스토어 가격 수정 input */
export type StoryBundleStorePriceUpdateInput = {
  /** 할인 종료일 */
  discountEndDate?: InputMaybe<Scalars['DateTime']>;
  /** 수동으로 입력 가능한 할인율(0.0~1.0) */
  discountRate?: InputMaybe<Scalars['Float']>;
  /** 할인 시작일 */
  discountStartDate?: InputMaybe<Scalars['DateTime']>;
  /** 할인 적용 전 가격 */
  originalPrice?: InputMaybe<Scalars['Int']>;
  /** 할인 적용 전 가격 */
  price?: InputMaybe<Scalars['Int']>;
  /** 아이디 */
  storyBundleStorePriceId: Scalars['Int'];
};

/** StoryBundle의 Store 관련 정보 업데이트를 위한 input */
export type StoryBundleStoreUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 스토어 사전 예약 설정 여부 */
  isStorePreorderEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 스토어 사전 예약일. 사전 예약일 ~ 정식 출시일 기간동안 사전 예약 가능 */
  storePreorderDate?: InputMaybe<Scalars['DateTime']>;
  /** 스토어 정식 출시일 */
  storeReleaseDate?: InputMaybe<Scalars['DateTime']>;
};

/** StoryBundle 업데이트를 위한 input */
export type StoryBundleUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType: Story_Play_Type;
};

/** 아이디 */
export type StoryChangeLog = {
  __typename?: 'StoryChangeLog';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  logId: Scalars['Int'];
  /** 로그 메시지 */
  message: Scalars['String'];
  /** 로그의 트랜잭션 아이디 */
  txId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 작품 댓글 복사를 위한 입력 */
export type StoryCommentCopyInput = {
  /** 원본 스토리 ID */
  sourceId: Scalars['Int'];
  /** 대상 스토리 ID */
  targetId?: InputMaybe<Scalars['Int']>;
};

/** 작품의 정주행율 랭킹 row */
export type StoryConversionRankCache = {
  __typename?: 'StoryConversionRankCache';
  /** 순위 변경된 수치 */
  changedRank?: Maybe<Scalars['Int']>;
  /** 현재 랭킹 */
  currentRank: Scalars['Int'];
  /** 1화 시작 인원수 */
  numPlayer: Scalars['Int'];
  /** 랭킹 점수(조회수, 댓글수 등) */
  score: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 1화 -> 2화 전환율 */
  to2Rate?: Maybe<Scalars['String']>;
  /** 1화 -> 3화 전환율 */
  to3Rate?: Maybe<Scalars['String']>;
  /** 정주행율 */
  toLastRate: Scalars['String'];
};

/** 작품 타입별 개수 (전체, 인터랙티브, 웹소설) */
export type StoryCounts = {
  __typename?: 'StoryCounts';
  /** 웹소설 작품 수 */
  epub: Scalars['Int'];
  /** 인터랙티브 작품 수  */
  interactive: Scalars['Int'];
  /** 전체 작품 수 */
  total: Scalars['Int'];
};

export type StoryForStudio = {
  __typename?: 'StoryForStudio';
  /** 이 작품의 모든 엔딩 */
  allEndings: Array<Ending>;
  /** 플레이어 보고서 CSS Link */
  playerReportCSSLink: Scalars['String'];
  /** 작품 */
  story?: Maybe<Story>;
};

/** 스토리 장르 정보 */
export type StoryGenre = {
  __typename?: 'StoryGenre';
  /** 앱에서 사용하는 장리 서브타이틀 */
  subTitle?: Maybe<LocalizeKey>;
  /** 장르 텍스트 */
  text: LocalizeKey;
  /** 엡에서 사용하는 장르 타이틀 */
  title?: Maybe<LocalizeKey>;
  /** 장르 구분 타입 */
  type: Scalars['String'];
};

/** 스토리의 배경 파일 */
export type StoryHasBackground = {
  __typename?: 'StoryHasBackground';
  createdAt: Scalars['DateTime'];
  /** 스튜디오에서 작가에게 보여질 배경 이름 */
  displayName?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  /** 파일 ID */
  fileId?: Maybe<Scalars['Int']>;
  /** 첨부 관련 이름 */
  name: Scalars['String'];
  /** 할당 ID */
  shfId: Scalars['Int'];
  /** 스토리 */
  story: Story;
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 해당 배경에 대한 스토리게임용 배경 */
  storygameBackgrounds: Array<StorygameBackground>;
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 배경 이미지 항목을 생성 할 때 필요한 데이터 */
export type StoryHasBackgroundCreationInput = {
  /** 스튜디오에서 작가에게 보여질 배경 이름 */
  displayName?: InputMaybe<Scalars['String']>;
  /** 배경 이미지 파일 */
  imageFile: Scalars['Upload'];
  /** 작품 ID */
  storyId: Scalars['Int'];
};

/** 배경 이미지 항목을 업데이트 할 때 필요한 데이터 */
export type StoryHasBackgroundPropsInput = {
  /** 스튜디오에서 작가에게 보여질 배경 이름 */
  displayName?: InputMaybe<Scalars['String']>;
  /** 이미지 파일 (필드명을 맞추기 위함) */
  file?: InputMaybe<Scalars['Upload']>;
  /** 배경 이미지 ID (shfId) */
  id?: InputMaybe<Scalars['Int']>;
  /** 이미지 파일 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile PrimaryKey */
  studioFileId?: InputMaybe<Scalars['Int']>;
};

/** 스토리에 할당된 첨부파일 */
export type StoryHasFile = {
  __typename?: 'StoryHasFile';
  createdAt: Scalars['DateTime'];
  /** 파일 */
  file?: Maybe<File>;
  /** 파일 ID */
  fileId?: Maybe<Scalars['Int']>;
  /** 첨부 관련 이름 */
  name: Scalars['String'];
  /** 할당 ID */
  shfId: Scalars['Int'];
  /** 스토리 */
  story: Story;
  /** 스토리 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 추천 작품 섹션을 저장하는 테이블 */
export type StoryHasRecommendSection = {
  __typename?: 'StoryHasRecommendSection';
  /** 작품 */
  chapter?: Maybe<Chapter>;
  /** 작품 회차 Id */
  chapterId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 이 섹션의 추천 스토리 목록 */
  recommendStories?: Maybe<Array<RecommendSectionStory>>;
  /** 작품 */
  story: Story;
  /** 아이디 */
  storyHasRecommendSectionId: Scalars['Int'];
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 섹션 서브 타이틀 */
  subTitle?: Maybe<Scalars['String']>;
  /** 섹션 타이틀 */
  title: Scalars['String'];
  /** 추천 타입 */
  type: Story_Has_Recommendation_Type;
  updatedAt: Scalars['DateTime'];
};

/** StoryHasRecommendSection 생성을 위한 input */
export type StoryHasRecommendSectionCreationInput = {
  /** 작품 회차 Id */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 섹션 서브 타이틀 */
  subTitle?: InputMaybe<Scalars['String']>;
  /** 섹션 타이틀 */
  title: Scalars['String'];
  /** 추천 타입 */
  type?: InputMaybe<Story_Has_Recommendation_Type>;
};

/** StoryHasRecommendSection 수정을 위한 input */
export type StoryHasRecommendSectionUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 섹션 서브 타이틀 */
  subTitle?: InputMaybe<Scalars['String']>;
  /** 섹션 타이틀 */
  title?: InputMaybe<Scalars['String']>;
  /** 추천 타입 */
  type?: InputMaybe<Story_Has_Recommendation_Type>;
};

/** 스토리가 가진 스토리 태그를 관리하는 엔티티 */
export type StoryHasStoryTag = {
  __typename?: 'StoryHasStoryTag';
  createdAt: Scalars['DateTime'];
  /** 작품 내 태그가 표시될 순서. 높을수록 먼저 노출됨 */
  showOrder: Scalars['Int'];
  /** 스토리 */
  story: Story;
  /** 아이디 */
  storyHasStoryTagId: Scalars['Int'];
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
  /** 스토리 태그 */
  storyTag: StoryTag;
  /** 스토리 태그 아이디 */
  storyTagId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StoryHasStoryTag 생성을 위한 input */
export type StoryHasStoryTagCreationInput = {
  /** 작품 내 태그가 표시될 순서. 높을수록 먼저 노출됨 */
  showOrder: Scalars['Int'];
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
  /** 스토리 태그 아이디 */
  storyTagId: Scalars['Int'];
};

/** StoryHasStoryTag 업데이트를 위한 input */
export type StoryHasStoryTagUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 작품이 갖고 있는 스튜디오용 파일 */
export type StoryHasStudioFile = {
  __typename?: 'StoryHasStudioFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 해당 리소스에 대한 이름 (작가가 보기 위한 용도) */
  displayName?: Maybe<Scalars['String']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  /** 파일타입 */
  fileType: Studio_File_Type;
  hashId: Scalars['String'];
  height: Scalars['Int'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  /** 해당 리소스에 대한 이름 (관리용) - 키값. 변경되면 안됨. */
  resName: Scalars['String'];
  /** 작품 */
  story: Story;
  /** 작품의 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

/** StoryHasStudioFile 항목을 생성할 때 필요한 데이터 */
export type StoryHasStudioFileCreationInput = {
  /** 파일 */
  file: Scalars['Upload'];
  /** 파일타입 */
  fileType: Studio_File_Type;
  /** 해당 리소스에 대한 이름 (관리용) - 키값. 변경되면 안됨. */
  resName: Scalars['String'];
  /** 작품의 ID */
  storyId: Scalars['Int'];
};

/** StoryHasStudioFile 항목을 업데이트할 때 필요한 데이터 */
export type StoryHasStudioFileUpdateInput = {
  /** 해당 리소스에 대한 이름 (작가가 보기 위한 용도) */
  displayName?: InputMaybe<Scalars['String']>;
  /** 파일의 id */
  id: Scalars['Int'];
};

/** 추출된 작품 zip 파일 정보 import 처리 로그 */
export type StoryImportLog = {
  __typename?: 'StoryImportLog';
  /** 작품 번들 */
  bundle?: Maybe<StoryBundle>;
  /** 작품 번들 ID */
  bundleId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 생성된 presigned url 로 저장될 파일이름 */
  hash: Scalars['String'];
  /** 작품 언어 */
  language?: Maybe<Language>;
  /** 작품 언어 코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 일련번호 */
  silId: Scalars['Int'];
  /** import 진행 상태 */
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 아이디 (presigned url을  생성한 사용자) */
  userId: Scalars['Int'];
};

export type StoryItem = {
  __typename?: 'StoryItem';
  createdAt: Scalars['DateTime'];
  /** 아이템 설명 */
  desc?: Maybe<Scalars['String']>;
  /** 실제로 표시될 아이템 이름 */
  displayName: Scalars['String'];
  /** 아이템 이미지 */
  imageFile?: Maybe<File>;
  /** 아이템 이미지 아이디 */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 아이템 숨김 여부. true이면 사용 획득, 사용 안됨 */
  isHidden: Scalars['Boolean'];
  /** 아이템 명 */
  name: Scalars['String'];
  /** 아이템이 속한 작품 */
  story: Story;
  /** 아이템이 속한 작품 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyItemId: Scalars['Int'];
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** StoryItem 생성을 위한 input */
export type StoryItemCreationInput = {
  /** 아이템 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 아이템 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile 에서 fileId 를 제공하면 이 값으로 업데이트 */
  imageFromStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 아이템 숨김 여부. true이면 사용 획득, 사용 안됨 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 아이템 명 */
  name: Scalars['String'];
  /** 아이템이 속한 작품 아이디 */
  storyId: Scalars['Int'];
};

/** StoryItem 업데이트를 위한 input */
export type StoryItemUpdateInput = {
  /** 아이템 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 실제로 표시될 아이템 이름 */
  displayName?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 아이템 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile 에서 fileId 를 제공하면 이 값으로 업데이트 */
  imageFromStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 아이템 숨김 여부. true이면 사용 획득, 사용 안됨 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 아이템 명 */
  name?: InputMaybe<Scalars['String']>;
};

/** 스토리 신작 스토리 캐싱 데이터 테이블 */
export type StoryNewSectionCache = {
  __typename?: 'StoryNewSectionCache';
  createdAt: Scalars['DateTime'];
  /** 캐싱 키값 */
  key: Scalars['String'];
  /** 스토리 신작 스토리 캐싱 데이터 ID */
  storyNewSectionCacheId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱 데이터 */
  value: Scalars['String'];
  /** 캐싱된 버전 */
  version: Scalars['Int'];
};

export type StoryNewSectionCacheCreationInput = {
  /** 캐싱 키값 */
  key: Scalars['String'];
  /** 캐싱 데이터 */
  value: Scalars['String'];
};

/** StoryNewSectionCache 데이터를 가져오기 위한 input */
export type StoryNewSectionCacheGetInput = {
  /** 캐싱 키값 */
  key: Scalars['String'];
};

/** 작품의 댓글수 통계 캐시 데이터 */
export type StoryNumCommentStatCacheData = {
  __typename?: 'StoryNumCommentStatCacheData';
  /** 챕터 */
  chapter: Chapter;
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 통계 수치 */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐시날짜 (YYYY-MM-DD) */
  date: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 캐시타입 (일간, 주간, 월간) */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
};

/** 작품의 좋아요 통계 캐시 데이터 */
export type StoryNumLikeStatCacheData = {
  __typename?: 'StoryNumLikeStatCacheData';
  /** 통계 수치 */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐시날짜 (YYYY-MM-DD) */
  date: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 캐시타입 (일간, 주간, 월간) */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
};

/** 작품의 조회수 통계 캐시 데이터 */
export type StoryNumPlayStatCacheData = {
  __typename?: 'StoryNumPlayStatCacheData';
  /** 챕터 */
  chapter: Chapter;
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 통계 수치 */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐시날짜 (YYYY-MM-DD) */
  date: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 캐시타입 (일간, 주간, 월간) */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
};

/** 전환율 계산을 위한 회차별 플레이어 수 */
export type StoryNumPlayerStatCacheData = {
  __typename?: 'StoryNumPlayerStatCacheData';
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 몇화인지? */
  chapterIndex: Scalars['Int'];
  /** 통계 종류 (신규 유저, 처음 작품 플레이, 전체) */
  conversionType: Story_Conversion_Stat_Type;
  /** 통계 수치 */
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐시날짜 (YYYY-MM-DD) */
  date: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 캐시타입 (일간, 주간, 월간) */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
};

/** 스토리 미리보기. 이미지, 동영상 */
export type StoryPreview = {
  __typename?: 'StoryPreview';
  createdAt: Scalars['DateTime'];
  /** 미리보기 파일 */
  file: File;
  /** 미리보기 파일 아이디 */
  fileId: Scalars['Int'];
  /** 리소스 타입 (Image or Video) */
  fileType: Storygame_Preview_Resource_Type;
  /** 이미지의 높이 */
  height?: Maybe<Scalars['Int']>;
  /** 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** 클라이언트에서 보여질 순서. 높을수록 먼저 등장 */
  showOrder: Scalars['Int'];
  /** 스토리 */
  story: Story;
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyPreviewId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 이미지의 너비 */
  width?: Maybe<Scalars['Int']>;
};

/** StoryPreview 생성을 위한 input */
export type StoryPreviewCreationInput = {
  /** 미리보기 파일 */
  file: Scalars['Upload'];
  /** 리소스 타입 (Image or Video) */
  fileType: Storygame_Preview_Resource_Type;
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
};

/** StoryPreview 업데이트를 위한 input */
export type StoryPreviewUpdateInput = {
  /** 미리보기 파일 */
  file?: InputMaybe<Scalars['Upload']>;
  /** 리소스 타입 (Image or Video) */
  fileType?: InputMaybe<Storygame_Preview_Resource_Type>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 숨김 여부 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
};

/** 스토리 가격 설정(스토리-국가) */
export type StoryPriceSetting = {
  __typename?: 'StoryPriceSetting';
  /** 광고 설정 여부 */
  adPerChapterActivated: Scalars['Boolean'];
  /** 광고 설정 */
  adPerChapterSetting?: Maybe<Scalars['String']>;
  /** 발행 후 기간 무료 여부 */
  afterSomePeriodFreePerChapterActivated: Scalars['Boolean'];
  /** 발행 후 기간 무료 설정 */
  afterSomePeriodFreePerChapterSetting?: Maybe<Scalars['String']>;
  /** 적용 국가 */
  country: Country;
  /** 적용 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 기간 무료 여부 */
  freedAtPerChapterActivated: Scalars['Boolean'];
  /** 기간 무료 설정 */
  freedAtPerChapterSetting?: Maybe<Scalars['String']>;
  /** 가격 설정 true: 무료 false: 유료 */
  isFree: Scalars['Boolean'];
  /** 회차 단위 판매 여부 */
  paidPerChapterActivated: Scalars['Boolean'];
  /** 회차 단위 판매 설정 */
  paidPerChapterSetting?: Maybe<Scalars['String']>;
  /** 통구매 판매 여부 */
  paidPerStoryActivated: Scalars['Boolean'];
  /** 통구매 판매 설정 */
  paidPerStorySetting?: Maybe<Scalars['String']>;
  /** 작품 */
  story: Story;
  /** 작품 ID */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyPriceSettingId: Scalars['Int'];
  /** 타임리프 무료 여부 */
  timeLeapFreePerChapterActivated: Scalars['Boolean'];
  /** 타임리프 무료 설정 */
  timeLeapFreePerChapterSetting?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 기스무 여부 */
  waitFreePerChapterActivated: Scalars['Boolean'];
  /** 기스무 설정 */
  waitFreePerChapterSetting?: Maybe<Scalars['String']>;
};

/** StoryPriceSetting 생성을 위한 input */
export type StoryPriceSettingCreationInput = {
  /** 광고 설정 여부 */
  adPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 광고 설정 (IAdPerChapterSetting stringify) */
  adPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 발행 후 기간 무료 여부 */
  afterSomePeriodFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 발행 후 기간 무료 설정 (IAfterSomePeriodFreePerChapterSetting stringify) */
  afterSomePeriodFreePerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 적용 국가 코드 */
  countryCode: Scalars['String'];
  /** 기간 무료 여부 */
  freedAtPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 기간 무료 설정 (IFreedAtPerChapterSetting stringify) */
  freedAtPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 가격 설정 true: 무료 false: 유료 */
  isFree: Scalars['Boolean'];
  /** 개별 회차 구매 여부 */
  paidPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 개별 회차 구매 설정 (IPaidPerChapterSetting stringify) */
  paidPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 통구매 판매 여부 */
  paidPerStoryActivated?: InputMaybe<Scalars['Boolean']>;
  /** 통구매 판매 설정 (IPaidPerStorySetting stringify) */
  paidPerStorySetting?: InputMaybe<Scalars['String']>;
  /** 작품아이디 */
  storyId: Scalars['Int'];
  /** 타임리프 무료 여부 */
  timeLeapFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 타임리프 무료 설정 (ITimeLeapFreePerChapterSetting stringify) */
  timeLeapFreePerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 기스무 여부 */
  waitFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 기스무 설정 (IWaitFreePerChapterSetting stringify) */
  waitFreePerChapterSetting?: InputMaybe<Scalars['String']>;
};

/** StoryPriceSetting 업데이트를 위한 input */
export type StoryPriceSettingUpdateInput = {
  /** 광고 설정 여부 */
  adPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 광고 설정 (IAdPerChapterSetting stringify) */
  adPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 발행 후 기간 무료 여부 */
  afterSomePeriodFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 발행 후 기간 무료 설정 (IAfterSomePeriodFreePerChapterSetting stringify) */
  afterSomePeriodFreePerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 기간 무료 여부 */
  freedAtPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 기간 무료 설정 (IFreedAtPerChapterSetting stringify) */
  freedAtPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 가격 설정 true: 무료 false: 유료 */
  isFree?: InputMaybe<Scalars['Boolean']>;
  /** 개별 회차 구매 여부 */
  paidPerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 개별 회차 구매 설정 (IPaidPerChapterSetting stringify) */
  paidPerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 통구매 판매 여부 */
  paidPerStoryActivated?: InputMaybe<Scalars['Boolean']>;
  /** 통구매 판매 설정 (IPaidPerStorySetting stringify) */
  paidPerStorySetting?: InputMaybe<Scalars['String']>;
  /** 타임리프 무료 여부 */
  timeLeapFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 타임리프 무료 설정 (ITimeLeapFreePerChapterSetting stringify) */
  timeLeapFreePerChapterSetting?: InputMaybe<Scalars['String']>;
  /** 기스무 여부 */
  waitFreePerChapterActivated?: InputMaybe<Scalars['Boolean']>;
  /** 기스무 설정 (IWaitFreePerChapterSetting stringify) */
  waitFreePerChapterSetting?: InputMaybe<Scalars['String']>;
};

/** 스토리 속성 */
export type StoryProperty = {
  __typename?: 'StoryProperty';
  createdAt: Scalars['DateTime'];
  /** 속성값에 대한 설명 */
  desc?: Maybe<Scalars['String']>;
  /** 노출되는 속성명 */
  displayPropName?: Maybe<Scalars['String']>;
  /** 속성의 이미지 파일 */
  imageFile?: Maybe<File>;
  /** 속성의 이미지 파일 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 핵심속성 여부 */
  isMain: Scalars['Boolean'];
  /** 대표 속성 여부. 대표 속성은 작품별로 하나만 존재해야 한다. */
  isPrimary: Scalars['Boolean'];
  /** 스토리에서 속성의 사용 여부 */
  isUsed: Scalars['Boolean'];
  /** 속성 값의 max값. propType이 number일 때만 유효. */
  maxValue?: Maybe<Scalars['Int']>;
  /** 스토리 속성 아이디 */
  propId: Scalars['Int'];
  /** 속성 이름. displayPropName이 지정되어 있으면 displayPropName을 리터한다 */
  propName: Scalars['String'];
  /** 디비에 저장 되어 있는 propName raw 값 */
  propNameRaw: Scalars['String'];
  /** 속성 타입 */
  propType: Story_Prop_Type;
  /** 핵심속성이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 속성이 속해 있는 스토리 */
  story: Story;
  /** 속성이 속해 있는 스토리 ID */
  storyId: Scalars['Int'];
  /** 동기화 때마다 랜덤하게 발급되는 token. 이번 동기화에서 upsert 되었는지 체크하기 위해 사용한다. */
  syncToken: Scalars['String'];
  /** 속성 단위 */
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** 스토리를 속성을 생성 하는데 필요한 데이터 */
export type StoryPropertyCreationInput = {
  /** 속성값에 대한 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 노출되는 속성명 */
  displayPropName?: InputMaybe<Scalars['String']>;
  /** 속성의 이미지 파일 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile 에서 fileId 를 제공하면 이 값으로 업데이트 */
  imageFromStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 핵심속성 여부 */
  isMain: Scalars['Boolean'];
  /** 속성 값의 max값. propType이 number일 때만 유효. */
  maxValue?: InputMaybe<Scalars['Int']>;
  /** 속성 이름. displayPropName이 지정되어 있으면 displayPropName을 리터한다 */
  propName: Scalars['String'];
  /** 속성 타입 */
  propType: Story_Prop_Type;
  /** 핵심속성이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder: Scalars['Int'];
  /** 속성이 속해 있는 스토리 ID */
  storyId: Scalars['Int'];
  /** 속성 단위 */
  unit?: InputMaybe<Scalars['String']>;
};

/** 스토리 속성 수정 */
export type StoryPropertyUpdateInput = {
  /** 속성값에 대한 설명 */
  desc?: InputMaybe<Scalars['String']>;
  /** 노출되는 속성명 */
  displayPropName?: InputMaybe<Scalars['String']>;
  /** 스토리 속성 아이디 */
  id: Scalars['Int'];
  /** 속성의 이미지 파일 ID */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile 에서 fileId 를 제공하면 이 값으로 업데이트 */
  imageFromStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 핵심속성 여부 */
  isMain?: InputMaybe<Scalars['Boolean']>;
  /** 대표 속성 여부. 대표 속성은 작품별로 하나만 존재해야 한다. */
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** 속성 값의 max값. propType이 number일 때만 유효. */
  maxValue?: InputMaybe<Scalars['Int']>;
  /** 속성 이름. displayPropName이 지정되어 있으면 displayPropName을 리터한다 */
  propName?: InputMaybe<Scalars['String']>;
  /** 핵심속성이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 속성 단위 */
  unit?: InputMaybe<Scalars['String']>;
};

/** 스토리를 생성 또는 수정하는데 필요한 데이터 */
export type StoryPropsInput = {
  /** 스토리 작성자 명 */
  authorName?: InputMaybe<Scalars['String']>;
  /** 웹소설, 인터랙티브 작품용 배너 이미지 파일 */
  bannerImageFile?: InputMaybe<Scalars['Upload']>;
  /** story의 bundleId. bundleId가 들어오지 않는다면, 작품 최초 생성시에는 StoryBundle도 함께 생성해준다. */
  bundleId?: InputMaybe<Scalars['Int']>;
  /** 스튜디오에서 발행가능한 작품인지 여부 */
  canPublish?: InputMaybe<Scalars['Boolean']>;
  /** 작품에 업적 기능을 사용할 수 있는지 여부 */
  canUseAchievement?: InputMaybe<Scalars['Boolean']>;
  /** 작품에 플레이어 보고서를 설정할 수 있는지 여부 (PlayerReport2) */
  canUserPlayerReport?: InputMaybe<Scalars['Boolean']>;
  /** 캐릭터 migration을 위한 정보 */
  characterMigration?: InputMaybe<CharacterMigrationInput>;
  /** 작품 띠지에 나올 텍스트 */
  coverText?: InputMaybe<Scalars['String']>;
  /** 스토리의 플레이어 디폴트 이름의 이름 */
  defaultFirstName?: InputMaybe<Scalars['String']>;
  /** 스토리의 플레이어 디폴트 이름의 성 */
  defaultLastName?: InputMaybe<Scalars['String']>;
  /** 스토리에 통계 예측을 위한 값 범위 ex) 10% */
  endingArrivalRateRange?: InputMaybe<Scalars['Int']>;
  /** 스토리 엔딩 도착 확률 표시 타입 설계값, 실제값, 미표시 */
  endingArrivalRateType?: InputMaybe<Story_Ending_Arrival_Rate_Type>;
  /** 엔딩 migration을 위한 정보 */
  endingMigration?: InputMaybe<EndingMigrationInput>;
  /** 완결 설정한 날짜 */
  finishedAt?: InputMaybe<Scalars['DateTime']>;
  /** 타임리프 무료 종료 일자 */
  freeTimeLeapEndAt?: InputMaybe<Scalars['DateTime']>;
  /** 타임리프 무료 시작 일자 */
  freeTimeLeapStartAt?: InputMaybe<Scalars['DateTime']>;
  /** 작품의 장르 타입. 확장성을 위해 string으로 유지하고, 호환성을 위해 CHALLENGE_STORY_GENRE 유지 */
  genre?: InputMaybe<Scalars['String']>;
  /** ID */
  id?: InputMaybe<Scalars['Int']>;
  /** 작품 소개 이미지 */
  introImageFile?: InputMaybe<Scalars['Upload']>;
  /** ip sourcing ID */
  ipSourcingId?: InputMaybe<Scalars['Int']>;
  /** 성인인증 필요작품 여부 */
  isAdult?: InputMaybe<Scalars['Boolean']>;
  /** 해당 작품이 홈 배너에 등록되어 있는가? */
  isBanner?: InputMaybe<Scalars['Boolean']>;
  /** 더미 데이터 여부 */
  isDummy?: InputMaybe<Scalars['Boolean']>;
  /** 현재 작품 상태 (연재 완료인가?) */
  isFinished?: InputMaybe<Scalars['Boolean']>;
  /** 메인 캐릭터 이름 고정 여부 */
  isFixMainChrName?: InputMaybe<Scalars['Boolean']>;
  /** 스플 오리지널 작품 여부 */
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  /** 해당 작품이 샘플로 제공되는지 여부 */
  isSample?: InputMaybe<Scalars['Boolean']>;
  /** 19금 작품 홈화면 노출 여부 */
  isShowHome?: InputMaybe<Scalars['Boolean']>;
  /** 스토리게임 노출 여부 */
  isStorygameHidden?: InputMaybe<Scalars['Boolean']>;
  /** 작품의 언어 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 작품 언어 코드 */
  languageCode?: InputMaybe<Scalars['String']>;
  /** 통구매 가능한 작품일 때 이 챕터 인덱스까지 무료로 볼 수 있다 */
  lastPreviewChapterIndex?: InputMaybe<Scalars['Int']>;
  /** 주인공 캐릭터 성별 */
  mainCharacterGender?: InputMaybe<Gender>;
  /** 메인 캐릭터 이미지 */
  mainCharacterImageFile?: InputMaybe<Scalars['Upload']>;
  /** StoryHasStudioFile 의 fileId 가 제공되면, 주인공 이미지를 변경한다. */
  mainCharacterImageStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 메인 캐릭터 레이블 */
  mainCharacterLabel?: InputMaybe<Scalars['String']>;
  /** StoryHasStudioFile 의 fileId 가 제공되면, 배경에 등장할 주인공 이미지(상반신)를 변경한다. */
  mainCharacterPortraitStudioFileId?: InputMaybe<Scalars['Int']>;
  /** 주인공 캐릭터 AI 자케 의상 프롬프트 */
  mainCharacterPrompt?: InputMaybe<Scalars['String']>;
  /** 메인 스토리 이미지 */
  mainImageFile?: InputMaybe<Scalars['Upload']>;
  /** 스토리의 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 공지사항 ID */
  noticeId?: InputMaybe<Scalars['Int']>;
  /** 해당 작품이 휴재중인가? */
  onHiatus?: InputMaybe<Scalars['Boolean']>;
  /** 작품소개 */
  oneLineDesc?: InputMaybe<Scalars['String']>;
  /** 할인 적용 전 스토리 통구매에 필요한 코인 수 */
  originalPrice?: InputMaybe<Scalars['Int']>;
  /** 작품의 플레이 타입 선택형(Interactive)/웹소설형(EPUB)/스토리게임(Storygame) */
  playType?: InputMaybe<Story_Play_Type>;
  /** 미공개시 스토리 이미지 */
  previewImageFile?: InputMaybe<Scalars['Upload']>;
  /** 할인이 적용된 originalPrice */
  price?: InputMaybe<Scalars['Int']>;
  /** 통구매 가능 설정 */
  purchasableRaw?: InputMaybe<Scalars['Boolean']>;
  /** 회차 구매 무료 시작일(회차 구매 무료 시작일 ~ 타임리프 무료 종료 일자 -> 구매건은 티켓 소비 없이 볼 수 있다.) */
  purchaseFreeStartAt?: InputMaybe<Scalars['DateTime']>;
  /** 해당 작품과 연관된 작품 아이디 */
  refStoryId?: InputMaybe<Scalars['Int']>;
  /** 월간 대표작의 월을 포함하고 있습니다.(일자는 무시해주세요) */
  representedAt?: InputMaybe<Scalars['DateTime']>;
  /** 스토리 스크립트, json encoded. */
  script?: InputMaybe<Scalars['String']>;
  /** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) 디폴트=도전스토리 */
  sectionType?: InputMaybe<Story_Section_Type>;
  /** 한줄소개(15자이내) */
  shortDesc?: InputMaybe<Scalars['String']>;
  /** 작품이 보여지는 우선순위 (높을수록 먼저 등장) */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 스토리 더보기 정보 JSON */
  storyDetailInfo?: InputMaybe<Scalars['JSONObject']>;
  /** 작품 만료일 */
  storyEndsAt?: InputMaybe<Scalars['DateTime']>;
  /** 속성 migration을 위한 회차별 정보 input */
  storyPropsMigration?: InputMaybe<StoryPropsMigrationInput>;
  /** 스토리게임 가로형 표지(16:9) */
  storygameCoverImageFile?: InputMaybe<Scalars['Upload']>;
  /** 미리보기(16:9). 리스트 순으로 정렬하고 포함되어 있지 않은 아이디의 미리보기는 삭제됨 */
  storygamePreviewIds?: InputMaybe<Array<Scalars['Int']>>;
  /** 정식출시일. 추후 사전예약 마감일로 동작한다고 함 */
  storygameReleaseDate?: InputMaybe<Scalars['DateTime']>;
  /** 태그. 리스트 순으로 정렬하고 포함되어 있지 않은 아이디의 태그는 삭제됨 */
  storygameTagIds?: InputMaybe<Array<Scalars['Int']>>;
  /** 연령대 (자유입력) */
  targetAge?: InputMaybe<Scalars['String']>;
  /** 티저 영상 */
  teaserVideoFile?: InputMaybe<Scalars['Upload']>;
  /** 주제 (자유입력) */
  topic?: InputMaybe<Scalars['String']>;
  /** 작품 타입 Novel: 소설형 스토리, Chat: 채팅형 스토리 디폴트=Chat */
  type?: InputMaybe<Story_Type>;
  /** 가로형 썸네일(4:3) 이미지 */
  wideImageFile?: InputMaybe<Scalars['Upload']>;
  /** (미래) 노출 예정 시각 */
  willPublishAt?: InputMaybe<Scalars['String']>;
};

/** 속성 디폴트값을 전달하기 위한 migration data. 이전 속성 디폴트 값과 merge 된 값이어야 함. */
export type StoryPropsMigrationInput = {
  /** 작품의 속성값 디폴트 설정 */
  props: Array<StoryPropsToMigrate>;
  /** 작품의 ID */
  storyId: Scalars['Int'];
};

/** 속성 디폴트값을 전달하기 위한 migration data */
export type StoryPropsToMigrate = {
  /** 속성의 챕터별 디폴트값 (length 는 신규 migration 될 작품 챕터의 길이여야 함) - 문자열 타입인 경우 length = 0 배열 */
  numberValues: Array<Scalars['Int']>;
  /** 속성의 이름 */
  propName: Scalars['String'];
  /** 속성의 챕터별 디폴트값 (length 는 신규 migration 될 작품 챕터의 길이여야 함) - 숫자 타입인 경우 length = 0 배열 */
  stringValues: Array<Scalars['String']>;
};

export type StoryRankCache = {
  __typename?: 'StoryRankCache';
  /** 순위 변경된 수치 */
  changedRank?: Maybe<Scalars['Int']>;
  /** 현재 랭킹 */
  currentRank: Scalars['Int'];
  /** 랭킹 점수(조회수, 댓글수 등) */
  score: Scalars['Int'];
  /** 작품 정보 */
  story: Story;
};

/** 작품 랭킹 */
export type StoryRankCacheData = {
  __typename?: 'StoryRankCacheData';
  /** 이전 기간 랭킹 */
  beforeRank: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 현재 랭킹 */
  currentRank: Scalars['Int'];
  /** YYYY-MM-DD */
  date: Scalars['String'];
  /** 작품 장르 (CHALLENGE_STORY_GENRE + All) */
  genre: Scalars['String'];
  /** 일련번호 */
  id: Scalars['Int'];
  /** 도전스토리 랭킹 여부 */
  isChallenge: Scalars['Boolean'];
  /** 랭크 종류 (조회수, 정주행률, 좋아요, 댓글수) */
  rankType: Story_Rank_Type;
  /** 해당 순위에 대한 score 데이터 raws 데이터 */
  rawScore: Scalars['String'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 일간, 주간, 월간 */
  type: Story_Stat_Cache_Type;
  updatedAt: Scalars['DateTime'];
};

/** 작품의 랭킹 정보 */
export type StoryRanking = {
  __typename?: 'StoryRanking';
  /** 순위 변동값 */
  amountChange: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 장르가 null이면 전체 장르에 대한 랭킹 */
  genre?: Maybe<Scalars['String']>;
  /** 새롭게 순위권 진입했는가? */
  isNew: Scalars['Boolean'];
  /** 작품의 순위 */
  ranking: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyRankingId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 생성할때마다 1씩 증가 */
  version: Scalars['Int'];
};

/** 스토리 통계값을 관리 */
export type StoryStat = {
  __typename?: 'StoryStat';
  createdAt: Scalars['DateTime'];
  /** 해당 작품에 마지막 댓글이 달린 시각 (long type) */
  latestCommentedAt?: Maybe<Scalars['Int']>;
  /** 대상 작품에 달린 모든 댓글 수 */
  numComment: Scalars['Int'];
  /** 대상 작품의 좋아요 수 */
  numLikes: Scalars['Int'];
  /** 대상 작품의 총 회차 플레이 수 */
  numPlay: Scalars['Int'];
  /** 대상 작품의 총 작품상세 조회수 */
  numViews: Scalars['Int'];
  /** 통계 대상 작품 */
  story: Story;
  /** 통계 대상 작품의 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyStatId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type StoryStatCache = {
  __typename?: 'StoryStatCache';
  /** 통계 수치 */
  count: Scalars['Int'];
  /** 이전 데이터와 비교했을 때 1/3 인 경우 */
  isDrop?: Maybe<Scalars['Boolean']>;
  /** 전체 조회할때 전체 기간 중 최고 수치인 경우 */
  isTop?: Maybe<Scalars['Boolean']>;
  /** 상세목록에 표시되는 기간 */
  period: Scalars['String'];
  /** 상세목록에 표시되는 기간 */
  x: Scalars['String'];
};

/** 작품 통계 */
export type StoryStatOutput = {
  __typename?: 'StoryStatOutput';
  /** 전체 작품 뷰 수의 실시간 평균값을 가져옵니다 */
  avgViews: Scalars['Int'];
};

/** 작품의 태그를 관리하는 엔티티. 현재는 스토리게임에서만 사용됨 */
export type StoryTag = {
  __typename?: 'StoryTag';
  createdAt: Scalars['DateTime'];
  /** 태그명 */
  name: Scalars['String'];
  /** 아이디 */
  storyTagId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StoryTag 생성을 위한 input */
export type StoryTagCreationInput = {
  /** 태그명 */
  name: Scalars['String'];
};

/** StoryHasStoryTag 목록을 가져오기 위한 input */
export type StoryTagSyncInput = {
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
  /** sync를 맞출 tagId 목록 */
  storyTagIds: Array<Scalars['Int']>;
};

/** StoryTag 업데이트를 위한 input */
export type StoryTagUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

export type StoryToggleReaction = {
  __typename?: 'StoryToggleReaction';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  reactionId: Scalars['Int'];
  /** 리액션 종류 */
  reactionType: Story_Reaction_Type;
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 리액션을 한 유저 */
  user: User;
  /** 리액션을 한 유저 아이디 */
  userId: Scalars['Int'];
};

/** StoryToggleReaction 생성을 위한 input */
export type StoryToggleReactionCreationInput = {
  /** 리액션 종류 */
  reactionType: Story_Reaction_Type;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** StoryToggleReaction 삭제를 위한 input */
export type StoryToggleReactionDeleteInput = {
  /** 리액션 종류 */
  reactionType: Story_Reaction_Type;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** StoryToggleReaction 업데이트를 위한 input */
export type StoryToggleReactionUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 작품 연재요일 정보 */
export type StoryWeekday = {
  __typename?: 'StoryWeekday';
  createdAt: Scalars['DateTime'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyWeekdayId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 연재요일 */
  weekday: Story_Weekday;
};

/** StoryWeekday 생성을 위한 input */
export type StoryWeekdayCreationInput = {
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 연재요일 */
  weekday: Story_Weekday;
};

/** StoryWeekday 삭제를 위한 input */
export type StoryWeekdayDeleteInput = {
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 연재요일 */
  weekday: Story_Weekday;
};

/** 작품의 랭킹 정보(playType으로 나눔) */
export type StoryWeeklyRanking = {
  __typename?: 'StoryWeeklyRanking';
  /** 순위 변동값 */
  amountChange: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 새롭게 순위권 진입했는가? */
  isNew: Scalars['Boolean'];
  /** playType이 null이면 전체 타입에 대한 랭킹 */
  playType?: Maybe<Scalars['String']>;
  /** 작품의 순위 */
  ranking: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storyWeeklyRankingId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 생성할때마다 1씩 증가 */
  version: Scalars['Int'];
};

/** 스토리게임 지원으로 추가로 필요한 배경들을 관리하는 엔티티 */
export type StorygameBackground = {
  __typename?: 'StorygameBackground';
  createdAt: Scalars['DateTime'];
  /** 파일 */
  file?: Maybe<File>;
  /** 파일 ID */
  fileId?: Maybe<Scalars['Int']>;
  /** 첨부 관련 이름 */
  name: Scalars['String'];
  /** 이미지가 참조되는 플랫폼 정보. Mobile, PC외 더 넓게 확장 가능 */
  platformType: Storygame_Image_Platform_Type;
  /** 할당 ID */
  shfId: Scalars['Int'];
  /** 스토리 */
  story: Story;
  /** 스토리게임의 기준이 되는 기존 배경 */
  storyHasBackground: StoryHasBackground;
  /** 스토리게임의 기준이 되는 기존 배경의 아이디 */
  storyHasBackgroundId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StorygameBackground 생성을 위한 input */
export type StorygameBackgroundCreationInput = {
  /** 업로드할 이미지 파일 */
  imageFile: Scalars['Upload'];
  /** 이미지가 참조되는 플랫폼 정보. Mobile, PC외 더 넓게 확장 가능 */
  platformType: Storygame_Image_Platform_Type;
  /** 스토리게임의 기준이 되는 기존 배경의 아이디 */
  storyHasBackgroundId: Scalars['Int'];
  /** 이미지가 속한 작품의 ID */
  storyId: Scalars['Int'];
};

/** StorygameBackground 업데이트를 위한 input */
export type StorygameBackgroundUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
  /** 업로드할 이미지 파일 */
  imageFile: Scalars['Upload'];
};

/** AI 자케 생성 정보 */
export type StorygameCharacter = {
  __typename?: 'StorygameCharacter';
  /** 원본 캐릭터 ID (-1이면 주인공) */
  chrId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 캐릭터 기본 이름 */
  firstName?: Maybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 이미지 파일 (아이콘) */
  imageFile?: Maybe<File>;
  /** 이미지 파일 ID */
  imageFileId?: Maybe<Scalars['Int']>;
  /** 캐릭터 기본 성 */
  lastName?: Maybe<Scalars['String']>;
  /** 스토리게임용 캐릭터 상반신 이미지 파일 */
  portraitFile?: Maybe<File>;
  /** 스토리게임용 캐릭터 상반신 이미지 파일 ID */
  portraitFileId?: Maybe<Scalars['Int']>;
  /** ref RequestAICharacter */
  request?: Maybe<RequestAiCharacter>;
  /** ref RequestAICharacter.id */
  requestId?: Maybe<Scalars['Int']>;
  /** 작품 */
  story: Story;
  /** 작품 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 유저 */
  user: User;
  /** 유저 ID */
  userId: Scalars['Int'];
};

/** 보정 프롬프트 */
export type StorygameCharacterAdditionalPrompt = {
  __typename?: 'StorygameCharacterAdditionalPrompt';
  /** 계층구조 /로 구분됨 (2-depth) ex. 얼굴/마름 */
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 남/여 */
  gender: Gender;
  /** ID */
  id: Scalars['Int'];
  /** template 에서 활용될 key */
  key: Scalars['String'];
  /** negative prompt */
  negativePrompt: Scalars['String'];
  /** 프롬프트 적용 우선 순위. (작을 수록 우선순위 높음) */
  order: Scalars['Int'];
  /** positive prompt */
  prompt: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** AI 자캐 생성 프롬프트 추가 및 생성 입력값 */
export type StorygameCharacterAdditionalPromptPropsInput = {
  /** 카테고리 */
  category?: InputMaybe<Scalars['String']>;
  /** 성별 */
  gender?: InputMaybe<Gender>;
  /** ID */
  id?: InputMaybe<Scalars['Int']>;
  /** key */
  key?: InputMaybe<Scalars['String']>;
  /** 부정 프롬프트 */
  negativePrompt?: InputMaybe<Scalars['String']>;
  /** 순서 */
  order?: InputMaybe<Scalars['Int']>;
  /** 프롬프트 */
  prompt?: InputMaybe<Scalars['String']>;
  /** 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** 추가 프롬프트 정보 */
export type StorygameCharacterAdditionalPrompts = {
  __typename?: 'StorygameCharacterAdditionalPrompts';
  /** object */
  prompts: Scalars['JSONObject'];
};

/** 핵심 등장인물 삭제를 위한 입력값 */
export type StorygameCharacterDeleteInput = {
  /** 캐릭터 아이디, -1이면 주인공 */
  chrId: Scalars['Int'];
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
};

/** 스토리게임의 등장인물 기본 프롬프트 */
export type StorygameCharacterPrompt = {
  __typename?: 'StorygameCharacterPrompt';
  /** 프롬프트에 들어가는 default positive context */
  context: Scalars['JSONObject'];
  createdAt: Scalars['DateTime'];
  /** 캐릭터 성별 */
  gender: Gender;
  /** ID */
  id: Scalars['Int'];
  /** 프롬프트에 들어가는 default negative context */
  negativeContext: Scalars['JSONObject'];
  /** negative prompt */
  negativePrompt: Scalars['String'];
  /** positive prompt */
  prompt: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** AI 자캐 생성 프롬프트 생성 및 수정 입력값 */
export type StorygameCharacterPromptPropsInput = {
  /** 컨텍스트 */
  contextRaw?: InputMaybe<Scalars['String']>;
  /** 성별 */
  gender?: InputMaybe<Gender>;
  /** ID */
  id?: InputMaybe<Scalars['Int']>;
  /** 부정 컨텍스트 */
  negativeContextRaw?: InputMaybe<Scalars['String']>;
  /** 부정 프롬프트 */
  negativePrompt?: InputMaybe<Scalars['String']>;
  /** 프롬프트 */
  prompt?: InputMaybe<Scalars['String']>;
  /** 작품 아이디 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** AI 캐릭터 설정 저장시 필요한 데이터 */
export type StorygameCharacterUpsertInput = {
  /** 캐릭터 아이디, -1이면 주인공 */
  chrId: Scalars['Int'];
  /** 캐릭터 이름 */
  firstName?: InputMaybe<Scalars['String']>;
  /** 크롭된 아이콘 이미지 */
  image?: InputMaybe<Scalars['Upload']>;
  /** 캐릭터 프로필 이미지 아이디 */
  imageId?: InputMaybe<Scalars['Int']>;
  /** 캐릭터 성 */
  lastName?: InputMaybe<Scalars['String']>;
  /** 선택된 AI 이미지 ID */
  portraitImageId?: InputMaybe<Scalars['Int']>;
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
};

/** 섹션 내 스토리게임의 노출 국가를 관리하는 엔티티 */
export type StorygameCountry = {
  __typename?: 'StorygameCountry';
  /** 노출할 국가 */
  country: Country;
  /** 노출할 국가의 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 스토리게임 */
  story: Story;
  /** 스토리게임의 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storygameCountryId: Scalars['Int'];
  /** 스토리게임 섹션 */
  storygameSection: StorygameSection;
  /** 스토리게임 섹션 아이디 */
  storygameSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StorygameCountry 업데이트를 위한 input */
export type StorygameCountryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 스토리게임 섹션 내 스토리의 노출 언어를 관리하는 엔티티 */
export type StorygameLanguage = {
  __typename?: 'StorygameLanguage';
  createdAt: Scalars['DateTime'];
  /** 노출할 언어 */
  language: Language;
  /** 노출할 언어의 코드 */
  languageCode: Scalars['String'];
  /** 스토리게임 */
  story: Story;
  /** 스토리게임의 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  storygameLanguageId: Scalars['Int'];
  /** 스토리게임 섹션 */
  storygameSection: StorygameSection;
  /** 스토리게임 섹션 아이디 */
  storygameSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스토리게임 랭킹 정보 */
export type StorygameRanking = {
  __typename?: 'StorygameRanking';
  /** 랭킹 순위 */
  rank: Scalars['Int'];
  /** 랭킹 타입 */
  type: StorygameRankingType;
};

/** 스토리게임 랭킹 타입 */
export enum StorygameRankingType {
  MostPlayed = 'MostPlayed',
  TopSeller = 'TopSeller'
}

/** 스토리게임의 섹션 정보를 관리하는 엔티티 */
export type StorygameSection = {
  __typename?: 'StorygameSection';
  /** 섹션 내 적용되는 알고리즘 */
  algorithm?: Maybe<StorygameSectionAlgorithm>;
  /** JSON string(StorygameSectionBanner[]).배너로 설정된 정보 */
  banners?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** true면 수정이 불가한 섹션 (개인화 섹션) */
  disabled: Scalars['Boolean'];
  /** 더보기 버튼 표시 여부 */
  hasViewMore?: Maybe<Scalars['Boolean']>;
  /** true면 클라이언트에서 무조건 노출 안됨 */
  isHidden: Scalars['Boolean'];
  /** 테스트 지면 여부 */
  isTestSection?: Maybe<Scalars['Boolean']>;
  /** 보여지는 컨텐츠 수 */
  numVisibleContents?: Maybe<Scalars['Int']>;
  /** 기간 기준 */
  period?: Maybe<StorygameSectionPeriod>;
  /** JSON string(number[]). 고정된 스토리게임 아이디들 */
  pinnedStorygameIds?: Maybe<Scalars['String']>;
  /** pinnedStorygameIds에 해당하는 스토리게임 정보 */
  pinnedStorygames?: Maybe<Array<Story>>;
  /** JSON string(RankListSection[]) 랭킹 리스트 섹션 정보 */
  rankListSections?: Maybe<Scalars['String']>;
  /** 섹션의 위치(header or footer). 서버 내부에서 고정된 섹션에 적용됨 */
  sectionPosition?: Maybe<Scalars['String']>;
  /** 각 섹션의 위치 내에서의 정렬 순서. 클수록 앞에 보여짐 */
  sectionPositionOrder?: Maybe<Scalars['Int']>;
  /** JSON string(StorygameSectionLocalizedText[]). 노출될 섹션의 타이틀 */
  sectionTitle?: Maybe<Scalars['String']>;
  /** 섹션 타입(추천, 유사 작품) */
  sectionType?: Maybe<StorygameSectionType>;
  /** sectionType이 null인 섹션이 보여지는 순서 */
  showOrder: Scalars['Int'];
  /** 스토리게임 출시일 노출 여부. true면 노출 */
  showReleaseDate?: Maybe<Scalars['Boolean']>;
  /** 아이디 */
  storygameSectionId: Scalars['Int'];
  /** 섹션에 노출 설정 가능한 국가 정보 */
  supportedCountries: Array<SupportedStorygameSectionCountry>;
  /** 섹션이 속한 스토리게임의 탭 */
  tab: StorygameTab;
  /** 섹션이 속한 스토리게임의 탭 아이디 */
  tabId: Scalars['Int'];
  /** 테스트 지면의 노출 비율(isTestSection이 true일 때만 사용) */
  testSectionPercentage?: Maybe<Scalars['Int']>;
  /** 섹션 생성 시 입력하는 타이틀. 키와 같은 역할을 하는 것으로 보인다. */
  title: Scalars['String'];
  /** UI 타입 */
  uiType: StorygameSectionUiType;
  updatedAt: Scalars['DateTime'];
};

/** 스토리게임 섹션 알고리즘 */
export enum StorygameSectionAlgorithm {
  FreeHighAppu = 'FreeHighAPPU',
  HighAppu = 'HighAPPU',
  HighSales = 'HighSales',
  HighViews = 'HighViews',
  Manual = 'Manual',
  ManyFavorites = 'ManyFavorites',
  RecentPlay = 'RecentPlay',
  RecentReleaseViews = 'RecentReleaseViews'
}

/** 스토리게임 섹션의 노출 국가를 관리하는 엔티 */
export type StorygameSectionCountry = {
  __typename?: 'StorygameSectionCountry';
  country: Country;
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  storygameSection: StorygameSection;
  /** 아이디 */
  storygameSectionCountryId: Scalars['Int'];
  storygameSectionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** StorygameSectionCountry 생성을 위한 input */
export type StorygameSectionCountryCreationInput = {
  countryCode: Scalars['String'];
  storygameSectionId: Scalars['Int'];
};

/** StorygameSection 생성을 위한 input */
export type StorygameSectionCreationInput = {
  /** 섹션이 속한 스토리게임의 탭 아이디 */
  tabId: Scalars['Int'];
  /** 섹션 생성 시 입력하는 타이틀. 키와 같은 역할을 하는 것으로 보인다. */
  title: Scalars['String'];
  /** UI 타입 */
  uiType: StorygameSectionUiType;
};

/** 스토리게임 섹션 기간 기준 */
export enum StorygameSectionPeriod {
  All = 'All',
  Day = 'Day',
  Day3 = 'Day3',
  Month = 'Month',
  Month3 = 'Month3',
  Month6 = 'Month6',
  Week = 'Week',
  Week2 = 'Week2',
  Year = 'Year'
}

/** 스토리게임 섹션 타입 */
export enum StorygameSectionType {
  Custom = 'Custom',
  GameBasedRecommendation = 'GameBasedRecommendation',
  SliderBanner = 'SliderBanner',
  TagBasedRecommendation = 'TagBasedRecommendation'
}

/** 스토리게임 섹션 UI 타입 */
export enum StorygameSectionUiType {
  CarouselLarge = 'CarouselLarge',
  CarouselMedium = 'CarouselMedium',
  CarouselProgress = 'CarouselProgress',
  CarouselRank = 'CarouselRank',
  CarouselSmall = 'CarouselSmall',
  RankList = 'RankList',
  Slider = 'Slider'
}

/** StorygameSection 업데이트를 위한 input */
export type StorygameSectionUpdateInput = {
  /** 섹션 내 적용되는 알고리즘 */
  algorithm?: InputMaybe<StorygameSectionAlgorithm>;
  /** JSON string(StorygameSectionBanner[]).배너로 설정된 정보 */
  banners?: InputMaybe<Scalars['String']>;
  /** 더보기 버튼 표시 여부 */
  hasViewMore?: InputMaybe<Scalars['Boolean']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** true면 클라이언트에서 무조건 노출 안됨 */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 테스트 지면 여부 */
  isTestSection?: InputMaybe<Scalars['Boolean']>;
  /** 보여지는 컨텐츠 수 */
  numVisibleContents?: InputMaybe<Scalars['Int']>;
  /** 기간 기준 */
  period?: InputMaybe<StorygameSectionPeriod>;
  /** JSON string(number[]). 고정된 스토리게임 아이디들 */
  pinnedStorygameIds?: InputMaybe<Scalars['String']>;
  /** JSON string(RankListSection[]) 랭킹 리스트 섹션 정보 */
  rankListSections?: InputMaybe<Scalars['String']>;
  /** JSON string(StorygameSectionLocalizedText[]). 노출될 섹션의 타이틀 */
  sectionTitle?: InputMaybe<Scalars['String']>;
  /** 스토리게임 출시일 노출 여부. true면 노출 */
  showReleaseDate?: InputMaybe<Scalars['Boolean']>;
  /** 테스트 지면의 노출 비율(isTestSection이 true일 때만 사용) */
  testSectionPercentage?: InputMaybe<Scalars['Int']>;
  /** 섹션 생성 시 입력하는 타이틀. 키와 같은 역할을 하는 것으로 보인다. */
  title?: InputMaybe<Scalars['String']>;
  /** UI 타입 */
  uiType?: InputMaybe<StorygameSectionUiType>;
};

/** 어드민에서 설정한 섹션 설정값을 서버에서 가공한 데이터. 어드민의 미리보기, 웹에서 공통으로 참조 가능한 구조 */
export type StorygameSectionView = {
  __typename?: 'StorygameSectionView';
  /** 더보기 여부 */
  hasViewMore: Scalars['Boolean'];
  /** 테스트 섹션 여부 */
  isTestSection: Scalars['Boolean'];
  sectionData: Scalars['String'];
  /** 해당 섹션의 ID */
  sectionId: Scalars['Int'];
  /** 섹션 제목. 리스트 순위형일 때 undefined */
  sectionTitle?: Maybe<Scalars['String']>;
  /** 섹션 타입 */
  sectionType?: Maybe<StorygameSectionType>;
  /** 해당 섹션의 ID */
  showReleaseDate: Scalars['Boolean'];
  /** 섹션 UI 타입 */
  uiType: StorygameSectionUiType;
};

/** 스토리게임 탭을 관리하는 엔티티 */
export type StorygameTab = {
  __typename?: 'StorygameTab';
  /** 탭이 노출될 국가의 정보 */
  country?: Maybe<Country>;
  /** 탭이 노출될 국가의 코드 */
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 어드민에서 보여지는 타이틀 */
  displayTitle: Scalars['String'];
  /** 장르 코드. 탭의 타입이 genre일 때만 값이 존재 */
  genre?: Maybe<Scalars['String']>;
  /** 숨김 여부 */
  isHidden: Scalars['Boolean'];
  /** 탭이 노출될 언어의 정보 */
  language?: Maybe<Language>;
  /** 탭이 노출될 언어의 코드 */
  languageCode?: Maybe<Scalars['String']>;
  /** 클라이언트에서 보여질 순서. 높을수록 먼저 등장 */
  showOrder: Scalars['Int'];
  /** 아이디 */
  storygameTabId: Scalars['Int'];
  /** 타이틀에 대한 lokalise key */
  titleLokaliseKey: Scalars['String'];
  /** 탭의 타입 */
  type: StorygameTabType;
  updatedAt: Scalars['DateTime'];
};

/** 스토리게임 탭 종류 */
export enum StorygameTabType {
  Genre = 'Genre',
  Nsfw = 'NSFW',
  Recommended = 'Recommended'
}

/** StorygameTab 업데이트를 위한 input */
export type StorygameTabUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 스토리게임 섹션 내 스토리의 노출 여부를 관리하는 엔티티. 데이터가 있으면 노출. 이 부분은 섹션 배너 처럼 json으로 country, language와 함께 통합해서 json으로 관리하는게 좋을 것 같음 */
export type StorygameVisibility = {
  __typename?: 'StorygameVisibility';
  createdAt: Scalars['DateTime'];
  /** 스토리게임 */
  story: Story;
  /** 스토리게임의 아이디 */
  storyId: Scalars['Int'];
  /** 스토리게임 섹션 */
  storygameSection: StorygameSection;
  /** 스토리게임 섹션 아이디 */
  storygameSectionId: Scalars['Int'];
  /** 아이디 */
  storygameVisibilityId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 스튜디오 릴리즈된 기능 */
export type StudioReleaseItem = {
  __typename?: 'StudioReleaseItem';
  createdAt: Scalars['DateTime'];
  /** 설명 */
  description: Scalars['String'];
  /** 외부 링크 */
  externalLink?: Maybe<Scalars['String']>;
  /** 우선순위 (default 1000) 낮을 수록 먼저 */
  showOrder: Scalars['Int'];
  /** 일련번호 */
  sriId: Scalars['Int'];
  /** 제목 */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** StudioReleaseVersion */
  version: StudioReleaseVersion;
  /** StudioReleaseVersion 아이디 */
  versionId: Scalars['Int'];
};

/** 스튜디오 릴리즈 기능 생성하는데 필요한 입력값 */
export type StudioReleaseItemCreationInput = {
  /** 설명 */
  description: Scalars['String'];
  /** 외부 링크 */
  externalLink?: InputMaybe<Scalars['String']>;
  /** 우선순위 (default 1000) 낮을 수록 먼저 */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 제목 */
  title: Scalars['String'];
  /** StudioReleaseVersion 아이디 */
  versionId: Scalars['Int'];
};

/** 스튜디오 릴리즈 기능 업데이트 하는데 필요한 입력값 */
export type StudioReleaseItemUpdateInput = {
  /** 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 외부 링크 */
  externalLink?: InputMaybe<Scalars['String']>;
  /** 일련번호 */
  id: Scalars['Int'];
  /** 우선순위 (default 1000) 낮을 수록 먼저 */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** StudioReleaseVersion 아이디 */
  versionId?: InputMaybe<Scalars['Int']>;
};

/** 스튜디오 릴리즈 버전 정보 */
export type StudioReleaseVersion = {
  __typename?: 'StudioReleaseVersion';
  createdAt: Scalars['DateTime'];
  /** 숨김처리 (default true) */
  isHidden: Scalars['Boolean'];
  /** 해당 릴리즈 버전에 포함된 기능 */
  items: Array<StudioReleaseItem>;
  /** 해당 릴리즈 버전에 포함된 기능 개수 */
  numItems: Scalars['Int'];
  /** 배포 시각 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  /** 일련번호 */
  srvId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 버전 이름 (ex. v2.0, v2.1) */
  versionName: Scalars['String'];
};

/** 스튜디오 릴리스 버전 정보 생성하는데 필요한 입력값 */
export type StudioReleaseVersionCreationInput = {
  /** 숨김처리 (default true) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 배포 시각 */
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  /** 버전 이름 (ex. v2.0, v2.1) */
  versionName: Scalars['String'];
};

/** 스튜디오 릴리스 버전 정보 수정하는데 필요한 입력값 */
export type StudioReleaseVersionUpdateInput = {
  /** 일련번호 */
  id: Scalars['Int'];
  /** 숨김처리 (default true) */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** 배포 시각 */
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  /** 버전 이름 (ex. v2.0, v2.1) */
  versionName?: InputMaybe<Scalars['String']>;
};

/** 사용자 별 튜토리얼 진행 정보 */
export type StudioTutorialCompletion = {
  __typename?: 'StudioTutorialCompletion';
  /** 특정 튜토리얼 항목에 해당하는 string enum */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 부가적으로 통계 등을 처리하기 위한 문자열 공간 */
  extra?: Maybe<Scalars['String']>;
  /** 일련번호 */
  stcId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 스튜디오 튜토리얼 완료하는데 필요한 입력값 */
export type StudioTutorialCompletionCreationInput = {
  /** 특정 튜토리얼 항목에 해당하는 string enum */
  code: Scalars['String'];
  /** 부가적으로 통계 등을 처리하기 위한 문자열 공간 */
  extra?: InputMaybe<Scalars['String']>;
};

/** 정액권 정보 */
export type SubsCoin = {
  __typename?: 'SubsCoin';
  /** 구매 즉시 받게 되는 코인의 갯수 */
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 정액권 구매시 받게 되는 일일 보상 코인 정보 */
  dailyRewardCoin?: Maybe<DailyRewardCoin>;
  /** 상품의 상세 설명 */
  description: Scalars['String'];
  /** 모바일 기기의 os */
  os: Os;
  /** 상품의 가격(원) */
  price: Scalars['Int'];
  /** 정액권 상품명 */
  productName: Scalars['String'];
  /** 목록 내에서의 상품 정렬 순서 */
  seq: Scalars['Int'];
  /** 아이디 */
  subsCoinId: Scalars['Int'];
  /** 스토어에 등록된 구독 상품 ID */
  subsProductId: Scalars['String'];
  /** 상품의 간략한 설명 */
  summary: Scalars['String'];
  /** 상품에 대한 타이틀(설명과 구분이 모호하나 가장 상단의 설명이라 title이라 명함) */
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 해당 정액권을 유저가 구독하고 있는가? */
  userHasSubscribed: Scalars['Boolean'];
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

/** 정액권을 생성하는데 필요한 데이터 */
export type SubsCoinInput = {
  /** 구매 즉시 받게 되는 코인의 갯수 */
  amount: Scalars['Int'];
  /** 상품의 상세 설명 */
  description: Scalars['String'];
  /** 모바일 기기의 os */
  os: Os;
  /** 상품의 가격(원) */
  price: Scalars['Int'];
  /** 정액권 상품명 */
  productName: Scalars['String'];
  /** 목록 내에서의 상품 정렬 순서 */
  seq: Scalars['Int'];
  /** 스토어에 등록된 구독 상품 ID */
  subsProductId: Scalars['String'];
  /** 상품의 간략한 설명 */
  summary: Scalars['String'];
  /** 상품에 대한 타이틀(설명과 구분이 모호하나 가장 상단의 설명이라 title이라 명함) */
  title: Scalars['String'];
  /** 노출 여부 */
  visible: Scalars['Boolean'];
};

export type SupportedStorygameCountryOutput = {
  __typename?: 'SupportedStorygameCountryOutput';
  /** 노출 허용 여부 */
  allowed: Scalars['Boolean'];
  /** 국가 코드 */
  countryCode: Scalars['String'];
  /** 설정 가능 여부 */
  disabled: Scalars['Boolean'];
};

export type SupportedStorygameLanguageOutput = {
  __typename?: 'SupportedStorygameLanguageOutput';
  /** 노출 허용 여부 */
  allowed: Scalars['Boolean'];
  /** 언어 코드 */
  languageCode: Scalars['String'];
};

/** 스토리게임 섹션에서 노출 설정 가능한 국가 데이터 */
export type SupportedStorygameSectionCountry = {
  __typename?: 'SupportedStorygameSectionCountry';
  /** true면 countryCode에 해당하는 국가에 섹션 노출됨 */
  allowed: Scalars['Boolean'];
  /** 국가 코드 */
  countryCode: Scalars['String'];
};

/** 사용자 입력 텍스트 룰베이스 동기화하는데 필요한 데이터 */
export type SyncChoiceAffectionRuleInput = {
  /** choice 아이디 */
  choiceId: Scalars['Int'];
  /** 해당 텍스트들이 감지되면 선택될 choice index */
  choiceIndex: Scalars['Int'];
  /** \n으로 구분된 모든 텍스트 */
  ruleTexts: Scalars['String'];
};

export type SyncStoryBlockedCountryInput = {
  /** 갱신할 지역코드 배열 */
  countryCodes: Array<Scalars['String']>;
  /** 아이디 */
  storyId: Scalars['Int'];
};

/** 프리뷰 이미지 순서 업데이트, 정리를 위한 input */
export type SyncStoryPreviewInput = {
  /** 프리뷰 아이디. 전달된 아이디 순서대로 배치됨. 포함되지 않은 아이디의 리소스는 삭제됨 */
  previewIds: Array<Scalars['Int']>;
  /** 스토리 아이디 */
  storyId: Scalars['Int'];
};

/** 현재 플레이 중인 챕터에서 아이템 sync를 맞추기 위한 input */
export type SyncUserItemsInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 업데이트 된 아이템 (JSON encoded), null 이거나 없으면 업데이트하지 않음. */
  updatedUserItems?: InputMaybe<Scalars['String']>;
};

/** 클라이언트 시스템 언어 정보 */
export type SystemLanguage = {
  __typename?: 'SystemLanguage';
  /** 언어 코드. 확장성을 위해 서버 내부에서만 enum을 사용 */
  code: Scalars['String'];
  /** 유저에게 보여질 텍스트 */
  display: Scalars['String'];
};

/** 전체 시스템에 대한 통계 수치 */
export type SystemStat = {
  __typename?: 'SystemStat';
  /** 익명 사용자의 수 */
  getNumAnonymousUsers: Scalars['Float'];
};

/** 약관 서비스 이름 */
export enum Terms_Service_Name {
  StoryPlay = 'StoryPlay',
  StoryPlayStore = 'StoryPlayStore',
  Storyreels = 'Storyreels'
}

/** 패키지 상품의 타임 세일 타입 */
export enum Time_Sale_Type {
  OnlyOnceSinceFirstVisitStore = 'OnlyOnceSinceFirstVisitStore',
  UntilFixedEndDate = 'UntilFixedEndDate'
}

/** 토스 페이먼츠 결제 타입 */
export enum Toss_Payments_Pay_Type {
  Domestic = 'DOMESTIC',
  Overseas = 'OVERSEAS'
}

/** 토스 페이먼츠 결제 상태 */
export enum Toss_Payments_Status {
  Aborted = 'ABORTED',
  Canceled = 'CANCELED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Forgery = 'FORGERY',
  InProgress = 'IN_PROGRESS',
  PartialCanceled = 'PARTIAL_CANCELED',
  Ready = 'READY',
  WaitingForDeposit = 'WAITING_FOR_DEPOSIT'
}

/** TTS 음의 속도/높낮이 */
export enum Tts_Dpe_Id {
  DefaultDefault = 'Default_Default',
  DefaultHigh = 'Default_High',
  DefaultLow = 'Default_Low',
  QuickDefault = 'Quick_Default',
  QuickHigh = 'Quick_High',
  QuickLow = 'Quick_Low',
  SlowDefault = 'Slow_Default',
  SlowHigh = 'Slow_High',
  SlowLow = 'Slow_Low'
}

/** TTS 화자 감정 */
export enum Tts_Emotion {
  Angry = 'Angry',
  Annoyance = 'Annoyance',
  Cry = 'Cry',
  Default = 'Default',
  Drunken = 'Drunken',
  Fear = 'Fear',
  Happy = 'Happy',
  Howl = 'Howl',
  Insipid = 'Insipid',
  Kind = 'Kind',
  Powerless = 'Powerless',
  Surprised = 'Surprised',
  WeekAngry = 'WeekAngry',
  Whisper = 'Whisper'
}

/** tts 발화자 상태값 */
export enum Tts_Speaker_Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

/** 휴면계정을 해지합니다. */
export type TerminateDormantUserInput = {
  /** 마케팅 푸시 수신 여부 */
  marketingPushEnabled: Scalars['Boolean'];
  /** 개정된 약관에 대한 동의 여부. 2022-07-27 기준 서비스 이용 약관, 개인정보처리방침을 묶어서 관리함 */
  termsAccepted: Scalars['Boolean'];
};

/** 약관을 관리하는 엔티티 */
export type Terms = {
  __typename?: 'Terms';
  /** true이면 필수로 동의가 필요한 약관 */
  acceptRequired: Scalars['Boolean'];
  /** 대상 국가 */
  country: Country;
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 약관의 링크 */
  link: Scalars['String'];
  /** 약관이 사용되는 서비스명 */
  serviceName: Terms_Service_Name;
  /** 아이디 */
  termsId: Scalars['Int'];
  /** 약관 타입(TOS: 서비스 이용 약관, collectionAndUseOfPrivacy: 개인정보 수집 및 이용, PP: 개인정보 처리방침) */
  type: TermsType;
  updatedAt: Scalars['DateTime'];
  /** 약관의 버전. 타입별로 고유함 */
  version: Scalars['Int'];
  /** 약관 유효일 */
  willActivateAt: Scalars['DateTime'];
  /** 약관 발효일 전 미리 동의를 받기 시작할 시각 */
  willNoticeAt: Scalars['DateTime'];
};

/** Terms 생성을 위한 input */
export type TermsCreationInput = {
  /** 대상 국가 코드 */
  countryCode: Scalars['String'];
  /** 약관의 링크 */
  link: Scalars['String'];
  /** 약관 타입(TOS: 서비스 이용 약관, collectionAndUseOfPrivacy: 개인정보 수집 및 이용, PP: 개인정보 처리방침) */
  type: TermsType;
  /** 약관의 버전. 타입별로 고유함 */
  version?: InputMaybe<Scalars['Int']>;
  /** 약관 유효일 */
  willActivateAt?: InputMaybe<Scalars['DateTime']>;
};

/** 스플 약관 타입 */
export enum TermsType {
  CollectionAndUseOfPrivacy = 'CollectionAndUseOfPrivacy',
  Pp = 'PP',
  Tos = 'TOS'
}

/** Terms 업데이트를 위한 input */
export type TermsUpdateInput = {
  /** 대상 국가 코드 */
  countryCode?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 약관의 링크 */
  link?: InputMaybe<Scalars['String']>;
  /** 약관 타입(TOS: 서비스 이용 약관, collectionAndUseOfPrivacy: 개인정보 수집 및 이용, PP: 개인정보 처리방침) */
  type?: InputMaybe<TermsType>;
  /** 약관의 버전. 타입별로 고유함 */
  version?: InputMaybe<Scalars['Int']>;
  /** 약관 유효일 */
  willActivateAt?: InputMaybe<Scalars['DateTime']>;
};

/** 인스턴트 테스팅을 위한 데이터 */
export type TestSession = {
  __typename?: 'TestSession';
  /** 챕터 */
  chapter?: Maybe<Chapter>;
  /** 챕터 아이디 */
  chapterId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** 스튜디오에서 생성된 mockup 플레이데이터 */
  playData: Scalars['String'];
  /** 작품 */
  story: Story;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  /** 일련번호 */
  testSessionId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 인스턴트 테스팅을 위한 데이터를 저장하기 위한 입력값 */
export type TestSessionCreationInput = {
  /** 챕터 아이디 */
  chapterId: Scalars['Int'];
  /** 스튜디오에서 생성된 mockup 플레이데이터 */
  playData: Scalars['String'];
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 서드파티 앱 정보 */
export type ThirdPartyApp = {
  __typename?: 'ThirdPartyApp';
  /** 고유 아이디 */
  appId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 앱에 대한 설명 */
  description: Scalars['String'];
  /** 아이디 */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 서드파티 앱의 클라이언트 정보 */
export type ThirdPartyClient = {
  __typename?: 'ThirdPartyClient';
  /** 서드파티 앱 */
  app?: Maybe<ThirdPartyApp>;
  /** 아이디 */
  clientId: Scalars['String'];
  /** 클라이언트 시크릿 키 */
  clientSecret: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 허용되는 client redirect urls (array) */
  redirectUrls?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
};

/** 서드파티에 대한 사용자 동의 및 권한 정보 테이블 */
export type ThirdPartyConsent = {
  __typename?: 'ThirdPartyConsent';
  /** 서드파티 앱의 클라이언트 정보 */
  client: ThirdPartyClient;
  /** 서드파티 앱의 클라이언트 아이디 */
  clientId: Scalars['String'];
  /** 고유 일련번호 */
  consentId: Scalars['Int'];
  /** 생성일 (유저가 동의한 시각, refreshToken 생성시각) */
  createdAt: Scalars['DateTime'];
  /** 해당 refreshToken 으로 접속한 마지막으로 접근한 시간 */
  lastAccessedAt: Scalars['DateTime'];
  /** 리프레시 토큰 */
  refreshToken: Scalars['String'];
  /** 해당 토큰에 허용된 scope */
  scopes: Array<Scalars['String']>;
  /** 동의한 스토리플레이 사용자 정보 */
  user: User;
  /** 동의한 스토리플레이 사용자 아이디 (ref User) */
  userId: Scalars['String'];
};

/** 도전스토리 탭의 오늘의 추천작 */
export type TodaysHotUgc = {
  __typename?: 'TodaysHotUGC';
  createdAt: Scalars['DateTime'];
  /** 추천작이 적용될 시각의 dateToken(YYYY-MM-DD). TZ가 적용된 시각임. 2022-08-24 기준 모두 Asia/Seoul로 설정 */
  dateTokenTZ: Scalars['String'];
  /** 추천작의 위치(1-based) */
  order: Scalars['Int'];
  /** 추천작 */
  story: Story;
  /** 추천작 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  todaysHotUGCId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 추천작을 추가한 유저 */
  user: User;
  /** 추천작을 추가한 유저의 아이디 */
  userId: Scalars['Int'];
};

/** TodaysHotUGC 생성을 위한 input */
export type TodaysHotUgcCreationInput = {
  /** 추천작이 적용될 시각의 dateToken(YYYY-MM-DD). TZ가 적용된 시각임. 2022-08-24 기준 모두 Asia/Seoul로 설정 */
  dateTokenTZ: Scalars['String'];
  /** 추천작의 위치(1-based) */
  order: Scalars['Int'];
  /** 추천작 아이디 */
  storyId: Scalars['Int'];
};

/** 도전스토리 탭의 오늘의 추천작 */
export type TodaysHotUgcGenreStory = {
  __typename?: 'TodaysHotUGCGenreStory';
  createdAt: Scalars['DateTime'];
  /** 추천작의 위치(1-based) */
  order: Scalars['Int'];
  /** 추천작 */
  story: Story;
  /** 추천작 아이디 */
  storyId: Scalars['Int'];
  /** 아이디 */
  todaysHotUGCGenreStoryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** TodaysHotUGC 업데이트를 위한 input */
export type TodaysHotUgcUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** StorygameCountry 설정 변경을 위한 input */
export type ToggleStorygameCountry = {
  /** 노출 허용 여부 */
  allowed: Scalars['Boolean'];
  /** 수정할 국가 코드 */
  countryCode: Scalars['String'];
  /** 수정할 스토리게임 섹션 아이디 */
  sectionId: Scalars['Int'];
  /** 수정할 섹션 내 스토리게임 아이디 */
  storyId: Scalars['Int'];
};

/** StorygameLanguage 설정 변경을 위한 input */
export type ToggleStorygameLanguage = {
  /** 노출 허용 여부 */
  allowed: Scalars['Boolean'];
  /** 수정할 언어 코드 */
  languageCode: Scalars['String'];
  /** 수정할 스토리게임 섹션 아이디 */
  sectionId: Scalars['Int'];
  /** 수정할 섹션 내 스토리게임 아이디 */
  storyId: Scalars['Int'];
};

/** StorygameSectionCountry 업데이트를 위한 input */
export type ToggleStorygameSectionCountry = {
  /** 노출 허용 여부 */
  allowed: Scalars['Boolean'];
  /** 수정할 국가 코드 */
  countryCode: Scalars['String'];
  /** 수정할 스토리게임 섹션 아이디 */
  sectionId: Scalars['Int'];
};

/** mutation toggleStorygameVisibility input */
export type ToggleStorygameVisibilityInput = {
  /** 수정할 스토리게임 섹션 아이디 */
  sectionId: Scalars['Int'];
  /** 수정할 섹션 내 스토리게임 아이디 */
  storyId: Scalars['Int'];
  /** true면 노출. false면 미출 */
  visible: Scalars['Boolean'];
};

/** 토스페이먼츠 결제 */
export type TossPayments = {
  __typename?: 'TossPayments';
  createdAt: Scalars['DateTime'];
  /** 인앱코인 상품 ID InappCoin 테이블 */
  inappCoinId: Scalars['Int'];
  /** 매 결제 요청마다 서버에서 생성하는 merchant_uid */
  merchantUID: Scalars['String'];
  /** 결제 타입 OVERSEAS: 해외(해와카드사 + 페이팔) DOMESTIC: 국내(국내카드사 + 휴대폰 등등) */
  payType: Toss_Payments_Pay_Type;
  /** 토스페이먼츠 결제 결과 데이터 */
  paymentData?: Maybe<Scalars['String']>;
  /** 상품을 구분하는 고유 아이디 */
  productId: Scalars['String'];
  /** 결제 완료 후 리워드를 정상적으로 받은 시각이 기록됨. null이면 정상적으로 받지 않았거나 알 수 없음 상태(legacy) */
  rewardedAt?: Maybe<Scalars['DateTime']>;
  /** 결제 진행 상태 */
  state: Toss_Payments_Status;
  /** 아이디 */
  tossPaymentsId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 결제를 요청한 유저 */
  user: User;
  /** 결제를 요청한 유저의 아이디 */
  userId: Scalars['Int'];
};

/** TossPayments 업데이트를 위한 input */
export type TossPaymentsUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** TTS 서버의 발화자 정보 */
export type TtsSpeaker = {
  __typename?: 'TtsSpeaker';
  /** 발화자 연령대 */
  ageGroupList?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 발화자 기타 특징 */
  extraCharacter?: Maybe<Scalars['String']>;
  /** 발화자 이름 */
  name: Scalars['String'];
  /** 발화자 톤 */
  pitchList?: Maybe<Scalars['String']>;
  /** 발화자 성별 */
  sexDistinction: Sex_Distinction;
  /** 발화자 말투 */
  speechList?: Maybe<Scalars['String']>;
  /** 발화자 상태 값 */
  status: Tts_Speaker_Status;
  /** TTS 서버에서 발화자 ID */
  ttsServerSpeakerId: Scalars['Int'];
  /** 아이디 */
  ttsSpeakerId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** 도전 스토리 장르 정보 */
export type UgcStoryGenre = {
  __typename?: 'UGCStoryGenre';
  /** 인터랙티브/웹소설 필터 보여줄지 여부 true: 보여준다. false: 보여주지 않는다. */
  showFilter: Scalars['Boolean'];
  /** 앱에서 사용하는 장리 서브타이틀 */
  subTitle?: Maybe<LocalizedText>;
  /** 장르 텍스트 */
  text: LocalizedText;
  /** 엡에서 사용하는 장르 타이틀 */
  title?: Maybe<LocalizedText>;
  /** 장르 구분 타입 */
  type: Scalars['String'];
};

/** 도전 스토리 장르 정보 V3 */
export type UgcStoryGenreV3 = {
  __typename?: 'UGCStoryGenreV3';
  /** 인터랙티브/웹소설 필터 보여줄지 여부 true: 보여준다. false: 보여주지 않는다. */
  showFilter: Scalars['Boolean'];
  /** 앱에서 사용하는 장리 서브타이틀 */
  subTitle?: Maybe<LocalizeKey>;
  /** 장르 텍스트 */
  text: LocalizeKey;
  /** 엡에서 사용하는 장르 타이틀 */
  title?: Maybe<LocalizeKey>;
  /** 장르 구분 타입 */
  type: Scalars['String'];
};

/** 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌) */
export enum Ui_Story_Section_Type {
  Adult = 'Adult',
  Banner = 'Banner',
  Dropped = 'Dropped',
  Finished = 'Finished',
  Mws = 'MWS',
  Monthly = 'Monthly',
  New = 'New',
  OnStage = 'OnStage',
  Original = 'Original'
}

/** 유저기반 추천 케이스 */
export enum User_Based_Recommendation_Case {
  FirstUser = 'FirstUser',
  Home = 'Home',
  LeaveChatroom = 'LeaveChatroom'
}

/** 사용자 추천 온보딩 상택값 */
export enum User_Recommendation_Onboarding_Status {
  Done = 'Done',
  Skip = 'Skip'
}

export type UgcRandomStory = {
  __typename?: 'UgcRandomStory';
  createdAt: Scalars['DateTime'];
  /** 스토리 */
  story: Story;
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 아이디 */
  ugcRandomStoryId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 버전 */
  version: Scalars['Int'];
};

/** 어드민이 사용자의 정보 변경하는데 필요한 입력값 */
export type UpdateAuthorByAdminInput = {
  /** 작품 관련 이메일 */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** 필명 */
  authorName?: InputMaybe<Scalars['String']>;
  /** 작가 유형 */
  authorType?: InputMaybe<Array<Story_Play_Type>>;
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  /** 사용자가 내부작가인지 여부 */
  isInternalAuthor?: InputMaybe<Scalars['Boolean']>;
  /** 클라이언트 시스템 언어 코드 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: InputMaybe<Scalars['String']>;
  /** 야간푸시를 enable 할 것인가? */
  nightPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 비밀번호 변경시 사용할 기존 비밀번호 */
  oldPassword?: InputMaybe<Scalars['String']>;
  /** 앱의 OS단 푸시가 허용되어 있는가? 허용되어 있으면 true, 안되어있으면 false */
  osPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 사용자가 등록한 otp Key, 등록하지 않은 경우 null */
  otpKey?: InputMaybe<Scalars['String']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** 프로필 */
  profile?: InputMaybe<Scalars['Upload']>;
  /** 푸시를 enable 할 것인가? */
  pushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 개정된 약관에 대한 동의 여부. 2022-07-27 기준 서비스 이용 약관, 개인정보처리방침을 묶어서 관리함 */
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

/** 스튜디오 작가 권한 부여 및 작가 정보 수정에 필요한 입력값 */
export type UpdateAuthorInput = {
  /** 작품 관련 이메일 */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** 필명 */
  authorName?: InputMaybe<Scalars['String']>;
  /** 작가 유형 */
  authorType?: InputMaybe<Array<Story_Play_Type>>;
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  /** 클라이언트 시스템 언어 코드 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: InputMaybe<Scalars['String']>;
  /** 야간푸시를 enable 할 것인가? */
  nightPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 비밀번호 변경시 사용할 기존 비밀번호 */
  oldPassword?: InputMaybe<Scalars['String']>;
  /** 앱의 OS단 푸시가 허용되어 있는가? 허용되어 있으면 true, 안되어있으면 false */
  osPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 사용자가 등록한 otp Key, 등록하지 않은 경우 null */
  otpKey?: InputMaybe<Scalars['String']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['String']>;
  /** 휴대폰 번호 */
  phone?: InputMaybe<Scalars['String']>;
  /** 프로필 */
  profile?: InputMaybe<Scalars['Upload']>;
  /** 푸시를 enable 할 것인가? */
  pushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 개정된 약관에 대한 동의 여부. 2022-07-27 기준 서비스 이용 약관, 개인정보처리방침을 묶어서 관리함 */
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
};

/** 사용자가 플레이 중인 챕터에 지나간 블록 히스토리를 업데이트하는 입력값 */
export type UpdateBlockHistoryInput = {
  /** 블록명이 콤마로 구분된 문자열 */
  blockHistory: Scalars['String'];
  /** 업데이트할 UserPlayedChapter ID */
  upcId: Scalars['Int'];
};

/** UpdateChapterIndexes 입력값 */
export type UpdateChapterIndexesInput = {
  /** 어떠한 필드로 재정렬할 것인가? (ascending only) */
  field: Scalars['String'];
  /** 작품의 아이디 */
  storyId: Scalars['Int'];
};

/** 스튜디오에서 회차 가격 정보 설정에 필요한 입력값 */
export type UpdateChapterPriceForStudioInput = {
  /** 회차 아이디 */
  chapterId: Scalars['Int'];
  /** 유료 설정인 경우 코인 */
  coin?: InputMaybe<Scalars['Int']>;
  /** 가격설정 타입 */
  type: Studio_Story_Price_Setting_Type;
};

/** 선택지 값을 수정합니다. */
export type UpdateChoiceInput = {
  /** 1번 선택지 어드민 조정 값 */
  choice1AdminAdd?: InputMaybe<Scalars['Int']>;
  /** 1번 선택지 통계 속성 */
  choice1PropName?: InputMaybe<Scalars['String']>;
  /** 2번 선택지 어드민 조정 값 */
  choice2AdminAdd?: InputMaybe<Scalars['Int']>;
  /** 2번 선택지 통계 속성 */
  choice2PropName?: InputMaybe<Scalars['String']>;
  /** 3번 선택지 어드민 조정 값 */
  choice3AdminAdd?: InputMaybe<Scalars['Int']>;
  /** 3번 선택지 통계 속성 */
  choice3PropName?: InputMaybe<Scalars['String']>;
  /** 4번 선택지 어드민 조정 값 */
  choice4AdminAdd?: InputMaybe<Scalars['Int']>;
  /** 4번 선택지 통계 속성 */
  choice4PropName?: InputMaybe<Scalars['String']>;
  /** 5번 선택지 어드민 조정 값 */
  choice5AdminAdd?: InputMaybe<Scalars['Int']>;
  /** 5번 선택지 통계 속성 */
  choice5PropName?: InputMaybe<Scalars['String']>;
  /** 선택지 ID primaryKey */
  id: Scalars['Int'];
};

/** 선택 팝업 선택지 수정 */
export type UpdateOnboardingPopupChoiceInput = {
  /** 선책지 속성값 display: 사용자에게 보여지는 초기 속성값, effect: 사용자에게 보여지지 않는 초기 속성값 */
  choiceProps?: InputMaybe<Scalars['JSONObject']>;
  /** 선택지 텍스트 */
  content?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 팝업 타입이 Profile 일때는 프로필 이미지, Choice 일때는 선택지 이미지 */
  imageFile?: InputMaybe<Scalars['Upload']>;
  /** 유저 타입(playerClass) ID */
  playerClassId?: InputMaybe<Scalars['Int']>;
  /** 선택지 순서 숫자가 높으면 먼저 노출 */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 선택지 상태값 */
  status?: InputMaybe<Status>;
};

/** 선택 팝업 수정 */
export type UpdateOnboardingPopupInput = {
  /** 등장인물 AI 캐릭터로 활성화 여부 */
  canAICharacter?: InputMaybe<Scalars['Boolean']>;
  /** 주인공 이름/상대방 이름 팝업에 프로필 이미지 업로드 가능 여부 */
  canUploadProfileImage?: InputMaybe<Scalars['Boolean']>;
  /** 팝업의 지문/질문 */
  description?: InputMaybe<Scalars['String']>;
  /** 수정할 팝업의 아이디 */
  id: Scalars['Int'];
  /** 해당 팝업에 대해 최대 광고 가능 횟수 */
  maxAdCount?: InputMaybe<Scalars['Int']>;
  /** 이름 팝업 이름만 입력할지 여/부 다른 팝업은 default false */
  onlyName?: InputMaybe<Scalars['Boolean']>;
  /** 팝업에 대한 coin 비용 */
  price?: InputMaybe<Scalars['Int']>;
  /** 팝업 순서 숫자가 높으면 먼저 노출 */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** Active: 활성, Inactive: 비활성 */
  status?: InputMaybe<Status>;
  /** 온보딩 파업 노출 조건(속성 값(number)으로만 설정 가능) */
  visibleConditions?: InputMaybe<Array<OnboardingPopupVisibleConditionsInput>>;
};

/** OnboardingPopupProfileImage 업데이트를 위한 input */
export type UpdateOnboardingPopupProfileImageInput = {
  /** 프로필 이미지 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 아이디 */
  id: Scalars['Int'];
  /** 프로필 이미지 파일 */
  profileImageFile?: InputMaybe<Scalars['Upload']>;
  /** 숫자가 높으면 먼저 노출 */
  showOrder?: InputMaybe<Scalars['Int']>;
  /** 프로필 이미지 상태값 */
  status?: InputMaybe<Status>;
};

/** StoryBundleCountry 업데이트를 위한 input */
export type UpdateStoryBundleCountriesInput = {
  /** 대상 번들 아이디 */
  bundleId: Scalars['Int'];
  /** 해당 번들(작품)이 서비스 되어야하는 국가 코드 목록 */
  serviceCountryCodes: Array<Scalars['String']>;
  /** 작품 아이디. 작품 상세에서 설정을 할 때 같이 보내야 함. 스토리게임 동기화를 위해서는 반드시 필요 */
  storyId?: InputMaybe<Scalars['Int']>;
};

/** StoryPreview의 이미지나 영상을 업데이트하기 위한 input */
export type UpdateStoryPreviewFileInput = {
  /** 미리보기 파일 */
  file: Scalars['Upload'];
  /** 리소스 타입 (Image or Video) */
  fileType: Storygame_Preview_Resource_Type;
  /** 아이디 */
  id: Scalars['Int'];
};

/** 스튜디오에서 작품 가격 정보 설정에 필요한 입력값 */
export type UpdateStoryPriceSettingForStudioInput = {
  /** 작품아이디 */
  storyId: Scalars['Int'];
  /** 작품 정보 설정 (STUDIO_STORY_PRICE_OPTION stringify) */
  studioPriceSetting: Scalars['String'];
};

/** 특정날의 랭킹정보를 업데이트하기 위한 입력값 */
export type UpdateStoryRankCacheInput = {
  /** 날짜 */
  date: Scalars['String'];
  /** 특정 랭킹만 업데이트 할 것인지? */
  rankType?: InputMaybe<Story_Rank_Type>;
};

/** 스토리게임 상세정보를 수정하는데 필요한 데이터 */
export type UpdateStorygameInput = {
  /** 스토리게임 Story ID */
  id?: InputMaybe<Scalars['Int']>;
  /** 스토리게임 노출 여부 */
  isStorygameHidden?: InputMaybe<Scalars['Boolean']>;
  /** 스토리게임 가로형 표지(16:9) */
  storygameCoverImageFile?: InputMaybe<Scalars['Upload']>;
  /** 미리보기(16:9). 리스트 순으로 정렬하고 포함되어 있지 않은 아이디의 미리보기는 삭제됨 */
  storygamePreviewIds?: InputMaybe<Array<Scalars['Int']>>;
  /** 정식출시일. 추후 사전예약 마감일로 동작한다고 함 */
  storygameReleaseDate?: InputMaybe<Scalars['DateTime']>;
  /** 태그. 리스트 순으로 정렬하고 포함되어 있지 않은 아이디의 태그는 삭제됨 */
  storygameTagIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** StorygameSection 순서 변경을 위한 input */
export type UpdateStorygameSectionOrderInput = {
  /** 순서를 변경할 섹션 아이디 목록. 아이디 목록의 순서대로 나열 */
  sectionIds: Array<Scalars['Int']>;
  /** 섹션이 속한 스토리게임의 탭 아이디 */
  tabId: Scalars['Int'];
};

/** StorygameTab 순서 업데이트를 위한 input */
export type UpdateStorygameTabOrderInput = {
  /** StorygameTab 업데이트 정보 */
  tabIds: Array<Scalars['Int']>;
};

/** 한국외의 국가에 있는 유저 성인인증 input(스토어용) */
export type UpdateUserAdultCertificationForStoreGlobalInput = {
  /** 생년월일(YYYY-MM-DD) */
  birthDateToken: Scalars['String'];
};

/** 성인인증 완료 정보를 아이엠 포트 유저 아이디를 이용하여 user정보에 업데이트 해줍니다. */
export type UpdateUserAdultCertificationInput = {
  /** 성인인증 완료 사용자 정보를 알 수 있는 아이엠 포트 유저 아이디 */
  impUserId: Scalars['String'];
};

export type UpdateUserAdultCertificationResponse = {
  __typename?: 'UpdateUserAdultCertificationResponse';
  /** true: 성인 인증 됨 false: 성인 아니라 성인 인증 불가 */
  isAdult: Scalars['Boolean'];
};

/** 현재 플레이 중인 챕터에서 아이템을 업데이트 하기 위한 input */
export type UpdateUserItemsInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 업데이트 된 아이템 이름 */
  updatedItem: Scalars['String'];
  /** 업데이트 된 아이템 (JSON encoded), null 이거나 없으면 업데이트하지 않음. */
  updatedUserItems?: InputMaybe<Scalars['String']>;
};

/** 현재 플레이중 챕터에서 선택을 내린다. */
export type UpdateUserPropsInput = {
  /** Chapter ID */
  chapterId: Scalars['Int'];
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 업데이트된 Props (JSON encoded), null 이거나 없으면 업데이트하지 않음. */
  updatedUserProps?: InputMaybe<Scalars['String']>;
};

/** 유저 프로퍼티 업데이트에 대한 output */
export type UpdateUserPropsOutput = {
  __typename?: 'UpdateUserPropsOutput';
  /** 유저가 작품을 플레이하며 발견한 유저 프로퍼티 */
  discoveredProps?: Maybe<Scalars['String']>;
  ok: Scalars['Float'];
};

/** 유저가 선택한 온보딩 답변 */
export type UpdateUserRecommendationOnboardingForClientInput = {
  /** 유저가 선택한 온보딩 선택지 성별 */
  gender: Gender;
  /** 유저가 선택한 온보딩 선택지 세대 */
  generation: Scalars['Int'];
  /** 유저가 선택한 온보딩 선택지 장르리스트 */
  genres: Array<Scalars['String']>;
};

export type UpdateUserSystemLanguageInput = {
  /** 업데이트 할 유저 시스템 언어 코드 */
  languageCode: Scalars['String'];
};

/** 주인공 설정 팝업을 upsert 합니다. */
export type UpsertMainChrOnboardingPopupInput = {
  /** 온보딩 지문 */
  description?: InputMaybe<Scalars['String']>;
  /** 주인공 이름을 고정할 것인지 여부 (null 이면 작품 설정에 따라 지정됨) */
  isFixMainChrName?: InputMaybe<Scalars['Boolean']>;
  /** 이름만 인지? */
  onlyName?: InputMaybe<Scalars['Boolean']>;
  /** 작품 아이디 */
  storyId: Scalars['Int'];
};

/** 일반 사용자 */
export type User = {
  __typename?: 'User';
  activatedSkipAds?: Maybe<UserPurchasedSkipAds>;
  /** 작품 관련 이메일 연락처 */
  authorEmail?: Maybe<Scalars['String']>;
  /** 일반 사용자가 작가로 전환시 작성한 필명 */
  authorName?: Maybe<Scalars['String']>;
  /** 작가 유형 (STORY_PLAY_TYPE 의 콤마 구분 문자열) */
  authorType: Array<Story_Play_Type>;
  /** 작가 유형 (STORY_PLAY_TYPE 의 콤마 구분 문자열) */
  authorTypeRaw?: Maybe<Scalars['String']>;
  /** 작가 알림 */
  authorUpdatePushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 계정정지 사유 */
  banReason?: Maybe<Scalars['String']>;
  /** 계정정지된 시각 (해당 필드가 있으면 정지된 계정) */
  bannedAt?: Maybe<Scalars['DateTime']>;
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: Maybe<Scalars['String']>;
  /** 해당 사용자가 스프레드 시트 export 할 수 있는지 여부 */
  canExportStory?: Maybe<Scalars['Boolean']>;
  /** 성인인증 한 날짜 */
  certificatedAt?: Maybe<Scalars['DateTime']>;
  /** 댓글/대댓글 좋아요 알림 */
  commentLikePushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** IP 기반 사용자 국가코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 현재 보유한 코인의 갯수 */
  currentCoin: Scalars['Int'];
  /** 탈퇴한 사용자의 탈퇴 이유 */
  deactivateReason?: Maybe<Scalars['String']>;
  /** 해당 계정이 휴면계정이 된 일자 */
  dormantAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  /** 추천작품/이벤트 혜택 알림 */
  eventPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 유저가 처음으로 스토어를 방문한 시각으로 패키지 상품의 타임세일 기준값이 됨. 최초 한번만 업데이트가 됨. */
  firstStoreVisitedAt?: Maybe<Scalars['DateTime']>;
  /** 클라이언트 위치정보 */
  geo?: Maybe<Geo>;
  /** 사용자가 익명 사용자인가? */
  isAnonymous: Scalars['Boolean'];
  /** 개발자 여부 */
  isDev?: Maybe<Scalars['Boolean']>;
  /** 휴면 계정 여부 true: 휴면 계정 false: 휴님 계정 아님 */
  isDormantUser: Scalars['Boolean'];
  /** 성인 인증 만료 여부 true: 만료됨 -> 재인증 필요, false: 만료되지 않음 (인증 1년이내) */
  isExpiredCertification?: Maybe<Scalars['Boolean']>;
  /** 해당 사용자가 내부 작가인지 여부 */
  isInternalAuthor?: Maybe<Scalars['Boolean']>;
  /** 프리미엄 사용자인가? (현재는 구독중일때만 true) */
  isPremium: Scalars['Boolean'];
  /** 사용자가 푸시 동의를 받아야 하는 상황인가? */
  isPushInfoRequired: Scalars['Boolean'];
  /** 추천 온보딩 받아올 대상자(특정일 기준 신규 사용자) 여부 (특정일 기준 이전 가입 사용자는 대상 아님) */
  isShowOnboarding?: Maybe<Scalars['Boolean']>;
  /** 추천 온보딩 버튼 보여줄 사용자 (기존 사용자 + 마케팅 등으로 자동 skip 된 사용자) */
  isShowOnboardingButton?: Maybe<Scalars['Boolean']>;
  isShowRecommendationOnboarding: Scalars['Boolean'];
  /** 사용자가 테스터인가? */
  isTester: Scalars['Boolean'];
  /**
   * 유저가 마지막으로 접속한 지역. 업데이트 주기는 lastAccessTime 업데이트 주기와 같다
   * @deprecated 글로벌 적용으로 lastAccessCountryCode로 대체되었습니다
   */
  lastAccessCountry?: Maybe<Scalars['String']>;
  /** 마지막 접속 시각 */
  lastAccessTime: Scalars['DateTime'];
  /** 사용자가 마지막으로 접근한 타임존 */
  lastAccessTimezone?: Maybe<Scalars['String']>;
  /** 마지막 접속 날짜 토큰 (YYYY-MM-DD) */
  lastLoginToken: Scalars['String'];
  /** 마지막으로 비밀번호가 변경된 또는 "다음에 변경하기"를 누른 시각 */
  lastPasswordModifiedAt?: Maybe<Scalars['DateTime']>;
  /** 탈퇴한 경우 탈퇴시각 */
  leftAt?: Maybe<Scalars['DateTime']>;
  /** 관심 스토리 알림 */
  likeStoryPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 마케팅 푸시가 허용된 경우, 허용시각 */
  marketingPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 내가 작성한 작품들에 대한 정보 */
  myStory?: Maybe<MyStory>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: Maybe<Scalars['String']>;
  /** 대댓글 알림 */
  nestedCommentPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /**
   * 해당 유저의 동의가 필요한 스플 스토어의 약관. 없으면 null을 리턴
   * @deprecated newTermsToAcceptV3로 대체되었습니다. 대체 필수는 아닙니다.
   */
  newStoreTermsToAccept?: Maybe<NewTermsToAcceptV2>;
  /**
   * 해당 유저의 동의가 필요한 약관. 없으면 null을 리턴
   * @deprecated 국가별 동의해야 할 약관이 달라져 newTermsToAcceptV2로 대체되었습니다.
   */
  newTermsToAccept?: Maybe<NewTermsToAccept>;
  /**
   * 해당 유저의 동의가 필요한 약관. 없으면 null을 리턴
   * @deprecated newTermsToAcceptV3로 대체되었습니다. 대체 필수는 아닙니다.
   */
  newTermsToAcceptV2?: Maybe<NewTermsToAcceptV2>;
  /** 유저가 필수로 동의해야할 약관이 있는지 확인합니다. */
  newTermsToAcceptV3?: Maybe<NewTermsToAcceptV3>;
  /** 야간푸시가 허용된 경우, 허용시각 */
  nightPushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 해당 사용자가 완료한 튜토리얼 개수 */
  numCompleteTutorial: Scalars['Int'];
  /** 앱의 OS단 푸시가 허용된 경우는 true, 허용되지 않은 경우는 false, 확인되지 않았다면 null */
  osPushEnabled?: Maybe<Scalars['Boolean']>;
  /** 사용자가 등록한 otp Key, 등록하지 않은 경우 null */
  otpKey?: Maybe<Scalars['String']>;
  /** 비밀번호 변경한지가 너무 오래되었다. */
  passwordTooOld?: Maybe<Scalars['Boolean']>;
  /** 휴대폰 번호 (010-1111-2222) */
  phone?: Maybe<Scalars['String']>;
  /** 사용자 권한 레벨 */
  priv: Scalars['Int'];
  /** 프로필 파일 */
  profileFile?: Maybe<UserProfileFile>;
  /** 프로필 이미지 아이디 */
  profileId?: Maybe<Scalars['Int']>;
  /** 푸시가 허용된 경우, 허용시각 */
  pushEnabledAt?: Maybe<Scalars['DateTime']>;
  /** 익명 사용자가 일반 사용자로 변경된 시각 */
  registeredAt?: Maybe<Scalars['DateTime']>;
  /** 연동된 소셜 로그인 계정들 */
  ssoLogins: Array<Sso>;
  /**
   * (deprecated) 클라이언트 시스템 언어 정보
   * @deprecated 글로벌 적용으로 systemLanguage로 대체 되었습니다
   */
  sysLang?: Maybe<SystemLanguage>;
  /** 시스템 설정 언어 */
  systemLanguage?: Maybe<Language>;
  /** 시스템 설정 언어코드 */
  systemLanguageCode?: Maybe<Scalars['String']>;
  /** 개정된 약관 동의 시각. 2022-07-27 현재 서비스 약관, 개인정보처리방침 동의를 이 필드 하나로 관리 */
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
  /** 현재까지 소비한 코인의 총 갯수 */
  totalCoinUsed: Scalars['Int'];
  /** 읽지 않은 알림의 갯수 */
  unread: Scalars['Int'];
  /** 일반 사용자가 작가로 변경된 시각 (약관 동의 시점) */
  updateToAuthorAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
  /** 이메일 인증시, 인증된 시각 */
  verifiedAt?: Maybe<Scalars['DateTime']>;
};


/** 일반 사용자 */
export type UserNewStoreTermsToAcceptArgs = {
  now?: InputMaybe<Scalars['String']>;
};


/** 일반 사용자 */
export type UserNewTermsToAcceptArgs = {
  now?: InputMaybe<Scalars['String']>;
};


/** 일반 사용자 */
export type UserNewTermsToAcceptV2Args = {
  now?: InputMaybe<Scalars['String']>;
};


/** 일반 사용자 */
export type UserNewTermsToAcceptV3Args = {
  now?: InputMaybe<Scalars['String']>;
  serviceName: Scalars['String'];
};

/** 약관에 동의한 유저를 관리하는 엔티티 */
export type UserAcceptedTerms = {
  __typename?: 'UserAcceptedTerms';
  /** 유저가 동의한 시각 */
  acceptedAt: Scalars['DateTime'];
  /** 약관에 동의 시 유저의 접속 국가 */
  acceptedCountry: Country;
  /** 유저의 접속 국가 코드 */
  acceptedCountryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 유저가 동의한 약관 */
  terms: Terms;
  /** 유저가 동의한 약관의 아이디 */
  termsId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 약관에 동의한 유저 */
  user: User;
  /** 아이디 */
  userAcceptedTermsId: Scalars['Int'];
  /** 약관에 동의한 유저의 이이디 */
  userId: Scalars['Int'];
};

/** 유저기반 추천 작품을 관리하는 엔티티 */
export type UserBasedRecommendation = {
  __typename?: 'UserBasedRecommendation';
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  id: Scalars['Int'];
  /** 퍼스널라이즈 추천 결과 원본 데이터 (JSON string) */
  rawOutput: Scalars['String'];
  /** 추천 작품의 아이디가 콤마로 구분된 문자열 (e.g. 1,2,3) */
  recommendedStoryIds: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 추천대상 유저 */
  user: User;
  /** 추천대상 유저 아이디 */
  userId?: Maybe<Scalars['Int']>;
};

/** 유저기반 작품 추천을 위한 input */
export type UserBasedRecommendationInput = {
  /** 유저 기반 추천 케이스 */
  case: User_Based_Recommendation_Case;
};

/** 사용자 성인인증 이력 테이블 */
export type UserCertificatedAdult = {
  __typename?: 'UserCertificatedAdult';
  /** 성인인증한 날짜 */
  certificatedAt?: Maybe<Scalars['DateTime']>;
  /** 인증한 국가 정보 */
  country: Country;
  /** 인증한 국가 코드 */
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 아이디 */
  userCertificatedAdultId: Scalars['Int'];
  /** 사용자 ID */
  userId: Scalars['Int'];
};

/** UserCertificatedAdult 생성을 위한 input */
export type UserCertificatedAdultCreationInput = {
  /** 인증한 국가 코드 */
  countryCode: Scalars['String'];
};

/** UserCertificatedAdult 업데이트를 위한 input */
export type UserCertificatedAdultUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

export type UserCoinPurchaseHistory = {
  __typename?: 'UserCoinPurchaseHistory';
  /** 코인(티켓) 구매 누적 장수 */
  numCoinBought: Scalars['Int'];
  /** 코인(티켓) 구매 횟수 */
  numPurchase: Scalars['Int'];
};

/** 유저가 받은 일일 보상 코인에 대한 정보 */
export type UserDailyRewardCoin = {
  __typename?: 'UserDailyRewardCoin';
  /** 보상받은 코인 갯수 */
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 유저가 받은 일일 보상 정보 */
  dailyRewardCoin: DailyRewardCoin;
  /** 유저가 받은 일일 보상 정보의 ID */
  drcId: Scalars['Int'];
  /** 보상받은 날짜 토큰(YYYY-MM-DD) */
  rewardedToken: Scalars['String'];
  /** 아이디 */
  udrcId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 보상을 받은 유저 정보 */
  user: User;
  /** 보상을 받은 유저의 ID */
  userId: Scalars['Int'];
};

/** 유저가 작품을 플레이 하면서 발견한 유저 프로퍼티를 관리하는 엔티티 */
export type UserDiscoveredProps = {
  __typename?: 'UserDiscoveredProps';
  createdAt: Scalars['DateTime'];
  /** JSON string. 유저가 작품을 플레이하며 발견한 유저 프로퍼티 */
  discoveredProps: Scalars['String'];
  /** JSON string. 마지막으로 업데이트된 유저 프로퍼티 */
  lastUpdatedProps: Scalars['String'];
  /** 대상 작품 */
  story: Story;
  /** 대상 작품 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 대상 유저 */
  user: User;
  /** 아이디 */
  userDiscoveredPropsId: Scalars['Int'];
  /** 대상 유저 ID */
  userId: Scalars['Int'];
};

/** 푸쉬 메시지 성공 이력 */
export type UserGotPushMessage = {
  __typename?: 'UserGotPushMessage';
  /** FCM notification.body */
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** FCM data (JSON object) */
  data: Scalars['String'];
  /** 에러 메시지. null이면 푸쉬 전송 성공 */
  error?: Maybe<Scalars['String']>;
  /** FCM notification.imageUrl */
  imageUrl?: Maybe<Scalars['String']>;
  /** FCM notification.title */
  title: Scalars['String'];
  /** 아이디 */
  ugpId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 푸쉬 메시지를 받은 유저 */
  user: User;
  /** 유저의 아이디 */
  userId: Scalars['Int'];
};

/** UserGotPushMessage 생성을 위한 input */
export type UserGotPushMessageCreationInput = {
  /** FCM notification.body */
  body: Scalars['String'];
  /** FCM data (JSON object) */
  data?: InputMaybe<Scalars['String']>;
  /** 에러 메시지. null이면 푸쉬 전송 성공 */
  error?: InputMaybe<Scalars['String']>;
  /** FCM notification.imageUrl */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** FCM notification.title */
  title: Scalars['String'];
  /** 유저의 아이디 */
  userId: Scalars['Int'];
};

/** UserGotPushMessage 업데이트를 위한 input */
export type UserGotPushMessageUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 사용자가 차단한 계정 정보 */
export type UserHasBlacklist = {
  __typename?: 'UserHasBlacklist';
  /** 차단당한 사용자 */
  blockedUser: User;
  /** 차단당한 사용자 ID */
  blockedUserId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  listId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 차단한 사용자 */
  user: User;
  /** 차단한 사용자 ID */
  userId: Scalars['Int'];
};

/** 사용자가 특정 엔딩을 수집한 기록 */
export type UserHasEnding = {
  __typename?: 'UserHasEnding';
  createdAt: Scalars['DateTime'];
  /** 엔딩 설명에 템플릿이 있거나 하여 독자 엔딩설명이 있는 경우의 설명 */
  customDescription?: Maybe<Scalars['String']>;
  /** 엔딩에 템플릿이 있거나 하여 독자 엔딩명이 있는 경우의 이름 */
  customName?: Maybe<Scalars['String']>;
  /** 엔딩 */
  ending: Ending;
  /** 엔딩 Id */
  endingId: Scalars['Int'];
  /** 생성 후 읽지 않은 플레이어 리포트가 있다면 true. 생성 후 변경된 내용이 없거나 읽었다면 false. 최종/조기 엔딩이 아니면 false */
  hasNewPlayerReport: Scalars['Boolean'];
  /** 플레이어 리포트가 있다면(읽음 여부 관련 없이) true. 플레이어 리포트가 있다면(읽음 여부 관련없이) false. 최종/조기 엔딩이 아니면 false */
  hasPlayerReport: Scalars['Boolean'];
  /** 이 엔딩을 (최초로) 본 UserPlayedChapter */
  playedChapter: UserPlayedChapter;
  /** 이 엔딩을 (최초로) 본 UserPlayedChapter Id */
  playedChapterId: Scalars['Int'];
  /** 아이디 */
  uheId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 Id */
  userId: Scalars['Int'];
};

/** 웹소설 챕터 엔딩 테이블 */
export type UserHasEpubChapterEnding = {
  __typename?: 'UserHasEpubChapterEnding';
  createdAt: Scalars['DateTime'];
  /** 어떤 UPC 에서 발생되었는가? */
  playedChapter?: Maybe<UserPlayedChapter>;
  /** 어떤 UPC 에서 의해 발생되었는가? (Id) */
  playedChapterId?: Maybe<Scalars['Int']>;
  /** [웹소설] 엔딩에 도달한 시점 */
  reachedEndingAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  userHasEpubChapterEndingId: Scalars['Int'];
};

/** 유저의 FCM 토큰을 관리하는 엔티티 */
export type UserHasFcmToken = {
  __typename?: 'UserHasFCMToken';
  createdAt: Scalars['DateTime'];
  /** 토큰이 비활성화 된 날짜. null이 아니면 유효한 토큰으로 판단. 멀티 디바이스가 될 가능성을 생각해서 추가 */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  /** 모바일 기기의 OS 종류 */
  os: Os;
  /** 앱으로 부터 수집한 FCM 토큰 */
  token: Scalars['String'];
  /** 아이디 */
  uhtId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 토큰을 소유하고 있는 유저 */
  user: User;
  /** 유저 아이디 */
  userId: Scalars['Int'];
};

/** UserHasFCMToken 업데이트를 위한 input */
export type UserHasFcmTokenUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 유저의 AI 자캐 스토리게임에 대한 전반적인 메타데이터 */
export type UserHasStorygameCharacterMetadata = {
  __typename?: 'UserHasStorygameCharacterMetadata';
  createdAt: Scalars['DateTime'];
  /** json 형태의 메타데이터 */
  data: Scalars['JSONObject'];
  /** ID */
  id: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 유저 */
  user: User;
  /** 유저 ID */
  userId: Scalars['Int'];
};

export type UserLikeStoryBundle = {
  __typename?: 'UserLikeStoryBundle';
  /** 작품 번들 */
  bundle?: Maybe<StoryBundle>;
  /** 작품 번들 ID */
  bundleId?: Maybe<Scalars['Int']>;
  /** 좋아요 누른 작품 */
  clickedStory: Story;
  /** 좋아요 누른 작품 아이디 */
  clickedStoryId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** 좋아요 한 유저 */
  user: User;
  /** 좋아요 한 유저 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userLikeStoryBundleId: Scalars['Int'];
};

/** 회차별 코멘트에 대한 좋아요 기록 */
export type UserLikesChapterCommentHistory = {
  __typename?: 'UserLikesChapterCommentHistory';
  /** 댓글이 속한 회차 */
  chapter: Chapter;
  /** 댓글이 속한 회차 아이디 */
  chapterId: Scalars['Int'];
  /** 댓글 */
  comment: ChapterComment;
  /** 댓글 아이디 */
  commentId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  historyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 좋아요를 한 유저 */
  user?: Maybe<User>;
  /** 좋아요를 한 유저 아이디 */
  userId?: Maybe<Scalars['Int']>;
  /** 댓글 작성자가 탈퇴 시 생성되는 토큰으로 유저마다 유니크 한 값을 가짐 */
  userToken?: Maybe<Scalars['String']>;
};

/** 회차 댓글 좋아요 or 좋아요 취소를 위한 입력값 */
export type UserLikesChapterCommentHistoryInput = {
  /** 댓글이 속한 회차 아이디 */
  chapterId: Scalars['Int'];
  /** 댓글 아이디 */
  commentId: Scalars['Int'];
};

/** 특정 작품과 사용자(관리자,작가 등)와의 관계 */
export type UserManagingStory = {
  __typename?: 'UserManagingStory';
  createdAt: Scalars['DateTime'];
  /** IStoryUserPermissionSchema 데이터 타입을 갖는 json */
  permSchema: Scalars['String'];
  /** 해당 작품에 대한 사용자의 권한 (STORY_USER_PRIV) */
  role: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 아이디 */
  umsId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 Id */
  userId: Scalars['Int'];
};

/** UserManagingStory 항목을 생성할 때 필요한 데이터 */
export type UserManagingStoryCreationInput = {
  /** IStoryUserPermissionSchema 데이터 타입을 갖는 json */
  permSchema: Scalars['String'];
  /** 해당 작품에 대한 사용자의 권한 (STORY_USER_PRIV) */
  role: Scalars['Int'];
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 사용자 Id */
  userId: Scalars['Int'];
};

/** UserManagingStory 항목 제거에 필요한 데이터 */
export type UserManagingStoryRemoveInput = {
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 사용자 Id */
  userId: Scalars['Int'];
};

/** UserManagingStory 항목 업데이트에 필요한 데이터 */
export type UserManagingStoryUpdateInput = {
  /** IStoryUserPermissionSchema 데이터 타입을 갖는 json */
  permSchema?: InputMaybe<Scalars['String']>;
  /** 해당 작품에 대한 사용자의 권한 (STORY_USER_PRIV) */
  role?: InputMaybe<Scalars['Int']>;
  /** 작품 Id */
  storyId: Scalars['Int'];
  /** 사용자 Id */
  userId: Scalars['Int'];
};

export type UserMetric = {
  __typename?: 'UserMetric';
  /** 코인 (티켓) 구매 히스토리 */
  coinPurchaseHistory: UserCoinPurchaseHistory;
  /** 코인 (티켓) 구독 히스토리 */
  coinSubsHistory: UserSubsHistory;
  /** 온보딩 스토리 (최초 플레이 기록) */
  onBoardingStory?: Maybe<UserOnBoardingStory>;
  /** 작품에 대한 플레이 기록 */
  storiesPlayed: Array<UserStoryPlayHistory>;
  /** 알람이 설정된 작품들 (작품 ID의 배열이 반환됨) */
  storiesSetAlarm: Array<Scalars['Int']>;
};

export type UserOnBoardingStory = {
  __typename?: 'UserOnBoardingStory';
  /** 유저가 작품을 플레이했을 때의 firstName */
  firstName: Scalars['String'];
  /** 유저가 작품을 플레이했을 때의 lastName */
  lastName?: Maybe<Scalars['String']>;
  /** 스토리 ID */
  storyId: Scalars['Int'];
  /** 스토리 작품명 */
  storyName: Scalars['String'];
};

/** 유저가 특정 챕터를 플레이한 정보 */
export type UserPlayedChapter = {
  __typename?: 'UserPlayedChapter';
  /** 만약 이 챕터, 또는 이 챕터 이전의 챕터를 재시작하게 되면 해당 챕터 이후의 플레이는 archived 로 마킹된다. */
  archivedAt?: Maybe<Scalars['DateTime']>;
  /** 클라이언트에서 보내는 정보를 저장하며, 채팅에서 진행된 블록의 히스토리를 기록한다. */
  blockHistory?: Maybe<Scalars['String']>;
  /** 챕터 */
  chapter: Chapter;
  /** 챕터 Id */
  chapterId: Scalars['Int'];
  /** 챕터를 오픈하기 위해 사용한 코인 */
  coinUsed: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 현재 사용자의 아이템 (json) */
  currentItems: Scalars['String'];
  /** 현재 사용자의 속성값 (json) */
  currentProps: Scalars['String'];
  /** 마지막으로 currentProps + initProps가 migration 된 시각 */
  currentPropsMigratedAt?: Maybe<Scalars['DateTime']>;
  /** 이 챕터를 플레이하면서 종료되었을 때 엔딩 정보 */
  ending?: Maybe<Ending>;
  /** 이 챕터를 플레이하면서 종료되었을 때 엔딩 정보 Id */
  endingId?: Maybe<Scalars['Int']>;
  /** 사용자가 입력한 이름 */
  firstName: Scalars['String'];
  /** 현재 사용자의 스토리 시작 초기 속성값 (json) */
  initProps: Scalars['JSONObject'];
  /** 사용자가 입력한 성씨 */
  lastName?: Maybe<Scalars['String']>;
  /** 유저 타입(playerClass) 정보 */
  playerClass?: Maybe<PlayerClass>;
  /** 유저 타입(playerClass) ID */
  playerClassId?: Maybe<Scalars['Int']>;
  /** 사용자가 설정한 상대역 정보리스트 */
  playingCharacters?: Maybe<Array<PlayingCharacter>>;
  /** 사용자가 설정한 상대역 정보리스트 */
  playingRemoteScripts?: Maybe<Array<UserPlayedRemoteScript>>;
  /** 플레이중인 스토리 정보 (UserPlayedStory) */
  playingStory: UserPlayedStory;
  /** 플레이중인 스토리 정보 (UserPlayedStory.upsId) */
  playingStoryId: Scalars['Int'];
  /** 이 챕터를 재반복할 때마다 증가하는 버전. UserPlayedStory 의 버전과 일치하는 것이 현재 평행 세계이다. */
  playingVersion: Scalars['Int'];
  /** 스토리게임의 주인공 반전신 이미지 */
  portraitImageFile?: Maybe<File>;
  /** 프로필 이미지 파일 */
  profileImageFile?: Maybe<File>;
  /** 프로필 이미지 파일 ID */
  profileImageFileId?: Maybe<Scalars['Int']>;
  /** 현재 사용자의 스토리 시작 초기 속성값 (json) */
  rawInitProps: Scalars['String'];
  /** 이 챕터를 플레이하면서 수행한 선택들 */
  selectedChoices: Array<UserSelectedChoice>;
  /** 아이디 */
  upcId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** AI로 생성된 자캐로 플레이를 진행하고 있는지 여부 (GA 이벤트에 필요한 필드) */
  withAICustomCharacter?: Maybe<Scalars['Boolean']>;
};

export type UserPlayedRemoteScript = {
  __typename?: 'UserPlayedRemoteScript';
  createdAt: Scalars['DateTime'];
  /** 사용자가 play 하고 있는 userPlayedChapter */
  playingChapter: UserPlayedChapter;
  /** 사용자가 play 하고 있는 userPlayedChapter ID */
  playingChapterId: Scalars['Int'];
  /** 원격스크립트 ID */
  remoteScriptId: Scalars['String'];
  /** statements list json string으로 들어감 */
  statements: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  userPlayedRemoteScriptId: Scalars['Int'];
};

/** 사용자가 작품을 플레이한 데이터 */
export type UserPlayedStory = {
  __typename?: 'UserPlayedStory';
  createdAt: Scalars['DateTime'];
  /**
   * 현재 플레이 중인 챕터입니다. 항상 존재합니다.
   * 만약 1챕터 종료 후 2챕터를 진행하지 않은 상태라면, 1챕터가 currentPlayingChapter 가 됩니다.
   * 단, 이 때 currentPlayingChapter.ending 의 존재여부에 따라 해당 챕터의 종료 여부를 알 수 있습니다.
   */
  currentPlayingChapter: UserPlayedChapter;
  /** 이 플레이의 최종 엔딩 */
  ending?: Maybe<Ending>;
  /** 이 플레이의 최종 엔딩 Id */
  endingId?: Maybe<Scalars['Int']>;
  /** 이 플레이가 종료되었다면, 종료된 시각. 종료되지 않았다면 null */
  finishedAt?: Maybe<Scalars['DateTime']>;
  /** 사용자가 입력한 이름 */
  firstName: Scalars['String'];
  /** 사용자가 입력한 성씨 */
  lastName?: Maybe<Scalars['String']>;
  /** 이번 플레이에 플레이한 챕터의 정보 */
  playedChapters: Array<UserPlayedChapter>;
  /** 현재 평행세계의 버전 (챕터 리스타트가 발생할 때마다 버전이 증가하며, 스토리를 재시작할 때에도 기존 버전에서 하나씩 증가한다.) */
  playingVersion: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 Id */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  upsId: Scalars['Int'];
  /** 사용자 */
  user: User;
  /** 사용자 Id */
  userId: Scalars['Int'];
};

/** 사용자의 플레이보고서 */
export type UserPlayerReport2 = {
  __typename?: 'UserPlayerReport2';
  createdAt: Scalars['DateTime'];
  /** 보고서가 생성된 시점의 엔딩 */
  ending?: Maybe<Ending>;
  /** 보고서가 생성된 시점의 엔딩 아이디 */
  endingId?: Maybe<Scalars['Int']>;
  /** 추출된 사용자의 플레이된 데이터 (IPlayerReport2PlayData) */
  extractData: Scalars['String'];
  /** 최종 완성된 html 코드 */
  htmlCode: Scalars['String'];
  /** 플레이 보고서2 */
  playerReport: PlayerReport2;
  /** 사용자가 작품을 플레이한 version */
  playingVersion: Scalars['Int'];
  /** 플레이 보고서2 아이디 */
  prId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  uprId: Scalars['Int'];
  /** 사용자가 작품을 플레이한 데이터 아이디 */
  upsId: Scalars['Int'];
  /** 사용자가 작품을 플레이한 데이터 */
  userPlayedStory: UserPlayedStory;
};

export type UserPlayerReport2DetailInfo = {
  __typename?: 'UserPlayerReport2DetailInfo';
  /** 플레이어 보고서의 UserHasEnding 정보 */
  userHasEnding?: Maybe<UserHasEnding>;
  /** 플레이한 데이터 목록 */
  userPlayedStory: UserPlayedStory;
  /** 생성된 플레이어 보고서 목록 */
  userPlayerReport2: UserPlayerReport2;
};

/** 사용자 프로필 파일이 등록되는 테이블 */
export type UserProfileFile = {
  __typename?: 'UserProfileFile';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  encoding: Scalars['String'];
  fileId: Scalars['Int'];
  fileName: Scalars['String'];
  hashId: Scalars['String'];
  /** 파일 링크 */
  link: Scalars['String'];
  mimeType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** 사용자 프로필 정보 업데이트시 사용하는 입력값 */
export type UserPropsInput = {
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  /** 클라이언트 시스템 언어 코드 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: InputMaybe<Scalars['String']>;
  /** 야간푸시를 enable 할 것인가? */
  nightPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 비밀번호 변경시 사용할 기존 비밀번호 */
  oldPassword?: InputMaybe<Scalars['String']>;
  /** 앱의 OS단 푸시가 허용되어 있는가? 허용되어 있으면 true, 안되어있으면 false */
  osPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 사용자가 등록한 otp Key, 등록하지 않은 경우 null */
  otpKey?: InputMaybe<Scalars['String']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['String']>;
  /** 푸시를 enable 할 것인가? */
  pushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 개정된 약관에 대한 동의 여부. 2022-07-27 기준 서비스 이용 약관, 개인정보처리방침을 묶어서 관리함 */
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
};

/** 관리자에 의해 사용자 프로필 정보 업데이트시 사용하는 입력값 */
export type UserPropsInputByAdmin = {
  /** 사용자의 생년월일 (YYYY-MM-DD) */
  birthDateToken?: InputMaybe<Scalars['String']>;
  /** 스프레드 시트에 작품을 export 할 수 있는지 여부 */
  canExportStory?: InputMaybe<Scalars['Boolean']>;
  /** userId */
  id: Scalars['Int'];
  /** 테스터 여부 */
  isTester?: InputMaybe<Scalars['Boolean']>;
  /** 클라이언트 시스템 언어 코드 */
  langCode?: InputMaybe<Scalars['String']>;
  /** 사용자 이름 (nickname) - 작가의 경우 작가 이름이기도 함. */
  name?: InputMaybe<Scalars['String']>;
  /** 야간푸시를 enable 할 것인가? */
  nightPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 비밀번호 변경시 사용할 기존 비밀번호 */
  oldPassword?: InputMaybe<Scalars['String']>;
  /** 앱의 OS단 푸시가 허용되어 있는가? 허용되어 있으면 true, 안되어있으면 false */
  osPushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 사용자가 등록한 otp Key, 등록하지 않은 경우 null */
  otpKey?: InputMaybe<Scalars['String']>;
  /** 비밀번호 */
  password?: InputMaybe<Scalars['String']>;
  /** 푸시를 enable 할 것인가? */
  pushEnabled?: InputMaybe<Scalars['Boolean']>;
  /** 개정된 약관에 대한 동의 여부. 2022-07-27 기준 서비스 이용 약관, 개인정보처리방침을 묶어서 관리함 */
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
};

export type UserPurchasedChapter = {
  __typename?: 'UserPurchasedChapter';
  /** 어떤 챕터에 대한 구매 기록인가? */
  chapter: Chapter;
  /** 어떤 챕터에 대한 구매 기록인가? (Id) */
  chapterId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 아이디 */
  purchasedChapterId: Scalars['Int'];
  /** 이 구매기록이 환불되었다면, 언제인가? */
  refundedAt?: Maybe<Scalars['DateTime']>;
  /** 어떤 작품에 대한 구매 기록인가? */
  story: Story;
  /** 어떤 작품에 대한 구매 기록인가? (Id) */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 이 구매에 사용된 코인의 갯수 */
  usedAmount: Scalars['Int'];
  /** 언제 이 구매기록이 사용되었는가? */
  usedAt?: Maybe<Scalars['DateTime']>;
  /** 사용되었다면, 어떤 UPC 에 의해 사용되었는가? */
  usedChapterPlaying?: Maybe<UserPlayedChapter>;
  /** 사용되었다면, 어떤 UPC 에 의해 사용되었는가? (Id) */
  usedChapterPlayingId?: Maybe<Scalars['Int']>;
  /** 이 구매는 누가한 것인가? */
  user: User;
  /** 이 구매는 누가한 것인가? (Id) */
  userId: Scalars['Int'];
};

/** UserPurchasedChapter 생성을 위한 input */
export type UserPurchasedChapterCreationInput = {
  /** 어떤 챕터에 대한 구매 기록인가? (Id) */
  chapterId: Scalars['Int'];
  /** 어떤 작품에 대한 구매 기록인가? (Id) */
  storyId: Scalars['Int'];
  /** 이 구매에 사용된 코인의 갯수 */
  usedAmount: Scalars['Int'];
  /** 이 구매는 누가한 것인가? (Id) */
  userId: Scalars['Int'];
};

/** 유저가 구매한 코인에 대한 정보 */
export type UserPurchasedCoin = {
  __typename?: 'UserPurchasedCoin';
  createdAt: Scalars['DateTime'];
  /** 유저가 구매한 코인 정보 */
  inappCoin: InappCoin;
  /** 유저가 구매한 코인의 ID */
  inappCoinId: Scalars['Int'];
  /** 구매 ID. AOS는 orderId, iOS는 transactionId */
  purchaseId: Scalars['String'];
  /** 구매한 코인의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: Maybe<Scalars['String']>;
  /** 결제 완료 후 리워드를 정상적으로 받은 시각이 기록됨. null이면 정상적으로 받지 않았거나 알 수 없음 상태(legacy) */
  rewardedAt?: Maybe<Scalars['DateTime']>;
  /** 아이디 */
  upcId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 코인을 구매한 유저 정보 */
  user: User;
  /** 코인을 구매한 유저의 ID */
  userId: Scalars['Int'];
};

/** UserPurchasedCoin을 입력하기 위한 데이터 */
export type UserPurchasedCoinInput = {
  /** mocking을 위한 필드로 영수증 검증 무조건 통과 */
  inappProductId: Scalars['String'];
  /** 모바일 기기의 os */
  os: Os;
  /** 구매한 코인의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: InputMaybe<Scalars['String']>;
};

/** 유저가 구매한 패키지에 대한 정보 */
export type UserPurchasedPackage = {
  __typename?: 'UserPurchasedPackage';
  createdAt: Scalars['DateTime'];
  /** 유저가 구매한 패키지 정보 */
  inappPackage: InappPackage;
  /** 유저가 구매한 패키지의 아이디 */
  inappPackageId: Scalars['Int'];
  /** 구매 ID. AOS는 orderId, iOS는 transactionId */
  purchaseId: Scalars['String'];
  /** 구매한 패키지의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 패키지를 구매한 유저 정보 */
  user: User;
  /** 패키지를 구매한 유저의 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userPurchasedPackageId: Scalars['Int'];
};

/** UserPurchasedPackage 생성을 위한 input */
export type UserPurchasedPackageCreationInput = {
  /** 유저가 구매한 패키지의 아이디 */
  inappPackageId: Scalars['Int'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  purchaseId: Scalars['String'];
  /** 구매한 패키지의 인앱 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: InputMaybe<Scalars['String']>;
  /** 패키지를 구매한 유저의 아이디 */
  userId: Scalars['Int'];
};

/** UserPurchasedPackage 업데이트를 위한 input */
export type UserPurchasedPackageUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 유저가 구매한 광고 스킵 상품을 관리 */
export type UserPurchasedSkipAds = {
  __typename?: 'UserPurchasedSkipAds';
  createdAt: Scalars['DateTime'];
  /** 만료가 되거나 추가 구매로 deactivated 된 시각 */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  /** 만료가 되었나? */
  expired: Scalars['Boolean'];
  /** 구매한 광고 스킵 상품 */
  skipAds: SkipAds;
  /** 구매한 광고 스킵 상품의 아이디 */
  skipAdsId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 구매한 유저 */
  user: User;
  /** 구매한 유저 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userPurchasedSkipAdsId: Scalars['Int'];
  /** 만료 예정 시각. 구매 시 계산되어 적용 됨 */
  willExpireAt: Scalars['DateTime'];
};

/** UserPurchasedSkipAds 생성을 위한 input */
export type UserPurchasedSkipAdsCreationInput = {
  /** 구매한 광고 스킵 상품의 아이디 */
  skipAdsId: Scalars['Int'];
  /** 구매한 유저 아이디 */
  userId: Scalars['Int'];
  /** 만료 예정 시각. 구매 시 계산되어 적용 됨 */
  willExpireAt: Scalars['DateTime'];
};

/** UserPurchasedSkipAds 업데이트를 위한 input */
export type UserPurchasedSkipAdsUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 유저가 구매한 작품 */
export type UserPurchasedStory = {
  __typename?: 'UserPurchasedStory';
  createdAt: Scalars['DateTime'];
  /** 구매한 작품 정보 */
  story: Story;
  /** 구매한 작품의 ID */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 이 구매에 사용된 코인의 갯수 */
  usedAmount: Scalars['Int'];
  /** 구매한 유저 */
  user: User;
  /** 구매한 유저 ID */
  userId: Scalars['Int'];
  /** 아이디 */
  userPurchasedStoryId: Scalars['Int'];
};

/** UserPurchasedStory 업데이트를 위한 input */
export type UserPurchasedStoryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 사용자 온보딩 진행여부 데이터 */
export type UserRecommendationOnboarding = {
  __typename?: 'UserRecommendationOnboarding';
  createdAt: Scalars['DateTime'];
  /** 온보딩 데이터 */
  data: Scalars['String'];
  /** 상태값 (default) skip: 넘김, done: 완료 */
  status: User_Recommendation_Onboarding_Status;
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 ID */
  userId: Scalars['Int'];
  /** 아이디 */
  userRecommendationOnboardingId: Scalars['Int'];
};

/** UserRecommendationOnboarding 생성을 위한 input */
export type UserRecommendationOnboardingCreationInput = {
  /** 상태값 (default) skip: 넘김, done: 완료 */
  status: User_Recommendation_Onboarding_Status;
};

/** UserRecommendationOnboarding 업데이트를 위한 input */
export type UserRecommendationOnboardingUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 유저가 신고한 도전 스토리 */
export type UserReportChallengeStory = {
  __typename?: 'UserReportChallengeStory';
  createdAt: Scalars['DateTime'];
  /** 기타 신고 사유 */
  customReason?: Maybe<Scalars['String']>;
  /** 신고 사유 */
  reason: ChallengeStoryReportingReason;
  /** 신고 사유 아이디 */
  reasonId: Scalars['Int'];
  /** 작품을 신고한 유저 */
  reporter?: Maybe<User>;
  /** 작품을 신고한 유저의 아이디 */
  reporterId?: Maybe<Scalars['Int']>;
  /** 신고 당한 작품 */
  story: Story;
  /** 신고 당한 작품의 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  userReportChallengeStoryId: Scalars['Int'];
  /** 댓글 작성자가 탈퇴 시 생성되는 토큰으로 유저마다 유니크 한 값을 가짐 */
  userToken?: Maybe<Scalars['String']>;
};

/** UserReportChallengeStory 생성을 위한 input */
export type UserReportChallengeStoryCreationInput = {
  /** 기타 신고 사유 */
  customReason?: InputMaybe<Scalars['String']>;
  /** 신고 사유 아이디 */
  reasonId: Scalars['Int'];
  /** 신고 당한 작품의 아이디 */
  storyId: Scalars['Int'];
};

/** UserReportChallengeStory 업데이트를 위한 input */
export type UserReportChallengeStoryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** 유저가 신고한 회차 코멘트 */
export type UserReportChapterComment = {
  __typename?: 'UserReportChapterComment';
  /** 신고 당한 댓글 */
  comment: ChapterComment;
  /** 신고 당한 댓글의 아이디 */
  commentId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 기타 신고 사유 */
  customReason?: Maybe<Scalars['String']>;
  /** 댓글 신고 사유 */
  reason: ChapterCommentReportingReason;
  /** 댓글 신고 사유 아이디 */
  reasonId: Scalars['Int'];
  /** 댓글을 신고한 유저 */
  reporter?: Maybe<User>;
  /** 댓글을 신고한 유저의 아이디 */
  reporterId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  urccId: Scalars['Int'];
  /** 댓글 작성자가 탈퇴 시 생성되는 토큰으로 유저마다 유니크 한 값을 가짐 */
  userToken?: Maybe<Scalars['String']>;
};

/** 회차 댓글 신고를 위한 입력값 */
export type UserReportChapterCommentCreationInput = {
  /** 신고 당한 댓글의 아이디 */
  commentId: Scalars['Int'];
  /** 기타 신고 사유 */
  customReason?: InputMaybe<Scalars['String']>;
  /** 댓글 신고 사유 아이디 */
  reasonId: Scalars['Int'];
};

/** 유저가 받은 보상 코인 */
export type UserRewardedAttendanceCoin = {
  __typename?: 'UserRewardedAttendanceCoin';
  /** 보상받은 코인 정보 */
  coin: AttendanceCoin;
  /** 보상받은 코인의 아이디 */
  coinId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** 보상받은 날짜 토큰(YYYY-MM-DD) */
  rewardedDateToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 보상받은 유저 */
  user: User;
  /** 보상받은 유저의 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userRewardedAttendanceCoinId: Scalars['Int'];
};

/** UserRewardedAttendanceCoin 생성을 위한 input */
export type UserRewardedAttendanceCoinCreationInput = {
  /** 보상받은 코인의 아이디 */
  coinId: Scalars['Int'];
  /** 보상받은 날짜 토큰(YYYY-MM-DD) */
  rewardedDateToken: Scalars['String'];
  /** 보상받은 유저의 아이디 */
  userId: Scalars['Int'];
};

/** UserRewardedAttendanceCoin 업데이트를 위한 input */
export type UserRewardedAttendanceCoinUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

/** user_selected_choice의 파티셔닝 테이블에 대한 entity */
export type UserSelectedChoice = {
  __typename?: 'UserSelectedChoice';
  /** 선택 */
  choice: Choice;
  /** 선택 Id */
  choiceId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** [자연어 입력 선택지] 상대방 텍스트 */
  leftUserInputText?: Maybe<Scalars['String']>;
  /** 이 선택을 수행한 UserPlayedChapter */
  playedChapter: UserPlayedChapter;
  /** 이 선택을 수행한 UserPlayedChapter Id */
  playedChapterId: Scalars['Int'];
  /** [자연어 입력 선택지] 주인공 사용자가 입력한 텍스트 */
  rightUserInputText?: Maybe<Scalars['String']>;
  /** 선택한 분기 (1-based) */
  selectedChoice: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  uscId: Scalars['Int'];
};

/** 특정 작품 - 특정 사용자의 통계 */
export type UserStatOnStory = {
  __typename?: 'UserStatOnStory';
  createdAt: Scalars['DateTime'];
  /** 플레이한 챕터 (시작 기준) */
  numChapter: Scalars['Int'];
  /** 이 작품에서 사용한 코인의 수 */
  numCoinSpent: Scalars['Int'];
  /** 이 작품에서 맞이한 엔딩의 수 (not unique, 중복 가능) */
  numEnding: Scalars['Int'];
  /** 처음부터 플레이한 횟수 */
  numPlay: Scalars['Int'];
  /** 이 작품에서 선택한 횟수 */
  numSelect: Scalars['Int'];
  /** 작품 */
  story: Story;
  /** 작품 Id */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 */
  user: User;
  /** 사용자 Id */
  userId: Scalars['Int'];
  /** 아이디 */
  usosId: Scalars['Int'];
};

/** 유저가 읽은 작품 기록 */
export type UserStoryHistory = {
  __typename?: 'UserStoryHistory';
  createdAt: Scalars['DateTime'];
  /** 해당 기록 삭제 여부 (soft delete) */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** 유저가 읽은 작품 */
  story: Story;
  /** 유저가 읽은 작품의 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 작품을 읽은 유저 */
  user: User;
  /** 작품을 읽은 유저의 아이디 */
  userId: Scalars['Int'];
  /** 아이디 */
  userStoryHistoryId: Scalars['Int'];
};

/** UserStoryHistory 생성을 위한 input */
export type UserStoryHistoryCreationInput = {
  /** 유저가 읽은 작품의 아이디 */
  storyId: Scalars['Int'];
};

/** UserStoryHistory 업데이트를 위한 input */
export type UserStoryHistoryUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

export type UserStoryPlayHistory = {
  __typename?: 'UserStoryPlayHistory';
  /** 사용자가 기스무로 기다리는 중인가? */
  isWaitingFree: Scalars['Boolean'];
  /** 유저가 플레이한 적이 있는 작품의 ID */
  storyId: Scalars['Int'];
  /** 유저가 플레이한 적이 있는 작품의 이름 */
  storyName: Scalars['String'];
};

/** 유저가 구매한 정액권에 대한 정보 */
export type UserSubsCoin = {
  __typename?: 'UserSubsCoin';
  createdAt: Scalars['DateTime'];
  /** 정액권 만료 여부 */
  expired: Scalars['Boolean'];
  /** 정액권이 만료된 날짜 */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** 정액권의 구독 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: Maybe<Scalars['String']>;
  /** 유저가 구매한 정액권 정보 */
  subsCoin: SubsCoin;
  /** 유저가 구매한 정액권 ID */
  subsCoinId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 아이디 */
  uscId: Scalars['Int'];
  /** 구매한 유저 정보 */
  user: User;
  /** 구매한 유저의 ID */
  userId: Scalars['Int'];
  /** 정액권 만료 예정 날짜 */
  willExpireAt: Scalars['DateTime'];
};

/** 유저가 구매한 정액권을 추가 하는데 필요한 데이터 */
export type UserSubsCoinInput = {
  /** 모바일 기기의 os */
  os: Os;
  /** 정액권의 구독 결제 영수증 */
  receipt: Scalars['String'];
  /** 인앱 결제 영수증에 대한 signature. 안드로이드에서는 필수 */
  receiptSignature?: InputMaybe<Scalars['String']>;
  /** mocking을 위한 필드로 영수증 검증 무조건 통과 */
  subsProductId: Scalars['String'];
};

export type UserSubsHistory = {
  __typename?: 'UserSubsHistory';
  /** 현재 구독중인가? */
  isSubscribing: Scalars['Boolean'];
  /** 가장 마지막 구독 유지 기간. 아예 없는 경우 null */
  lastSubDuration?: Maybe<Scalars['Int']>;
  /** 구독 시작을 했던 횟수 */
  numSubStarted: Scalars['Int'];
};

/** 사용자가 작품에 대하여 갖는 알림 설정 */
export type UserSubsStory = {
  __typename?: 'UserSubsStory';
  createdAt: Scalars['DateTime'];
  /** 작품 아이디 */
  storyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** 사용자 아이디 */
  userId: Scalars['Int'];
};

export type WeeklyStoryRanking = {
  __typename?: 'WeeklyStoryRanking';
  amountChange: Scalars['Int'];
  isNew: Scalars['Boolean'];
  playType?: Maybe<Scalars['String']>;
  ranking: Scalars['Int'];
  story: Story;
  storyId: Scalars['Int'];
};

/** 이번 주 급상승 작품 캐시데이터를 관리하는 entity */
export type WeeklyStoryRankingCache = {
  __typename?: 'WeeklyStoryRankingCache';
  createdAt: Scalars['DateTime'];
  /** 캐싱키 값 */
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 캐싱된 값 (JSON string) */
  value: Scalars['String'];
  /** 캐싱 버전 */
  version: Scalars['Int'];
  /** 아이디 */
  weeklyStoryRankingCacheId: Scalars['Int'];
};

/** WeeklyStoryRankingCache 업데이트를 위한 input */
export type WeeklyStoryRankingCacheUpdateInput = {
  /** 아이디 */
  id: Scalars['Int'];
};

export type XsollaPaymentToken = {
  __typename?: 'XsollaPaymentToken';
  /** xsolla payment token */
  token: Scalars['String'];
};

/** 공모전 참여 번호 선택 현황을 반환합니다. */
export type ListableSelectedNumber = {
  __typename?: 'listableSelectedNumber';
  /** 공모전 참가 번호 현황 */
  list: Array<SelectedNumber>;
  /** page index (1-based) */
  page: Scalars['Int'];
  /** page size */
  pageSize: Scalars['Int'];
  /** Total count */
  totalCount: Scalars['Int'];
};
