import AppIcon from './icon_app.svg';
import BellIcon from './icon_bell.svg';
import CheckIcon from './icon_check.svg';
import CheckboxOffBtn from './btn_checkbox_off.svg';
import CheckboxOnBtn from './btn_checkbox_on.svg';
import ClosePopupBtn from './btn_close_popup.svg';
import CommentBtn from './btn_comment.svg';
import ContentsPauseBtn from './btn_contents_pause.svg';
import ContentsPlayBtn from './btn_contents_play.svg';
import DeleteBtn from './btn_delete.svg';
import DownBtn from './btn_down.svg';
import EndingLockIcon from './icon_ending_lock.svg';
import FirstNextBtn from './btn_first_next.svg';
import FirstNextDisableBtn from './btn_first_next_disable.svg';
import FloatingNextBtn from './btn_floating_next.svg';
import FloatingNextDisableBtn from './btn_floating_next_disable.svg';
import FloatingPrevBtn from './btn_floating_prev.svg';
import FloatingPrevDisableBtn from './btn_floating_prev_disable.svg';
import IfBlockIcon from './icon_ifblock.svg';
import LikeOffIcon from './icon_like_off.svg';
import LikeOnIcon from './icon_like_on.svg';
import LinkBtn from './btn_link.svg';
import LockIcon from './icon_lock.svg';
import LogoAppleIcon from './icon_logo_apple.svg';
import LogoFacebookIcon from './icon_logo_facebook.svg';
import LogoGoogleIcon from './icon_logo_google.svg';
import LogoInstagramIcon from './icon_logo_instagram.svg';
import LogoNaverIcon from './icon_logo_naver.svg';
import LogoTwitterIcon from './icon_logo_twitter.svg';
import LogotypeColorImg from './img_logotype_color.svg';
import LogotypeMainIcon from './icon_logotype_main.svg';
import MusicIcon from './icon_music.svg';
import NavBackBtn from './btn_nav_back.svg';
import NavLanguageBtn from './btn_nav_language.svg';
import NavCloseBtn from './btn_nav_close.svg';
import NavCommentBtn from './btn_nav_comment.svg';
import NavHomeBtn from './btn_nav_home.svg';
import NavMenuBtn from './btn_nav_menu.svg';
import NavShareBtn from './btn_nav_share.svg';
import NextBtn from './btn_next.svg';
import PauseBtn from './btn_pause.svg';
import PyramidIcon1 from './icon_pyramid_1.svg';
import PyramidIcon2 from './icon_pyramid_2.svg';
import PyramidIcon3 from './icon_pyramid_3.svg';
import PyramidIcon4 from './icon_pyramid_4.svg';
import PyramidStoryGameIcon1 from './icon_pyramid_storygame_1.svg';
import PyramidStoryGameIcon2 from './icon_pyramid_storygame_2.svg';
import PyramidStoryGameIcon3 from './icon_pyramid_storygame_3.svg';
import PyramidStoryGameIcon4 from './icon_pyramid_storygame_4.svg';
import RadioOffBtn from './btn_radio_off.svg';
import RadioOnBtn from './btn_radio_on.svg';
import RetryBtn from './btn_retry.svg';
import SearchNotFoundImg from './img_search_notfound.svg';
import StatBtn from './btn_stat.svg';
import ToastEntryIcon from './icon_toast_entry.svg';
import UpBtn from './btn_up.svg';
import ValueCheckIcon from './icon_value_check.svg';
import VerificationImg from './img_verification.svg';
import VisibilityBtn from './btn_visibility.svg';
import VisibilityOffBtn from './btn_visibility_off.svg';
import GoogleplayIcon from './icon_googleplay.svg';
import AppstoreIcon from './icon_appstore.svg';
import AutoPlayIcon from './icon_auto_play.svg';
import AppleAccountIcon from './icon_account_apple.svg';
import CheckStatisticsIcon from './icon_check_statistics.svg';
import CheckStatisticsIconBlack from './icon_check_with_ellipse_black.svg';
import VariousEnding from './img-various_ending.svg';
import VerificationDoneImg from './img-verification-done.svg';
import VerificationCancelImg from './img-verification-cancel.svg';
import BottomAppIcon from './icon_bottom_app.svg';
import BottomWebIcon from './icon_bottom_web.svg';
import AlertSmallIcon from './icon_alert_small.svg';
import FloatingCloseIcon from './icon_floating_close.svg';
import CoinColorIcon from './icon_coin_color.svg';
import SaleIcon from './icon_sale.svg';
import AutoRenewIcon from './icon_autorenew.svg';
import HourGlassIcon from './icon_hourglass.svg';
import TimeIcon from './icon_time.svg';
import TextFieldUploadIcon from './icon_textfield_upload.svg';
import TextFieldUploadOnIcon from './icon_textfield_upload_on.svg';
import AlertSmallRedIcon from './icon_alert_s.svg';
import MusicOffBtn from './btn_music_off.svg';
import MusicOnBtn from './btn_music_on.svg';
import SoundEffectOffBtn from './btn_sound_effect_off.svg';
import SoundEffectOnBtn from './btn_sound_effect_on.svg';
import AdultIcon from './icon_adult.svg';
import Adult18Icon from './icon_adult18.svg';
import ImageUploadIcon from './icon_img_upload.svg';
import UploadImageIcon from './icon_upload_img.svg';
import UploadEditImageIcon from './icon_upload_edit_img.svg';
import ProfileCropIcon from './icon_profile_crop.svg';
import ProfileRoundIcon from './icon_profile_round.svg';
import ProfileAddIcon from './icon_profile_add.svg';
import SoundTTSOffBtn from './btn_tts_off.svg';
import SoundTTSOnBtn from './btn_tts_on.svg';
import ChatPlayTTSIcon from './icon_chat_play_tts.svg';
import ArrowIcon from './icon_arrow.svg';
import ThingsflowIcon from './icon_thingsflow.svg';
import ChosunIcon from './icon_chosun.svg';
import BamboIcon from './icon_bambo.svg';
import SbaIcon from './icon_sba.svg';
import ContestPrevBtn from './btn_contest_prev.svg';
import ContestNextBtn from './btn_contest_next.svg';
import CrownIcon from './icon_crown.svg';
import ArrowDownIcon from './icon_arrow_down.svg';
import ChapterLogoIcon from './icon_chapter_logo.svg';
import ChapterBtn from './btn_chapter.svg';
import CoinLargeIcon from './icon_coin_large.svg';
import ListArrowNextLightIcon from './icon_list_arrow_next_light.svg';
import MenuBookIcon from './icon_menu_book.svg';
import MenuCommentIcon from './icon_menu_comment.svg';
import MenuLikeIcon from './icon_menu_like.svg';
import MenuStoryIcon from './icon_menu_story.svg';
import MenuViewIcon from './icon_menu_view.svg';
import SearchLightIcon from './icon_search_light.svg';
import AlarmIcon from './icon_alarm.svg';
import ChallengeWriteIcon from './icon_challenge_write.svg';
import NotificationPlaceholderImage from './img_notification_placeholder.svg';
import AlarmDeleteIcon from './icon_alarm_delete.svg';
import AlarmNoneImage from './img_alarm_none.svg';
import CoinIcon from './icon_coin_header.svg';
import Coin1Icon from './icon_coin_1.svg';
import Coin2Icon from './icon_coin_2.svg';
import Coin3Icon from './icon_coin_3.svg';
import CommentNoneImage from './img_comment_none.svg';
import LikeOffLargeIcon from './icon_like_off_large.svg';
import LikeOnLargeIcon from './icon_like_on_large.svg';
import CheckWithEllipseIcon from './icon_check_with_ellipse.svg';
import LogoNaverBlogIcon from './logo_naver_blog_icon.svg';
import LogoTiktokIcon from './logo_tiktok_icon.svg';
import LogoYoutubeIcon from './logo_youtube_icon.svg';
import FaceBookIcon from './icon_facebook.svg';
import MBTI19Logo from './logo_mbti19.svg';
import ModalCloseIcon from './icon_modal_close.svg';
import NavPreIcon from './icon_nav_pre.svg';
import CommentIcon from './icon_comment.svg';
import NavFilterIcon from './icon_nav_filter.svg';
import ListMoreIcon from './icon_list_more.svg';
import TextFieldCheckOnIcon from './icon_textfield_check_on.svg';
import TextFieldCheckOffIcon from './icon_textfield_check_off.svg';
import ReCommentLineIcon from './icon_recomment_line.svg';
import FindUserId01Image from './img-find-userid1.svg';
import FindUserId02Image from './img-find-userid2.svg';
import QuestionIcon from './icon-question.svg';
import TextFieldCloseIcon from './icon_textfield_close.svg';
import WeekdayEmptyStoryIcon from './icon_weekday_empty_story.svg';
import HomeTabTitleUpIcon from './icon_home_tab_title_up.svg';
import HomeTabTitlePauseIcon from './icon_home_tab_title_pause.svg';
import CheckBottomSheet from './icon_check_bottomsheet.svg';
import StoryTitlePlaceHolderImage from './img_story_title_placeholder.svg';
import TextSettingIcon from './icon_text_setting.svg';
import RankUpIcon from './icon_rank_up.svg';
import RankDownIcon from './icon_rank_down.svg';
import BookIcon from './icon_book.svg';
import PopupXIcon from './icon_popup_x.svg';
import StoryPlayLogoIcon from './icon_storyplay_logo.svg';
import IconDot from './icon_dot.svg';
import CongratsImage from './img_congrats.png';
import IconSearch from './icon_search.svg';
import AdultFillLabelIcon from './icon_adult_fill_label.svg';
import AdultLabelIcon from './icon_adult_label.svg';
import LogoTypeIcon from './img_logotype.svg';
import MoreLabelIcon from './icon_label_more.svg';
import IconPropsPlaceholder from './icon_props_placeholder.png';
import ModalCloseBtn from './btn_modal_close.svg';
import StorygameMenuIcon from './icon_storygame_menu.svg';
import StorygameMobileMenuIcon from './icon_storygame_mobile_menu.svg';
import StorygameMenuBackIcon from './icon_storygame_menu_back.svg';
import StorygameMenuPlayIcon from './icon_storygame_menu_play.svg';
import StorygameMenuPauseIcon from './icon_storygame_menu_pause.svg';
import StorygameMenuSkipIcon from './icon_storygame_menu_skip.svg';
import StorygameMenuCommentIcon from './icon_storygame_comment.svg';
import StorygameMenuLogIcon from './icon_storygame_menu_log.svg';
import StorygameMenuPropsIcon from './icon_storygame_menu_props.svg';
import StorygameMenuBgmPopupIcon from './icon_storygame_menu_bgm.svg';
import StorygameMenuAccordion from './icon_storygame_menu_accordion.svg';
import StorygameMenuBgmIcon from './icon_storygame_bgm_background.svg';
import StorygameMenuVibrationIcon from './icon_storygame_bgm_vibration.svg';
import StorygameMenuEffectIcon from './icon_storygame_bgm_effect.svg';
import StorygameMenuTTSIcon from './icon_storygame_bgm_tts.svg';
import StorygameAchivementIcon from './icon_storygame_achivement.svg';
import StorygameEndingIcon from './icon_storygame_ending.svg';
import StorygameItemIcon from './icon_storygame_item.svg';
import StorygameMyStatusIcon from './icon_storygame_my_status.svg';
import StorygameStatusProgress0Icon from './icon_storygame_status_progress_0.svg';
import StorygameStatusProgress1Icon from './icon_storygame_status_progress_1.svg';
import StorygameStatusProgress2Icon from './icon_storygame_status_progress_2.svg';
import StorygameStatusProgress3Icon from './icon_storygame_status_progress_3.svg';
import StorygameStatusProgress4Icon from './icon_storygame_status_progress_4.svg';
import StorygameStatusProgress5Icon from './icon_storygame_status_progress_5.svg';
import StorygameStatusProgress6Icon from './icon_storygame_status_progress_6.svg';
import StorygameStatusProgress7Icon from './icon_storygame_status_progress_7.svg';
import StorygameStatusProgress8Icon from './icon_storygame_status_progress_8.svg';
import StorygameStatusProgress9Icon from './icon_storygame_status_progress_9.svg';
import StorygameStatusProgress10Icon from './icon_storygame_status_progress_10.svg';
import StorygameGuideMenuIcon from './icon_storygame_guide_menu.svg';
import StorygameEndingCloseIcon from './icon_storygame_ending_close.svg';
import StorygameEndingLogIcon from './icon_storygame_ending_log.svg';
import StorygameEndingPropsIcon from './icon_storygame_ending_props.svg';
import StorygameShareIcon from './icon_storygame_share.svg';
import StorygameLikeOffIcon from './icon_storygame_like_off.svg';
import StorygameLikeOnIcon from './icon_storygame_like_on.svg';
import StorygameLandscapeIcon from './icon_storygame_landscape.svg';
import StorygameSoundIcon from './icon_storygame_sound.svg';
import StorygamePlaySpeed1XIcon from './icon_storygame_play_speed_1x.svg';
import StorygamePlaySpeed2XIcon from './icon_storygame_play_speed_2x.svg';
import StorygamePlaySpeed3XIcon from './icon_storygame_play_speed_3x.svg';
import PropertyLockIcon from './icon_property_lock.svg';
import IconPropsPlaceholderSmall from './icon_props_placeholder_small.svg';
import LinkWhiteIcon from './icon_link_white.svg';
import LinkNewTabIcon from './icon_link_new_tab.svg';
import CloseLightIcon from './icon_close_light.svg';
import ArrowWhiteIcon from './icon_arrow_white.svg';
import ChoiceFloatingNextBtn from './btn_choice_floating_next.svg';
import ChoiceFloatingNextDisableBtn from './btn_choice_floating_next_disable.svg';
import ChoiceFloatingPrevBtn from './btn_choice_floating_prev.svg';
import ChoiceFloatingPrevDisableBtn from './btn_choice_floating_prev_disable.svg';
import SendInputTextBtn from './btn_send_input_text.svg';
import SendInputTextDisableBtn from './btn_send_input_text_disable.svg';
import CommentFilterIcon from './icon_comment_filter.svg';
import CheckboxOffSmallIcon from './icon_checkbox_off_small.svg';
import CheckboxOnSmallIcon from './icon_checkbox_on_small.svg';
import ArrowCircleLeftIcon from './icon_arrow_circle_left.svg';
import ArrowCircleRightIcon from './icon_arrow_circle_right.svg';
import StorygamePlayIcon from './icon_storygame_play.svg';
import StorygamePlayCircleIcon from './icon_storygame_play_circle.svg';
import ReviewIcon from './icon_review.svg';
import LanguageIcon from './icon_language.svg';
import ArrowRightIcon from './icon_arrow_right.svg';
import FlagIcon from './icon_flag.svg';
import IconStatistics from './icon_statistics.svg';
import IconStatisticsComment from './icon_statistics_comment.svg';
import IconBack from './icon_back.svg';
import ArrowRight24Icon from './icon_24_arrow_right.svg';
import RetryIcon from './icon_retry.svg';
import TicketRightArrowIcon from './icon_ticket_right_arrow.svg';
import StoryPlayLogo2Icon from './storyplay_logo_2.svg';
import Icon24Lock from './icon_24_lock.svg';
import IconLockOpenFinished from './icon_lock_open_finished.svg';
import IconArrowCircleDownBorder from './icon_arrow_circle_down_border.svg';

import SSOUncheckLine from './btn_sso_uncheck_line.svg';
import SSOUncheckBox from './btn_sso_uncheck_box.svg';
import SSOCheckLine from './btn_sso_checked_line.svg';
import SSOCheckBox from './btn_sso_checked_box.svg';
import LogoReels from './img_logo_reels.svg';

import MagicWand from './icon_magic_wand.svg';
import UploadCheck from './upload_check.svg';
import UploadWrong from './upload_wrong.svg';
import UploadInfo from './icon_upload_Info.svg';
import FilterIconStorygame from './icon_filter_storygame.svg';
import RefreshIcon from './icon_refresh.svg';
import CloseIconStoryGame from './icon_close_storygame.svg';
import GalleryStorygame from './icon_gallery_storygame.svg';
import ProfileEditIcon from './icn_image_edit.svg';
import StoryGameArrowRight from './icn_storygame_arrow_right.svg';
import StoryGameArrowLeft from './icn_storygame_arrow_left.svg';
import StoryGamePromotionImg from './storygame_promotion_img.png';
import StoryGamePromotionImg2 from './storygame_promotion_img2.png';
import StoryGamePromotionImg3 from './storygame_promotion_img3.png';
import StoryGameProfileEdit from './icn_storygame_profile_edit.svg';
import StoryGameImage1 from './storygame_image1.png';
import StoryGameImage2 from './storygame_image2.png';
import StoryGameNameEdit from './icon_name_edit.svg';
import StoryGameCropIcon from './icon_crop.svg';
import StorygameAutorenewIcon from './icon-storygame-autorenew.svg';
import StorygameHourglassIcon from './icon-storygame-hourglass.svg';
import StorygameSaleIcon from './icon-storygame-sale.svg';
import StorygameTimeIcon from './icon-storygame-time.svg';
import StorygameAutorenewIconMobile from './icon-storygame-autorenew-mobile.svg';
import StorygameHourglassIconMobile from './icon-storygame-hourglass-mobile.svg';
import StorygameSaleIconMobile from './icon-storygame-sale-mobile.svg';
import StorygameTimeIconMobile from './icon-storygame-time-mobile.svg';

import IconCoinPlus from './icon_coin_plus.svg';
import IconProfileCoin from './icon_profile_coin.svg';
import IconProfilePlaying from './icon_profile_playing.svg';
import IconProfileHistory from './icon_profile_history.svg';
import IconProfileHeart from './icon_profile_heart.svg';
import IconProfileMenuArrow from './icon_profile_menu_arrow.svg';

import ImgLogoStoryReels from './img_logo_story_reels.svg';
import ImgReelsUserIdGuideMobile from './img_reels_user_id_guide_mobile.png';
import ImgReelsUserIdGuidePC from './img_reels_user_id_guide_pc.png';
import IcStep1 from './ic_step_1.svg';
import IcStep2 from './ic_step_2.svg';
import IcHint from './ic_hint.svg';
import ImgReelsAlarm from './img_reels_alarm.png';
import ImgBannerGooglePlay from './img_banner_google_play.svg';
import ImgBannerAppStore from './img_banner_app_store.svg';

export {
  // Icon
  StorygameAutorenewIconMobile,
  StorygameHourglassIconMobile,
  StorygameSaleIconMobile,
  StorygameTimeIconMobile,
  StorygameAutorenewIcon,
  StorygameHourglassIcon,
  StorygameSaleIcon,
  StorygameTimeIcon,
  IconBack,
  PyramidStoryGameIcon1,
  PyramidStoryGameIcon2,
  PyramidStoryGameIcon3,
  PyramidStoryGameIcon4,
  ReviewIcon,
  StorygamePlayCircleIcon,
  StorygamePlayIcon,
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  CheckboxOffSmallIcon,
  CheckboxOnSmallIcon,
  CommentFilterIcon,
  ArrowWhiteIcon,
  CloseLightIcon,
  LinkWhiteIcon,
  LinkNewTabIcon,
  StorygameEndingPropsIcon,
  StorygameEndingLogIcon,
  StorygameEndingCloseIcon,
  StorygameGuideMenuIcon,
  StorygameMenuBgmPopupIcon,
  StorygameMenuEffectIcon,
  StorygameMenuTTSIcon,
  StorygameMenuVibrationIcon,
  StorygameAchivementIcon,
  StorygameEndingIcon,
  StorygameItemIcon,
  StorygameMyStatusIcon,
  IconPropsPlaceholderSmall,
  StorygameStatusProgress0Icon,
  StorygameStatusProgress1Icon,
  StorygameStatusProgress2Icon,
  StorygameStatusProgress3Icon,
  StorygameStatusProgress4Icon,
  StorygameStatusProgress5Icon,
  StorygameStatusProgress6Icon,
  StorygameStatusProgress7Icon,
  StorygameStatusProgress8Icon,
  StorygameStatusProgress9Icon,
  StorygameStatusProgress10Icon,
  PropertyLockIcon,
  StorygameMenuAccordion,
  StorygameMenuBgmIcon,
  StorygameMenuCommentIcon,
  StorygameMenuLogIcon,
  StorygameMenuPropsIcon,
  StorygameMenuIcon,
  StorygameMobileMenuIcon,
  StorygameMenuBackIcon,
  StorygameMenuPlayIcon,
  StorygameMenuPauseIcon,
  StorygameMenuSkipIcon,
  StorygameShareIcon,
  StorygameLikeOffIcon,
  StorygameLikeOnIcon,
  StorygameLandscapeIcon,
  StorygameSoundIcon,
  StorygamePlaySpeed1XIcon,
  StorygamePlaySpeed2XIcon,
  StorygamePlaySpeed3XIcon,
  MoreLabelIcon,
  LogoTypeIcon,
  AdultFillLabelIcon,
  AdultLabelIcon,
  StoryPlayLogoIcon,
  IconDot,
  IconSearch,
  RankUpIcon,
  RankDownIcon,
  TextSettingIcon,
  TextFieldCloseIcon,
  TextFieldCheckOnIcon,
  TextFieldCheckOffIcon,
  ListMoreIcon,
  NavFilterIcon,
  CommentIcon,
  NavPreIcon,
  CheckWithEllipseIcon,
  LikeOffLargeIcon,
  LikeOnLargeIcon,
  CoinIcon,
  Coin1Icon,
  Coin2Icon,
  Coin3Icon,
  ArrowDownIcon,
  CrownIcon,
  AlarmDeleteIcon,
  AlarmIcon,
  ChallengeWriteIcon,
  SearchLightIcon,
  MenuViewIcon,
  MenuStoryIcon,
  MenuLikeIcon,
  MenuCommentIcon,
  MenuBookIcon,
  ListArrowNextLightIcon,
  CoinLargeIcon,
  AppIcon,
  GoogleplayIcon,
  AppstoreIcon,
  AutoPlayIcon,
  BellIcon,
  CheckIcon,
  CheckStatisticsIcon,
  CheckStatisticsIconBlack,
  EndingLockIcon,
  IfBlockIcon,
  LikeOffIcon,
  LikeOnIcon,
  LockIcon,
  LogoAppleIcon,
  LogoFacebookIcon,
  LogoGoogleIcon,
  LogoInstagramIcon,
  LogoNaverIcon,
  LogoTwitterIcon,
  LogotypeMainIcon,
  MusicIcon,
  PyramidIcon1,
  PyramidIcon2,
  PyramidIcon3,
  PyramidIcon4,
  ToastEntryIcon,
  ValueCheckIcon,
  AppleAccountIcon,
  BottomAppIcon,
  BottomWebIcon,
  AlertSmallIcon,
  FloatingCloseIcon,
  TextFieldUploadIcon,
  TextFieldUploadOnIcon,
  AlertSmallRedIcon,
  CoinColorIcon,
  SaleIcon,
  AutoRenewIcon,
  HourGlassIcon,
  TimeIcon,
  ImageUploadIcon,
  UploadImageIcon,
  UploadEditImageIcon,
  ProfileCropIcon,
  ProfileRoundIcon,
  ProfileAddIcon,
  ChapterLogoIcon,
  ChatPlayTTSIcon,
  ModalCloseIcon,
  ReCommentLineIcon,
  QuestionIcon,
  ArrowRight24Icon,
  RetryIcon,
  TicketRightArrowIcon,
  CheckBottomSheet,
  StoryPlayLogo2Icon,
  Icon24Lock,
  IconLockOpenFinished,
  IconArrowCircleDownBorder,
  // Button
  CheckboxOffBtn,
  CheckboxOnBtn,
  ClosePopupBtn,
  CommentBtn,
  ContentsPauseBtn,
  ContentsPlayBtn,
  DeleteBtn,
  DownBtn,
  FirstNextBtn,
  FirstNextDisableBtn,
  FloatingNextBtn,
  FloatingNextDisableBtn,
  FloatingPrevBtn,
  FloatingPrevDisableBtn,
  LinkBtn,
  NavBackBtn,
  NavLanguageBtn,
  NavCloseBtn,
  NavCommentBtn,
  NavHomeBtn,
  NavMenuBtn,
  NavShareBtn,
  NextBtn,
  PauseBtn,
  RadioOffBtn,
  RadioOnBtn,
  RetryBtn,
  StatBtn,
  UpBtn,
  VisibilityBtn,
  VisibilityOffBtn,
  MusicOffBtn,
  MusicOnBtn,
  SoundEffectOffBtn,
  SoundEffectOnBtn,
  SoundTTSOffBtn,
  SoundTTSOnBtn,
  ChapterBtn,
  FaceBookIcon,
  WeekdayEmptyStoryIcon,
  HomeTabTitleUpIcon,
  HomeTabTitlePauseIcon,
  IconPropsPlaceholder,
  //logo
  MBTI19Logo,
  // image
  StoryTitlePlaceHolderImage,
  CommentNoneImage,
  AlarmNoneImage,
  NotificationPlaceholderImage,
  LogotypeColorImg,
  SearchNotFoundImg,
  VerificationImg,
  VariousEnding,
  VerificationDoneImg,
  VerificationCancelImg,
  AdultIcon,
  Adult18Icon,
  ArrowIcon,
  ThingsflowIcon,
  ChosunIcon,
  BamboIcon,
  SbaIcon,
  ContestPrevBtn,
  ContestNextBtn,
  LogoNaverBlogIcon,
  LogoTiktokIcon,
  LogoYoutubeIcon,
  FindUserId01Image,
  FindUserId02Image,
  BookIcon,
  PopupXIcon,
  CongratsImage,
  ModalCloseBtn,
  ChoiceFloatingPrevBtn,
  ChoiceFloatingPrevDisableBtn,
  ChoiceFloatingNextBtn,
  ChoiceFloatingNextDisableBtn,
  SendInputTextBtn,
  SendInputTextDisableBtn,
  LanguageIcon,
  ArrowRightIcon,
  FlagIcon,
  IconStatistics,
  IconStatisticsComment,
  SSOUncheckLine,
  SSOUncheckBox,
  SSOCheckLine,
  SSOCheckBox,
  LogoReels,
  MagicWand,
  UploadCheck,
  UploadWrong,
  UploadInfo,
  FilterIconStorygame,
  RefreshIcon,
  CloseIconStoryGame,
  GalleryStorygame,
  ProfileEditIcon,
  StoryGameArrowRight,
  StoryGameArrowLeft,
  StoryGamePromotionImg,
  StoryGamePromotionImg2,
  StoryGamePromotionImg3,
  StoryGameProfileEdit,
  StoryGameImage1,
  StoryGameImage2,
  StoryGameNameEdit,
  StoryGameCropIcon,
  IconCoinPlus,
  IconProfileCoin,
  IconProfilePlaying,
  IconProfileHistory,
  IconProfileHeart,
  IconProfileMenuArrow,
  ImgLogoStoryReels,
  ImgReelsUserIdGuideMobile,
  ImgReelsUserIdGuidePC,
  IcStep1,
  IcStep2,
  IcHint,
  ImgReelsAlarm,
  ImgBannerGooglePlay,
  ImgBannerAppStore,
};

const emptyImage = require('./img_empty.png');

export { emptyImage };
