import React, { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';
import { sha256 } from 'js-sha256';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '@api/user';
import { RootState } from '@store/store';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  checkDevice,
  isInApp,
  isMobileDevice,
  setUserProperties,
  setUserToken,
} from '@common/utils';

import Alert from '@components/commons/alert/Alert';
import SPHead from '@components/commons/head/SPHead';
import Navbar from '@components/commons/navbar/Navbar';
import SPFooter from '@components/commons/footer/SPFooter';
import { setCurrentPath } from '@/slices/eventSlice';
import { useInitUserSystemLanguageMutation } from '@/operations/queries/user/__generated__/useInitUserSystemLanguage.generated';
import { useGetRefreshCountryTokenMutation } from '@/operations/queries/user/__generated__/useGetRefreshCountryToken.generated';
import { STORAGE_KEY } from '@/common/values';
import TermsPopup from '../commons/popup/TermsPopup';
import {
  useGetUserQuery,
  TermsDtoFragment,
} from '@/operations/queries/user/__generated__/getUser.generated';
import { Toast } from '../commons';
import { setToastMessage } from '@/slices/alertSlice';
import { Story_Play_Type } from '@/baseType';
import useTranslation from 'next-translate/useTranslation';
import { setCommonReactLang } from '@/lib/common-react/utils/translate';

interface ILayout {
  children: ReactElement;
  playType?: Story_Play_Type;
}

const Layout = ({ children, playType }: ILayout) => {
  const router = useRouter();
  const { lang } = useTranslation();
  const dispatch = useAppDispatch();
  const [newTermsToAccept, setNewTermsToAccept] =
    useState<TermsDtoFragment | null>(null);

  const [showBottomNav, setShowBottomNav] = useState<boolean>(false);
  const [showFooter, setShowFooter] = useState<boolean>(false);

  const alertMessage = useAppSelector(
    (state: RootState) => state.alert.message,
    shallowEqual,
  );
  const toastMessage = useAppSelector(
    (state: RootState) => state.alert.toastMessage,
    shallowEqual,
  );

  const { data, error: getUserError } = useGetUserQuery({
    fetchPolicy: 'network-only',
    skip: router.pathname.includes('webview'),
    onCompleted: (data) => {
      setNewTermsToAccept(data.getUser?.newTermsToAcceptV2 ?? null);
    },
  });

  const [refreshToken] = useGetRefreshCountryTokenMutation({
    onCompleted: (res) => {
      setUserToken({
        token: res.refreshToken?.token,
      });

      initUserSystemLanguage({
        variables: {
          languageCode:
            router.locale === 'ko'
              ? 'ko_KR'
              : router.locale === 'en'
              ? 'en_US'
              : 'es_ES', // router.locale ?? '',
        },
      });
    },
  });

  const [initUserSystemLanguage] = useInitUserSystemLanguageMutation({
    onCompleted: (res) => {
      setUserProperties({
        registeredAt: res.initUserSystemLanguage.registeredAt,
        isTester: res.initUserSystemLanguage.isTester,
        userId: res.initUserSystemLanguage.userId,
        ...(!res.initUserSystemLanguage.email.includes('anonymous') && {
          hashedEmail: sha256(res.initUserSystemLanguage.email),
        }),
        ...(!res.initUserSystemLanguage.email.includes('anonymous') && {
          email: res.initUserSystemLanguage.email,
        }),
        language: res.initUserSystemLanguage.systemLanguage?.code,
        countryCode: res.initUserSystemLanguage.countryCode ?? '',
        isAdult:
          res.initUserSystemLanguage.certificatedAt &&
          !res.initUserSystemLanguage.isExpiredCertification,
        isAnonymous: res.initUserSystemLanguage.isAnonymous,
      });
    },
  });

  const [updateUserSystemLanguage, { error: updateUserError }] = useMutation(
    UPDATE_USER,
    {
      refetchQueries: 'active',
    },
  );

  useEffect(() => {
    // 공통 UI 컴포넌트 다국어 세팅
    setCommonReactLang(lang === 'ko' ? 'ko' : 'en');
  }, [lang]);

  useEffect(() => {
    dispatch(setCurrentPath(router.asPath));
  }, [router.asPath]);

  useEffect(() => {
    if (!data?.getUser) return;

    const userInfo = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);

    if (userInfo) return;

    if (data.getUser.isAnonymous) {
      refreshToken();
    } else {
      setUserProperties({
        registeredAt: data.getUser.registeredAt,
        isTester: data.getUser.isTester,
        userId: data.getUser.userId,
        ...(!data.getUser.email.includes('anonymous') && {
          hashedEmail: sha256(data.getUser.email),
        }),
        ...(!data.getUser.email.includes('anonymous') && {
          email: data.getUser.email,
        }),
        language: data.getUser.systemLanguage?.code,
        countryCode: data.getUser.countryCode ?? '',
        isAdult:
          data.getUser.certificatedAt && !data.getUser.isExpiredCertification,
        isAnonymous: data.getUser.isAnonymous,
      });
    }
  }, [data, router.pathname]);

  useEffect(() => {
    if (!data?.getUser) return;

    // 사용자의 언어설정이 되어있지 않거나, 사용자의 언어설정과 페이지의 언어 설정이 다르면 페이지의 locale값으로 언어를 설정합니다.
    if (
      data.getUser.systemLanguage === null ||
      !data.getUser.systemLanguage?.code.includes(router.locale || '')
    ) {
      updateUserSystemLanguage({
        variables: { languageCode: router.locale === 'ko' ? 'ko_KR' : 'en_US' },
      });
    }
  }, [data, router.locale]);

  useEffect(() => {
    // 안드로이드 기기이고, 인앱 브라우저에서 접근 시 크롬 브라우저로 열기
    if (checkDevice() === 'ANDROID' && isInApp()) {
      location.href =
        'intent://' +
        location.href.replace(/https?:\/\//i, '') +
        '#Intent;scheme=http;package=com.android.chrome;end';
    }

    setShowBottomNav(
      !isMobileDevice() &&
        !router.route.startsWith('/chatstory') &&
        !router.route.startsWith('/appdownload') &&
        !router.route.startsWith('/auth') &&
        !router.route.startsWith('/privacy') &&
        !router.route.startsWith('/terms') &&
        !router.route.startsWith('/story') &&
        !router.route.startsWith('/notification') &&
        !router.route.startsWith('/share') &&
        !router.route.startsWith('/payment') &&
        !router.route.startsWith('/blame') &&
        !router.route.startsWith('/comment') &&
        !router.route.startsWith('/notices') &&
        !router.route.startsWith('/ugc-guideline') &&
        !router.route.startsWith('/adult') &&
        !router.route.startsWith('/novelstory') &&
        !router.route.startsWith('/morestory') &&
        !router.route.startsWith('/contest') &&
        !router.route.startsWith('/search') &&
        !router.route.startsWith('/storygame') &&
        !router.route.startsWith('/sso') &&
        !router.route.startsWith('/404'),
    );

    setShowFooter(
      !isMobileDevice() &&
        !router.route.startsWith('/chatstory') &&
        !router.route.startsWith('/appdownload') &&
        !router.route.startsWith('/auth') &&
        !router.route.startsWith('/privacy') &&
        !router.route.startsWith('/terms') &&
        !router.route.startsWith('/notification') &&
        !router.route.startsWith('/contest') &&
        !router.route.startsWith('/payment') &&
        !router.route.startsWith('/novelstory') &&
        !router.route.startsWith('/storygame'),
    );
  }, [router.route]);

  if (getUserError || updateUserError) {
    if (!router.asPath.includes('404')) {
      router.push('/404');
    }
  }

  return (
    <>
      <SPHead
        userId={data?.getUser?.userId}
        userEmail={data?.getUser?.email}
        userAgent={
          typeof window !== 'undefined' &&
          typeof window.navigator !== 'undefined' &&
          navigator.userAgent
        }
        userCountry={data?.getUser?.countryCode}
        isAdult={router.pathname.includes('adult')}
      />
      {newTermsToAccept ? (
        <TermsPopup
          isShow
          onClose={() => setNewTermsToAccept(null)}
          data={newTermsToAccept}
        />
      ) : (
        <div
          css={container({
            isStoryGamePage: router.route.startsWith('/storygame'),
            isStoryGameDetailPage:
              !router.route.startsWith('/storygame') &&
              playType === Story_Play_Type.Storygame,
          })}
        >
          {children}
          {showFooter && <SPFooter playType={playType} />}
          {showBottomNav && (
            <Navbar isDarkTheme={playType === Story_Play_Type.Storygame} />
          )}
        </div>
      )}
      {alertMessage && <Alert />}
      {toastMessage && (
        <div css={toastWrapper}>
          <Toast
            message={toastMessage}
            onClear={() => dispatch(setToastMessage(null))}
          />
        </div>
      )}
    </>
  );
};

const toastWrapper = css`
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 6em;
  z-index: 10;
`;

const container = ({
  isStoryGamePage,
  isStoryGameDetailPage,
}: {
  isStoryGamePage?: boolean;
  isStoryGameDetailPage?: boolean;
}) => css`
  height: 100%;
  ${!isStoryGamePage &&
  `
  display: flex;
  flex-direction: column;
  align-items: center;
  `}
  ${isStoryGameDetailPage && `padding-bottom: 4.5rem;`}
`;

export default Layout;
