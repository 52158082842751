import { gql } from '@apollo/client';
import { USER_DTO } from './fragment/user';

export const REGISTER_ANONYMOUS_USER = gql`
  mutation ResisterAnonymousUser {
    registerAnonymousUser {
      token
      me {
        userId
        email
        name
      }
    }
  }
`;

export const CHECK_EMAIL = gql`
  query CheckEmail($email: String!) {
    checkEmail(data: { email: $email }) {
      emailExists
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation ResendEmailVerification {
    resendEmailVerification {
      ok
    }
  }
`;

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(data: { email: $email, password: $password }) {
      token
      me {
        ssoLogins {
          ssoProvider
        }
        isAnonymous
        isTester
        isPremium
        userId
        email
        birthDateToken
        name
        currentCoin
        priv
        createdAt
        updatedAt
        verifiedAt
        totalCoinUsed
        isPushInfoRequired
        nightPushEnabledAt
        marketingPushEnabledAt
        pushEnabledAt
        lastLoginToken
        registeredAt
        systemLanguageCode
        isExpiredCertification
        certificatedAt
        countryCode
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    getUser {
      userId
      name
      isAnonymous
      email
      verifiedAt
      currentCoin
      updateToAuthorAt
      authorName
      priv
      isInternalAuthor
      ssoLogins {
        sId
        userId
        ssoProvider
        ssoId
        profile
      }
      isExpiredCertification
      certificatedAt
      birthDateToken
      myStory {
        stat {
          numStory
          numViews
          numChapter
          numViews
          numLikes
        }
      }
      registeredAt
      isTester
      geo {
        country
        timezone
        region
      }
      countryCode
      systemLanguage {
        code
        display
      }
    }
  }
`;

export const GET_USER_TERM = gql`
  query getUser($serviceName: String!) {
    getUser {
      userId
      name
      isAnonymous
      newTermsToAcceptV3(serviceName: $serviceName) {
        CollectionAndUseOfPrivacy {
          termsId
          link
        }
        PP {
          termsId
          link
        }
        TOS {
          termsId
          link
        }
      }
    }
  }
`;

export const GET_USER_LOGIN_STATUS = gql`
  query getUserLoginStatus {
    getUser {
      isAnonymous
    }
  }
`;

export const FIND_PASSWORD_EMAIL = gql`
  mutation FindPasswordEmail($email: String!) {
    sendResetLinkWithEmail(data: { email: $email }) {
      ok
    }
  }
`;

export const LOGIN_WITH_SSO = gql`
  mutation LoginWithSSO($ssoProvider: SSO_PROVIDER!, $token: String!) {
    loginUserWithSSO(
      data: { ssoProvider: $ssoProvider, ssoId: "", ssoPayload: $token }
    ) {
      me {
        ...UserDto
      }
      token
    }
  }
  ${USER_DTO}
`;

export const SIGNUP_WITH_SSO = gql`
  mutation SignUpWithSso(
    $ssoProvider: SSO_PROVIDER!
    $token: String!
    $birthDateToken: String!
  ) {
    linkUserWithSSO(
      data: {
        ssoProvider: $ssoProvider
        ssoId: ""
        ssoPayload: $token
        birthDateToken: $birthDateToken
      }
    ) {
      me {
        ...UserDto
      }
      token
    }
  }
  ${USER_DTO}
`;

export const CONVERT_USER_WITH_EMAIL = gql`
  mutation ConvertUserWithEmail(
    $email: String!
    $password: String!
    $name: String!
    $birthDateToken: String!
    $marketingPushEnabled: Boolean!
    $tosId: Int!
    $collectionAndUseOfPrivacyId: Int!
  ) {
    convertUserWithEmailV2(
      data: {
        email: $email
        password: $password
        name: $name
        birthDateToken: $birthDateToken
        marketingPushEnabled: $marketingPushEnabled
        termsToAccept: {
          tosId: $tosId
          collectionAndUseOfPrivacyId: $collectionAndUseOfPrivacyId
        }
      }
    ) {
      me {
        ...UserDto
      }
      token
    }
  }
  ${USER_DTO}
`;

export const GET_USER_ONBOARDING_STORY = gql`
  query GetUserOnboardingStory {
    getUserMetric {
      onBoardingStory {
        storyId
      }
    }
  }
`;

export const CHANGE_NAME = gql`
  mutation ChangeName($name: String!) {
    updateUser(data: { name: $name }) {
      name
    }
  }
`;

export const LEAVE_SERVICE = gql`
  mutation LeaveService($reason: String!) {
    leaveService(data: { reason: $reason }) {
      ok
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $password: String!) {
    updateUser(data: { oldPassword: $oldPassword, password: $password }) {
      updatedAt
    }
  }
`;

export const ADULT_CERTIFICATION = gql`
  mutation AdultCertification($impUserId: String!) {
    updateUserAdultCertification(data: { impUserId: $impUserId }) {
      isAdult
    }
  }
`;

export const ADULT_CERTIFICATION_FOR_GLOBAL = gql`
  mutation AdultCertificationForGlobal {
    updateUserAdultCertificationForGlobal {
      ok
    }
  }
`;

// languageCode ex. AppConfig.systemLanguages 중 하나로 업데이트가 되어야 합니다
export const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguage($languageCode: String!) {
    updateUserSystemLanguage(data: { languageCode: $languageCode }) {
      systemLanguageCode
    }
  }
`;

export const GET_APP_CONFIG = gql`
  query GetAppConfig {
    appConfig {
      systemLanguages {
        code
        display
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserSystemLanguage($languageCode: String!) {
    updateUserSystemLanguage(data: { languageCode: $languageCode }) {
      systemLanguage {
        code
        display
      }
    }
  }
`;

export const SP_USER_SSO_REFRESH_TOKEN_FOR_OAUTH = gql`
  mutation CreateRefreshTokenForOAuth(
    $redirectUrl: String!
    $clientId: String!
    $scopes: [String!]!
  ) {
    createRefreshTokenForOAuth(
      data: { redirectUrl: $redirectUrl, clientId: $clientId, scopes: $scopes }
    ) {
      refreshToken
    }
  }
`;

export const ACCEPT_STORE_TERMS = gql`
  mutation acceptTermsV2(
    $tosId: Int!
    $ppId: Int
    $collectionAndUseOfPrivacyId: Int
    $serviceName: TERMS_SERVICE_NAME!
  ) {
    acceptTermsV2(
      data: {
        tosId: $tosId
        ppId: $ppId
        collectionAndUseOfPrivacyId: $collectionAndUseOfPrivacyId
        serviceName: $serviceName
      }
    ) {
      ok
    }
  }
`;
