import React, { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import setLanguage from 'next-translate/setLanguage';
import { useQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { ArrowDownIcon } from 'public/assets';
import theme from '@styles/theme';
import { GET_APP_CONFIG, UPDATE_USER, GET_USER } from '@api/user';
import { GetAppConfig, GetUser } from '@customTypes/user';
import { setUserProperties } from '@/common/utils';
import { STORAGE_KEY } from '@/common/values';
import { ListStoryForEpubContestWinnerDocument } from '@/operations/queries/home/__generated__/useUGCContestWinnerStoryList.generated';

declare interface MouseEvent extends Event {
  target: (HTMLDivElement & EventTarget) | null;
}

const localeTexts: Record<string, string> = {
  ko: '한국어',
  en: 'English',
  es: 'Español',
};

const LanguageDropDown = ({
  isDarkTheme = false,
}: {
  isDarkTheme?: boolean;
}) => {
  const router = useRouter();
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const dropdownList = useRef<HTMLUListElement | null>(null);

  const { data: appConfigData } = useQuery<GetAppConfig>(GET_APP_CONFIG, {
    fetchPolicy: 'network-only',
    skip:
      typeof window !== 'undefined' &&
      router.pathname.includes('webview') &&
      !sessionStorage.getItem(STORAGE_KEY.APP_INFO_FOR_WEBVIEW),
  });

  const [updateUserSystemLanguage] = useMutation(UPDATE_USER, {
    update: (cache, { data }) => {
      const newLangCode = data.updateUserSystemLanguage.systemLanguage.code;
      const existingUserData = cache.readQuery<GetUser>({ query: GET_USER });

      if (existingUserData) {
        cache.writeQuery({
          query: GET_USER,
          data: {
            getUser: {
              ...existingUserData.getUser,
              systemLanguage: {
                code: newLangCode,
                ...existingUserData.getUser.systemLanguage,
              },
            },
          },
        });
      }
    },
    // 시스템 언어 변경 후 "웹소설 공모전 수상작" 섹션 데이터를 다시 가져온다.
    refetchQueries: [{ query: ListStoryForEpubContestWinnerDocument }],
  });

  const handleItemClick = async (e: any, selectedLocale: string) => {
    if (selectedLocale.includes(router.locale ?? '')) return;

    e.stopPropagation();
    setIsListOpen(false);
    const storageInfo = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
    const parsedInfo = storageInfo && JSON.parse(storageInfo);

    setUserProperties({
      ...parsedInfo,
      registeredAt: parsedInfo.sign_up_at,
      isTester: parsedInfo.is_tester,
      userId: parsedInfo.user_id,
      language: selectedLocale,
      isAnonymous: parsedInfo.is_anonymous,
    });
    await updateUserSystemLanguage({
      variables: { languageCode: selectedLocale },
    });
    await setLanguage(selectedLocale === 'ko_KR' ? 'ko' : 'en');
    await setLanguage(
      selectedLocale === 'ko_KR'
        ? 'ko'
        : selectedLocale === 'en_US'
        ? 'en'
        : 'es',
    );
  };

  const handleOutsideClick: any = (e: MouseEvent) => {
    const isHeaderClicked = e.target?.closest('.dropdown-header');

    // 드롭다운 헤더 외부 영역이 클릭된 경우, 드롭다운 메뉴를 닫는다
    if (isHeaderClicked && !isListOpen) {
      setIsListOpen(true);
      return;
    }

    setIsListOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isListOpen]);

  return (
    <div
      css={wrapper({
        isListOpen,
        isShowBottomPadding: router.pathname.includes('store'),
        isDarkTheme,
      })}
    >
      <div className="dropdown-header">
        <p className="title">{localeTexts[router.locale ?? 'ko']}</p>
        <ArrowDownIcon
          fill={theme.colors.gray600}
          className="arrow-icon"
          width={24}
          height={24}
        />
      </div>
      <ul className="dropdown-list" ref={dropdownList}>
        {appConfigData?.appConfig.systemLanguages?.map((item, index) => (
          <li
            key={`${item}_${index}`}
            className={`dropdown-item ${
              item.code === router.locale && 'active'
            }`}
            onClick={(e) => handleItemClick(e, item.code)}
          >
            {item.display}
          </li>
        ))}
      </ul>
    </div>
  );
};

const wrapper = ({
  isListOpen,
  isShowBottomPadding,
  isDarkTheme,
}: {
  isListOpen: boolean;
  isShowBottomPadding: boolean;
  isDarkTheme: boolean;
}) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  position: relative;
  ${isShowBottomPadding && `padding-bottom: 3.75rem;`};

  .dropdown-header {
    width: 7.5rem;
    height: 2.5rem;
    ${!isDarkTheme && `background: ${theme.colors.white};`}
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0.625rem 0 1rem;
    justify-content: space-between;
    ${isDarkTheme &&
    `
      border-radius: 0.5rem;
      border: 0.0625rem solid rgba(255, 255, 255, 0.25);
    `}

    .title {
      color: ${isDarkTheme
        ? 'rgba(255, 255, 255, 0.75)'
        : theme.colors.gray600};
      font-size: 0.875rem;
    }

    .arrow-icon {
      transition: transform 0.2s ease-in;
      transform: ${isListOpen ? 'rotate(-180deg)' : 'rotate(0deg)'};
    }
  }

  .dropdown-list {
    position: absolute;
    bottom: 2.75rem;
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 0.25rem;
    border-radius: 0.5rem;
    visibility: ${isListOpen ? 'visible' : 'hidden'};
    background: ${isDarkTheme ? '#1a1a1a' : theme.colors.white};
    border-radius: 0.5rem;
    box-shadow: 0rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.12),
      0rem 0.25rem 0.875rem rgba(0, 0, 0, 0.12);
    transition: all 0.1s ease-in;
    opacity: ${isListOpen ? 1 : 0.6};
    transform: ${isListOpen ? 'translateY(0)' : 'translateY(0.3125rem)'};

    .dropdown-item {
      display: flex;
      align-items: center;
      list-style: none;
      width: 7.5rem;
      height: 2.5rem;
      padding: 0.625rem 0.75rem;
      cursor: pointer;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      ${isDarkTheme && `color: rgba(255, 255, 255, 0.75)`}
      ${!isDarkTheme && `background: ${theme.colors.white};`}
    }

    .dropdown-item.active {
      background: ${theme.colors.gray100};
    }
  }
`;

export default LanguageDropDown;
